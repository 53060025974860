import Image from 'next/image';
import React from 'react';

import SectionHeader from '@Components/core/section-header';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { WHAT_TO_AVOID } from '@Utils/constants';
import { useTrans } from '@Utils/hooks/translation';

const WhatToAvoid = () => {
  const { t, languageUsed } = useTrans('ax');
  return (
    <main>
      <SectionHeader title={t('you-should-avoid')} />
      <section className='flex flex-col items-center justify-center gap-8 px-6 rounded-xl'>
        <div className='flex justify-around gap-6 lg:px-6'>
          {WHAT_TO_AVOID?.map((avoiding) => (
            <div key={avoiding?.title} className='flex flex-col gap-3 items-center py-6'>
              <Image src={avoiding?.icon} width={32} height={32} alt={avoiding?.title} />
              <Text
                font={FontType.SEMIBOLD_14_150}
                color={ColorType.COOL_GREY_600}
                language={languageUsed}
                className='text-center'>
                {t(avoiding?.title)}
              </Text>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default WhatToAvoid;
