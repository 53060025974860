import { ISlotConfig } from './slot.interface';

import { TimePeriod } from '.';
export enum Visibility {
  SHOW = 'show',
  HIDDEN = 'hidden',
  FALLBACK = 'fallback',
}

export const SlotConfig: ISlotConfig = {
  0: {
    //current day
    '0-12': {
      visibility: {
        [TimePeriod.Instant]: Visibility.SHOW,
        [TimePeriod.Morning]: Visibility.SHOW,
        [TimePeriod.Afternoon]: Visibility.SHOW,
        [TimePeriod.Evening]: Visibility.SHOW,
      },
      count: {
        [TimePeriod.Instant]: 5,
        [TimePeriod.Morning]: 10,
        [TimePeriod.Afternoon]: 10,
        [TimePeriod.Evening]: 10,
      },
    },
    '13-17': {
      visibility: {
        [TimePeriod.Instant]: Visibility.SHOW,
        [TimePeriod.Morning]: Visibility.HIDDEN,
        [TimePeriod.Afternoon]: Visibility.SHOW,
        [TimePeriod.Evening]: Visibility.SHOW,
      },
      count: {
        [TimePeriod.Instant]: 5,
        [TimePeriod.Morning]: 0,
        [TimePeriod.Afternoon]: 10,
        [TimePeriod.Evening]: 10,
      },
    },
    '18-21': {
      visibility: {
        [TimePeriod.Instant]: Visibility.SHOW,
        [TimePeriod.Morning]: Visibility.HIDDEN,
        [TimePeriod.Afternoon]: Visibility.HIDDEN,
        [TimePeriod.Evening]: Visibility.SHOW,
      },
      count: {
        [TimePeriod.Instant]: 5,
        [TimePeriod.Morning]: 0,
        [TimePeriod.Afternoon]: 0,
        [TimePeriod.Evening]: 10,
      },
    },
    '22-23': {
      visibility: {
        [TimePeriod.Instant]: Visibility.HIDDEN,
        [TimePeriod.Morning]: Visibility.HIDDEN,
        [TimePeriod.Afternoon]: Visibility.HIDDEN,
        [TimePeriod.Evening]: Visibility.HIDDEN,
      },
      count: {
        [TimePeriod.Instant]: 0,
        [TimePeriod.Morning]: 0,
        [TimePeriod.Afternoon]: 0,
        [TimePeriod.Evening]: 0,
      },
    },
  },
  1: {
    //current day +1
    '0-12': {
      visibility: {
        [TimePeriod.Instant]: Visibility.HIDDEN,
        [TimePeriod.Morning]: Visibility.FALLBACK,
        [TimePeriod.Afternoon]: Visibility.HIDDEN,
        [TimePeriod.Evening]: Visibility.HIDDEN,
      },
      count: {
        [TimePeriod.Instant]: 0,
        [TimePeriod.Morning]: 10,
        [TimePeriod.Afternoon]: 0,
        [TimePeriod.Evening]: 0,
      },
    },
    '13-17': {
      visibility: {
        [TimePeriod.Instant]: Visibility.HIDDEN,
        [TimePeriod.Morning]: Visibility.SHOW,
        [TimePeriod.Afternoon]: Visibility.FALLBACK,
        [TimePeriod.Evening]: Visibility.HIDDEN,
      },
      count: {
        [TimePeriod.Instant]: 0,
        [TimePeriod.Morning]: 10,
        [TimePeriod.Afternoon]: 10,
        [TimePeriod.Evening]: 0,
      },
    },
    '18-21': {
      visibility: {
        [TimePeriod.Instant]: Visibility.HIDDEN,
        [TimePeriod.Morning]: Visibility.SHOW,
        [TimePeriod.Afternoon]: Visibility.SHOW,
        [TimePeriod.Evening]: Visibility.FALLBACK,
      },
      count: {
        [TimePeriod.Instant]: 0,
        [TimePeriod.Morning]: 10,
        [TimePeriod.Afternoon]: 10,
        [TimePeriod.Evening]: 10,
      },
    },
    '22-23': {
      visibility: {
        [TimePeriod.Instant]: Visibility.HIDDEN,
        [TimePeriod.Morning]: Visibility.SHOW,
        [TimePeriod.Afternoon]: Visibility.SHOW,
        [TimePeriod.Evening]: Visibility.SHOW,
      },
      count: {
        [TimePeriod.Instant]: 0,
        [TimePeriod.Morning]: 10,
        [TimePeriod.Afternoon]: 10,
        [TimePeriod.Evening]: 10,
      },
    },
  },
};

export const AssessmentSlotConfig = {
  MAX_SLOTS: 10,
  MAX_SLOTS_PER_PERIOD: 3,
  MAX_SLOTS_FOR_NEXT_DAY: 9,
};
