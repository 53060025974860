import { IItemFlags } from '@Components/prescription/adjusted-amount-view';
import { IConsultationInvoice, IInvoiceItem } from '@Reducers/billing';
import { BillType } from '@Reducers/checkout';
import { IRx } from '@Reducers/encounter';
import { IPrice } from '@Reducers/patient';
import { BillingService } from '@Services/Billing';
import { CheckoutService } from '@Services/index';
import {
  CONSULTATION_NAMES,
  CONSULTATION_TYPE,
  ItemType,
  ONLINE_LOCATION_ID,
  PRACTO_LOCATION_ID,
} from '@Utils/constants';
import { LOG_ERROR } from '@Utils/logger';

const getItemPrices = (items: IInvoiceItem[]): IPrice => {
  if (!items?.length) return;
  return items.reduce(
    (acc, item) => ({
      listingPrice: acc.listingPrice + item.listingAmount,
      sellingPrice: acc.sellingPrice + item.payableAmount,
    }),
    { listingPrice: 0, sellingPrice: 0 },
  );
};

export const getItemPricesFuture = (items: IInvoiceItem[]): IPrice => {
  if (!items?.length) return;
  return items.reduce(
    (acc, item) => ({
      listingPrice: ((acc.listingPrice + item.listingAmount) / item.quantity) * item?.selection?.quantity,
      sellingPrice: ((acc.sellingPrice + item.payableAmount) / item.quantity) * item?.selection?.quantity,
    }),
    { listingPrice: 0, sellingPrice: 0 },
  );
};

const roundPrice = (price?: IPrice): IPrice =>
  price
    ? {
        listingPrice: Number.parseFloat(price.listingPrice.toFixed(2)),
        sellingPrice: Number.parseFloat(price.sellingPrice.toFixed(2)),
      }
    : undefined;

export const getInvoiceItemPrices = (invoiceItems: IInvoiceItem[]) => ({
  drugsPrice: roundPrice(getItemPrices(invoiceItems?.filter((item) => item.type === ItemType.DRUG))),
  labtestsPrice: roundPrice(getItemPrices(invoiceItems?.filter((item) => item.type === ItemType.LAB))),
  counsellingsPrice: roundPrice(
    getItemPrices(
      invoiceItems?.filter((item) => item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.TH),
    ),
  ),
  followupPrice: roundPrice(
    getItemPrices(
      invoiceItems?.filter((item) => item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.FU),
    ),
  ),
});

export const getInvoiceItemPricesFuture = (invoiceItems: IInvoiceItem[]) => ({
  drugsPrice: roundPrice(
    getItemPricesFuture(invoiceItems?.filter((item) => item.type === ItemType.DRUG && item?.selection?.isSelected)),
  ),
  labtestsPrice: roundPrice(
    getItemPricesFuture(invoiceItems?.filter((item) => item.type === ItemType.LAB && item?.selection?.isSelected)),
  ),
  counsellingsPrice: roundPrice(
    getItemPricesFuture(
      invoiceItems?.filter(
        (item) =>
          item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.TH && item?.selection?.isSelected,
      ),
    ),
  ),
  followupPrice: roundPrice(
    getItemPricesFuture(
      invoiceItems?.filter(
        (item) =>
          item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.FU && item?.selection?.isSelected,
      ),
    ),
  ),
});

export const getMandatoryItemPrices = (invoiceItems: IInvoiceItem[]) => ({
  drugsPrice: roundPrice(
    getItemPrices(
      invoiceItems?.filter((item) => item.type === ItemType.DRUG && !item.isOptional).length > 0
        ? invoiceItems?.filter((item) => item.type === ItemType.DRUG && !item.isOptional)
        : invoiceItems?.filter((item) => item.type === ItemType.DRUG),
    ),
  ),
  labtestsPrice: roundPrice(
    getItemPrices(
      invoiceItems?.filter((item) => item.type === ItemType.LAB && !item.isOptional).length > 0
        ? invoiceItems?.filter((item) => item.type === ItemType.LAB && !item.isOptional)
        : invoiceItems?.filter((item) => item.type === ItemType.LAB),
    ),
  ),
  counsellingsPrice: roundPrice(
    getItemPrices(
      invoiceItems?.filter(
        (item) => item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.TH && !item.isOptional,
      ).length > 0
        ? invoiceItems?.filter(
            (item) => item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.TH && !item.isOptional,
          )
        : invoiceItems?.filter((item) => item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.TH),
    ),
  ),
  followupPrice: roundPrice(
    getItemPrices(
      invoiceItems?.filter((item) => item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.FU),
    ),
  ),
});

export const getInvoiceItemsByCategory = (invoiceItems: IInvoiceItem[]) => ({
  drugs: invoiceItems?.filter((item) => item.type === ItemType.DRUG) || [],
  labtests: invoiceItems?.filter((item) => item.type === ItemType.LAB) || [],
  counsellings:
    invoiceItems?.filter((item) => item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.TH) || [],
  followups:
    invoiceItems?.filter((item) => item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.FU) || [],
});

export const getInvoiceItemsStatus = (paidItems: IInvoiceItem[], unpaidItems: IInvoiceItem[]) => {
  const {
    drugs: paidDrugs,
    labtests: paidLabtests,
    counsellings: paidCounsellings,
    followups: paidFollowups,
  } = getInvoiceItemsByCategory(paidItems || []);
  const {
    drugs: unpaidDrugs,
    labtests: unpaidLabtests,
    counsellings: unpaidCounsellings,
    followups: unpaidFollowups,
  } = getInvoiceItemsByCategory(unpaidItems || []);

  return {
    drugsStatus:
      paidDrugs.length > 0 && unpaidDrugs.length === 0
        ? BillType.PAID
        : paidDrugs.length > 0 && unpaidDrugs.length > 0
        ? BillType.PARTIAL
        : BillType.PREVIEW,
    labtestsStatus:
      paidLabtests.length > 0 && unpaidLabtests.length === 0
        ? BillType.PAID
        : paidLabtests.length > 0 && unpaidLabtests.length > 0
        ? BillType.PARTIAL
        : BillType.PREVIEW,
    counsellingsStatus:
      paidCounsellings.length > 0 && unpaidCounsellings.length === 0
        ? BillType.PAID
        : paidCounsellings.length > 0 && unpaidCounsellings.length > 0
        ? BillType.PARTIAL
        : BillType.PREVIEW,
    followupsStatus:
      paidFollowups.length > 0 && unpaidFollowups.length === 0
        ? BillType.PAID
        : paidFollowups.length > 0 && unpaidFollowups.length > 0
        ? BillType.PARTIAL
        : BillType.PREVIEW,
  };
};

export const getInvoiceForEncounter = async (encounterId: string, voucherCode?: string, ipxId?: string) => {
  const allinvoices = await CheckoutService.GetAllInvoice({
    encounterId,
    ipxId: ipxId,
  })
    .then((res) => res?.data?.data || [])
    .catch((error) => {
      LOG_ERROR('getInvoiceForEncounter', error);
      return [];
    });
  const modifiedInvoice = allinvoices.find(
    (invoice) => invoice?.attributes?.isModified && invoice.status === 'created',
  );
  const paidInvoices = allinvoices.filter((invoice) => invoice?.status === 'paid');
  //combine all paid invoices
  const combinedPaidInvoices = formatInvoice(combinePaidInvoices(paidInvoices));
  //if there is a modified invoice, return it
  if (modifiedInvoice) {
    const modifiedInvoiceResult = combinedPaidInvoices
      ? createInvoiceForPartialPayment(combinedPaidInvoices, modifiedInvoice)
      : formatInvoice(modifiedInvoice);
    return {
      invoice: modifiedInvoiceResult,
      billType: modifiedInvoiceResult?.items?.length ? BillType.MODIFIED : BillType.PAID,
    };
  }

  const previewinvoice = await BillingService.PreviewPrescriptionInvoice({
    encounterId,
    voucherCode,
    ignoreWhenNoItems: true,
    ipxId,
  })
    .then((res) => res.data)
    .catch(() => []);
  const isPartiallyPaid = checkIfPrescriptionIsPartiallyPaid(combinedPaidInvoices, previewinvoice);
  if (isPartiallyPaid) {
    return {
      invoice: createInvoiceForPartialPayment(combinedPaidInvoices, previewinvoice),
      billType: BillType.PARTIAL,
    };
  } else if (!isPartiallyPaid && combinedPaidInvoices) {
    return {
      invoice: {
        ...combinedPaidInvoices,
        items: [],
        paidItems: combinedPaidInvoices.items,
      },
      billType: BillType.PAID,
    };
  } else {
    return {
      invoice: formatInvoice(previewinvoice),
      billType: BillType.PREVIEW,
    };
  }
};

const createInvoiceForPartialPayment = (paidInvoice, previewInvoice) => {
  //look for item present in preview invoice but not in paid invoice and create a new invoice
  const missingItems = previewInvoice?.items?.filter(
    (item) => !paidInvoice?.items?.some((paidItem) => paidItem?.typeId === item?.typeId),
  );
  const payableAmount = missingItems.reduce((acc, item) => acc + item.payableAmount, 0);
  return formatInvoice({
    encounterId: previewInvoice?.encounterId,
    items: missingItems,
    paidItems: paidInvoice?.items,
    amount: payableAmount,
    payableAmount,
    dueAmount: payableAmount,
  });
};

export const combinePaidInvoices = (invoices) => {
  if (!invoices.length) return;
  const combinedItems = [];
  for (const invoice of invoices) {
    for (const item of invoice.items) {
      const existingItem = combinedItems.find((combinedItem) => combinedItem?.typeId === item?.typeId);
      if (existingItem) {
        existingItem.quantity += item?.quantity;
      } else {
        combinedItems.push(item);
      }
    }
  }
  const combinedInvoice: IConsultationInvoice = {
    encounterId: invoices[0]?.encounterId,
    items: combinedItems,
    amount: 0,
    payableAmount: 0,
    dueAmount: 0,
  };

  for (const invoice of invoices) {
    combinedInvoice.amount += invoice.amount ?? 0;
    combinedInvoice.payableAmount += invoice.payableAmount ?? 0;
    combinedInvoice.dueAmount += invoice.dueAmount ?? 0;
  }
  return combinedInvoice;
};

export const formatInvoice = (invoice) => {
  if (!invoice?.items?.length && !invoice?.paidItems?.length) return;
  const nonEmptyItems = invoice?.items?.filter((item) => item?.quantity > 0) || [];
  const items = nonEmptyItems?.map((item: any) => {
    return {
      ...item,
      costAmount: formatPrice(item.costAmount),
      originalAmount: formatPrice(item.originalAmount),
      listingAmount: formatPrice(item.listingAmount),
      payableAmount: formatPrice(item.payableAmount),
      additionalDiscount: formatPrice(item.originalAmount - item.payableAmount),
    };
  });

  const paidItems = invoice?.paidItems?.map((item: any) => {
    return {
      ...item,
      costAmount: formatPrice(item.costAmount),
      originalAmount: formatPrice(item.originalAmount),
      listingAmount: formatPrice(item.listingAmount),
      payableAmount: formatPrice(item.payableAmount),
      additionalDiscount: formatPrice(item.originalAmount - item.payableAmount),
    };
  });

  const listingAmount: number = items.reduce((acc: number, item: any) => acc + item.listingAmount, 0);
  const amount = formatPrice(invoice?.amount);
  const dueAmount = formatPrice(invoice?.dueAmount);
  const payableAmount = formatPrice(invoice?.payableAmount);
  const listingDiscount = Number.parseFloat((listingAmount - payableAmount).toFixed(2));
  const isValidCoupon = amount > payableAmount;
  const additionalDiscount = isValidCoupon ? Number.parseFloat((amount - payableAmount).toFixed(2)) : 0;
  return {
    ...invoice,
    items,
    paidItems,
    amount,
    payableAmount,
    dueAmount,
    listingDiscount,
    additionalDiscount,
    listingAmount,
    isValidCoupon,
  };
};

export const formatInvoiceFuture = (invoice): IConsultationInvoice => {
  if (!invoice?.items?.length && !invoice?.paidItems?.length) return;
  const items: IInvoiceItem[] = [];
  const paidItems: IInvoiceItem[] = [];
  for (const item of invoice?.items || []) {
    const formattedItem: IInvoiceItem = {
      ...item,
      originalQuantity: item.rxQuantity,
      costAmount: formatPrice(item.costAmount),
      originalAmount: formatPrice(item.originalAmount),
      listingAmount: formatPrice(item.listingAmount),
      payableAmount: formatPrice(item.payableAmount),
      additionalDiscount:
        item.name === CONSULTATION_NAMES.FU ? formatPrice(0) : formatPrice(item.originalAmount - item.payableAmount),
    };
    if (item?.rxQuantity !== item?.purchasedQuantity && item?.quantity !== 0) items.push(formattedItem);
    if (item?.purchasedQuantity) {
      paidItems.push(formattedItem);
    }
  }

  const listingAmount: number = items.reduce((acc: number, item: any) => acc + item.listingAmount, 0);
  const amount = formatPrice(invoice?.amount);
  const dueAmount = formatPrice(invoice?.dueAmount);
  const payableAmount = formatPrice(invoice?.payableAmount);
  const listingDiscount = Number.parseFloat((listingAmount - payableAmount).toFixed(2));
  const isValidCoupon = amount > payableAmount;
  const additionalDiscount = isValidCoupon ? Number.parseFloat((amount - payableAmount).toFixed(2)) : 0;

  return {
    ...invoice,
    items,
    paidItems,
    amount,
    payableAmount,
    dueAmount,
    listingDiscount,
    additionalDiscount,
    listingAmount,
    isValidCoupon,
  };
};

const groomInvoiceItem = (item, status: BillType): IInvoiceItem => {
  //this can be handled in backend, total price is incorrect in case of preference change
  const unitPayableAmount = item.payableAmount / item.quantity;
  const unitListingAmount = item.listingAmount / item.quantity;
  const unitOriginalAmount = item.originalAmount / item.quantity;
  const quantity =
    status === BillType.PAID
      ? item.quantity
      : item.purchasedQuantity !== item.modifiedQuantity
      ? item.modifiedQuantity || item.rxQuantity - item.purchasedQuantity
      : item.rxQuantity - item.purchasedQuantity;
  return {
    typeId: item.typeId,
    type: item.type,
    name: item.name,
    packSize: item.packSize,
    quantity: quantity,
    originalQuantity: item.rxQuantity,
    originalAmount: formatPrice(unitOriginalAmount * quantity),
    listingAmount: formatPrice(unitListingAmount * quantity),
    payableAmount: formatPrice(unitPayableAmount * quantity),
    additionalDiscount:
      item.name === CONSULTATION_NAMES.FU
        ? formatPrice(0)
        : formatPrice((unitOriginalAmount - unitPayableAmount) * quantity),
    isModified: item?.modifiedQuantity > 0 && item?.modifiedQuantity !== item?.quantity,
    isOptional: item?.isOptional,
  };
};

export const getPreviewFromOverview = (overview: any, encounterId: string): IConsultationInvoice => {
  const items: IInvoiceItem[] = [];
  const paidItems: IInvoiceItem[] = [];

  overview.paidItems?.forEach((item) => paidItems.push(groomInvoiceItem(item, BillType.PAID)));

  const itemsToProcess = overview.unpaidItems?.length > 0 ? overview.unpaidItems : overview.billingItems;
  itemsToProcess?.forEach((item) => {
    //TODO: remove this check once backend is fixed
    if (item.quantity > 0)
      items.push(groomInvoiceItem(item, overview.unpaidItems?.length > 0 ? BillType.PARTIAL : BillType.PREVIEW));
  });

  return {
    encounterId,
    isValidCoupon: !!overview?.voucherCode,
    voucherCode: overview?.voucherCode,
    items,
    paidItems,
  };
};

export const unapplyPseudoCoupon = (invoice: IConsultationInvoice): IConsultationInvoice => {
  return {
    ...invoice,
    items: invoice.items.map((item) => {
      return {
        ...item,
        payableAmount: item.listingAmount,
      };
    }),
    listingDiscount: 0,
    discountAmount: 0,
    amount: invoice.listingAmount,
    dueAmount: invoice.listingAmount,
    payableAmount: invoice.listingAmount,
  };
};

const formatPrice = (price): number => {
  return Number.parseFloat((price / 100).toFixed(2));
};

/**
 * partial is when a category is missing
 * item level inequalities are not considered
 */
const checkIfPrescriptionIsPartiallyPaid = (paidInvoice, previewInvoice) => {
  if (!previewInvoice?.items?.length) return false;
  const drugsPaid = paidInvoice?.items?.some((item) => item?.type === ItemType.DRUG);
  const labTestsPaid = paidInvoice?.items?.some((item) => item?.type === ItemType.LAB);
  const counsellingPaid = paidInvoice?.items?.some((item) => item?.type === ItemType.CONSULTATION);
  const drugsPresent = previewInvoice?.items?.some((item) => item?.type === ItemType.DRUG);
  const labTestsPresent = previewInvoice?.items?.some((item) => item?.type === ItemType.LAB);
  const counsellingPresent = previewInvoice?.items?.some((item) => item?.type === ItemType.CONSULTATION);
  return (
    paidInvoice?.items?.length > 0 &&
    ((!drugsPaid && drugsPresent) || (!labTestsPaid && labTestsPresent) || (!counsellingPaid && counsellingPresent))
  );
};

export const transformInvoicePayload = (
  invoice: IConsultationInvoice,
  selections: IItemFlags,
): {
  encounterId: string;
  items: {
    type: string;
    typeId: string;
    quantity: number;
  }[];
} => {
  if (!invoice?.items?.length) return;
  const drugs = selections.medicine ? invoice?.items?.filter((item) => item.type === ItemType.DRUG) || [] : [];
  const labTests = selections.labtests ? invoice?.items?.filter((item) => item.type === ItemType.LAB) || [] : [];
  const counsellings = selections.counsellings
    ? invoice?.items?.filter((item) => item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.TH) || []
    : [];
  const followups =
    invoice?.items?.filter((item) => item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.FU) || [];

  return {
    encounterId: invoice.encounterId,
    items: [
      ...drugs.map((drug) => ({
        type: ItemType.DRUG,
        typeId: drug.typeId,
        quantity: drug.quantity,
      })),
      ...labTests.map((labTest) => ({
        type: ItemType.LAB,
        typeId: labTest.typeId,
        quantity: labTest.quantity,
      })),
      ...counsellings.map((consultation) => ({
        type: ItemType.CONSULTATION,
        typeId: consultation.typeId,
        quantity: consultation.quantity,
      })),
      ...followups.map((followup) => ({
        type: ItemType.CONSULTATION,
        typeId: followup.typeId,
        quantity: followup.quantity,
      })),
    ],
  };
};

export const getSelectiveItemPrices = (invoiceItems: IInvoiceItem[], selections: IItemFlags) => {
  if (!invoiceItems?.length)
    return {
      listingAmount: 0,
      payableAmount: 0,
      listingDiscount: 0,
      additonalDiscount: 0,
    };
  const prices = invoiceItems.reduce(
    (acc, item) => {
      const isSelected =
        (selections.medicine && item.type === ItemType.DRUG) ||
        (selections.labtests && item.type === ItemType.LAB) ||
        (selections.counsellings && item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.TH) ||
        (selections.followup && item.type === ItemType.CONSULTATION && item.name === CONSULTATION_NAMES.FU);

      if (isSelected) {
        acc.listingAmount += item.listingAmount;
        acc.payableAmount += item.payableAmount;
        acc.additionalDiscount += item.additionalDiscount;
      }

      return acc;
    },
    { listingAmount: 0, payableAmount: 0, additionalDiscount: 0 },
  );
  return {
    listingAmount: Number.parseFloat(prices.listingAmount.toFixed(2)),
    listingDiscount: Number.parseFloat(
      (prices.listingAmount - prices.payableAmount - prices.additionalDiscount).toFixed(2),
    ),
    additionalDiscount: Number.parseFloat(prices.additionalDiscount.toFixed(2)),
    payableAmount: Number.parseFloat(prices.payableAmount.toFixed(2)),
  };
};

export const getSelectiveItemPricesFuture = (invoiceItems: IInvoiceItem[]) => {
  if (!invoiceItems?.length)
    return {
      listingAmount: 0,
      payableAmount: 0,
      listingDiscount: 0,
      additonalDiscount: 0,
    };
  const prices = invoiceItems.reduce(
    (acc, item) => {
      if (item?.selection?.isSelected) {
        acc.listingAmount += (item.listingAmount / item.quantity) * item?.selection?.quantity;
        acc.payableAmount += (item.payableAmount / item.quantity) * item?.selection?.quantity;
        acc.additionalDiscount +=
          ((item.originalAmount - item.payableAmount) / item.quantity) * item?.selection?.quantity;
      }
      return acc;
    },
    { listingAmount: 0, payableAmount: 0, additionalDiscount: 0 },
  );
  return {
    listingAmount: Number.parseFloat(prices.listingAmount.toFixed(2)),
    listingDiscount: Number.parseFloat(
      (prices.listingAmount - prices.payableAmount - prices.additionalDiscount).toFixed(2),
    ),
    additionalDiscount: Number.parseFloat(prices.additionalDiscount.toFixed(2)),
    payableAmount: Number.parseFloat(prices.payableAmount.toFixed(2)),
  };
};

export const getCounsellingCouponValidOrNot = (rx: IRx, isCounsellingSelected: boolean, payableAmount: number) => {
  const hasPrematureEjaculation = rx.diagnoses.some((diagnosis) =>
    diagnosis.name?.toLowerCase()?.includes('premature ejaculation'),
  );
  const hasErectileDysfunction = rx.diagnoses.some((diagnosis) =>
    diagnosis.name?.toLowerCase()?.includes('erectile dysfunction'),
  );
  if (
    rx.counsellings.length === 0 ||
    !isCounsellingSelected ||
    rx.appointmentType !== CONSULTATION_TYPE.SC ||
    // location id is not online or practo online
    ![ONLINE_LOCATION_ID, PRACTO_LOCATION_ID].includes(rx.locationId) ||
    rx.billType === BillType.MODIFIED
  ) {
    return false;
  }
  if (
    (hasPrematureEjaculation && !hasErectileDysfunction && payableAmount > 0) ||
    (hasErectileDysfunction && !hasPrematureEjaculation && payableAmount > 3000) ||
    (hasPrematureEjaculation && hasErectileDysfunction && payableAmount > 3000)
  ) {
    return true;
  }
  return false;
};
