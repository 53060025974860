import { IQueryData, updateValidUserCity } from '@Reducers/common';
import { IAssigedProviderLocations } from '@Reducers/provider';
import { IClinicLocationsData } from '@Reducers/schedule';
import { useAppDispatch, useAppSelector } from '@Redux/hooks';
import {
  sortClinicsByDistance,
  filterClinicLocations,
  Coordinate,
  sortCitiesByClinicDistance,
} from '@Utils/Helpers/location/helper';
import { CONSULTATION_TYPE } from '@Utils/constants';
import { decodeCookie } from '@Utils/helper';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

function useGetLocations(doNotUpdateLocation?: boolean) {
  const dispatch = useAppDispatch();
  const ipCoordinates = useAppSelector((state) => state.user.ipCity.coordinates);
  const ipCity = useAppSelector((state) => state.user.ipCity.city);
  const [isLoading, setIsLoading] = useState(true);
  const [position, setPosition] = useState(ipCoordinates);
  const [permission, setPermission] = useState('');

  const queryData: IQueryData = useAppSelector((state) => state.common?.queryData);
  const offlineClinicLocations: Record<string, IClinicLocationsData[]> = useAppSelector(
    (state) => state.location?.offlineClinicLocations,
  );
  const assignedProviderLocations: IAssigedProviderLocations = useAppSelector(
    (state) => state.provider?.assignedProviderLocations,
  );
  // Check permission status periodically
  useEffect(() => {
    if (!navigator?.permissions?.query) return;
    const interval = setInterval(() => {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        setPermission(result.state);
      });
    }, 1000); // Check every 1000 milliseconds (1 second)

    return () => clearInterval(interval);
  }, []);

  // Get user's current coordinates
  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          //eslint-disable-next-line empty-block
        },
      );
    }
  }, [navigator?.geolocation, permission]);

  const [filteredClinicLocations, setFilteredClinicLocations] = useState<Record<string, IClinicLocationsData[]>>({});
  const [clinicCities, setClinicCities] = useState<string[]>([]);

  useEffect(() => {
    if (
      Object.keys(offlineClinicLocations || {}).length === 0 ||
      (queryData?.consultationCode === CONSULTATION_TYPE.FU && assignedProviderLocations?.isLoading !== false)
    )
      return;

    const processLocations = async () => {
      // Determine the locations based on consultation type
      const locations =
        queryData?.consultationCode === CONSULTATION_TYPE.FU
          ? filterClinicLocations(offlineClinicLocations, assignedProviderLocations?.locationIds)
          : offlineClinicLocations;

      // Sort the clinics by distance if a position is available
      const sortedClinics = position ? sortClinicsByDistance(locations, position as Coordinate) : locations;
      setFilteredClinicLocations(sortedClinics);
      let clinicCities;
      if (permission === 'granted') {
        clinicCities = sortCitiesByClinicDistance(sortedClinics);
      } else {
        clinicCities = Object.keys(sortedClinics).sort((a, b) => a.localeCompare(b));
      }
      setClinicCities(clinicCities);
      const utmCampaign = decodeCookie(Cookies.get('utm_campaign'))?.toLowerCase();
      const nearestCity =
        permission === 'granted' && sortedClinics?.[clinicCities?.[0]]?.[0]?.distance <= 100 ? clinicCities?.[0] : '';
      const userCity = nearestCity || ipCity || utmCampaign;
      //add the city and first location in the city to querydata
      if (userCity) {
        clinicCities?.map((offlineCity) => {
          if (userCity?.toLowerCase()?.includes(offlineCity?.toLowerCase())) {
            dispatch(updateValidUserCity(offlineCity));
          }
        });
      }
    };

    processLocations().finally(() => setIsLoading(false));
  }, [position, offlineClinicLocations, assignedProviderLocations, queryData?.consultationCode, ipCity]);

  useEffect(() => {
    if (!position && ipCoordinates?.latitude && ipCoordinates?.longitude) setPosition(ipCoordinates);
  }, [ipCoordinates]);

  return {
    clinicLocations: filteredClinicLocations,
    clinicCities,
    isLoading,
    permission,
  };
}

export default useGetLocations;
