import Image from 'next/image';
import styled from 'styled-components';

import ClinicDark from '@Images/svgs/clinic-dark.svg';
import ClinicLight from '@Images/svgs/clinic-light.svg';
import VideoDark from '@Images/svgs/video-dark.svg';
import VideoLight from '@Images/svgs/video-light.svg';
import { useTrans } from '@Utils/hooks/translation';

import { colors } from './design-system/color';
import { ColorType } from './design-system/color/types';
import Text from './design-system/text';
import { FontType } from './design-system/text/types';

function ConsultationModeSelection({
  isOffline,
  setConsultationModeToOffline,
  setConsultationModeToOnline,
  hideHeading,
  isThemeDark,
}: Readonly<{
  isOffline: boolean;
  setConsultationModeToOffline: () => void;
  setConsultationModeToOnline: () => void;
  isThemeDark: boolean;
  hideHeading?: boolean;
}>) {
  const { t, languageUsed } = useTrans();

  return (
    <div className={`${isThemeDark ? '' : 'w-full'}`}>
      <div
        className={`self-stretch p-1.5 my-1 bg-GREYS_100 ${
          isThemeDark ? 'rounded-full' : 'rounded-lg'
        } justify-start items-center inline-flex md:max-w-sm shadow-slot w-full`}>
        <ButtonGradient selected={isOffline} onClick={setConsultationModeToOffline} isThemeDark={isThemeDark}>
          <Image src={isOffline ? ClinicLight.src : ClinicDark.src} width={24} height={24} alt='allo icon' />
          <Text
            font={isOffline ? FontType.SEMIBOLD_14_150 : FontType.REGULAR_14_150}
            color={isOffline ? ColorType.WHITE : ColorType.GREYS_900}
            language={languageUsed}
            className='ml-1 whitespace-nowrap'>
            {t('appointment.clinicTab')}
          </Text>
        </ButtonGradient>
        <ButtonGradient selected={!isOffline} onClick={setConsultationModeToOnline} isThemeDark={isThemeDark}>
          <Image src={isOffline ? VideoDark.src : VideoLight.src} width={24} height={24} alt='allo icon' />
          <Text
            font={isOffline ? FontType.REGULAR_14_150 : FontType.SEMIBOLD_14_150}
            color={!isOffline ? ColorType.WHITE : ColorType.GREYS_900}
            language={languageUsed}
            className='ml-1 whitespace-nowrap'>
            {t('appointment.onlineTab')}
          </Text>
        </ButtonGradient>
      </div>
    </div>
  );
}

export default ConsultationModeSelection;

interface IButtonProps {
  selected: boolean;
  isThemeDark: boolean;
}
export const ButtonGradient = styled.div.attrs({
  className:
    'pay-button flex justify-center items-center px-3 mx-1 text-center cursor-pointer hover:opacity-90 h-11 w-1/2',
})<IButtonProps>`
  background: ${(props) => (props.selected ? colors.PRIMARY_600 : colors.GREYS_200)};
  border-radius: ${(props) => (props.isThemeDark ? '999px' : '8px')};
`;
