import dayjs from 'dayjs';
import Image from 'next/image';

import { FontType, ColorType } from '@Components/common-exports';
import Text from '@Components/design-system/text';
import { ISlot } from '@Reducers/schedule';

const InstantSlotListing = ({
  instantSlots,
  selectedSlot,
  handleSlotChange,
}: {
  instantSlots: ISlot[];
  selectedSlot: ISlot;
  handleSlotChange: (slot: ISlot) => void;
}) => {
  return (
    instantSlots?.length > 0 && (
      <section className='px-4 pt-6'>
        <div className={`flex gap-2 items-center pb-3`}>
          <Image
            src={`/assets/svgs/instant.svg`}
            width={20}
            height={20}
            layout='fixed'
            quality={100}
            priority
            alt='allo-icon'
          />
          <Text font={FontType.SEMIBOLD_10_170} color={ColorType.GREYS_700}>
            Instant slots
          </Text>
        </div>
        <div className='grid grid-cols-5 py-3 gap-3'>
          {instantSlots?.map((slot) => (
            <button
              key={slot?.startDate}
              onClick={() => handleSlotChange(slot)}
              className={
                `${
                  slot?.startDate === selectedSlot?.startDate
                    ? 'bg-PRIMARY_600 border border-PRIMARY_600'
                    : 'bg-GREYS_100 border-[1.5px] border-GREYS_400'
                }` + ' cursor-pointer px-1 py-1.5 flex justify-center rounded-lg relative'
              }>
              <div className='rounded border border-white absolute -top-3 -right-3 bg-ORANGE_YELLOW_200 w-5 h-5 flex items-center justify-center'>
                <Image src={`/assets/svgs/instant.svg`} width={16} height={16} alt='instant slot' layout='fixed' />
              </div>
              <Text
                className='whitespace-nowrap'
                font={FontType.SEMIBOLD_11_170}
                color={slot?.startDate === selectedSlot?.startDate ? ColorType.GREYS_100 : ColorType.GREYS_800}>
                {dayjs(slot?.startDate)?.format('h:mm A')}
              </Text>
            </button>
          ))}
        </div>
      </section>
    )
  );
};

export default InstantSlotListing;
