import { IConsultationInvoice, IInvoiceItem } from '@Reducers/billing';
import { appointmentTypes } from '@Utils/constants';

export const getConsultations = (consultationOrders = [], invoice: IConsultationInvoice) => {
  const counsellings = [];
  const followups = [];
  for (const consultation of consultationOrders) {
    const invoiceConsultation: IInvoiceItem =
      invoice?.items?.find((item) => item?.typeId === consultation?.consultation?.id) ||
      invoice?.paidItems?.find((item) => item?.typeId === consultation?.consultation?.id);

    if (!invoiceConsultation && invoice) continue;

    if (consultation?.description === 'Followup Consultation') {
      followups.push({
        id: consultation?.consultation?.id,
        name: consultation?.description,
        type: consultation?.consultation?.name,
        instructions: 'This followup consultation helps you review your progress and get your updated next course.',
        dueDate: consultation?.dueDate,
        totalQuantity: invoiceConsultation?.quantity,
      });
    } else if (!counsellings.some((consult) => consult?.type === appointmentTypes.THERAPY)) {
      const quantity = invoiceConsultation?.quantity || consultation?.quantity;
      counsellings.push({
        id: consultation?.consultation?.id,
        name: 'Counselling Sessions',
        type: appointmentTypes.THERAPY,
        instructions:
          'This counselling session helps you identify the cause behind your sexual health symptoms - such as mental health concerns like stress and anxiety.',
        dueDate: consultation?.dueDate,
        totalQuantity: quantity,
        frequency: `${quantity} ${quantity > 1 ? 'sessions' : 'session'}`,
      });
    }
  }
  return { counsellings, followups };
};
