import { Dispatch } from '@reduxjs/toolkit';

import { showNotification, NotificationType } from '@Components/design-system/notification/notification';
import { ActionConsts } from '@Definitions/index';
import { updateSelectedLanguages } from '@Reducers/common';
import { ScheduleService } from '@Services/index';
import {
  GetAppointmentPayload,
  GetAvailableLanguagesPayload,
  GetSlotsPayload,
  ReleaseConsultationSlotPayload,
  RescheduleConsultationPayload,
} from '@Services/Schedule/schedule-payload';
import { CONSULTATION_TYPE, DEFAULT_LOCATION_CODE, ERROR_MESSAGES } from '@Utils/constants';
import { groupSlotsByDate } from '@Utils/Helpers/schedule/helper';
import { LOG_ERROR } from '@Utils/logger';

export const ScheduleActions = {
  GetAppointment: (requestPayload: GetAppointmentPayload) => async (dispatch: Dispatch) => {
    try {
      const { status, ...payload } = requestPayload;
      dispatch({ type: ActionConsts.Schedule.GetAppointmentInit });
      const result = await ScheduleService.GetAppointment(payload);
      const tokenResult = await ScheduleService.GetHMSToken(requestPayload);
      if (
        result?.status === 200 &&
        tokenResult?.status === 200 &&
        status?.findIndex((appointmentStatus) => appointmentStatus == result.data?.status) !== -1
      ) {
        dispatch({
          appointmentData: {
            status: result.data?.status,
            id: result.data?.id,
            type: result.data?.consultation?.consultationType?.name,
            consultationTypeId: result.data?.consultation?.consultationType?.id,
            consultationId: result.data?.consultation?.id,
            startTime: result.data?.startTime,
            alternateLink: result.data?.conferenceDetails?.alternateLink,
            patientMeetingLink:
              result.data?.conferenceDetails?.meetingLinks?.patient || result.data?.conferenceDetails?.meetingLink,
            hmsToken: tokenResult.data,
          },
          providerId: result.data?.providerId,
          patientId: result.data?.patientId,
          type: ActionConsts.Schedule.GetAppointmentSuccess,
        });
      } else if (result?.status === 404) {
        const message = `Please Login from registered phone number !`;
        showNotification(NotificationType.ERROR, message, 3000);
        dispatch({
          message: result?.data?.message,
          type: ActionConsts.Schedule.GetAppointmentFail,
        });
        setTimeout(() => {
          window.top.location.href = '/logout?relogin=true';
        }, 4000);
      } else {
        dispatch({
          message: result?.data?.message,
          type: ActionConsts.Schedule.GetAppointmentFail,
        });
      }
    } catch (error) {
      const referenceData = {
        error: error,
        request: requestPayload,
      };
      LOG_ERROR('Error: ScheduleActions>>GetAppointment', referenceData);
      dispatch({
        message: ERROR_MESSAGES.get,
        type: ActionConsts.Schedule.GetAppointmentFail,
      });
    }
  },
  GetSlots: (requestPayload: GetSlotsPayload) => async (dispatch: Dispatch, getState) => {
    try {
      dispatch({ type: ActionConsts.Schedule.GetSlotsInit });
      const result = await ScheduleService.GetSlots(requestPayload);
      if (result?.status === 200) {
        //if screening call and online restrict slot visibility
        const isScreeningCallAndOnline =
          getState().common.queryData?.locationCode === DEFAULT_LOCATION_CODE &&
          getState().consultation.consultationType?.consultationCode === CONSULTATION_TYPE.SC;
        const showInstantSlots: boolean = isScreeningCallAndOnline;
        dispatch({
          slotsData: groupSlotsByDate(result.data, isScreeningCallAndOnline, showInstantSlots),
          type: ActionConsts.Schedule.GetSlotsSuccess,
        });
      } else {
        dispatch({ type: ActionConsts.Schedule.GetSlotsFail });
      }
    } catch (error) {
      const referenceData = {
        error: error,
        request: requestPayload,
      };
      LOG_ERROR('Error: ScheduleActions>>GetSlots', referenceData);
      dispatch({
        message: ERROR_MESSAGES.get,
        type: ActionConsts.Schedule.GetSlotsFail,
      });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetAppointmentData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Schedule.ResetAppointmentData,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetReleaseConsultationSlot: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Schedule.ResetReleaseConsultationSlot,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetAvailableLanguages: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Schedule.ResetAvailableLanguages,
    });
  },

  // eslint-disable-next-line unicorn/consistent-function-scoping
  GetLanguages: () => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Schedule.GetLanguagesInit });
      const result = await ScheduleService.GetLanguages();
      if (result?.status === 200) {
        dispatch({
          languages: result?.data?.map((language: string) => language),
          type: ActionConsts.Schedule.GetLanguagesSuccess,
        });
      } else {
        dispatch({
          message: result?.data?.message,
          type: ActionConsts.Schedule.GetLanguagesFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: ScheduleActions>>GetLanguages', error);
      dispatch({
        message: ERROR_MESSAGES.get,
        type: ActionConsts.Schedule.GetLanguagesFail,
      });
    }
  },

  GetAvailableLanguages: (payload: GetAvailableLanguagesPayload) => async (dispatch: Dispatch, getState) => {
    try {
      dispatch({ type: ActionConsts.Schedule.GetAvailableLanguagesInit });
      const result = await ScheduleService.GetAvailableLanguages(payload);
      if (result?.status === 200) {
        const formatLanguages = result?.data?.filter((language: string) => language);
        const availableLanguages = formatLanguages.length > 0 ? formatLanguages : ['English'];
        const languagesSet = new Set(availableLanguages);
        const prefillLanguages = getState().user.userData.preferredLanguage?.filter((language) =>
          languagesSet.has(language),
        );
        dispatch({
          availableLanguages,
          type: ActionConsts.Schedule.GetAvailableLanguagesSuccess,
        });
        dispatch(updateSelectedLanguages(prefillLanguages));
      } else {
        dispatch({
          type: ActionConsts.Schedule.GetAvailableLanguagesFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: ScheduleActions>>GetAvailableLanguages', error);
      dispatch({
        type: ActionConsts.Schedule.GetAvailableLanguagesFail,
      });
    }
  },
  UpdateConsultationState:
    (updatedConsultation: { appointmentId: string; date: string }) => async (dispatch: Dispatch) => {
      dispatch({
        consultationData: updatedConsultation,
        type: ActionConsts.Schedule.UpdateConsultationStateSuccess,
      });
    },
  RescheduleConsultation: (requestPayload: RescheduleConsultationPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Schedule.RescheduleConsultationInit });
      const result = await ScheduleService.RescheduleConsultation(requestPayload);
      if (result?.status === 201) {
        dispatch({
          rescheduleData: {
            status: result?.status,
            message: 'Rescheduled successfully',
          },
          type: ActionConsts.Schedule.RescheduleConsultationSuccess,
        });
      } else {
        dispatch({
          rescheduleData: {
            status: result?.status,
            message: ERROR_MESSAGES.reschedule,
          },
          type: ActionConsts.Schedule.RescheduleConsultationFail,
        });
      }
    } catch (error) {
      const referenceData = {
        request: requestPayload,
        error: error,
      };
      LOG_ERROR('Error: ScheduleActions>>RescheduleConsultation', referenceData);
      dispatch({
        rescheduleData: {
          message: ERROR_MESSAGES.reschedule,
        },
        type: ActionConsts.Schedule.RescheduleConsultationFail,
      });
    }
  },
  ReleaseConsultationSlots: (requestPayload: ReleaseConsultationSlotPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Schedule.ReleaseConsultationSlotInit });
      const result = await ScheduleService.ReleaseConsultationSlot(requestPayload);
      if (result?.status === 201) {
        dispatch({
          releaseConsultationSlot: true,
          type: ActionConsts.Schedule.ReleaseConsultationSlotSuccess,
        });
      } else {
        dispatch({
          releaseConsultationSlot: false,
          type: ActionConsts.Schedule.ReleaseConsultationSlotFail,
        });
      }
      return;
    } catch (error) {
      const referenceData = {
        request: requestPayload,
        error: error,
      };
      LOG_ERROR('Error: ScheduleActions>>ReleaseConsultationSlots', referenceData);
      dispatch({
        releaseConsultationSlot: false,
        type: ActionConsts.Schedule.ReleaseConsultationSlotFail,
      });
    }
  },
  InitiateAppointmentIVR: (appointmentId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Schedule.AppointmentIVRInit });
      const result = await ScheduleService.InitiateAppointmentIVR(appointmentId);
      if (result?.status === 201) {
        dispatch({
          ivrData: {
            status: result?.status,
            message: 'Call Initiated',
          },
          type: ActionConsts.Schedule.AppointmentIVRSuccess,
        });
      } else {
        dispatch({
          ivrData: {
            status: result?.status,
            message: ERROR_MESSAGES.call,
          },
          type: ActionConsts.Schedule.AppointmentIVRFail,
        });
      }
    } catch (error) {
      const referenceData = {
        appointmentId: appointmentId,
        error: error,
      };
      LOG_ERROR('Error: ScheduleActions>>InitiateAppointmentIVR', referenceData);
      dispatch({
        ivrData: {
          message: ERROR_MESSAGES.call,
        },
        type: ActionConsts.Schedule.RescheduleConsultationFail,
      });
    }
  },
  InitiateGoogleMeet: (appointmentId: string, patientId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Schedule.AppointmentGMeetInit });
      const result = await ScheduleService.InitiateGoogleMeet(appointmentId, patientId);
      if (result?.status === 201) {
        dispatch({
          gMeetData: {
            status: result?.status,
            message: 'Google Meet Link Sent',
          },
          type: ActionConsts.Schedule.AppointmentGMeetSuccess,
        });
      } else {
        dispatch({
          gMeetData: {
            status: result?.status,
            message: ERROR_MESSAGES.call,
          },
          type: ActionConsts.Schedule.AppointmentGMeetFail,
        });
      }
    } catch (error) {
      const referenceData = {
        appointmentId: appointmentId,
        error: error,
      };
      LOG_ERROR('Error: ScheduleActions>>InitiateAppointmentGmeet', referenceData);
      dispatch({
        gMeetData: {
          message: ERROR_MESSAGES.call,
        },
        type: ActionConsts.Schedule.AppointmentGMeetFail,
      });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetGmeetData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Schedule.ResetGMeetData,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetConsultationData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Schedule.ResetConsultationData,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetIVRData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Schedule.ResetIVRData,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetRescheduleData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Schedule.ResetRescheduleData,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetSlotsData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Schedule.ResetSlotsData,
    });
  },
};
