import * as React from 'react';

const Loader = ({ duration = 3000, size = 1, ...props }) => {
  const [per, updatePer] = React.useState(0);
  const timer = React.useRef(null);

  React.useEffect(() => {
    triggerLoader();
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const triggerLoader = () => {
    timer.current = setInterval(() => {
      updatePer((val) => {
        if (val === 100) {
          return 0;
        }
        return val + 1;
      });
    }, duration / 100);
  };

  const sizeInPx = 33 * size;

  return (
    <svg
      width={sizeInPx}
      height={sizeInPx}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox={`0 0 ${sizeInPx + 4} ${sizeInPx + 4}`}
      {...props}>
      <defs>
        <linearGradient x2='0%' y1='100%' y2='0%' id='fill'>
          <stop offset={per + '%'} stopColor='#725fa5'></stop>
          <stop offset={per + '%'} opacity='0.2' stopColor='#001f0933'></stop>
        </linearGradient>
      </defs>
      <path
        d='M317.867 6.068h-59.809a7.47 7.47 0 0 0-5.174 2.171 7.398 7.398 0 0 0-2.182 5.148v13.366A157.765 157.765 0 0 0 163.067.34C70.314.34.59 72.897.59 169.321c0 96.424 69.724 168.981 162.477 168.981 31.33-.032 61.905-9.581 87.635-27.368v14.003a7.716 7.716 0 0 0 2.275 5.373 7.795 7.795 0 0 0 5.401 2.264h59.809a7.791 7.791 0 0 0 5.4-2.264 7.712 7.712 0 0 0 2.276-5.373V13.387a8.483 8.483 0 0 0-2.677-5.088 8.561 8.561 0 0 0-5.319-2.23ZM164.666 264.472c-51.814 0-87.955-39.142-87.955-95.151 0-55.054 36.781-95.151 87.635-95.151 48.295 0 86.356 41.688 86.356 95.151 0 53.463-38.061 95.151-86.036 95.151Z'
        fill='url(#fill)'
        viewBox={`0 0 ${sizeInPx} ${sizeInPx}`}
        width={sizeInPx}
        height={sizeInPx}
        style={{
          transform: `scale(${size * 0.1 || 0.2})`,
        }}
      />
    </svg>
  );
};

export default Loader;
