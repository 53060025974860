import Image from 'next/image';

const ChatbotHeader = ({ toggleChat }) => {
  return (
    <div className='self-stretch p-5 flex justify-between items-center' style={{ backgroundColor: '#4e5ba6' }}>
      <div className='flex justify-start items-center gap-3.5'>
        <div className='w-10 rounded-full border-0 flex justify-center items-center'>
          <Image src={'/assets/svgs/sexpertai-main.svg'} alt='chat-icon' height={40} width={40} />
        </div>
        <div className='text-stone-50 text-lg'>Allo Assistant</div>
      </div>
      <div
        className='w-10 h-10 rounded-full border-0 flex justify-center items-center cursor-pointer'
        onClick={toggleChat}>
        <Image src={'/assets/svgs/cross-white.svg'} alt='close-icon' height={24} width={24} />
      </div>
    </div>
  );
};

export default ChatbotHeader;
