import { ActionConsts } from '@Definitions/index';
import { IAddress } from '@Services/Patient/patient-payload';

export interface IreferralData {
  referedTo: string;
  referalReason: string;
}

export interface IPrice {
  sellingPrice: number;
  listingPrice: number;
}

export interface IDrugData {
  id: string;
  name: string;
  brandName: string;
  type: string;
  duration: number;
  frequency: string;
  durationUnit: string;
  instructions: string;
  note: string;
  condition: string;
  dispense?: string;
  packageSize?: number;
  unitPrice?: number;
  totalPrice?: number;
  unitListingPrice?: number;
  totalListingPrice?: number;
  totalQuantity?: number;
  isVerified?: boolean;
  applicationEvents: IApplicationEvents[];
}

export interface IApplicationEvents {
  quantity: number;
  direction: string;
  time: string;
  type: string;
  offset: any;
  offsetUnit: string;
}

export interface ILabTestData {
  id: string;
  name: string;
  type: string;
  instructions: string;
  inclusions: string[];
  preparation: string;
  note: string;
  unitPrice: number;
  totalPrice: number;
  listingPrice?: number;
  totalListingPrice?: number;
  totalQuantity?: number;
  isVerified?: boolean;
  dueDate?: string;
}

export interface IConsultationData {
  totalQuantity: number;
  consumedQuantity: number;
  id: string;
  name: string;
  type: string;
  instructions: string;
  dueDate?: string;
  note: string;
  frequency: string;
  unitPrice: number;
  totalPrice: number;
  unitListingPrice?: number;
  totalListingPrice?: number;
  isVerified?: boolean;
}

export interface IMetadataData {
  id: string;
  referenceId: string;
  metadataType: string;
  title?: string;
  seqNo?: number;
  description?: string;
}

export interface IResourceData {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  type: 'blog' | 'video' | 'story';
  externalUrl: string;
  label?: string;
  badge?: string;
  category?: string;
  lifestyle?: string;
  lifestyleImage?: string;
  foodTitle?: string;
  foodDescription?: string;
  foodImage?: string;
}

export interface IPatient {
  diagnosesData: Array<string>;
  metadataData: Array<IMetadataData>;
  resourceData: Array<IResourceData>;
  allEncounters: any[];
  encounterData: any;
  diagnoses: string[];
  addresses: IAddress[];
  isLoading: boolean;
}

const INITIAL_STATE: IPatient = {
  diagnosesData: [],
  metadataData: [],
  resourceData: [],
  allEncounters: [],
  encounterData: {},
  diagnoses: [],
  addresses: [],
  isLoading: false,
};

export const PatientReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Patient.GetPatientDiagnosesInit:
      return {
        ...state,
        diagnosesData: INITIAL_STATE.diagnosesData,
        isLoading: true,
      };
    case ActionConsts.Patient.GetPatientDiagnosesSuccess:
      return {
        ...state,
        diagnosesData: action.diagnosesData,
        isLoading: false,
      };
    case ActionConsts.Patient.GetPatientEncountersFail:
    case ActionConsts.Patient.ResetPatientDiagnoses:
      return {
        ...state,
        diagnosesData: INITIAL_STATE.diagnosesData,
        isLoading: false,
      };
    case ActionConsts.Patient.ResetPatientReports:
      return {
        ...state,
        diagnosesData: INITIAL_STATE.diagnosesData,
        metadataData: INITIAL_STATE.metadataData,
        isLoading: false,
      };
    case ActionConsts.Patient.GetPatientMetadataInit:
      return {
        ...state,
        metadataData: INITIAL_STATE.metadataData,
        isLoading: true,
      };
    case ActionConsts.Patient.GetPatientMetadataSuccess:
      return {
        ...state,
        metadataData: action.metadataData,
        isLoading: false,
      };
    case ActionConsts.Patient.GetPatientMetadataFail:
    case ActionConsts.Patient.ResetPatientMetadata:
      return {
        ...state,
        metadataData: INITIAL_STATE.metadataData,
        isLoading: false,
      };
    case ActionConsts.Patient.GetPatientResourcesInit:
      return {
        ...state,
        resourceData: INITIAL_STATE.resourceData,
        isLoading: true,
      };
    case ActionConsts.Patient.GetPatientResourcesSuccess:
      return {
        ...state,
        resourceData: action.resourceData,
        isLoading: false,
      };
    case ActionConsts.Patient.GetPatientResourcesFail:
    case ActionConsts.Patient.ResetPatientResources:
      return {
        ...state,
        resourceData: INITIAL_STATE.resourceData,
        isLoading: false,
      };
    case ActionConsts.Patient.GetAllPatientEncountersInit:
      return {
        ...state,
        allEncounters: INITIAL_STATE.allEncounters,
        diagnoses: INITIAL_STATE.diagnoses,
        isLoading: true,
      };
    case ActionConsts.Patient.GetAllPatientEncountersSuccess:
      return {
        ...state,
        allEncounters: action.allEncounters,
        diagnoses: action.diagnoses,
        isLoading: false,
      };
    case ActionConsts.Patient.GetAllPatientEncountersFail:
      return {
        ...state,
        allEncounters: INITIAL_STATE.allEncounters,
        diagnoses: INITIAL_STATE.diagnoses,
        isLoading: false,
      };
    case ActionConsts.Patient.UpdateEncounterInit:
      return {
        ...state,
        encounterData: INITIAL_STATE.encounterData,
        isLoading: true,
      };
    case ActionConsts.Patient.UpdateEncounterSuccess:
      return {
        ...state,
        encounterData: action.encounterData,
        isLoading: false,
      };
    case ActionConsts.Patient.UpdateEncounterFail:
      return {
        ...state,
        encounterData: INITIAL_STATE.encounterData,
        isLoading: false,
      };
    case ActionConsts.Patient.GetAllAddressInit:
    case ActionConsts.Patient.AddAddressInit:
      return {
        ...state,
        addresses: [],
        isLoading: true,
      };
    case ActionConsts.Patient.AddAddressSuccess:
    case ActionConsts.Patient.GetAllAddressSuccess:
      return {
        ...state,
        addresses: action.addresses,
        isLoading: false,
      };
    case ActionConsts.Patient.GetAllAddressFail:
    case ActionConsts.Patient.AddAddressFail:
    case ActionConsts.Patient.ResetAllAddressData:
      return {
        ...state,
        addresses: [],
      };
    default:
      return state;
  }
};
