import styled from 'styled-components';

import { colors } from '../color-v2';

interface CardProps {
  rounded: 'large' | 'medium' | 'small' | 'xlarge';
}

const getBorderRadius = (props: CardProps) => {
  switch (props.rounded) {
    case 'xlarge':
      return '16px';
    case 'large':
      return '12px';
    case 'medium':
      return '8px';
    case 'small':
      return '4px';
    default:
      return '0px';
  }
};

export const Glassy = styled.div.attrs({
  className: '',
})<CardProps>`
  border-radius: ${(props) => getBorderRadius(props)};
  background: radial-gradient(121.49% 277.08% at 0% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 5%),
    linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 118.44%);

  border-image-source: radial-gradient(
    121.49% 277.08% at 0% 0%,
    rgba(255, 255, 255, 0.8) 10%,
    rgba(255, 255, 255, 0) 90%
  );
`;

export const BlueGreyGradient = styled.div.attrs({
  className: '',
})<CardProps>`
  background-repeat: repeat-x;
  clip-path: ellipse(100% 100%);
  background: linear-gradient(180deg, #4e5ba6 0%, #3c4782 100%);
  border-radius: ${(props) => getBorderRadius(props)};
  border: 1px solid;
`;

export const IndigoBlueGradient = styled.div.attrs({
  className: '',
})<CardProps>`
  background-repeat: repeat-x;
  clip-path: ellipse(100% 100%);
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 21.19%,
      rgba(163, 120, 255, 0.31) 47.92%,
      rgba(0, 0, 0, 0.6) 97.4%
    ),
    #1f1b46;
  border-radius: ${(props) => getBorderRadius(props)};
  border: 1px solid;
`;

export const ReportStatusGradient = styled.div.attrs({
  className: '',
})<CardProps>`
  background: linear-gradient(90deg, #c7cef8 0%, #e0e6fe 55.73%, rgba(255, 255, 255, 0) 66.13%),
    linear-gradient(91.23deg, #e0e6fe 0.08%, rgba(234, 236, 245, 0) 84.26%), linear-gradient(0deg, #f2f4f7, #f2f4f7);
`;

export const EmeraldGreen = styled.div.attrs({
  className: 'bg-gradient-to-b from-[#007755] to-[#25D366] to-100%',
})``;

export const PurpleCubes = styled.div.attrs({
  className: 'bg-PRIMARY_1000',
})`
  background-image: url('/assets/svgs/bg-cubes.svg');
  background-size: fit;
`;

export const PurpleLight = styled.div.attrs({})`
  background: linear-gradient(45deg, #f4f3ff 0%, #ffffff 100%);
`;

export const PrescriptionCard = styled.div.attrs({
  className: ' bg-white',
})`
  box-shadow: -1px 4px 7px rgba(0, 0, 0, 0.09), 4px -1px 250px rgba(213, 217, 235, 0.63);
`;

export const WhiteContainer = styled.div`
  background-color: ${colors.background.LIGHT_1};
`;
