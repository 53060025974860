import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';

const Bullet = ({
  children,
  fontType,
  colorType,
  classes = '',
  bulletClasses = '',
}: {
  children: any;
  fontType?: FontType;
  colorType?: ColorType;
  classes?: string;
  bulletClasses?: string;
}) => {
  return (
    <div className='flex gap-2'>
      <div className='flex justify-center items-start pt-2'>
        <div className={`w-1 h-1 bg-GREYS_900 rounded-full ${bulletClasses}`}></div>
      </div>
      <Text font={fontType || FontType.REGULAR_14_150} color={colorType || ColorType.GREYS_900} className={classes}>
        {children}
      </Text>
    </div>
  );
};
export default Bullet;
