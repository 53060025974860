import Image from 'next/image';
import { useEffect } from 'react';

import CrossWhite from '../public/assets/svgs/cross-white.svg';

export interface IDoctorData {
  name?: string;
  designation?: string;
  signature?: string;
  image?: string;
  linkName?: string;
  regno?: string;
  certification?: string;
  email?: string;
}

export const DoctorInfoFrame = ({
  doctorData,
  handleCloseFrame,
}: {
  doctorData: IDoctorData;
  handleCloseFrame: any;
}) => {
  useEffect(() => {
    if (document.body.style.overflow === 'hidden') return;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    doctorData?.linkName && (
      <section className='z-50 fixed w-full h-full inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-end'>
        <div className='flex my-3 md:w-128 px-6 w-full justify-end'>
          <Image
            className='py-2 cursor-pointer'
            onClick={handleCloseFrame}
            src={CrossWhite.src}
            width={24}
            height={24}
            alt='allo-icon'
          />
        </div>
        <div className='bg-white p-4 md:w-128 w-full overflow-y-scroll rounded-t-lg'>
          <iframe src={`${doctorData?.linkName}?origin=${window.location.origin}`} width='100%' height={600}></iframe>
        </div>
      </section>
    )
  );
};
