import { causeTypes } from '@Components/assessment-report/collapsable-list';
import Bmi from '@Images/svgs/assessment-report/bmi.svg';
import Brain from '@Images/svgs/assessment-report/brain.svg';
import PastExperience from '@Images/svgs/assessment-report/clock-red.svg';
import Harmonal from '@Images/svgs/assessment-report/harmonal.svg';
import Medical from '@Images/svgs/assessment-report/medical.svg';
import Physical from '@Images/svgs/assessment-report/physical.svg';
import Relationship from '@Images/svgs/assessment-report/relationship.svg';
import thumbDown from '@Images/svgs/assessment-report/thumb-down.svg';
import TrendingDown from '@Images/svgs/assessment-report/trending-down.svg';
import Hormone from '@Images/svgs/hormone-therapy.svg';
import Injections from '@Images/svgs/injections.svg';
import Lifestyle from '@Images/svgs/lifestyle-change.svg';
import Medication from '@Images/svgs/oral-medication.svg';
import Surgery from '@Images/svgs/surgery.svg';
import Counselling from '@Images/svgs/therapy-counselling.svg';
import Vaccum from '@Images/svgs/vaccum-device.svg';
import { formatName } from '@Utils/helper';

import { CollapsableListTypes } from '../../../pages/assessment-report/v2';

export const getReasons = (reasons: string) => {
  switch (true) {
    case reasons.includes('lifestyle'):
    case reasons.includes('जीवनशैली'):
      return thumbDown;
    case reasons.includes('psychological'):
    case reasons.includes('performance'):
    case reasons.includes('मनोवैज्ञानिक'):
      return Brain;
    case reasons.includes('hormonal'):
    case reasons.includes('हार्मोनल'):
      return Harmonal;
    case reasons.includes('deficiencies'):
    case reasons.includes('low sexual desire'):
    case reasons.includes('कम यौन इच्छा'):
      return TrendingDown;
    case reasons.includes('relationship'):
      return Relationship;
    case reasons.includes('medical'):
      return Medical;
    case reasons.includes('physical'):
    case reasons.includes('शारीरिक'):
    case reasons.includes('भौतिक'):
      return Physical;
    case reasons.includes('past'):
      return PastExperience;
    case reasons.includes('bmi'):
      return Bmi;
  }
};

export const getCollapsableIcons = (options: string) => {
  switch (true) {
    case options.includes('medication'):
      return Medication;
    case options.includes('lifestyle'):
      return Lifestyle;
    case options.includes('counseling'):
      return Counselling;
    case options.includes('vacuum'):
      return Vaccum;
    case options.includes('injection'):
      return Injections;
    case options.includes('hormone'):
      return Hormone;
    case options.includes('surgery'):
      return Surgery;
  }
};

export const getSortedMetadata = (
  metaData: {
    metadataType: string;
    seqNo: number;
    title: string;
    description: string;
  }[],
) => {
  const sortedMetadata = [...metaData].sort((a, b) => a.seqNo - b.seqNo);
  const formattedData: Record<string, string[]> = {};
  for (const data of sortedMetadata) {
    if (!formattedData[data.title]) {
      formattedData[data.title] = [];
    }
    formattedData[data.title].push(data?.description);
  }
  return formattedData;
};

export const segregateTreatmentResources = (resources, diagnoses) => {
  const lifeStyleResources = [];
  const drugResources = [];
  const labtestResources = [];
  const counsellingResources = [];
  const categories = new Set();
  const hasSevereDiagnosis = diagnoses?.some((diagnosis) => diagnosis?.severity === 'severely');
  const hasModerateDiagnosis = diagnoses?.some((diagnosis) => diagnosis?.severity === 'moderately');
  const severityMap = new Map();
  for (const diagnosis of diagnoses || []) {
    severityMap.set(diagnosis?.medicalCondition?.name?.toLowerCase(), diagnosis?.severity?.toLowerCase());
  }
  if (hasSevereDiagnosis) {
    processDiagnosis('severely');
  } else if (hasModerateDiagnosis) {
    processDiagnosis('moderately');
  } else {
    processDiagnosis('normal');
  }
  function processDiagnosis(diagnosisSeverity: string) {
    for (const resource of resources || []) {
      if (severityMap?.get(resource?.category?.toLowerCase()) !== diagnosisSeverity) continue;
      {
        if (categories?.has(resource?.badge?.toLowerCase())) continue;

        if (resource?.subCategory?.toLowerCase()?.includes('medication')) {
          drugResources?.push(resource);
        } else if (resource?.subCategory?.toLowerCase()?.includes('diagnostic')) {
          labtestResources?.push(resource);
        } else if (resource?.subCategory?.toLowerCase()?.includes('counselling')) {
          counsellingResources?.push(resource);
        } else {
          lifeStyleResources?.push(resource);
        }

        categories?.add(resource?.badge?.toLowerCase().trim());
      }
    }
  }

  for (const diagnosisList of severityMap.values() ?? []) {
    for (const diagnosis of diagnosisList ?? []) {
      processDiagnosis(diagnosis);
    }
  }

  return {
    lifeStyleResources,
    drugResources,
    labtestResources,
    counsellingResources,
  };
};

export const categorizeMetadata = (metadata, categorizeBy, allowedCategories?) => {
  const categorizeMetadata = {};
  const allowedCategoriesSet = new Set(allowedCategories);
  const sortedMetadata = (metadata || [])?.slice().sort((a, b) => a.seqNo - b.seqNo);
  for (const data of sortedMetadata || []) {
    if (allowedCategories && !allowedCategoriesSet?.has(data?.metadataType)) continue;
    const categoryToGoBy: string = data?.[categorizeBy].trim();
    if (!categorizeMetadata?.[categoryToGoBy]) {
      categorizeMetadata[categoryToGoBy] = [];
    }
    categorizeMetadata?.[categoryToGoBy]?.push(data);
  }
  return categorizeMetadata;
};

export const getAllowedCategories = (listType) => {
  if (listType === CollapsableListTypes.causes) return Object.values(causeTypes);
};

export const getHeadingForCollapsableList = (listType, listingFor, translate?) => {
  switch (listType) {
    case CollapsableListTypes.causes:
      return {
        heading: translate(`diagnosis.causes-for`, {
          condition: listingFor,
        }),
        ctaHeading: translate('diagnosis.want-to-understand-why-this-is-happening-to-you'),
      };
    case CollapsableListTypes.treatments:
      return {
        heading: translate(`diagnosis.possible-treatment-options-for`, {
          condition: listingFor,
        }),
        ctaHeading: translate('diagnosis.learn-what-treatment-works-best-for-you'),
      };
    case CollapsableListTypes.solutions:
      return {
        heading: translate('how-can-you-address-this'),
        ctaHeading: translate('diagnosis.have-doubts-about-your-report'),
      };
    // No default
  }
};

export const getFilteredAilments = (ailments, type, medicalConditionId) => {
  return ailments?.filter(
    (ailment) => ailment?.ailment?.type === type && ailment?.ailment?.medicalCondition?.id === medicalConditionId,
  );
};

export const filterTags = (tags) => {
  const uniqueTags = new Set();
  const filteredTags = [];
  for (const tag of tags || []) {
    const [firstWord, ..._] = (tag?.description || '').split(' ');
    if (uniqueTags.has(firstWord?.toLowerCase())) continue;
    uniqueTags.add(firstWord?.toLowerCase());
    filteredTags.push(tag);
  }
  return filteredTags;
};

export const sortDiagnoses = (diagnoses = []) => {
  const preferenceOrder = { LSD: 1, PE: 2, ED: 3 }; //reverse order
  const severe = [];
  const moderate = [];
  const normal = [];
  const diagnosesPreferenceWise = [...diagnoses].sort((a, b) => {
    const aIndex = preferenceOrder?.[a?.medicalCondition?.code] || -1;
    const bIndex = preferenceOrder?.[b?.medicalCondition?.code] || -1;
    return bIndex - aIndex;
  });
  //likeliness preference order
  for (const diagnosis of diagnosesPreferenceWise) {
    switch (diagnosis?.severity) {
      case 'severely': {
        severe.push(diagnosis);
        break;
      }
      case 'moderately': {
        moderate.push(diagnosis);
        break;
      }
      case 'normal':
        {
          normal.push(diagnosis);
          // No default
        }
        break;
    }
  }
  return [...severe, ...moderate, ...normal];
};

export const isUuid = (uuid: string): boolean => {
  const uuidRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
  return uuidRegex.test(uuid);
};

export const filterTranslation = (word, translate) => {
  const translatedWord = translate(`causes.${word}`);
  return !translatedWord.includes('assessment') ? translatedWord : formatName(word);
};
