import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/index';
import { GetAppointmentsPayload } from '@Services/Checkout/checkout-payload';
import { CheckoutService, PatientService } from '@Services/index';
import { ERROR_MESSAGES } from '@Utils/constants';
import { getLatestAppointment, getMatchingAppointment, filterAppointments } from '@Utils/helper';
import { sortEncounters } from '@Utils/Helpers/Patient/helper';
import { LOG_ERROR } from '@Utils/logger';

export const AppointmentCategory = {
  LATEST: 'LATEST',
  CURRENT: 'CURRENT',
};

export const CheckoutActions = {
  GetFilteredAppointments: (requestPayload: GetAppointmentsPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Checkout.GetFilteredAppointmentsInit });
      const { types, statusFilter, rxTypes, ...payload } = requestPayload;
      const result = await CheckoutService.GetAppointments(payload);
      if (result?.status === 200) {
        const filteredAppointments = filterAppointments(result?.data?.data, types, statusFilter, rxTypes);
        dispatch({
          filteredAppointments: filteredAppointments?.map((appointment) => ({
            id: appointment?.id,
            consultationId: appointment?.consultationId,
            status: appointment?.status,
            startTime: appointment?.startTime,
            endTime: appointment?.endTime,
            locationId: appointment?.locationId,
            providerId: appointment?.providerId,
            type: appointment?.consultation?.consultationType?.name,
            providerData: {
              name: appointment?.provider?.name,
              image: appointment?.provider?.profileImage,
            },
          })),
          type: ActionConsts.Checkout.GetFilteredAppointmentsSuccess,
        });
      } else {
        dispatch({
          message: result?.data?.data?.message,
          type: ActionConsts.Checkout.GetFilteredAppointmentsFail,
        });
      }
    } catch (error) {
      const referenceData = {
        request: requestPayload ?? {},
        error: error ?? '',
      };
      LOG_ERROR('Error: CheckoutActions>>GetFilteredAppointments', referenceData);
      dispatch({
        message: ERROR_MESSAGES.get,
        type: ActionConsts.Checkout.GetAppointmentsFail,
      });
    }
  },
  GetAppointments: (requestPayload: GetAppointmentsPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Checkout.GetAppointmentsInit });
      const { date, ...payload } = requestPayload;
      const isDatePresent = date && typeof date == 'string' && date?.length > 0;
      const result = await CheckoutService.GetAppointments(payload);
      if (result?.status === 200 && result?.data?.data?.length > 0) {
        const appointment = isDatePresent
          ? getMatchingAppointment(result?.data?.data, date)
          : getLatestAppointment(result?.data?.data);
        const appointmentData = {
          id: appointment?.id,
          consultationId: appointment?.consultationId,
          status: appointment?.status,
          alternateLink: appointment?.conferenceDetails?.alternateLink,
          patientMeetingLink: appointment?.conferenceDetails?.meetingLinks?.patient,
          type: appointment?.consultation?.consultationType?.name,
          startTime: appointment?.startTime,
          patientId: appointment?.patientId,
          providerId: appointment?.providerId,
          consultationCode: appointment?.consultation?.consultationType?.code,
          category: isDatePresent ? AppointmentCategory.CURRENT : AppointmentCategory.LATEST,
        };
        dispatch({
          appointments: appointmentData,
          type: ActionConsts.Checkout.GetAppointmentsSuccess,
        });
      } else if (result?.status === 200 && result?.data?.data?.length === 0) {
        dispatch({
          appointments: {},
          type: ActionConsts.Checkout.GetAppointmentsSuccess,
        });
      } else {
        dispatch({
          message: result?.data?.data?.message,
          type: ActionConsts.Checkout.GetAppointmentsFail,
        });
      }
    } catch (error) {
      const referenceData = {
        error: error ?? '',
        request: requestPayload ?? {},
      };
      LOG_ERROR('Error: CheckoutActions>>GetAppointments', referenceData);
      dispatch({
        message: ERROR_MESSAGES.get,
        type: ActionConsts.Checkout.GetAppointmentsFail,
      });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetAppointments: () => async (dispatch: Dispatch) => {
    dispatch({ type: ActionConsts.Checkout.ResetAppointments });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetInvoice: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Checkout.ResetInvoice,
    });
    dispatch({
      type: ActionConsts.Checkout.ResetInvoicePreview,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetCheckoutData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Checkout.ResetCheckoutData,
    });
  },
  GetEncounterForAppointment:
    (requestPayload: { appointmentId: string; ipxId?: string }) => async (dispatch: Dispatch) => {
      try {
        dispatch({
          type: ActionConsts.Checkout.GetEncounterFromAppointmentInit,
        });
        const result = await PatientService.GetPatientEncounters(requestPayload);
        if (result?.status === 200) {
          const resultData = sortEncounters(result?.data)?.[0];
          const encounterId = resultData?.id;
          dispatch({
            encounterId: encounterId,
            type: ActionConsts.Checkout.GetEncounterFromAppointmentSuccess,
          });
        } else {
          dispatch({
            type: ActionConsts.Checkout.GetEncounterFromAppointmentFail,
          });
        }
      } catch (error) {
        const referenceData = {
          error: error,
          request: requestPayload,
        };
        LOG_ERROR('Error: CheckoutActions>>GetEncounterFromAppointment', referenceData);
        dispatch({
          message: ERROR_MESSAGES.get,
          type: ActionConsts.Checkout.GetEncounterFromAppointmentFail,
        });
      }
    },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetEncounterFromAppointment: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Checkout.ResetEncounterFromAppointment,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  TogglePauseSlotRelease: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Checkout.TogglePauseSlotRelease,
    });
  },
};
