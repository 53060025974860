import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/index';
import { SendActivityPayload, TrackEncounterPayload, UpdateLeadPayload } from '@Services/Analytics/analytics-payload';
import { AnalyticsService } from '@Services/index';
import { rxTypes } from '@Utils/constants';
import { PaperformIdEncounterTypeMap } from '@Utils/helper';
import { LOG_ERROR } from '@Utils/logger';

export const AnalyticsActions = {
  GetLead: (leadId: string) => async (dispatch: Dispatch, getState) => {
    try {
      dispatch({ type: ActionConsts.Analytics.GetLeadInit });
      const response = await AnalyticsService.Get(leadId);
      if (response?.status === 200) {
        dispatch({
          type: ActionConsts.Analytics.GetLeadSuccess,
          leadData: response.data,
        });
      } else {
        dispatch({
          type: ActionConsts.Analytics.GetLeadFail,
        });
      }
    } catch {
      dispatch({
        type: ActionConsts.Analytics.GetLeadFail,
      });
    }
  },
  UpdateLead: (updateLeadPayload: UpdateLeadPayload) => async (dispatch: Dispatch, getState) => {
    try {
      dispatch({ type: ActionConsts.Analytics.UpdateLeadInit });
      const response = await AnalyticsService.Update(updateLeadPayload, getState().user.userData.leadId);
      if (response?.status === 200) {
        dispatch({
          type: ActionConsts.Analytics.UpdateLeadSuccess,
          leadData: response.data,
        });
      } else {
        dispatch({
          type: ActionConsts.Analytics.UpdateLeadFail,
        });
      }
    } catch {
      dispatch({
        type: ActionConsts.Analytics.UpdateLeadFail,
      });
      LOG_ERROR('Error: UpdateLead', updateLeadPayload);
    }
  },
  TrackEncounter: (TrackEncounterPayload: TrackEncounterPayload) => async () => {
    const encounterType = PaperformIdEncounterTypeMap(TrackEncounterPayload.paperformId);
    if (encounterType === rxTypes.TAKE_AX) {
      AnalyticsService.TrackEncounter({
        status: 'attempted',
        encounterType,
      });
    }
  },
  SendActivity: (payload: SendActivityPayload) => async (dispatch: Dispatch) => {
    try {
      await AnalyticsService.SendActivity(payload);
    } catch {}
  },
};
