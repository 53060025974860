import Image from 'next/image';

import { ColorType, FontType } from '@Components/common-exports';
import Text from '@Components/design-system/text';
import { MEDIA_GRID } from '@Utils/constants';
import { useTrans } from '@Utils/hooks/translation';

const MediaGrid = () => {
  const { t, languageUsed } = useTrans('ax');
  return (
    <div>
      <Text
        font={FontType.SEMIBOLD_25_150}
        color={ColorType.GREYS_900}
        language={languageUsed}
        className='text-center pt-5'>
        {t('we-have-been-covered-in')}
      </Text>
      <div className='pt-6'>
        {MEDIA_GRID?.map((media) => {
          return (
            <div className='flex flex-col items-center justify-center p-4 ' key={media?.id}>
              <Image src={media?.image} alt='allo-icon' width={media?.width} height={media?.height} layout='fixed' />
              <div className='w-full bg-PRIMARY_75 h-px mt-6' />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MediaGrid;
