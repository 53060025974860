import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  ClientMetadata,
  ISignUpResult,
  IAuthenticationCallback,
  CognitoUserSession,
  GetSessionOptions,
  CognitoRefreshToken,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';

export class CognitoAsyncClient {
  readonly userPool: CognitoUserPool;
  readonly cognitoUser: CognitoUser;

  constructor(clientOptions: ICognitoUserPoolData, userName: string) {
    if (typeof window !== 'undefined') {
      this.userPool = new CognitoUserPool(clientOptions);
      this.cognitoUser = new CognitoUser({
        Username: userName,
        Pool: this.userPool,
      });
      this.cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
    }
  }

  signupAsync = async (
    username: string,
    password: string,
    userAttributes: CognitoUserAttribute[] = [],
    validationData: CognitoUserAttribute[] = [],
    clientMetadata?: ClientMetadata,
  ): Promise<ISignUpResult> => {
    return new Promise((resolve, reject) => {
      this.userPool.signUp(
        username,
        password,
        userAttributes,
        validationData,
        async (err: Error, result: ISignUpResult) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
        clientMetadata,
      );
    });
  };

  initiateAuthAsync = async (authenticationDetails: AuthenticationDetails) => {
    return new Promise((resolve, reject) => {
      this.cognitoUser.initiateAuth(authenticationDetails, {
        onSuccess: function (
          session: CognitoUserSession,
          // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
          userConfirmationNecessary?: boolean,
        ) {
          resolve(session);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFailure: (err: any) => {
          reject(err);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customChallenge: (challengeParameters: any) => {
          resolve(challengeParameters);
        },
      } as IAuthenticationCallback);
    });
  };

  sendCustomChallengeAnswerAsync = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    answer: any,
    clientMetaData?: ClientMetadata,
  ): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
      this.cognitoUser.sendCustomChallengeAnswer(
        answer,
        {
          onSuccess: (result: CognitoUserSession) => {
            resolve(result);
          },
          onFailure: (err) => {
            reject(err);
          },
          customChallenge: () => {
            reject(new Error('Incorrect OTP. Please try again.'));
          },
        } as IAuthenticationCallback,
        clientMetaData,
      );
    });
  };

  getSessionAsync = async (options?: GetSessionOptions): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
      this.cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      }, options);
    });
  };

  refreshTokenAsync = async (refreshToken: CognitoRefreshToken): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
      this.cognitoUser.refreshSession(refreshToken, (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  };

  signoutAsync = async (): Promise<void> => {
    // NOTE: Have not implemented token revoking. Library supports it.
    return this.cognitoUser.signOut();
  };
}
