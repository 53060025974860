import Image from 'next/image';
import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

import styles from '../../styles/ReactMarkdown.module.css';

const ChatBodyContainer = styled.div`
  padding: 10px;
  overflow-y: auto;
  flex-grow: 1;
`;

const ChatbotQuickReplySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
  overflow-y: auto;
`;

// Shared styled component for message bubbles
const MessageBubble = styled.div`
  background-color: ${(props) => props.bgColor || '#d5d9eb'};
  border-radius: 9px;
  padding: 8px 10px;
  margin-bottom: 10px;
  max-width: 80%;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
  display: flex;
  align-items: center;
  color: ${(props) => props.textColor || '#293056'}; // default to black
  cursor: ${(props) => (props.isQuickReply ? 'pointer' : 'auto')};
  &:hover {
    background-color: ${(props) => (props.isQuickReply ? '#4e5ba6' : props.bgColor)};
    color: ${(props) => (props.isQuickReply ? '#fff' : props.textColor)};
  }
`;

const Icon = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MarkdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

// Message component for both Chatbot and User
const Message = ({ text, isUser, isThinking }) => {
  const bgColor = isUser ? '#4e5ba6' : '#f2f4f7';
  const textColor = isUser ? '#fff' : '#222';
  const alignRight = isUser;
  const justifyClass = isUser ? 'justify-end' : 'justify-start';

  return (
    <div className={`flex flex-row ${justifyClass} items-end mb-1.5`}>
      {isUser ? undefined : (
        <Icon>
          <Image
            src={isThinking ? '/assets/svgs/sexpertai-typing.svg' : '/assets/svgs/sexpertai-static.svg'}
            alt='allo-icon'
            height={32}
            width={32}
          />
        </Icon>
      )}
      <MessageBubble bgColor={bgColor} textColor={textColor} alignRight={alignRight}>
        <MarkdownContainer>
          {isThinking ? (
            <div className='px-6 py-4'>
              <div className='flex space-x-2'>
                <span className='inline-block bg-gray-400 rounded-full h-2 w-2 animate-pulse' />
                <span className='inline-block bg-gray-400 rounded-full h-2 w-2 animate-pulse' />
                <span className='inline-block bg-gray-400 rounded-full h-2 w-2 animate-pulse' />
              </div>
            </div>
          ) : (
            <ReactMarkdown remarkPlugins={[remarkGfm]} className={styles.reactMarkDown}>
              {text}
            </ReactMarkdown>
          )}
        </MarkdownContainer>
      </MessageBubble>
    </div>
  );
};

const AlwaysScrollToBottom = ({ messagesLength }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    elementRef.current.scrollIntoView();
  }, [messagesLength]);

  return <div ref={elementRef} />;
};

const ChatbotBody = ({ messages, quickReplies, quickReplyClick, isTyping }) => {
  return (
    <ChatBodyContainer>
      {messages.map((msg, index) => (
        <Message text={msg.text} key={index} isUser={msg.isUser} isThinking={false} />
      ))}

      {isTyping ? <Message text={'Thinking....'} isUser={false} isThinking={true} /> : undefined}
      {messages.length > 0 ? undefined : (
        <ChatbotQuickReplySection>
          {quickReplies.map((text, index) => (
            <MessageBubble key={index} isQuickReply onClick={() => quickReplyClick(text)}>
              {text}
            </MessageBubble>
          ))}
        </ChatbotQuickReplySection>
      )}
      <AlwaysScrollToBottom messagesLength={messages.length} />
    </ChatBodyContainer>
  );
};

export default ChatbotBody;
