import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/ActionConsts';
import { IPatientLabFiles, IUploadFile } from '@Reducers/file';
import { FileService } from '@Services/index';
import { LOG_ERROR } from '@Utils/logger';

export const FileActions = {
  UploadFile: (requestPayload: IUploadFile) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.File.UploadFileInit });
      const response = await FileService.UploadFile(requestPayload);
      if (response.status === 201) {
        dispatch({
          type: ActionConsts.File.UploadFileSuccess,
          uploadFile: response.data,
        });
      } else {
        dispatch({ type: ActionConsts.File.UploadFileFail });
      }
      return response;
    } catch (error) {
      const reference = {
        error,
        requestPayload,
      };
      LOG_ERROR('Error: FileActions>>UploadFile', reference);
      dispatch({ type: ActionConsts.File.UploadFileFail });
    }
  },
  GetUploadedfiles: (requestPayload: { userId: string }) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.File.GetPatientfileInit });
      const result = await FileService.GetPatientFiles({
        userId: requestPayload.userId,
      });
      if (result?.status === 200) {
        const filesData: IPatientLabFiles[] = result?.data?.map((file: any) => ({
          patientId: file?.userId,
          name: file?.name,
          remarks: file?.remarks,
          updatedAt: file?.updatedAt,
          fileId: file?.id,
          fileType: file?.fileType,
        }));
        dispatch({
          files: filesData,
          type: ActionConsts.File.GetPatientfileSuccess,
        });
      } else {
        dispatch({ type: ActionConsts.File.GetPatientfileFail });
      }
    } catch {
      dispatch({ type: ActionConsts.File.GetPatientfileFail });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetFileUploads: () => async (dispatch: Dispatch) => {
    dispatch({ type: ActionConsts.File.ResetFileUploads });
  },
};
