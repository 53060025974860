import { useAppDispatch } from '@Redux/hooks';
import { UpdateQueryDataPayload } from '@Services/Common/common-payload';
import { useRouter } from 'next/navigation';
import { ParsedUrlQueryInput } from 'querystring';
import { CommonActions, ScheduleActions } from '@Actions/index';
import { updateCommonIsLoading } from '@Reducers/common';
import { REDUCER_KEY } from 'modules/appointment/hooks/directional-engine/constants';

interface IRouterParams {
  pathname: string;
  query?: UpdateQueryDataPayload;
}

function useCustomRouter() {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const customRouter = (payload: IRouterParams) => {
    const { pathname, query } = payload as {
      pathname: string;
      query?: ParsedUrlQueryInput | string;
    };
    dispatch(CommonActions.ResetQueryData());
    dispatch(ScheduleActions.ResetSlotsData());
    dispatch(ScheduleActions.ResetReleaseConsultationSlot());
    dispatch(updateCommonIsLoading(true));
    setTimeout(() => {
      let url = `/${pathname}`;
      sessionStorage.removeItem(REDUCER_KEY);
      if (query) {
        const searchParams = new URLSearchParams();
        Object.entries(query).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            searchParams.append(key, value.toString());
          }
        });
        const queryString = searchParams.toString();
        if (queryString) {
          url += `?${queryString}`;
        }
      }
      router.push(url);
    }, 500);
  };

  return customRouter;
}

export default useCustomRouter;
