import { ConsultationActions } from '@Actions/ConsultationActions';
import { IQueryData } from '@Reducers/common';
import { IConsultationType } from '@Reducers/consultation';
import { IClinicLocationsData } from '@Reducers/schedule';
import { IUserData } from '@Reducers/user';
import { useAppDispatch, useAppSelector } from '@Redux/hooks';
import { DEFAULT_LOCATION_CODE } from '@Utils/constants';
import { useEffect, useMemo } from 'react';

const useConsultationType = () => {
  const dispatch = useAppDispatch();
  const userData: IUserData = useAppSelector((state) => state.user.userData);
  const consultationType: IConsultationType = useAppSelector((state) => state.consultation.consultationType);
  const queryData: IQueryData = useAppSelector((state) => state.common.queryData);
  const commonIsLoading = useAppSelector((state) => state.common.isLoading);

  const clinicLocationToCityMap: Record<string, string> = useAppSelector(
    (state) => state.location?.clinicLocationToCity,
  );

  const clinicLocation: IClinicLocationsData = useAppSelector((state) =>
    state.location?.offlineClinicLocations?.[
      clinicLocationToCityMap?.[queryData?.locationCode] || queryData?.validUserCity
    ]?.find((clinic) => clinic.code === queryData?.locationCode),
  );
  const onlineLocation = useAppSelector((state) => state.location?.onlineLocation);

  const locationType: IClinicLocationsData = useMemo(() => {
    return queryData?.locationCode === DEFAULT_LOCATION_CODE ? onlineLocation : clinicLocation;
  }, [queryData?.locationCode, clinicLocation, onlineLocation]);

  useEffect(() => {
    if (queryData?.consultationCode && !commonIsLoading && !consultationType?.isLoading && userData?.id)
      dispatch(ConsultationActions.GetConsultationTypeDetails(queryData?.consultationCode));
  }, [queryData?.consultationCode, commonIsLoading, userData?.id]);

  return { consultationType, locationType };
};

export default useConsultationType;
