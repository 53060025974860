import dayjs from 'dayjs';
import Image from 'next/image';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';

import DoctorImage from './core/doctor-image/v1';
import { Button } from './design-system/button/button';
import { colors } from './design-system/color';
import { IDoctorData } from './expert-advice';

const FollowUpCard = ({
  findSlot,
  doctorData,
  dueDate,
}: {
  findSlot?: () => void;
  doctorData: IDoctorData;
  dueDate: string;
}) => {
  const isFollowupDate = dayjs().diff(dayjs(dueDate), 'day') >= 0 && dayjs().diff(dayjs(dueDate), 'day') <= 7;
  return (
    <main className='flex flex-col bg-PRIMARY_50 border border-PRIMARY_300 shadow-sm rounded-xl overflow-hidden'>
      <FollowUpUtil
        doctorData={doctorData}
        description={
          'Regular follow-ups help maintain the effectiveness of your treatment and ensure ongoing support for your well-being.'
        }
      />
      <section className='flex gap-6 bg-white rounded-b-2xl px-4 py-2'>
        {findSlot && (
          <div className='w-1/2'>
            <Button
              onPress={findSlot}
              fullWidth={true}
              disabled={!isFollowupDate}
              disabledBackgroundColour={colors[ColorType.GREYS_600]}
              label={'Find a slot'}
              primaryColour={colors[ColorType.GREYS_1000]}
              secondaryBackgroundColour={colors[ColorType.GREYS_100]}
              justifyContent='center'
            />
          </div>
        )}
        <div className={findSlot ? 'flex flex-col' : 'flex flex-row gap-2 justify-center items-center w-full'}>
          <Text font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_900}>
            To be scheduled
          </Text>
          <Text className='whitespace-nowrap' font={FontType.REGULAR_11_170} color={ColorType.GREYS_900}>
            between&nbsp;
            {dayjs(dueDate).format('D MMM')}&nbsp;-&nbsp;
            {dayjs(dueDate).add(7, 'day').format('D MMM YYYY')}
          </Text>
        </div>
      </section>
    </main>
  );
};
export default FollowUpCard;

export const FollowUpUtil = ({ doctorData, description }: { doctorData: IDoctorData; description: string }) => {
  return (
    <section className='flex gap-2 p-4 relative overflow-hidden bg-PRIMARY_50'>
      <div className='w-20'>
        <DoctorImage doctorImage={doctorData?.image} size={34} backgroundColor={ColorType.BLUE_GREY_200} />
      </div>
      <div>
        <Text className='pb-2 pt-1' font={FontType.SEMIBOLD_14_150} color={ColorType.BLUE_GREY_800}>
          Follow up with {doctorData?.name}
        </Text>
        <Text className='pb-1 pr-10' color={ColorType.BLUE_GREY_800} font={FontType.REGULAR_11_170}>
          {description}
        </Text>
      </div>
      <div className='absolute -bottom-6 -right-4'>
        <Image src={'/assets/svgs/stethoscope.svg'} width={100} height={100} layout='fixed' alt='allo icon' />
      </div>
    </section>
  );
};
