import { AxiosResponse } from 'axios';

import { TrackSlotsPayload } from '@Services/Track/track-payload';

import { Axios } from '../AxiosApiServerWrapper';

export const TrackService = {
  TrackSlots: async (payload: TrackSlotsPayload): Promise<AxiosResponse> => {
    const url = `track/slots`;
    return Axios.Request('POST', url, payload);
  },
};
