import Image from 'next/image';
import { useMemo } from 'react';

import Accordion from '@Components/accordion';
import MidPopUp from '@Components/core/mid-popup';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { scrollToSection } from '@Utils/helper';
import {
  categorizeMetadata,
  getAllowedCategories,
  getHeadingForCollapsableList,
  getCollapsableIcons,
  filterTranslation,
} from '@Utils/Helpers/AssessmentReport/helper';
import { useTrans } from '@Utils/hooks/translation';

import { CollapsableListTypes } from '../../pages/assessment-report/v2';
import HeaderWithBack from './header-with-back';
import { InfoQuestion } from './information-popup';
import StickyCta from './sticky-cta';

export const causeTypes = {
  organic_causes: 'organic_causes',
  psychological_causes: 'psychological_causes',
  lifestyle_causes: 'lifestyle_causes',
  causes: 'causes', //temp
};

export const treatmentTypes = {
  counselling: 'counselling',
  lifestyle_changes: 'lifestyle_changes',
  therapy: 'therapy',
  diagnostic_test: 'diagnostic_test',
  medication: 'medication',
  treatment: 'treatment', //temp
};

const CollapsableList = ({
  listType,
  information,
  informationFor,
  categorizeInformationBy,
  titleizeBy,
  closeModal,
  handleBooking,
  handleViewDetailedContent,
  mainNav,
  onCloseModal,
}: {
  listType: CollapsableListTypes;
  information: any[];
  informationFor: string;
  categorizeInformationBy: string;
  titleizeBy?: string;
  closeModal: () => void;
  handleBooking?: () => void;
  handleViewDetailedContent?: (listType: CollapsableListTypes, categorizeBy: string, titleizeBy: string) => void;
  mainNav?: Record<string, { nav_item: string; scroll_to: any }>;
  onCloseModal?: () => void;
}) => {
  const { t, languageUsed } = useTrans('ax');
  const allowedCategories = getAllowedCategories(listType);
  const categorizedData = useMemo(() => {
    return categorizeMetadata(information, categorizeInformationBy, allowedCategories);
  }, [information]);
  const { heading, ctaHeading } = getHeadingForCollapsableList(listType, informationFor, t) || {
    heading: '',
    ctaHeading: '',
  };

  const scrollRoute = () => {
    closeModal();
    if (onCloseModal) onCloseModal();
    const sectionName = listType === CollapsableListTypes.causes ? 'Causes & Symptoms' : 'Treatment Options';
    const section = Object.values(mainNav ?? {})?.find((navItem) => navItem.nav_item === sectionName);
    if (section) {
      scrollToSection(section.scroll_to);
    }
  };
  return (
    <MidPopUp closeModal={closeModal} classes='relative'>
      <main className='bg-white px-4 pt-6 '>
        <div className='pb-6 '>
          <HeaderWithBack
            heading={heading}
            onBack={closeModal}
            btnHeading={listType === CollapsableListTypes.causes ? t('my-causes') : t('my-treatment')}
            onClickBtn={scrollRoute}
          />
        </div>
        <section className='flex flex-col gap-4 pb-4'>
          {Object.keys(categorizedData)?.map((category, idx) => (
            <Accordion
              expanded={!idx}
              key={category}
              className='pt-4 rounded-lg shadow-evenly'
              backgroundColor={''}
              headerClasses='px-4 pb-3'
              header={
                <div className='flex gap-2 items-center'>
                  {listType === CollapsableListTypes.treatments && getCollapsableIcons(category.toLowerCase()) && (
                    <Image src={getCollapsableIcons(category.toLowerCase())} width={24} height={24} alt='allo icon' />
                  )}
                  <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900} language={languageUsed}>
                    {listType === CollapsableListTypes.causes ? filterTranslation(category, t) : category}
                  </Text>
                </div>
              }
              openComponent={
                <Image
                  src='/assets/svgs/chevron-down.svg'
                  width={16}
                  height={16}
                  alt='allo-icon'
                  objectFit='contain'
                  className='rotate-180'
                />
              }
              closeComponent={
                <Image src='/assets/svgs/chevron-down.svg' width={16} height={16} alt='allo-icon' objectFit='contain' />
              }
              mobile={true}>
              <div className='flex overflow-x-scroll gap-4 pb-2 px-4'>
                {categorizedData?.[category]?.length === 1 ? (
                  <section className='flex flex-col gap-2'>
                    {categorizedData?.[category][0]?.[titleizeBy] && (
                      <div className='px-4 py-3 bg-CYAN_50 flex items-center'>
                        <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900} language={languageUsed}>
                          {categorizedData?.[category][0]?.[titleizeBy]}
                        </Text>
                      </div>
                    )}
                    <Text
                      className={`${categorizedData?.[category][0]?.[titleizeBy] ? 'px-4 pb-3' : 'pb-1'}`}
                      font={FontType.REGULAR_14_150}
                      color={ColorType.GREYS_700}
                      language={languageUsed}>
                      {categorizedData?.[category][0]?.description}
                    </Text>
                  </section>
                ) : (
                  categorizedData?.[category]?.map((item) => (
                    <div className='min-w-[90%]' key={item?.title}>
                      <ArticleCard title={item?.[titleizeBy]} description={item?.description} />
                    </div>
                  ))
                )}
              </div>
            </Accordion>
          ))}
        </section>
        {listType === CollapsableListTypes.causes && (
          <div className='py-6 px-2'>
            <InfoQuestion
              onClickHandler={() => handleViewDetailedContent(CollapsableListTypes.treatments, 'title', 'subTitle')}
              text={t(`diagnosis.possible-treatment-options`)}
            />
          </div>
        )}
        {listType === CollapsableListTypes.treatments && (
          <div className='py-6 px-2'>
            <InfoQuestion
              onClickHandler={() => handleViewDetailedContent(CollapsableListTypes.causes, 'metadataType', 'title')}
              text={t(`diagnosis.causes-for`, {
                condition: informationFor,
              })}
            />
          </div>
        )}
        <div className='bg-white shadow-lg sticky bottom-0 '>
          <StickyCta heading={ctaHeading} handleBooking={handleBooking} />
        </div>
      </main>
    </MidPopUp>
  );
};

export default CollapsableList;

const ArticleCard = ({ title, description }) => {
  const { languageUsed } = useTrans('ax');
  return (
    <main className='bg-white rounded-lg overflow-hidden shadow-slot w-full'>
      <section className='px-4 py-3 bg-CYAN_50 flex items-center'>
        <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900} language={languageUsed}>
          {title}
        </Text>
      </section>
      <section className='p-4'>
        <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_700} language={languageUsed}>
          {description}
        </Text>
      </section>
    </main>
  );
};
