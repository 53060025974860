import { AxiosResponse } from 'axios';

import { Axios } from '../AxiosApiServerWrapper';

export const ConsultationService = {
  GetConsultationDetails: async (consultationId: string): Promise<AxiosResponse> => {
    const url = `consultations/${consultationId}`;
    return Axios.Request('GET', url);
  },
  GetConsultationType: async (consultationCode: string): Promise<AxiosResponse> => {
    const url = `catalog/consultation/code/${consultationCode}`;
    return Axios.Request('GET', url);
  },
};
