import { Dispatch } from '@reduxjs/toolkit';

import { RootState } from '@Redux/store';
import { LOG_ERROR } from '@Utils/logger';

import { ActionInputs } from './request-payloads';

interface AsyncReturn {
  type: 'fail' | 'success';
  result: Record<string, any>;
}

export interface CreateAsyncActionPayload {
  initAction: string;
  successAction: string;
  failAction: string;
  asyncFn: (payload: ActionInputs[keyof ActionInputs], getState: () => RootState) => Promise<AsyncReturn>;
  errorHandler?: (error: any) => any;

  returnResult?: boolean;
}

export type CreateAsyncAction<P = any> = (payload?: P) => (dispatch: Dispatch) => Promise<void>;

const createAsyncAction = ({
  initAction,
  successAction,
  failAction,
  asyncFn,
  errorHandler,
  returnResult = false,
}: CreateAsyncActionPayload) => {
  return (payload) => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch({ type: initAction });
      const response: AsyncReturn = await asyncFn(payload, getState);
      if (response.type === 'success') {
        dispatch({ type: successAction, ...response.result });

        //if returnResult is true, return the result
        if (returnResult) return response.result;
      } else {
        dispatch({ type: failAction, ...response.result });

        if (returnResult) throw new Error('Axios error');
      }
    } catch (error) {
      const errorPayload = errorHandler ? errorHandler(error) : { message: error.message };
      if (error?.message !== 'Axios Error') LOG_ERROR(`Error: ${initAction}`, { error, request: payload });
      dispatch({ type: failAction, ...errorPayload });

      if (returnResult) return error;
    }
  };
};

export default createAsyncAction;
