import { AxiosResponse } from 'axios';

import { LabVenue } from '@Reducers/encounter';
import {
  GetPaperformDataPayload,
  GetPatientEncountersPayload,
  GetPatientMetadataPayload,
  GetResourcesPayload,
  UpdateEncountersPayload,
  UpdatePatientAddressPayload,
  UpdatePatientPayload,
} from '@Services/Patient/patient-payload';

import { Axios } from '../AxiosApiServerWrapper';

export const PatientService = {
  UpdatePatient: async (payload: UpdatePatientPayload): Promise<AxiosResponse> => {
    const { shouldUpdate, ...body } = payload;
    const methodType = shouldUpdate ? 'PATCH' : 'POST';
    const url = 'core/patients';
    return Axios.Request(methodType, url, body);
  },
  UpdatePatientAddress: async (payload: UpdatePatientAddressPayload): Promise<AxiosResponse> => {
    const { ipxId, id, ...body } = payload;
    const url = id ? `core/patients/address/${id}` : `core/patients/address`;
    const isDefault = id ? undefined : true;
    const methodType = id ? 'PATCH' : 'POST';
    return Axios.Request(
      methodType,
      url,
      {
        ...body,
        ...(isDefault && { isDefault }),
      },
      { ipxId },
    );
  },
  GetPatientEncounters: async (payload: GetPatientEncountersPayload): Promise<AxiosResponse> => {
    const url = `encounters`;
    return Axios.Request('GET', url, {}, payload);
  },
  UpdateEncounters: async (payload: UpdateEncountersPayload): Promise<AxiosResponse> => {
    const { encounterId, ...body } = payload;
    const url = `encounters/${encounterId}`;
    return Axios.Request('PATCH', url, body, {});
  },
  UpdateLabVenuePreference: async (payload: { encounterId: string; venue: LabVenue }): Promise<AxiosResponse> => {
    const atttibutes = { venue: payload.venue };
    const url = `encounters/${payload.encounterId}/patient-preference`;
    return Axios.Request('PATCH', url, atttibutes, {});
  },
  GetPatientEncountersByType: async (payload: GetPatientEncountersPayload): Promise<AxiosResponse> => {
    const { type, ...rest } = payload;
    const url = `encounters/${type}`;
    return Axios.Request('GET', url, {}, rest);
  },
  GetPaperformData: async (payload: GetPaperformDataPayload): Promise<AxiosResponse> => {
    const { encounterId, ipxId, customKey } = payload;
    const url = `paperform-qa/${encounterId}`;
    return Axios.Request('GET', url, {}, { ipxId, customKey });
  },
  GetPatientDiagnoses: async (): Promise<AxiosResponse> => {
    const type = 'consultation-rx';
    const url = `encounters/diagnoses`;
    return Axios.Request('GET', url, {}, { type });
  },
  GetPatientMetadata: async (payload: GetPatientMetadataPayload): Promise<AxiosResponse> => {
    const url = `metadata`;
    return Axios.Request('GET', url, {}, payload);
  },
  GetPatientResources: async (payload: GetResourcesPayload): Promise<AxiosResponse> => {
    const url = `article`;
    return Axios.Request('GET', url, {}, payload);
  },
  GetPatient: async (): Promise<AxiosResponse> => {
    const url = `core/patients`;
    return Axios.Request('GET', url);
  },
  GetXPatient: async (patientId: string): Promise<AxiosResponse> => {
    const url = `core/patients/${patientId}`;
    return Axios.Request('GET', url);
  },
  GetXPatientAddress: async (patientId = ''): Promise<AxiosResponse> => {
    const url = `core/patients/address`;
    return Axios.Request('GET', url, {}, { ipxId: patientId });
  },
  AddPatientAddress: async (payload, ipxId?: string): Promise<AxiosResponse> => {
    const url = `core/patients/address`;
    return Axios.Request('POST', url, payload, { ipxId });
  },
  updateAddress: async (payload, ipxId?: string): Promise<AxiosResponse> => {
    const { id, ...rest } = payload;
    const url = `core/patients/address/${id}`;
    return Axios.Request('PATCH', url, rest, { ipxId });
  },
};
