import Image from 'next/image';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';

import Message from '../public/assets/cancellation-whatsapp-message.png';
import Reply from '../public/assets/cancellatoin-whatsapp-message-reply.png';
import MidPopUp from './core/mid-popup';
import { Button } from './design-system/button/button';
import { colors } from './design-system/color';

const CancellationInstructions = ({ closeModal }: { closeModal: () => void }) => {
  return (
    <MidPopUp closeModal={closeModal}>
      <main>
        <section className='p-4 flex gap-2 bg-PRIMARY_50'>
          <div className='flex items-start h-full'>
            <Image src={'/assets/svgs/tick-cancel-outline.svg'} height={28} width={28} lang='fixed' alt='allo-logo' />
          </div>
          <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_1000}>
            You have requested cancellation of this appointment
          </Text>
        </section>
        <section className='p-4'>
          <Text className='pb-4' font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_600}>
            Why to select new appointment time?
          </Text>
          <div className='flex h-full'>
            <Image
              className='rounded-xl'
              src={Message.src}
              width={200}
              height={280}
              layout='fixed'
              alt='whatsapp cancellation'
            />
            <div className='mt-auto -ml-12 flex items-end h-full'>
              <Image
                className='rounded-xl'
                src={Reply.src}
                width={200}
                height={102}
                layout='fixed'
                alt='whatsapp cancellation'
              />
            </div>
          </div>
          <Text className='text-center pb-3 pt-4' font={FontType.REGULAR_11_170} color={ColorType.GREYS_800}>
            Based on your response to{' '}
            <Image src={'/assets/svgs/whatsapp.svg'} width={12} height={12} layout='fixed' alt='allo icon' /> reminder
          </Text>
          <Button
            label={'Continue with your appointment'}
            onPress={closeModal}
            size='large'
            fullWidth={true}
            align='center'
            secondaryBackgroundColour={colors[ColorType.GREYS_100]}
            primaryColour={colors[ColorType.GREYS_900]}
            justifyContent='center'
          />
        </section>
      </main>
    </MidPopUp>
  );
};

export default CancellationInstructions;
