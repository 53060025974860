import { MedicineStates, THERAPY_STATES } from '@Components/design-system/message-card';
import { IConsultationOrder } from '@Reducers/order';
import { AppointmentStatus } from '@Reducers/schedule';
import { checkIfConsultationDay } from '@Utils/helper';

import dayjs from 'dayjs';

export const getStatus = (deliveryStatus, vendor) => {
  if (deliveryStatus?.is_delivered || vendor === 'offline') {
    return MedicineStates.DELIVERED;
  } else if (Object.keys(deliveryStatus)?.length === 0 || !deliveryStatus) {
    return MedicineStates.PENDING_DISPATCH;
  } else if (Object.keys(deliveryStatus)?.length > 0 && !deliveryStatus?.is_delivered) {
    return MedicineStates.ON_THE_WAY;
  }
};

const getConsultationStatus = (consultationStatus, appointmentStatus, startTime = '', endTime = '') => {
  if (consultationStatus === 'pending' && appointmentStatus === AppointmentStatus.RECONSULTED) {
    return THERAPY_STATES.RECONSULT;
  } else if (consultationStatus === 'pending' && appointmentStatus === AppointmentStatus.MISSED) {
    return THERAPY_STATES.MISSED;
  } else if (consultationStatus === 'pending') {
    return THERAPY_STATES.SCHEDULE_SESSION;
  } else if (
    consultationStatus === 'confirmed' &&
    (!checkIfConsultationDay(startTime) || dayjs().isAfter(dayjs(endTime)?.add(1, 'hour')))
  ) {
    return THERAPY_STATES.UPCOMING;
  } else if (
    consultationStatus === 'confirmed' &&
    checkIfConsultationDay(startTime) &&
    dayjs().isBefore(dayjs(endTime)?.add(1, 'hour'))
  ) {
    return THERAPY_STATES.JOIN_SESSION;
  } else if (consultationStatus === 'done') {
    return THERAPY_STATES.COMPLETED;
  } else if (consultationStatus === 'cancelled') {
    return THERAPY_STATES.CANCELLED;
  } else return undefined;
};

export const getValidSortedInvoices = (invoices: any = []) => {
  const sortedInvoices = invoices?.sort((a, b) => {
    return new Date(b?.createdAt)?.getTime() - new Date(a?.createdAt)?.getTime();
  });
  //get invoices with encounterId only
  const invoicesWithEncounter = sortedInvoices?.filter(
    (invoice) => invoice?.encounterId !== '' && invoice?.encounterId !== null,
  );
  return invoicesWithEncounter;
};

export const formatOrderData = (orders, date) => {
  const formattedOrders = [];
  for (const [idx, consultationOrder] of (orders || []).entries()) {
    const formattedOrder = {
      serialNo: idx + 1,
      consultationId: consultationOrder?.id,
      status: getConsultationStatus(
        consultationOrder?.status,
        consultationOrder?.appointment?.status,
        consultationOrder?.appointment?.startTime,
        consultationOrder?.appointment?.endTime,
      ),
      invoiceItemId: consultationOrder?.invoiceItemId,
      consultationTypeId: consultationOrder?.consultationTypeId,
      consultationType: {
        code: consultationOrder?.consultationType?.code,
        name: consultationOrder?.consultationType?.name,
        duration: consultationOrder?.consultationType?.duration,
        sellingPrice: consultationOrder?.consultationType?.prices?.[0]?.sellingPrice,
        listingPrice: consultationOrder?.consultationType?.prices?.[0]?.listingPrice,
      },
      appointmentData: {
        id: consultationOrder?.appointment?.id,
        consultationId: consultationOrder?.id,
        locationId: consultationOrder?.appointment?.locationId,
        startTime: consultationOrder?.appointment?.startTime,
        providerId: consultationOrder?.appointment?.providerId,
        alternateLink: consultationOrder?.appointment?.conferenceDetails?.alternateLink,
        patientMeetingLink: consultationOrder?.appointment?.conferenceDetails?.meetingLinks?.patient,
        consultationCode: consultationOrder?.consultationType?.code,
      },
      timestamps: {
        prescribed: getPrescribedDate(orders, idx, date),
        sessionDate: consultationOrder?.appointment?.startTime,
        ...(consultationOrder?.appointment?.status === AppointmentStatus.CANCELLED && {
          cancelled: consultationOrder?.appointment?.updatedAt,
        }),
      },
    } as IConsultationOrder;
    if (formattedOrder?.status !== undefined) {
      formattedOrders.push(formattedOrder);
    }
  }
  return formattedOrders;
};

const getPrescribedDate = (orders, idx, date) => {
  //prescribed date here is prescribed booking date
  if (idx === 0) return date;
  else {
    //if previous consultation is not completed, then do not give prescribed date
    const prevOrder = orders[idx - 1];
    const prevOrderDate = prevOrder?.status === 'done' ? prevOrder?.appointment?.startTime : undefined;
    return prevOrderDate;
  }
};

export const sortCounsellingOrders = (orders) => {
  orders?.sort((a, b) => {
    if (
      (!a?.appointment || (a?.appointment?.status === AppointmentStatus.RESCHEDULED && a?.status === 'pending')) &&
      b?.appointment &&
      b?.appointment?.status !== AppointmentStatus.RESCHEDULED &&
      b?.status !== 'pending'
    ) {
      return 1;
    }
    if (
      (!b?.appointment || (a?.appointment?.status === AppointmentStatus.RESCHEDULED && a?.status === 'pending')) &&
      a?.appointment &&
      a?.appointment?.status !== AppointmentStatus.RESCHEDULED &&
      a?.status !== 'pending'
    ) {
      return -1;
    }
    // Sort by appointment start time
    const startTimeA = new Date(a?.appointment?.createdAt)?.getTime();
    const startTimeB = new Date(b?.appointment?.createdAt)?.getTime();
    return startTimeA - startTimeB;
  });
  return orders;
};

export const sortFollowUpOrdersOnCreatedAt = (orders) => {
  //sort by consultation created at
  orders?.sort((a, b) => {
    const createdAtA = new Date(a?.createdAt)?.getTime();
    const createdAtB = new Date(b?.createdAt)?.getTime();
    return createdAtB - createdAtA;
  });
  return orders;
};

export const sortFollowUpOrdersOnAppointment = (orders, attribute: 'createdAt' | 'startTime') => {
  //sort by consultation appointment attributes
  orders?.sort((a, b) => {
    const createdAtA = new Date(a?.appointment?.[attribute])?.getTime();
    const createdAtB = new Date(b?.appointment?.[attribute])?.getTime();
    return createdAtB - createdAtA;
  });
  return orders;
};

export enum InvoiceStatus {
  CREATED = 'created',
  CANCELLED = 'cancelled',
  PAID = 'paid',
  PARTIAL_REFUND_PENDING = 'part_refund_pending',
  PARTIALLY_REFUNDED = 'partly_refunded',
  REFUND_PENDING = 'refund_pending',
  REFUNDED = 'refunded',
  REFUND_FAILED = 'refund_failed',
}
