import dayjs from 'dayjs';
import Image from 'next/image';
import React from 'react';

import MidPopUp from '@Components/core/mid-popup';
import { Button } from '@Components/design-system/button/button';
import { colors } from '@Components/design-system/color';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { transformAmount } from '@Utils/helper';
import { useTrans } from '@Utils/hooks/translation';

const PayAtClinicPopup = ({
  closeModal,
  selectedSlot,
  selectedDate,
  locality,
  handleBookAppointment,
  feeAmount,
}: {
  closeModal: () => void;
  selectedSlot: any;
  selectedDate: string;
  locality: string;
  handleBookAppointment: (selectedSlot: any, payOnArrival: boolean) => void;
  feeAmount: number;
}) => {
  const { t, languageUsed } = useTrans();
  return (
    <MidPopUp closeModal={closeModal}>
      <div className='flex flex-col gap-2 '>
        <div className='pt-6 px-4 flex flex-col gap-3'>
          <div>
            <Text
              font={FontType.SEMIBOLD_18_150}
              color={ColorType.GREYS_900}
              language={languageUsed}
              className='text-start'>
              {t('pay-at-clinic-popup.pay-at-clinic')}
            </Text>
            <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_900}>
              {t(`pay-at-clinic-popup.description`, {
                amount: transformAmount(feeAmount),
              })}
            </Text>
          </div>
          <div className='bg-BLUE_GREY_75 border border-INDIGO_700 rounded-md flex flex-col pb-3'>
            <div className='bg-BLUE_GREY_700 flex gap-1 px-1.5 py-px items-center rounded-b-lg ml-48  whitespace-nowrap'>
              <Image src='/assets/svgs/star-grey.svg' width={10} height={10} alt='attention' />
              <Text
                font={FontType.REGULAR_10_170}
                color={ColorType.GREYS_100}
                language={languageUsed}
                className='uppercase'>
                {t('pay-at-clinic-popup.pay-at-clinic')}
              </Text>
            </div>
            <div className='mx-4 '>
              <Text font={FontType.REGULAR_12_170} color={ColorType.GREYS_800} language={languageUsed}>
                {t('pay-at-clinic-popup.appointment-info')}
              </Text>
              <div className='flex justify-between'>
                <BookingDetails
                  imagePath='/assets/svgs/calendar-black.svg'
                  text={dayjs(selectedDate).format('ddd, DD MMM ')}
                />
                <BookingDetails
                  imagePath='/assets/svgs/clock.svg'
                  text={dayjs(selectedSlot?.startDate)?.format('h:mm A')}
                />
                <BookingDetails imagePath='/assets/svgs/location-black.svg' text={locality} />
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col py-2 shadow-lg border-t border-GREYS_400 shadow-GREYS_500 px-4 mt-4'>
          <Button
            label={
              <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_100} language={languageUsed}>
                {t('pay-at-clinic-popup.pay-at-clinic')}
              </Text>
            }
            labelColour={colors[ColorType.GREYS_100]}
            type='secondary'
            secondaryBackgroundColour={``}
            className='whitespace-nowrap '
            fullWidth={true}
            size='small'
            align='center'
            borderRadius={50}
            onPress={() => handleBookAppointment(selectedSlot, true)}
          />
          <button
            onClick={() => handleBookAppointment(selectedSlot, false)}
            className='flex items-center my-2 gap-2 mx-auto '>
            <Text font={FontType.SEMIBOLD_12_170} color={ColorType.INDIGO_600} language={languageUsed}>
              {t('pay-at-clinic-popup.continue-with-payment')}
            </Text>
            <Image
              src='/assets/svgs/left-arrow-primary.svg'
              alt='location'
              width={6}
              height={6}
              className='rotate-180'
            />
          </button>
        </div>
      </div>
    </MidPopUp>
  );
};

export default PayAtClinicPopup;

export const BookingDetails = ({ imagePath, text }) => {
  return (
    <div className='flex items-center gap-2 mt-2'>
      <Image src={imagePath} width={16} height={16} alt='doctor' />
      <Text font={FontType.REGULAR_10_170} color={ColorType.GREYS_900} className='whtespace-nowrap'>
        {text}
      </Text>
    </div>
  );
};
