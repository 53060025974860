import { rxTypes } from '@Utils/constants';
import { IAddress } from '@Services/Patient/patient-payload';

export const sortEncounters = (encounters) => {
  const sortedEncounters = encounters?.sort((a, b) => {
    return new Date(b?.createdAt)?.getTime() - new Date(a?.createdAt)?.getTime();
  });
  return sortedEncounters;
};

export const getDiagnoses = (encounters) => {
  const diagnosesList = [];
  for (const encounter of encounters || []) {
    for (const diagnosis of encounter?.diagnoses || []) {
      if (encounter?.type !== rxTypes.MERGE_RX && encounter?.type !== rxTypes.SCREENING_RX) continue;
      if (!diagnosesList?.includes(diagnosis?.description)) diagnosesList?.push(diagnosis?.description);
    }
  }
  return diagnosesList;
};

export const getSessionGoals = (summaries, sessionGoalsDictionary = {}) => {
  const sessionGoals = [];
  for (const summary of summaries || []) {
    const { type, description } = summary;
    if (!type || type !== 'counselling_goal') {
      continue;
    }
    sessionGoals.push({
      title: description,
      description: sessionGoalsDictionary?.[description] !== 0 ? sessionGoalsDictionary?.[description] : '',
    });
  }
  return sessionGoals;
};

export const extractArray = (summaries = [], key: string = ''): string[] => {
  const sessionSummary = [];
  for (const summary of summaries || []) {
    const { type, description } = summary;
    if (!type || type !== key) {
      continue;
    }
    sessionSummary?.push(description);
  }
  return sessionSummary;
};

export const getUniqueEncounters = (encounters = []) => {
  //get unique encounters based on appointmentId and filter out unnecessary rx type encounters
  const uniqueEncounters = [];
  if (encounters.length === 0) return uniqueEncounters;
  const uniqueAppointments = new Set();
  return encounters.filter((encounter) => {
    if (!uniqueAppointments.has(encounter?.appointmentId)) {
      uniqueAppointments.add(encounter?.appointmentId);
      return true;
    }
    return false;
  });
};
export const getAllAddresses = (addresses: IAddress[]) => {
  const defaultAddressPresent = addresses?.some((address) => address?.isDefault);
  return addresses?.map((address, index) => {
    return {
      id: address?.id,
      street: address?.street,
      city: address?.city,
      state: address?.state,
      pincode: address?.pincode,
      country: address?.country,
      isDefault: !defaultAddressPresent ? index === 0 : address?.isDefault,
    };
  });
};

export function UpdateAddress({
  isCurrentAddress,
  selectedAddress,
  street,
  city,
  state,
  country,
  pin,
  updateExistingAddress,
  addNewAddresses,
}: {
  isCurrentAddress: boolean;
  selectedAddress: IAddress;
  street: string;
  city: string;
  state: string;
  country: string;
  pin: string;
  updateExistingAddress: any;
  addNewAddresses: (payload) => void;
}) {
  if (selectedAddress?.id) {
    updateExistingAddress({
      id: selectedAddress.id.toString(),
      street: street.toString(),
      city: city.toString(),
      state: state.toString(),
      country: country.toString(),
      pincode: pin.toString(),
      isDefault: isCurrentAddress,
    });
  } else {
    addNewAddresses({
      street: street?.toString(),
      city: city?.toString(),
      state: state?.toString(),
      country: country?.toString(),
      pincode: pin?.toString(),
      isDefault: isCurrentAddress,
    });
  }
}
