import Image from 'next/image';
import React from 'react';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { callInstructions } from '@Utils/constants';

import MidPopUp from './core/mid-popup';
import { Button } from './design-system/button/button';
import { colors } from './design-system/color';

const CallInstructions = ({ closeModal }: { closeModal: () => void }) => {
  return (
    <MidPopUp closeModal={closeModal}>
      <main className='p-6'>
        <div className='flex gap-2'>
          <Image src={'/assets/svgs/info-purple.svg'} height={24} width={24} alt='information' />
          <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_1000}>
            Important Steps
          </Text>
        </div>
        <div className='grid grid-cols-1 divide-y divide-GREYS_400'>
          {callInstructions?.map((instruction, index) => (
            <div key={instruction.instrction} className='py-4'>
              <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_800}>
                {index + 1}. {instruction.instrction}
              </Text>
              <div className='flex gap-2 pt-1'>
                {instruction.images?.map((image) => (
                  <Image
                    key={image.src}
                    src={image.src}
                    width={image.width}
                    height={image.height}
                    alt='allo-icon'
                    layout='fixed'
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className='w-2/3 ml-auto'>
          <Button
            align='center'
            label={'Continue'}
            onPress={closeModal}
            secondaryBackgroundColour={colors[ColorType.GREYS_100]}
            primaryColour={colors[ColorType.GREYS_900]}
            justifyContent='center'
            fullWidth={true}
            disabledBackgroundColour={colors[ColorType.GREYS_600]}
            size='large'
          />
        </div>
      </main>
    </MidPopUp>
  );
};

export default CallInstructions;
