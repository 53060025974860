export function setLocal(key, value) {
  try {
    if (typeof window !== 'undefined' && window?.localStorage && value != 'undefined') {
      localStorage.setItem(key, value);
    }
  } catch (error) {
    throw new Error('storage_access_denied');
  }
}

export function getLocal(key) {
  try {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key);
    }
  } catch (error) {
    throw new Error('storage_access_denied');
  }
}

export function removeFromLocal(key) {
  try {
    if (typeof window !== 'undefined') {
      localStorage.removeItem(key);
    }
  } catch (error) {
    throw new Error('storage_access_denied');
  }
}
