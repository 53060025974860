import dayjs from 'dayjs';
import Image from 'next/image';
import { useState } from 'react';

import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { IAppointments } from '@Reducers/checkout';
import { IQueryData } from '@Reducers/common';
import { RESCHEDULE_REASON, CONSULTATION_STATE, whatsappContactLink, PAGE } from '@Utils/constants';
import { checkAppointmentExpiry } from '@Utils/helper';
import useCustomRouter from '@Utils/hooks/custom-router';

import Calendar from '../public/assets/svgs/calendar.svg';
import ChevronRightPurple from '../public/assets/svgs/chevron-right-purple.svg';
import DoctorImage from './core/doctor-image/v1';
import { Button } from './design-system/button/button';
import { colors } from './design-system/color';
import { ColorType } from './design-system/color/types';
import { IDoctorData } from './expert-advice';
import { DoctorInfoFrame } from './expert-advice';

interface JoinCallWigetProps {
  appointment?: IAppointments;
  doctorData?: IDoctorData;
  doctorLanguages?: string[];
  callLink?: string;
  queryData: IQueryData;
}

const launchwhatsapp = () => {
  window.open(`${whatsappContactLink}Hi, Reschedule with doctor who speaks my language`);
};

const JoinCallWiget = ({ appointment, doctorData, callLink, doctorLanguages = [], queryData }: JoinCallWigetProps) => {
  const [viewDoctorInfo, setViewDoctorInfo] = useState<boolean>(false);
  const [viewDisclaimer, setViewDisclaimer] = useState<boolean>(false);
  const aboutTime = dayjs(appointment?.startTime).diff(dayjs(), 'minute', true) <= 15;
  const handleJoinCall = () => {
    if (callLink) {
      window.top.location = callLink;
    }
  };
  const handleViewDoctorInfo = () => {
    setViewDoctorInfo(!viewDoctorInfo);
  };
  const handleViewDisclaimer = () => {
    setViewDisclaimer(!viewDisclaimer);
  };
  const customRouter = useCustomRouter();
  const rescheduleRedirection = () => {
    customRouter({
      pathname: PAGE.APPOINTMENT.ROUTE,
      query: {
        appointment_state: CONSULTATION_STATE.RESCHEDULE,
        form_type: 'DIRECT_LINK',
        consultation_id: appointment?.consultationId,
        product_code: appointment?.consultationCode,
        reason: RESCHEDULE_REASON,
        selected_languages: queryData?.selectedLanguages,
        valid_user_city: queryData?.validUserCity,
      },
    });
  };
  return (
    <main className='bg-white shadow-md rounded-lg shadow-slot w-full'>
      <section className='grid grid-cols-1 divide-y divide-y-GREYS_300 px-4'>
        <section className='grid grid-cols-3 justify-items-stretch py-4'>
          <div className='flex justify-self-start gap-3 col-span-2 w-full'>
            <div>
              <div className='flex gap-2'>
                <Image
                  className='h-full flex flex-col justify-start items-start'
                  src={Calendar.src}
                  width={24}
                  height={24}
                  alt='allo-icon'
                />
                <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900}>
                  Your consultation day
                </Text>
              </div>
              <Text className='flex pl-8 items-center' font={FontType.REGULAR_11_170} color={ColorType.GREYS_700}>
                {appointment?.startTime && dayjs(appointment?.startTime).format('dddd, DD MMM, YYYY')}
              </Text>
            </div>
          </div>
          <div className='flex justify-end items-center'>
            {!checkAppointmentExpiry(appointment?.startTime) ? (
              <div onClick={rescheduleRedirection} className='flex justify-self-end items-center gap-1'>
                <Text font={FontType.SEMIBOLD_12_170} color={ColorType.GREYS_900}>
                  Reschedule
                </Text>
                <Image src={ChevronRightPurple.src} width={8} height={8} alt='allo-icon' />
              </div>
            ) : (
              <Image src={'/assets/svgs/Check.svg'} width={32} height={32} layout='fixed' alt='allo-icon' />
            )}
          </div>
        </section>
        <section onClick={handleViewDoctorInfo} className='flex justify-between py-4'>
          <div className='flex gap-2 '>
            {doctorData?.image && (
              <Image
                className='rounded-full bg-PRIMARY_50'
                src={`${doctorData?.image}`}
                width={60}
                height={60}
                layout='fixed'
                alt='allo-icon'
              />
            )}
            <div className='flex flex-col gap-0.5'>
              <Text font={FontType.SEMIBOLD_10_170} color={ColorType.GREYS_600}>
                Your sexual expert
              </Text>
              <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900}>
                {doctorData?.name}
              </Text>
              <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_700}>
                {doctorData?.designation}
              </Text>
            </div>
          </div>
          <div className='flex flex-col justify-center'>
            <Text className='underline cursor-pointer' color={ColorType.GREYS_900} font={FontType.SEMIBOLD_10_170}>
              View profile
            </Text>
          </div>
        </section>
      </section>
      {doctorLanguages?.length > 0 && (
        <section className='px-6 flex gap-2 items-center pb-3 relative'>
          <Image
            onClick={handleViewDisclaimer}
            className='cursor-pointer'
            src={'/assets/svgs/info-orange.svg'}
            height={16}
            width={16}
            alt={'allo-icon'}
            layout='fixed'
          />
          {viewDisclaimer && (
            <div className='absolute left-2 -top-24 flex flex-col'>
              <div className=' w-80 flex items-center gap-2 py-2 px-2 border border-ATTENTION_600 rounded-lg bg-ORANGE_YELLOW_100'>
                <div className='w-1/5'>
                  <Image src={'/assets/svgs/spotlight.svg'} height={40} width={40} alt={'allo-icon'} layout='fixed' />
                </div>
                <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_1000}>
                  Please &nbsp;
                  <span onClick={launchwhatsapp} className='underline font-semibold text-blue-900'>
                    contact support
                  </span>
                  &nbsp; if the languages spoken by the doctor are not as per your preference.
                </Text>
              </div>
              <div className='ml-1 pl-4 -mt-0.5 inline-block overflow-hidden'>
                <div className='h-6 w-6 origin-top-right rotate-45 transform border border-ATTENTION_600 bg-ORANGE_YELLOW_100'></div>
              </div>
            </div>
          )}
          <Text font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_900}>
            Languages spoken
          </Text>
          {doctorLanguages?.map((language) => (
            <Text
              className='bg-ORANGE_YELLOW_100 py-0.5 px-2 rounded-full'
              key={language}
              font={FontType.REGULAR_11_170}
              color={ColorType.ATTENTION_800}>
              {language}
            </Text>
          ))}
        </section>
      )}
      <section className=' py-6 px-4 bg-PRIMARY_25'>
        <div className='flex justify-between items-center'>
          <div className='w-5/12'>
            <Button
              onPress={handleJoinCall}
              fullWidth={true}
              disabled={!aboutTime}
              disabledBackgroundColour={colors[ColorType.GREYS_600]}
              label={'Join video call'}
              primaryColour={colors[ColorType.GREYS_1000]}
              secondaryBackgroundColour={colors[ColorType.GREYS_100]}
              justifyContent='center'
            />
          </div>
          <div className='flex justify-center w-1/2'>
            <Text font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_1000}>
              {dayjs(appointment?.startTime).format('DD MMM, YYYY') + ','}
              &nbsp;
            </Text>
            <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_1000}>
              {dayjs(appointment?.startTime).format('h:mm A')}
            </Text>
          </div>
        </div>
        <div>
          <Text font={FontType.SEMIBOLD_10_170} color={ColorType.GREYS_800} className='pt-2'>
            You can join the call 15 minutes before the scheduled time.
          </Text>
        </div>
      </section>
    </main>
  );
};
export default JoinCallWiget;

export const DoctorWidget = ({ doctorData, button }: { doctorData: IDoctorData; button?: React.ReactNode }) => {
  const [viewDoctorInfo, setViewDoctorInfo] = useState<boolean>(false);
  const handleViewDoctorInfo = () => {
    setViewDoctorInfo(!viewDoctorInfo);
  };
  return (
    <>
      {viewDoctorInfo && <DoctorInfoFrame doctorData={doctorData} handleCloseFrame={handleViewDoctorInfo} />}
      <section onClick={handleViewDoctorInfo} className='flex justify-between py-4'>
        <div className='flex gap-2 '>
          <DoctorImage doctorImage={doctorData?.image} size={60} backgroundColor={ColorType.PRIMARY_50} />
          <div className='flex flex-col gap-0.5'>
            <Text font={FontType.SEMIBOLD_10_170} color={ColorType.GREYS_600}>
              Your sexual expert
            </Text>
            <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900}>
              {doctorData?.name}
            </Text>
            <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_700}>
              {doctorData?.designation}
            </Text>
          </div>
        </div>
        {button ??
          (doctorData?.linkName && (
            <div className='flex flex-col justify-center'>
              <Text
                className='underline cursor-pointer whitespace-nowrap'
                color={ColorType.GREYS_900}
                font={FontType.SEMIBOLD_10_170}>
                View profile
              </Text>
            </div>
          ))}
      </section>
    </>
  );
};
