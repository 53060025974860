import { ActionConsts } from '@Definitions/ActionConsts';

export interface IToggle {
  uploadingFile: boolean;
}

const INITIAL_STATE = {
  uploadingFile: false,
} as IToggle;
export const ToggleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionConsts.Toggle.ToggleData:
      return {
        ...state,
        ...action.payload,
      };
    case ActionConsts.Toggle.ResetToggleData:
      return INITIAL_STATE;
    default:
      return state;
  }
};
