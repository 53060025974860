import React from 'react';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';

const SlotVisibilityDisclaimer = ({
  showText,
  index,
  margin,
}: {
  showText: Record<string, string>;
  index?: number;
  margin?: number;
}) => {
  return (
    <div
      className={`absolute flex flex-col  mx-auto rounded-lg bg-GREYS_900 p-2 z-10 mt-2 ${
        index > 1 ? 'right-2 md:left-2 ' : ''
      } ${margin && `mt-${margin}`}`}
      style={{ maxWidth: '280px' }}>
      <Text color={ColorType.GREYS_100} font={FontType.SEMIBOLD_11_170}>
        {showText?.heading}
      </Text>
      <Text color={ColorType.GREYS_100} font={FontType.REGULAR_11_170}>
        {showText?.subtext}
      </Text>
    </div>
  );
};
export default SlotVisibilityDisclaimer;
