import Image from 'next/image';
import { useState } from 'react';

import { LANGUAGE, LOCALE } from '@Utils/constants';

import { ColorType } from './design-system/color/types';
import Text from './design-system/text';
import { FontType } from './design-system/text/types';

export default function LanguageDropdown({ language, setLanguage, handleChangeLanguage }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLanguageChange = (language, locale) => {
    setLanguage(language);
    setDropdownOpen(false);
    handleChangeLanguage(locale);
  };

  return (
    <div className='z-30 flex items-start justify-start'>
      <div className='z-20 relative'>
        <div
          className='z-20 px-4 bg-white rounded cursor-pointer flex flex-row'
          onClick={() => setDropdownOpen(!dropdownOpen)}>
          <span className='relative flex h-3 w-3 mx-2'>
            <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-PRIMARY opacity-75' />
            <span className='relative inline-flex rounded-full h-3 w-3 bg-PRIMARY_600' />
          </span>
          <Text font={FontType.SEMIBOLD_11_170} color={ColorType.INDIGO_600}>
            {language}
          </Text>
          <Image src={'/assets/svgs/chevron-indigo.svg'} height={16} width={16} alt='allo icon' layout='fixed' />
        </div>

        {dropdownOpen && (
          <div className='z-50 absolute top-full mt-2 w-28 rounded-md shadow-lg'>
            <div className='z-50 rounded-md bg-white shadow-xs'>
              <div
                className='z-50 cursor-pointer p-2 hover:bg-gray-200'
                onClick={() => handleLanguageChange(LANGUAGE.ENGLISH, LOCALE.ENGLISH)}>
                <Text font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_800}>
                  {LANGUAGE.ENGLISH}
                </Text>
              </div>
              <div
                className='z-50 cursor-pointer p-2 hover:bg-gray-200'
                onClick={() => handleLanguageChange(LANGUAGE.HINDI, LOCALE.HINDI)}>
                <Text font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_800}>
                  {LANGUAGE.HINDI}
                </Text>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
