import { useEffect, useReducer, useState } from 'react';

import { ISchedule, ISlot } from '@Reducers/schedule';
import { IUserData } from '@Reducers/user';
import { useAppSelector } from '@Redux/hooks';
import { SLOT_SELECTION } from '@Utils/constants';
import { getFirstSlot, getFirstSlotForSelectedDate } from '@Utils/Helpers/slot.helper';

export interface ISelectables {
  day: string;
  slot?: ISlot;
}

const INIT_SELECTED_DATE: ISelectables = {
  day: '',
  slot: {} as ISlot,
};

enum SelectableActions {
  INITIALIZE = 'INITIALIZE',
  SELECT_DAY = 'SELECT_DAY',
  SELECT_SLOT = 'SELECT_SLOT',
}

interface ISelectableReturnType {
  selectables: ISelectables;
  changeDay: (day: string) => void;
  changeSlot: (slot: ISlot) => void;
}

const useSelectables = (slots, trackSlots: (eventName: string, slot: ISlot) => void): ISelectableReturnType => {
  const services = new Services();
  const schedule: ISchedule = useAppSelector((state) => state.schedule);
  const [nonDefaultActivityCount, setNonDefaultActivityCount] = useState(0);
  const [selectables, selectableDispatch] = useReducer(
    (
      state: ISelectables,
      action: {
        type: SelectableActions;
        payload: any;
      },
    ) => {
      switch (action.type) {
        case SelectableActions.INITIALIZE: {
          return {
            day: action.payload?.day,
            slot: action.payload?.slot,
          };
        }
        case SelectableActions.SELECT_DAY: {
          const firstSlot = getFirstSlotForSelectedDate(slots[action.payload]);
          trackSlots(SLOT_SELECTION.SELECTED, firstSlot);
          return {
            day: action.payload,
            slot: firstSlot,
          };
        }
        case SelectableActions.SELECT_SLOT: {
          trackSlots(SLOT_SELECTION.SELECTED, action.payload);
          return {
            ...state,
            slot: action.payload,
          };
        }
        default:
          return state;
      }
    },
    INIT_SELECTED_DATE,
  );

  // Initialize the first slot
  useEffect(() => {
    if (schedule?.isFetchingSlots || schedule?.fetchedSlots === undefined) return;
    const firstSlot = getFirstSlot(slots);
    selectableDispatch({
      type: SelectableActions.INITIALIZE,
      payload: {
        day: Object.keys(slots || {})?.[0],
        slot: firstSlot,
      },
    });
    trackSlots(SLOT_SELECTION.DEFAULT, firstSlot);
  }, [slots, schedule?.isFetchingSlots]);

  const changeDay = (day: string) => {
    if (day !== selectables.day && nonDefaultActivityCount < 1) {
      setNonDefaultActivityCount(nonDefaultActivityCount + 1);
    }
    selectableDispatch({
      type: SelectableActions.SELECT_DAY,
      payload: day,
    });
  };

  const changeSlot = (slot: ISlot) => {
    if (slot?.startDate !== selectables?.slot?.startDate && nonDefaultActivityCount < 1) {
      setNonDefaultActivityCount(nonDefaultActivityCount + 1);
    }
    selectableDispatch({
      type: SelectableActions.SELECT_SLOT,
      payload: slot,
    });
  };

  return { selectables, changeDay, changeSlot };
};

export default useSelectables;

class Services {
  user: IUserData;

  constructor() {
    this.user = useAppSelector((state) => state.user.userData);
  }
}
