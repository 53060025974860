import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { defaultBookingAmount } from '@Utils/constants';
import { useTrans } from '@Utils/hooks/translation';

export function UndeterminedCauseCard({ handleBooking, amount }: { handleBooking: () => void; amount?: number }) {
  const { t, languageUsed } = useTrans('ax');
  return (
    <section className={`px-4 py-6 bg-PRIMARY_1000`}>
      <Container>
        <div className='flex justify-start flex-col items-start flex-none box-border gap-2 w-full'>
          <div className='flex w-full justify-between flex-row items-start flex-none box-border gap-2 pb-2'>
            <Text
              className='inline'
              font={FontType.SEMIBOLD_18_150}
              color={ColorType.GREYS_900}
              language={languageUsed}>
              {t('undeterminedCard.title')}
            </Text>
            <div className='bg-ERROR_75 rounded-full w-8 h-8 flex items-center justify-center'>
              <Image
                className='rounded bg-PRIMARY_50'
                src={`/assets/svgs/alert-octagon.svg`}
                width={20}
                height={20}
                alt='Question'
              />
            </div>
          </div>
          <div className='flex justify-start flex-col items-start flex-none box-border gap-1.5 pb-4'>
            <Text className='inline' font={FontType.REGULAR_14_150} color={ColorType.GREYS_700} language={languageUsed}>
              {t('undeterminedCard.description')}
            </Text>
          </div>
          {!!amount && (
            <div
              onClick={() => handleBooking()}
              className='w-full bg-black py-3 rounded flex justify-center items-center'>
              <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_100} language={languageUsed}>
                {t('talk-to-doctor-at', {
                  amount: defaultBookingAmount,
                })}
              </Text>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
}

const Container = styled.div.attrs({
  className: 'flex justify-start flex-col items-start rounded bg-white box-border gap-2.5 p-4 shadow-sm',
})``;
