import dayjs, { Dayjs } from 'dayjs';

import { ISlot, ISlotData, TimePeriod } from '@Reducers/schedule';
import { SlotConfig, Visibility } from '@Reducers/schedule/slot-config';
import { timePeriods } from '@Utils/constants';

/**
 * Determines the time period for a given hour based on the start date and instant slot availability.
 * The function decides whether a slot should be considered as an instant slot or falls into one of the predefined time periods.
 * An instant slot is defined as a slot that occurs within the next hour, hasn't surpassed the allowed instant slot count,
 * and is enabled through the showInstantSlots flag.
 *
 * @param {number} hour - The hour for which the time period is to be determined.
 * @param {Dayjs} startDate - The start date of the slot to compare with the current time.
 * @param {boolean} showInstantSlots - Flag indicating whether instant slots are allowed.
 * @param {number} allowedInstantSlotCount - The maximum number of instant slots allowed.
 * @param {number} [instantCount=0] - The current count of instant slots.
 * @returns {TimePeriod} The time period (including Instant) for the given hour.
 */
const getTimePeriodIncludingInstant = (
  hour: number,
  startDate: Dayjs,
  showInstantSlots: boolean,
  allowedInstantSlotCount: number,
  instantCount = 0,
) => {
  //slot is taken instant if is in next 1 hour and less than allowed instant slot count
  const isInstant =
    showInstantSlots &&
    dayjs(startDate).diff(dayjs(), 'hour', true) < 1 &&
    dayjs(startDate).diff(dayjs(), 'hour', true) >= 0 &&
    instantCount < allowedInstantSlotCount;
  if (isInstant) return TimePeriod.Instant;

  return Object.values(TimePeriod).find((p) => {
    if (p === TimePeriod.Instant) return false;
    const { start, end } = timePeriods[p];
    return hour >= start && hour < end;
  });
};

const getDifferenceInDays = (date: string): number => dayjs(date).diff(dayjs().startOf('day'), 'day');

const addSlotToGroup = (slotsGrouped, day, period, slot, startDate) => {
  if (!slotsGrouped[day])
    slotsGrouped[day] = {
      Instant: [],
      Morning: [],
      Afternoon: [],
      Evening: [],
    };
  slotsGrouped[day][period].push({ ...slot, startDate });
};

export const groupSlotsByDate = (
  slots: {
    startDate: string;
  }[],
  restrictSlotPeriods: boolean,
  showInstantSlots: boolean,
) => {
  const slotsGrouped = {};
  let slotsAdded = 0;
  let fallbackEncountered = false;
  for (const slot of slots ?? []) {
    if (!slot?.startDate) return;
    const startDate = dayjs(new Date(slot.startDate));
    const day = startDate.format('YYYY-MM-DD');
    const hour = Number.parseInt(startDate.format('HH'));
    //day not present in Visibility config then do not proceed
    if (restrictSlotPeriods && !SlotConfig[getDifferenceInDays(day)]) break;

    const { start, end } = timePeriods['All Slots'];
    if (hour >= start && hour < end) {
      const allowedInstantSlotCount = getVisibility(getDifferenceInDays(day), TimePeriod.Instant)?.count;
      const period: TimePeriod = getTimePeriodIncludingInstant(
        hour,
        startDate,
        showInstantSlots,
        allowedInstantSlotCount,
        slotsGrouped?.[day]?.[TimePeriod.Instant]?.length,
      );
      if (!period) continue;
      //get Visibility, setting default to be shown
      const slotVisibility = restrictSlotPeriods
        ? getVisibility(getDifferenceInDays(day), period)?.visibility
        : Visibility.SHOW;

      switch (slotVisibility) {
        case Visibility.SHOW: {
          addSlotToGroup(slotsGrouped, day, period, slot, startDate);
          slotsAdded++;
          break;
        }
        case Visibility.FALLBACK: {
          if (slotsAdded <= 5 || fallbackEncountered) {
            fallbackEncountered = true;
            addSlotToGroup(slotsGrouped, day, period, slot, startDate);
            slotsAdded++;
          }
          break;
        }
        case Visibility.HIDDEN: {
          continue;
        }
      }
    }
  }
  restrictSlots(slotsGrouped, restrictSlotPeriods);
  return slotsGrouped;
};

export const getVisibility = (day, period: TimePeriod): { visibility: Visibility; count: number } => {
  const currentHour = dayjs().hour();
  let visibility = Visibility.SHOW;
  let count;

  const slotConfig = SlotConfig[day];
  if (slotConfig) {
    for (const [hourRange, config] of Object.entries(slotConfig)) {
      const [start, end] = hourRange.split('-').map(Number);
      if (currentHour >= start && currentHour <= end) {
        visibility = config.visibility[period];
        count = config.count[period];
        break;
      }
    }
  }

  return { visibility, count };
};

/**
 * Applies slot count restrictions per time period for each date, adjusting for instant slot usage.
 * It trims slots exceeding allowed counts.
 */
const restrictSlots = (groupedSlots: Record<string, Record<TimePeriod, ISlot[]>>, restrictSlotPeriods: boolean) => {
  let invertSelection = false;
  for (const [date, periods] of Object.entries(groupedSlots)) {
    for (const [period, slots] of Object.entries(periods)) {
      //setting default restriction to be 10, if slot is not in slot-config
      let { count = 10 } = restrictSlotPeriods ? getVisibility(getDifferenceInDays(date), period as TimePeriod) : {};
      if (
        getDifferenceInDays(date) === 0 &&
        period === getTimePeriodIncludingInstant(Number.parseInt(dayjs().format('HH')), dayjs(), false, 0)
      ) {
        invertSelection = true;
        count = count - groupedSlots[date][TimePeriod.Instant].length;
      } else {
        invertSelection = false;
      }
      if (slots.length > count) {
        groupedSlots[date][period] = selectSlots(slots, count, invertSelection); // Selects a subset of slots based on the allowed count.
      }
    }
  }
};

/**
 * Selects and sorts slots up to a specified count, balancing across different hours.
 * First half are taken directly; the rest are distributed by hour for variety.
 *
 * @param {Array<ISlot>} slots - Original slot array.
 * @param {number} allowedCount - Max slots to select.
 * @returns {ISlot[]} Sorted selected slots, balancing early and varied hours.
 */
const selectSlots = (slots: Array<ISlot>, allowedCount: number, invertSelection: boolean): ISlot[] => {
  if (slots?.length <= allowedCount) return slots;
  const selectedSlots: ISlot[] = invertSelection ? [] : slots?.slice(0, allowedCount / 2);
  // Group slots by hour
  const slotsByHour: { [hour: string]: ISlot[] } = {};
  slots?.slice(allowedCount / 2)?.forEach((slot: ISlot) => {
    if (slot) {
      const hour = dayjs(slot.startDate).format('HH');
      if (!slotsByHour[hour]) slotsByHour[hour] = [];
      slotsByHour[hour].push(slot);
    }
  });

  const totalHours = Object.keys(slotsByHour)?.length;
  if (!totalHours) return;
  for (const _ in Array.from({
    length: Math.ceil(allowedCount / totalHours) ?? 0,
  })) {
    for (const hour in slotsByHour ?? {}) {
      if (selectedSlots?.length === allowedCount) break;
      const randomPos = getRandomIndex(slotsByHour?.[hour]?.length);
      const selectedSlot = slotsByHour?.[hour]?.[randomPos];
      if (selectedSlot) selectedSlots?.push(selectedSlot);
      slotsByHour?.[hour]?.splice(randomPos, 1);
    }
    if (selectedSlots?.length === allowedCount) break;
  }
  selectedSlots?.sort((a, b) => {
    return new Date(a?.startDate)?.getTime() - new Date(b?.startDate)?.getTime();
  });
  return selectedSlots;
};

function getRandomIndex(upperRange) {
  if (upperRange <= 0 || !Number.isInteger(upperRange)) {
    return 0;
  }
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0] % upperRange;
}

export const getTimePeriodForHour = (hour: number): TimePeriod => {
  return Object.values(TimePeriod).find((p) => {
    if (p === TimePeriod.Instant) return false;
    const { start, end } = timePeriods[p];
    return hour >= start && hour < end;
  });
};

//if instant slots are available and there are no slots in other time periods, then add instant slots to the other time periods
export const convertInstantSlots = (slots: ISlotData): ISlotData => {
  const today = dayjs().format('YYYY-MM-DD');
  const todaySlots = slots[today];

  if (
    !todaySlots ||
    todaySlots.Instant.length === 0 ||
    todaySlots.Morning.length > 0 ||
    todaySlots.Afternoon.length > 0 ||
    todaySlots.Evening.length > 0
  ) {
    return slots;
  }

  const instantSlotsTimePeriod = getTimePeriodIncludingInstant(dayjs().hour(), dayjs(), false, 0);

  if (instantSlotsTimePeriod !== TimePeriod.Instant) {
    todaySlots[instantSlotsTimePeriod] = [...(todaySlots[instantSlotsTimePeriod] || []), ...todaySlots.Instant];
    todaySlots.Instant = [];
  }

  return slots;
};
