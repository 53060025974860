import Image from 'next/image';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { useTrans } from '@Utils/hooks/translation';

const Disclaimer = () => {
  const { t, languageUsed } = useTrans('ax');
  return (
    <section
      className='border border-gray-200 shadow-lg flex flex-col gap-2 py-3 px-4 rounded-lg'
      style={{
        background: 'linear-gradient(290.86deg, #F4F3FF 52.96%, rgba(244, 243, 255, 0) 100%)',
      }}>
      <div className='flex pt-1 gap-[6px] items-center'>
        <Image src={`/assets/svgs/alert-primary.svg`} width={16} height={16} alt='allo-icon' />
        <Text color={ColorType.PRIMARY_700} font={FontType.SEMIBOLD_11_170} language={languageUsed}>
          {t('disclaimer.important')}
        </Text>
      </div>
      <div className='w-11/12'>
        <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_900} language={languageUsed}>
          {t('disclaimer.disclaimer')}
        </Text>
      </div>
    </section>
  );
};

export default Disclaimer;
