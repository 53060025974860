import dayjs from 'dayjs';
import Image from 'next/image';

import { DefaultScreeningCallStatusWidget, DoctorsShowCaseWidget } from '@Components/appointment-status-widget';
import { colors } from '@Components/design-system/color';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { IDoctorData } from '@Components/expert-advice';
import { FollowUpUtil } from '@Components/followup-widget';
import { DoctorWidget } from '@Components/join-call-widget';
import { ReconsultWidget } from '@Components/reconsult-widget';
import { RescheduleUtil } from '@Components/reschedule-appointment';
import Callday from '@Images/svgs/call-day.svg';
import Capsule from '@Images/svgs/capsule-purple.svg';
import Tickgreen from '@Images/svgs/Check.svg';
import Check from '@Images/svgs/circle-green-bg.svg';
import Frown from '@Images/svgs/frown.svg';
import Heart from '@Images/svgs/heart-purple.svg';
import Testtube from '@Images/svgs/test-tube-purple.svg';
import Cancel from '@Images/svgs/tick-cancel-outline.svg';
import { IQueryData } from '@Reducers/common';
import { IConsultationOrder, IDrugOrder } from '@Reducers/order';
import { IAddress } from '@Services/Patient/patient-payload';
import { CONSULTATION_STATE, CONSULTATION_TYPE, PAGE } from '@Utils/constants';
import { canBookConsultation, formatDate, getSevenDayRange, getProvider, formatAddress } from '@Utils/helper';
import useCustomRouter from '@Utils/hooks/custom-router';

import { Button } from '../button/button';
interface MessageCardProps {
  deliveryItem: any;
  prescriptionType:
    | 'medications'
    | 'diagnostic tests'
    | 'sex counselling'
    | 'followup'
    | 'patient query'
    | 'report reading';
  state: string;
  launchAction?: any;
  address?: any;
  providerData?: IDoctorData;
  sessionCount?: number;
  isAgentOrProvider?: boolean;
  queryData?: IQueryData;
  allProviderData?: any[];
}

export interface IMessage {
  heading?: string;
  title?: string;
  buttonLabel?: string;
  icon?: string;
  label?: string;
  address?: IAddress;
  primaryActionLine?: string;
  secondaryActionLine?: string;
  tertioaryActionLine?: string;
  description?: string;
  action?: () => void;
  showDoctorInfo?: boolean;
  bulletColor?: ColorType;
  enableReschedule?: boolean;
  midWidget?: React.ReactNode;
}

export enum MedicineStates {
  ACTION_PENDING = 'Action Pending',
  UPDATE_ADDRESS = 'Update Address',
  RECEIVED_ORDER = 'Received Order',
  PENDING_DISPATCH = 'Pending Dispatch',
  ON_THE_WAY = 'On the Way',
  DELIVERED = 'Delivered',
}

export enum TEST_STATES {
  ACTION_PENDING = 'Action Pending',
  UPDATE_ADDRESS = 'Update Address',
  PENDING_COLLECTION = 'Pending Collection',
  ON_THE_WAY = 'On the Way',
  SAMPLE_COLLECTED = 'Sample Collected',
  REPORT_GENERATED = 'Report Generated',
  DEAD_STATE = 'Dead State',
  CANCELLED = 'Cancelled',
}

export enum THERAPY_STATES {
  PURCHASE_MORE = 'Purchase More',
  PURCHASE_PENDING = 'Purchase Pending',
  SCHEDULE_SESSION = 'Schedule Session',
  MISSED = 'Missed',
  UPCOMING = 'Upcoming',
  JOIN_SESSION = 'Join Session',
  COMPLETED = 'Completed',
  RECONSULT = 'Reconsult',
  CANCELLED = 'Cancelled',
}

export enum GENERAL_CONSULTATION_STATES {
  PURCHASE_MORE = 'Purchase More',
  PURCHASE_PENDING = 'Purchase Pending',
  SCHEDULE_SESSION = 'Schedule Session',
  UPCOMING = 'Upcoming',
  MISSED = 'Missed',
  JOIN_SESSION = 'Join Session',
  COMPLETED = 'Completed',
  RECONSULT = 'Reconsult',
  CANCELLED = 'Cancelled',
}

export enum LEAD_TO_CALL_STATES {
  STARTED = 'started',
  ASSESSMENT_INITIATED = 'assessmentInitiated',
  ASSESSMENT_COMPLETED = 'assessmentCompleted',
  BOOKING_COMPLETED = 'bookingCompleted',
}

export enum CALL_DONE_TO_PURCHASE_STATES {
  CALL_DONE = 'callDone',
  PURCHASE_COMPLETED = 'purchaseCompleted',
}

const getMedicineMessage = (state: string, deliveryItem: IDrugOrder, launchAction: () => void, address: string) => {
  const message = {};
  switch (state) {
    case MedicineStates.ACTION_PENDING: {
      message['title'] = 'Please add your delivery address to receive medicines on time.';
      message['buttonLabel'] = 'Add Address';
      message['icon'] = Capsule.src;
      message['label'] = 'Medicines';
      message['primaryActionLine'] = deliveryItem?.timeStamps?.prescribed && 'Prescribed on';
      message['secondaryActionLine'] = deliveryItem?.timeStamps?.prescribed
        ? dayjs(deliveryItem?.timeStamps?.prescribed).format('dddd, DD MMM, YYYY')
        : '';
      message['action'] = launchAction;
      break;
    }
    case MedicineStates.UPDATE_ADDRESS: {
      message['title'] = 'You want to update this address?';
      message['buttonLabel'] = 'Yes';
      message['icon'] = Capsule.src;
      message['label'] = 'Medicines';
      message['address'] = address;
      message['primaryActionLine'] = 'Modify address';
      message['action'] = launchAction;
      break;
    }
    case MedicineStates.RECEIVED_ORDER: {
      message['heading'] = 'Pending dispatch';
      message['title'] = 'We have received the order and our team is working on your dispatch.';
      message['icon'] = Capsule.src;
      message['label'] = 'Medicines';
      message['primaryActionLine'] = 'Order received';
      message['secondaryActionLine'] = deliveryItem?.timeStamps?.received
        ? dayjs(deliveryItem?.timeStamps?.received).format("h:mm A, D MMM'YYYY")
        : '';
      message['buttonLabel'] = 'Track Order';
      break;
    }
    case MedicineStates.PENDING_DISPATCH: {
      message['title'] = 'Your medicines will be dispatched soon!';
      message['buttonLabel'] = 'Track Order';
      message['icon'] = Capsule.src;
      message['label'] = 'Medicines';
      message['primaryActionLine'] = 'Order packed';
      message['secondaryActionLine'] = deliveryItem?.timeStamps?.packed
        ? dayjs(deliveryItem?.timeStamps?.packed).format("h:mm A, D MMM'YYYY")
        : '';
      message['action'] = launchAction;
      break;
    }
    case MedicineStates.ON_THE_WAY: {
      message['title'] = 'We have dispatched your medicines order.';
      message['buttonLabel'] = 'Track Order';
      message['icon'] = Capsule.src;
      message['label'] = 'Medicines';
      message['primaryActionLine'] = 'Dispatched';
      message['secondaryActionLine'] = deliveryItem?.timeStamps?.dispatched
        ? dayjs(deliveryItem?.timeStamps?.dispatched).format("h:mm A, D MMM'YYYY")
        : '';
      message['action'] = launchAction;
      break;
    }
    case MedicineStates.DELIVERED: {
      message['title'] = 'Please take your medicines as prescribed!';
      message['buttonLabel'] = 'View Prescription';
      message['icon'] = Capsule.src;
      message['label'] = 'Medicines';
      message['primaryActionLine'] = 'Have any doubts?';
      message['secondaryActionLine'] = 'Related to your medicines..';
      message['description'] =
        "Our experts encourage you to take to your medications as per your doctor's instructions.";
      message['action'] = launchAction;
      message['heading'] = deliveryItem?.timeStamps?.delivered
        ? `Delivered on ${dayjs(deliveryItem?.timeStamps?.delivered).format("h:mm A, D MMM'YYYY")}`
        : 'Delivered';
      break;
    }
  }
  return message;
};

const getTestMessage = (state, deliveryItem, launchAction) => {
  const message = {};
  switch (state) {
    case TEST_STATES.ACTION_PENDING: {
      message['title'] = 'Please add your delivery address.';
      message['buttonLabel'] = 'Add Address';
      message['icon'] = Testtube.src;
      message['label'] = 'Diagnostic Tests';
      message['primaryActionLine'] = deliveryItem?.timeStamps?.prescribed && 'Prescribed on';
      message['secondaryActionLine'] = deliveryItem?.timeStamps?.prescribed
        ? dayjs(deliveryItem?.timeStamps?.prescribed).format('dddd, DD MMM, YYYY')
        : '';
      message['action'] = launchAction;
      break;
    }
    case TEST_STATES.UPDATE_ADDRESS: {
      message['title'] = 'You want to update this address?';
      message['buttonLabel'] = 'Yes';
      message['icon'] = Testtube.src;
      message['label'] = 'Diagnostic Tests';
      message['address'] = deliveryItem?.address;
      message['primaryActionLine'] = 'Modify address';
      message['action'] = launchAction;
      break;
    }
    case TEST_STATES.PENDING_COLLECTION: {
      message['title'] = 'Our team will contact you shortly';
      message['buttonLabel'] = 'View Preparations';
      message['icon'] = Testtube.src;
      message['label'] = 'Diagnostic Tests';
      message['primaryActionLine'] = 'Order received';
      message['secondaryActionLine'] = formatDate(deliveryItem?.timeStamps?.prescribed, "h:mm A, D MMM'YYYY");
      message['description'] =
        'We will get in touch with you to schedule your sample collection at your preferred place and time';
      message['action'] = launchAction;
      break;
    }
    case TEST_STATES.ON_THE_WAY: {
      message['title'] = 'Sample collected scheduled';
      message['buttonLabel'] = 'View Order';
      message['icon'] = Testtube.src;
      message['label'] = 'Diagnostic Tests';
      message['primaryActionLine'] = 'Collection scheduled';
      message['secondaryActionLine'] = formatDate(deliveryItem?.timeStamps?.scheduled, "h:mm A, D MMM'YYYY");
      message['description'] =
        'Your sample collection has been scheduled successfully, our lab partners will contact you with more details';
      message['action'] = launchAction;
      break;
    }
    case TEST_STATES.SAMPLE_COLLECTED: {
      message['title'] = ' Sample collection successfully done';
      message['buttonLabel'] = 'Track Status';
      message['icon'] = Testtube.src;
      message['label'] = 'Diagnostic Tests';
      message['primaryActionLine'] = 'Sample collected';
      message['secondaryActionLine'] = formatDate(deliveryItem?.timeStamps?.collected, "h:mm A, D MMM'YYYY");
      message['description'] = 'Our lab partners have received your sample and will be sharing you test results soon';
      message['action'] = launchAction;
      break;
    }
    case TEST_STATES.REPORT_GENERATED: {
      message['title'] = 'Your test report are ready!';
      message['buttonLabel'] = 'View Reports';
      message['icon'] = Testtube.src;
      message['label'] = 'Diagnostic Tests';
      message['primaryActionLine'] = 'Report generated';
      message['description'] =
        'Your lab test results are now ready, please schedule a report discussion with your doctor';
      message['secondaryActionLine'] = formatDate(deliveryItem?.timeStamps?.reported, "h:mm A, D MMM'YYYY");
      message['action'] = launchAction;
      break;
    }
    case TEST_STATES.DEAD_STATE: {
      //temporary state to show the user has lab test order.
      message['heading'] = 'In Progress';
      message['icon'] = Testtube.src;
      message['label'] = 'Diagnostic Tests';
      message['title'] = 'Our team will get in touch with you to execute this.';
      message['description'] = 'Our team will get in touch with you to execute this.';
      break;
    }
    case TEST_STATES.CANCELLED: {
      message['heading'] = 'Cancelled';
      message['icon'] = Testtube.src;
      message['label'] = 'Diagnostic Tests';
      message['title'] = 'Your test has been cancelled.';
      message['description'] = 'Our team will get in touch with you to execute this.';
      break;
    }
  }
  return message;
};

const getTherapyMessage = (
  state,
  launchAction,
  deliveryItem,
  prescriptionType,
  sessionNumber = '',
  isAgentOrProvider = false,
) => {
  const message = {};
  switch (state) {
    case THERAPY_STATES.PURCHASE_MORE: {
      message['heading'] = 'Purchase session';
      message['title'] = `Purchase session ${sessionNumber}`;
      message['buttonLabel'] = 'Purchase session';
      message['icon'] = Heart.src;
      message['label'] = 'Counselling session';
      message['description'] =
        'Your purchased sessions have been completed. Please purchase another session to continue your counselling';
      message['action'] = () => launchAction(state, deliveryItem);
      message['showDoctorInfo'] = true;
      break;
    }
    case THERAPY_STATES.PURCHASE_PENDING: {
      message['heading'] = 'Recommended by Counsellor';
      message['title'] = `Purchase session ${sessionNumber}`;
      message['buttonLabel'] = 'Purchase session';
      message['icon'] = Heart.src;
      message['label'] = 'Counselling session';
      message['description'] = 'This session will help you gain better insight on your concerns.';
      message['primaryActionLine'] = `₹ ${deliveryItem?.sellingPrice}`;
      message['action'] = launchAction;
      break;
    }
    case THERAPY_STATES.MISSED:
    case THERAPY_STATES.SCHEDULE_SESSION: {
      const isFirstTherapySession =
        deliveryItem?.consultationType?.code === CONSULTATION_TYPE.TH && Number.parseInt(sessionNumber || '') === 1;
      const secondaryActionLine = isFirstTherapySession
        ? ''
        : `after session ${sessionNumber ? '#' + (Number.parseInt(sessionNumber) - 1)?.toString() : ''}`;
      const secondaryFollowUpActionLine = deliveryItem?.timestamps?.prescribed
        ? `Between ${getSevenDayRange(deliveryItem?.timestamps?.prescribed)}`
        : 'towards the end of the course.';
      message['heading'] = `Schedule session ${sessionNumber}`;
      message['title'] = `Schedule Counselling Session ${'#' + sessionNumber}`;
      message['buttonLabel'] = 'Book session';
      message['icon'] = Heart.src;
      message['label'] = 'Counselling session';
      message['primaryActionLine'] = isFirstTherapySession ? '' : 'To be scheduled';
      message['secondaryActionLine'] =
        deliveryItem?.consultationType?.code === CONSULTATION_TYPE.FU
          ? secondaryFollowUpActionLine
          : secondaryActionLine;
      message['description'] = 'Your introductory session will help you gain better insight on your concerns.';
      message['action'] = canBookConsultation(deliveryItem, isAgentOrProvider)
        ? () => launchAction(state, deliveryItem)
        : undefined;
      break;
    }
    case THERAPY_STATES.UPCOMING: {
      message['heading'] = dayjs(deliveryItem?.timestamps?.sessionDate).isBefore(dayjs())
        ? 'Session Missed'
        : undefined;
      message['title'] = dayjs(deliveryItem?.timestamps?.sessionDate).isBefore(dayjs())
        ? `Session ${'#' + sessionNumber} missed`
        : `Session ${'#' + sessionNumber} scheduled`;
      message['description'] = 'We assure you the guidance to help you in this journey.';
      message['icon'] = Heart.src;
      message['label'] = 'Counselling session';
      message['action'] = canBookConsultation(deliveryItem, isAgentOrProvider)
        ? () => launchAction(state, deliveryItem)
        : undefined;
      break;
    }
    case THERAPY_STATES.RECONSULT: {
      // the consultation is marked reconstulted from retool as requested. The patient is expected to schedule a new session but make it look as reconsulting appointment.
      message['title'] = `Reconsult Session ${'#' + sessionNumber}`;
      message['description'] = 'This session will help you gain better insight on your concerns.';
      message['icon'] = Heart.src;
      message['label'] = 'Counselling session';
      break;
    }
    case THERAPY_STATES.JOIN_SESSION: {
      message['title'] = `Session ${'#' + sessionNumber}`;
      message['description'] = 'Your session with our mental health expert is today.';
      message['icon'] = Heart.src;
      message['label'] = 'Counselling session';
      message['buttonLabel'] = 'Join Call';
      message['primaryActionLine'] = deliveryItem?.timestamps?.sessionDate ? 'Join 15 minutes before' : '';
      message['secondaryActionLine'] = deliveryItem?.timestamps?.sessionDate
        ? 'Today, ' + dayjs(deliveryItem?.timestamps?.sessionDate).format('h:mm A')
        : '';
      message['action'] =
        dayjs(deliveryItem?.timestamps?.sessionDate).diff(dayjs(), 'minute', true) <= 15
          ? () => window.open(deliveryItem?.appointmentData?.patientMeetingLink, '_blank')
          : undefined;
      message['showDoctorInfo'] = true;
      break;
    }
    case THERAPY_STATES.COMPLETED: {
      message['title'] = `Session ${'#' + sessionNumber} report ready!`;
      message['description'] =
        'Your mental health expert has written a customised report for you based on the session.';
      message['icon'] = Heart.src;
      message['label'] = 'Counselling session';
      message['buttonLabel'] = 'View Report';
      message['primaryActionLine'] = deliveryItem?.timestamps?.sessionDate ? 'Completed on' : '';
      message['secondaryActionLine'] = deliveryItem?.timestamps?.sessionDate
        ? dayjs(deliveryItem?.timestamps?.sessionDate).format('D MMM, YYYY, h:mm A')
        : '';
      message['action'] = () => launchAction(state, deliveryItem);
      message['showDoctorInfo'] = true;
      break;
    }
    case THERAPY_STATES.CANCELLED: {
      message['title'] = `Session ${'#' + sessionNumber} cancelled!`;
      message['description'] = 'This counselling session has been cancelled.';
      message['icon'] = Heart.src;
      message['label'] = 'Counselling session';
      message['primaryActionLine'] = deliveryItem?.timestamps?.cancelled ? 'Cancelled on' : '';
      message['secondaryActionLine'] = deliveryItem?.timestamps?.cancelled
        ? dayjs(deliveryItem?.timestamps?.cancelled).format('D MMM, YYYY')
        : '';
      break;
    }
  }
  return message;
};

const getPatientQueryMessage = (state, launchAction, patientQueryOrder, sessionNumber = '') => {
  const message = {};
  switch (state) {
    case GENERAL_CONSULTATION_STATES.SCHEDULE_SESSION: {
      message['heading'] = `Schedule session ${sessionNumber}`;
      message['title'] = `Schedule Query Session ${'#' + sessionNumber}`;
      message['buttonLabel'] = 'Book session';
      message['icon'] = Heart.src;
      message['label'] = 'Query session';
      message['description'] = 'The session will clear your doubts and help you in this journey.';
      message['action'] = () => launchAction(state, patientQueryOrder);
      break;
    }
    case GENERAL_CONSULTATION_STATES.UPCOMING: {
      message['title'] = `Session ${'#' + sessionNumber} scheduled`;
      message['description'] = 'We assure you the guidance to help you in this journey.';
      message['icon'] = Heart.src;
      message['label'] = 'Query session';
      message['action'] = () => launchAction(state, patientQueryOrder);
      break;
    }
    case GENERAL_CONSULTATION_STATES.RECONSULT: {
      // the consultation is marked reconstulted from retool as requested. The patient is expected to schedule a new session but make it look as reconsulting appointment.
      message['title'] = `Reconsult Session ${'#' + sessionNumber}`;
      message['description'] = 'The session will clear your doubts and help you in this journey.';
      message['icon'] = Heart.src;
      message['label'] = 'Query session';
      break;
    }
    case GENERAL_CONSULTATION_STATES.JOIN_SESSION: {
      message['title'] = `Session ${'#' + sessionNumber}`;
      message['description'] = 'Please connect to resolve your queries';
      message['icon'] = Heart.src;
      message['label'] = 'Query session';
      message['buttonLabel'] = 'Join Call';
      message['primaryActionLine'] = patientQueryOrder?.timestamps?.sessionDate ? 'Join 15 minutes before' : '';
      message['secondaryActionLine'] = patientQueryOrder?.timestamps?.sessionDate
        ? 'Today, ' + dayjs(patientQueryOrder?.timestamps?.sessionDate).format('h:mm A')
        : '';
      message['action'] =
        dayjs(patientQueryOrder?.timestamps?.sessionDate).diff(dayjs(), 'minute', true) <= 15
          ? () => window.open(patientQueryOrder?.appointmentData?.patientMeetingLink, '_blank')
          : undefined;
      message['showDoctorInfo'] = true;
      break;
    }
    case GENERAL_CONSULTATION_STATES.COMPLETED: {
      message['title'] = `Session ${'#' + sessionNumber} Completed!`;
      message['description'] = 'We hope your queries were resolved in the session.';
      message['icon'] = Heart.src;
      message['label'] = 'Query session';
      message['primaryActionLine'] = patientQueryOrder?.timestamps?.sessionDate ? 'Completed on' : '';
      message['secondaryActionLine'] = patientQueryOrder?.timestamps?.sessionDate
        ? dayjs(patientQueryOrder?.timestamps?.sessionDate).format('D MMM, YYYY, h:mm A')
        : '';
      message['showDoctorInfo'] = true;
      break;
    }
    case GENERAL_CONSULTATION_STATES.CANCELLED: {
      message['title'] = `Session ${'#' + sessionNumber} cancelled!`;
      message['description'] = 'This Query session has been cancelled.';
      message['icon'] = Heart.src;
      message['label'] = 'Query session';
      message['primaryActionLine'] = patientQueryOrder?.timestamps?.cancelled ? 'Cancelled on' : '';
      message['secondaryActionLine'] = patientQueryOrder?.timestamps?.cancelled
        ? dayjs(patientQueryOrder?.timestamps?.cancelled).format('D MMM, YYYY')
        : '';
      break;
    }
  }
  return message;
};

const getReportReadingMessage = (state, launchAction, reportReadingOrder, sessionNumber = '') => {
  const message = {};
  switch (state) {
    case GENERAL_CONSULTATION_STATES.SCHEDULE_SESSION: {
      message['heading'] = `Schedule session ${sessionNumber}`;
      message['title'] = `Schedule Report Reading Session ${'#' + sessionNumber}`;
      message['buttonLabel'] = 'Book session';
      message['icon'] = Heart.src;
      message['label'] = 'Report Reading session';
      message['description'] = 'Your doctor will discuss your lab test reports and provide next steps.';
      message['action'] = () => launchAction(state, reportReadingOrder);
      break;
    }
    case GENERAL_CONSULTATION_STATES.UPCOMING: {
      message['title'] = `Session ${'#' + sessionNumber} scheduled`;
      message['description'] = 'We assure you the guidance to help you in this journey.';
      message['icon'] = Heart.src;
      message['label'] = 'Report reading session';
      message['action'] = () => launchAction(state, reportReadingOrder);
      break;
    }
    case GENERAL_CONSULTATION_STATES.RECONSULT: {
      // the consultation is marked reconstulted from retool as requested. The patient is expected to schedule a new session but make it look as reconsulting appointment.
      message['title'] = `Reconsult Session ${'#' + sessionNumber}`;
      message['description'] = 'Your doctor will discuss your lab test reports and provide next steps.';
      message['icon'] = Heart.src;
      message['label'] = 'Report reading session';
      break;
    }
    case GENERAL_CONSULTATION_STATES.JOIN_SESSION: {
      message['title'] = `Session ${'#' + sessionNumber}`;
      message['description'] = 'Please connect to understand your diagnostic test results.';
      message['icon'] = Heart.src;
      message['label'] = 'Report reading session';
      message['buttonLabel'] = 'Join Call';
      message['primaryActionLine'] = reportReadingOrder?.timestamps?.sessionDate ? 'Join 15 minutes before' : '';
      message['secondaryActionLine'] = reportReadingOrder?.timestamps?.sessionDate
        ? 'Today, ' + dayjs(reportReadingOrder?.timestamps?.sessionDate).format('h:mm A')
        : '';
      message['action'] =
        dayjs(reportReadingOrder?.timestamps?.sessionDate).diff(dayjs(), 'minute', true) <= 15
          ? () => window.open(reportReadingOrder?.appointmentData?.patientMeetingLink, '_blank')
          : undefined;
      message['showDoctorInfo'] = true;
      break;
    }
    case GENERAL_CONSULTATION_STATES.COMPLETED: {
      message['title'] = `Session ${'#' + sessionNumber} Completed!`;
      message['description'] = 'We hope your doctor was able answer your queries related to the lab test reports.';
      message['icon'] = Heart.src;
      message['label'] = 'Report reading session';
      message['primaryActionLine'] = reportReadingOrder?.timestamps?.sessionDate ? 'Completed on' : '';
      message['secondaryActionLine'] = reportReadingOrder?.timestamps?.sessionDate
        ? dayjs(reportReadingOrder?.timestamps?.sessionDate).format('D MMM, YYYY, h:mm A')
        : '';
      message['showDoctorInfo'] = true;
      break;
    }
    case GENERAL_CONSULTATION_STATES.CANCELLED: {
      message['title'] = `Session ${'#' + sessionNumber} cancelled!`;
      message['description'] = 'This Report reading session has been cancelled.';
      message['icon'] = Heart.src;
      message['label'] = 'Report reading session';
      message['primaryActionLine'] = reportReadingOrder?.timestamps?.cancelled ? 'Cancelled on' : '';
      message['secondaryActionLine'] = reportReadingOrder?.timestamps?.cancelled
        ? dayjs(reportReadingOrder?.timestamps?.cancelled).format('D MMM, YYYY')
        : '';
      break;
    }
  }
  return message;
};

export const getScreeningCallMessage = (state, consultationOrder: IConsultationOrder, launchAction: any) => {
  const message: IMessage = {};
  switch (state) {
    case GENERAL_CONSULTATION_STATES.PURCHASE_MORE: {
      message.title = `Book a new consultation to Re-Assess your condition.`;
      message.buttonLabel = 'Book Appointment';
      message.icon = Callday.src;
      message.midWidget = <DoctorsShowCaseWidget />;
      message.label = 'Please book a new consultation for an updated personalized treatment.';
      message.action = () => launchAction(state, consultationOrder);
      message.showDoctorInfo = false;
      message.enableReschedule = false;
      break;
    }
    case GENERAL_CONSULTATION_STATES.RECONSULT: {
      message.title = 'We are offering you a free consultation with the doctor.';
      message.icon = Callday.src;
      message.bulletColor = ColorType.ORANGE_YELLOW_600;
      message.enableReschedule = false;
      message.showDoctorInfo = false;
      message.buttonLabel = 'Book Appointment for ₹0';
      message.action = () => launchAction(state, consultationOrder);
      message.midWidget = <DoctorsShowCaseWidget />;
      break;
    }
    case GENERAL_CONSULTATION_STATES.SCHEDULE_SESSION: {
      message.title = 'Schedule a call with the doctor.';
      message.icon = Callday.src;
      message.bulletColor = ColorType.ORANGE_YELLOW_600;
      message.enableReschedule = false;
      message.showDoctorInfo = true;
      message.buttonLabel = 'Book Session';
      message.action = () => launchAction(state, consultationOrder);
      break;
    }
    case GENERAL_CONSULTATION_STATES.MISSED: {
      message.title = 'Call could not be completed';
      message.icon = Frown.src;
      message.description = 'Missed';
      message.bulletColor = ColorType.ERROR_800;
      message.buttonLabel = 'Rebook appointment';
      message.label = 'Book a new appointment with the doctor';
      message.enableReschedule = false;
      message.primaryActionLine = formatDate(consultationOrder?.appointmentData?.startTime, 'ddd, D MMM');
      message.secondaryActionLine = formatDate(consultationOrder?.appointmentData?.startTime, 'h:mm A');
      message.showDoctorInfo = true;
      message.action = () => launchAction(state, consultationOrder);
      message.midWidget = <DefaultScreeningCallStatusWidget message={message} />;
      break;
    }
    case GENERAL_CONSULTATION_STATES.UPCOMING: {
      const didMiss: boolean = dayjs(consultationOrder?.appointmentData?.startTime).isBefore(dayjs());
      message.primaryActionLine = formatDate(consultationOrder?.appointmentData?.startTime, 'ddd, D MMM');
      message.secondaryActionLine = formatDate(consultationOrder?.appointmentData?.startTime, 'h:mm A');
      message.showDoctorInfo = true;
      message.action = () => launchAction(state, consultationOrder);
      if (!didMiss) {
        message['title'] = 'Consultation booked!';
        message['icon'] = Check.src;
        message.description = 'Confirmed';
        message.bulletColor = ColorType.SUCCESS_600;
        message.enableReschedule = true;
      } else {
        message.title = 'Call could not be completed';
        message.icon = Frown.src;
        message.description = 'Missed';
        message.bulletColor = ColorType.ERROR_800;
        message.buttonLabel = 'Rebook appointment';
        message.label = 'Book a new appointment with the doctor';
        message.enableReschedule = false;
      }
      message.midWidget = <DefaultScreeningCallStatusWidget message={message} />;
      break;
    }
    case GENERAL_CONSULTATION_STATES.JOIN_SESSION: {
      message.title = 'It’s your consultation day!';
      message.icon = Callday.src;
      message.description = 'Confirmed';
      message.bulletColor = ColorType.SUCCESS_600;
      message.buttonLabel = 'Join call';
      message.action =
        dayjs(consultationOrder?.timestamps?.sessionDate).diff(dayjs(), 'minute', true) <= 15
          ? () => window.open(consultationOrder?.appointmentData?.patientMeetingLink, '_blank')
          : undefined;
      message.showDoctorInfo = true;
      message.label = 'Button will be activated 15 mins before.';
      message.primaryActionLine = formatDate(consultationOrder?.appointmentData?.startTime, 'DD/MM/YYYY');
      message.secondaryActionLine = formatDate(consultationOrder?.appointmentData?.startTime, 'h:mm A');
      message.enableReschedule = false;
      message.tertioaryActionLine = 'View important steps';
      message.midWidget = <DefaultScreeningCallStatusWidget message={message} />;
      break;
    }
    case GENERAL_CONSULTATION_STATES.CANCELLED: {
      message.title = 'You have requested cancellation of this appointment';
      message['icon'] = Cancel.src;
      message.primaryActionLine = formatDate(consultationOrder?.appointmentData?.startTime, 'DD/MM/YYYY');
      message.secondaryActionLine = formatDate(consultationOrder?.appointmentData?.startTime, 'h:mm A');
      message.description = 'Unattended';
      message.bulletColor = ColorType.ATTENTION_500;
      message.buttonLabel = 'Book a new appointment';
      message.action = () => launchAction(state, consultationOrder);
      message.label = 'Book a new slot at your convenient time';
      message.enableReschedule = false;
      message.tertioaryActionLine = 'How this works';
      message.showDoctorInfo = true;
      message.midWidget = <DefaultScreeningCallStatusWidget message={message} />;
      break;
    }
    case GENERAL_CONSULTATION_STATES.COMPLETED: {
      message.title = 'Consultation completed successfully!';
      message.icon = Tickgreen.src;
      message.primaryActionLine = formatDate(consultationOrder?.appointmentData?.startTime, "ddd, D MMM'YY");
      message.secondaryActionLine = formatDate(consultationOrder?.appointmentData?.startTime, 'h:mm A');
      message.description = 'Completed';
      message.bulletColor = ColorType.SUCCESS_600;
      message.enableReschedule = false;
      message.showDoctorInfo = true;
      message.midWidget = <DefaultScreeningCallStatusWidget message={message} />;
      break;
    }
  }
  return message;
};

const getMessage = (prescriptionType, state, deliveryItem, launchAction, address, sessionCount, isAgentOrProvider) => {
  switch (prescriptionType) {
    case 'medications': {
      return getMedicineMessage(state, deliveryItem, launchAction, address);
    }
    case 'diagnostic tests': {
      return getTestMessage(state, deliveryItem, launchAction);
    }
    case 'followup':
    case 'sex counselling': {
      return getTherapyMessage(state, launchAction, deliveryItem, prescriptionType, sessionCount, isAgentOrProvider);
    }
    case 'patient query': {
      return getPatientQueryMessage(state, launchAction, deliveryItem, sessionCount);
    }
    case 'report reading': {
      return getReportReadingMessage(state, launchAction, deliveryItem, sessionCount);
    }
  }
};

const getHeadColors = (state) => {
  switch (state) {
    case MedicineStates.ACTION_PENDING:
    case MedicineStates.UPDATE_ADDRESS:
    case THERAPY_STATES.SCHEDULE_SESSION:
      return {
        textColor: ColorType.ATTENTION_600,
        backgroundColor: ColorType.ORANGE_YELLOW_50,
      };
    default:
      return {
        textColor: ColorType.PRIMARY_600,
        backgroundColor: ColorType.PRIMARY_50,
      };
  }
};

const MessageCard = ({
  deliveryItem,
  prescriptionType,
  state,
  launchAction,
  address,
  sessionCount,
  providerData,
  isAgentOrProvider,
  queryData,
  allProviderData,
}: MessageCardProps) => {
  const message: IMessage = getMessage(
    prescriptionType,
    state,
    deliveryItem,
    launchAction,
    address,
    sessionCount,
    isAgentOrProvider,
  );
  const { textColor, backgroundColor } = getHeadColors(state);
  const doctorData = getProvider(allProviderData, deliveryItem?.appointmentData?.providerId);
  return (
    <section className='rounded-lg overflow-hidden shadow-lg w-full min-h-[160px]'>
      <div className={`w-full flex justify-end py-0.5 px-4 bg-${backgroundColor}`}>
        <Text font={FontType.SEMIBOLD_11_170} color={textColor}>
          {message?.heading || state}
        </Text>
      </div>
      {prescriptionType === 'followup' ? (
        <FollowUpUtil
          doctorData={
            state === THERAPY_STATES.SCHEDULE_SESSION || state === THERAPY_STATES?.PURCHASE_MORE
              ? providerData
              : doctorData
          }
          description='Please schedule a follow up appointment with your doctor to review your progress and get your updated next course.'
        />
      ) : (
        <section className='grid grid-cols-1 divide-y divide-GREYS_400 px-4 gap-3 pb-3 bg-white'>
          <MidCardDefault message={message} />
          {message?.showDoctorInfo && (
            <DoctorWidget
              doctorData={state === THERAPY_STATES.PURCHASE_MORE ? providerData : doctorData}
              button={
                state === THERAPY_STATES.JOIN_SESSION ? (
                  <RescheduleButton appointment={deliveryItem?.appointmentData} queryData={queryData} />
                ) : undefined
              }
            />
          )}
        </section>
      )}
      {state !== TEST_STATES.DEAD_STATE && (
        <div className='bg-PRIMARY_25 px-4 py-3 flex justify-between gap-6'>
          {state === THERAPY_STATES.UPCOMING || state === THERAPY_STATES.RECONSULT ? (
            (state === THERAPY_STATES.UPCOMING && (
              <RescheduleUtil
                color={ColorType.GREYS_900}
                calendarIcon={undefined}
                chevronIcon={undefined}
                appointment={deliveryItem?.appointmentData}
                appointmentDate={deliveryItem?.appointmentData?.startTime}
                queryData={queryData}
                launchAction={message?.action}
              />
            )) ||
            (state === THERAPY_STATES.RECONSULT && (
              <ReconsultWidget
                appointment={deliveryItem?.appointmentData}
                appointmentDate={deliveryItem?.appointmentData?.startTime}
                queryData={queryData}
              />
            ))
          ) : (
            <BottomCardDefault state={state} message={message} />
          )}
        </div>
      )}
    </section>
  );
};

export default MessageCard;

const RescheduleButton = ({ appointment, queryData }) => {
  const customRouter = useCustomRouter();
  const rescheduleRedirection = () => {
    customRouter({
      pathname: PAGE.APPOINTMENT.ROUTE,
      query: {
        appointment_state: CONSULTATION_STATE.RESCHEDULE,
        form_type: 'DIRECT_LINK',
        consultation_id: appointment?.consultationId,
        product_code: appointment?.consultationCode,
        selected_languages: queryData?.selectedLanguages,
        valid_user_city: queryData?.validUserCity,
        ...(queryData?.ipxId && { ipx_id: queryData?.ipxId }),
      },
    });
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        rescheduleRedirection();
      }}
      className='flex justify-self-end items-center gap-1'>
      <Text font={FontType.SEMIBOLD_10_170} color={ColorType.GREYS_900}>
        Reschedule
      </Text>
      <Image src={'/assets/svgs/chevron-right-purple.svg'} width={8} height={8} alt='allo-icon' />
    </div>
  );
};

const BottomCardDefault = ({ state, message }: { state: string; message: IMessage }) => {
  return (
    <>
      {message?.buttonLabel && (
        <div
          style={{
            width: !message?.primaryActionLine && !message?.secondaryActionLine ? '100%' : '50%',
          }}>
          <Button
            onPress={message?.action}
            label={message?.buttonLabel}
            disabled={!message?.action}
            secondaryBackgroundColour={colors[ColorType.GREYS_100]}
            primaryColour={colors[ColorType.GREYS_900]}
            justifyContent='center'
            fullWidth={true}
            disabledBackgroundColour={colors[ColorType.GREYS_600]}
            className='whitespace-nowrap'
            borderRadius={50}
          />
        </div>
      )}
      {(message?.primaryActionLine || message?.secondaryActionLine) && (
        <div className='flex flex-col items-start justify-center w-1/2'>
          <Text className='whitespace-nowrap' font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_900}>
            {message?.primaryActionLine}
          </Text>
          <Text className='whitespace-nowrap' font={FontType.REGULAR_11_170} color={ColorType.COOL_GREY_600}>
            {message?.secondaryActionLine}
          </Text>
        </div>
      )}
    </>
  );
};

const MidCardDefault = ({ message }: { message: IMessage }) => {
  return (
    <div>
      <div className='flex gap-2 py-3'>
        {message?.icon && <Image src={message?.icon} height={16} width={16} layout='fixed' alt='allo-icon' />}
        <Text font={FontType.SEMIBOLD_11_170} color={ColorType.PRIMARY_600}>
          {message?.label}
        </Text>
      </div>
      <Text className='pb-2' font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900}>
        {message?.title}
      </Text>
      {message?.address && Object.values(message?.address)?.length > 0 && (
        <>
          <div className='flex gap-2 items-center pb-2'>
            <Image src={'/assets/svgs/location-grey.svg'} height={12} width={12} layout='fixed' alt='allo-icon' />
            <Text font={FontType.SEMIBOLD_10_170} color={ColorType.GREYS_600}>
              DELIVERY ADDRESS
            </Text>
          </div>
          <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_900}>
            {formatAddress(message?.address)}
          </Text>
        </>
      )}
      <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_700}>
        {message?.description}
      </Text>
    </div>
  );
};
