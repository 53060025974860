import Image from 'next/image';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { useTrans } from '@Utils/hooks/translation';

const ImprovementTrustMarker = () => {
  const { t, languageUsed } = useTrans('ax');
  return (
    <div
      className='flex flex-col items-center justify-center text-start py-6'
      style={{
        backgroundImage:
          'linear-gradient(to bottom, #f8f9fc, #f8f9fc, #f8f9fc, #f8f9fc,#f8f9fc,#D5EFFB, #e9f6ff, #e9f6ff, #eaf6ff, #ebf7ff, #f8faff, #f8faff, #f8faff, #ffffff)',
      }}>
      <div className='flex w-full items-center '>
        <div
          className='w-full h-0.5 '
          style={{
            rotate: '180deg',
            backgroundImage:
              'linear-gradient(to right, #0e79ff, #0088ff, #0096ff, #00a2ff, #00aefe, #38b6fc, #53bdfa, #69c4f8, #80caf7, #94d1f7, #a7d7f6, #b8ddf6)',
          }}
        />
        <div className='w-36 '>
          <Image src='/assets/svgs/trust-percentage.svg' width={64} height={64} alt='Allo logo' />
        </div>
        <div
          className='w-full h-0.5 '
          style={{
            backgroundImage:
              'linear-gradient(to right, #0e79ff, #0088ff, #0096ff, #00a2ff, #00aefe, #38b6fc, #53bdfa, #69c4f8, #80caf7, #94d1f7, #a7d7f6, #b8ddf6)',
          }}
        />
      </div>
      <div className=' text-center flex flex-col gap-2 py-2 '>
        <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900} language={languageUsed}>
          {t('Patients-saw-improvements')}
        </Text>
        <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_600} language={languageUsed} className='-mt-1'>
          {t('with-Allo-within-weeks')}
        </Text>
      </div>
    </div>
  );
};

export default ImprovementTrustMarker;
