import styled from 'styled-components';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import UserCard from '@Components/design-system/user-card';
import { useTrans } from '@Utils/hooks/translation';

const getTypeAttributes = (type: string) => {
  switch (type) {
    default:
      return {
        bg: 'bg-transparent',
        tc: ColorType.GREYS_900,
      };
  }
};

export const ScrollWidget = ({
  content,
}: {
  content: {
    id: number;
    name: string;
    designation: string;
    linkName: string;
    experience: string;
    image: any;
  };
}) => {
  return (
    <ScrollContainer>
      <UserCard doctorData={content} />
    </ScrollContainer>
  );
};

const ScrollContainer = styled.div.attrs({
  className: '',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  cursor: pointer;
`;

export default function HorizontalRectangleScrollWidget({
  data,
  viewContent,
}: {
  data: any;
  viewContent?: (content) => void;
}) {
  const title = data?.title;
  const description = data?.description;
  const contents = data?.contents;
  const isOffline = data?.isOffline;
  const typeAttributes = getTypeAttributes(data?.type);
  const { t, languageUsed } = useTrans();
  return (
    <section className={`${typeAttributes?.bg}`}>
      {title && (
        <Text className='mb-2' font={FontType.SEMIBOLD_24_150} color={typeAttributes?.tc} language={languageUsed}>
          {isOffline ? <span>{t('consultation.title')}</span> : title}
        </Text>
      )}
      {description && (
        <Text className='mb-2' font={FontType.REGULAR_14_150} color={ColorType.GREYS_800} language={languageUsed}>
          {description}
        </Text>
      )}
      {contents && Object.keys(contents)?.length > 0 && (
        <Container>
          {contents?.map((content) => (
            <div key={content?.id} onClick={() => false && viewContent(content)}>
              <ScrollWidget key={content?.id} content={content} />
            </div>
          ))}
        </Container>
      )}
    </section>
  );
}

const Container = styled.div.attrs({
  className: 'flex gap-4 p-2',
})`
  overflow-x: scroll;
  scroll-snap-type: x;
  cursor: grab;
  scroll-behavior: smooth;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
