import Cookies from 'js-cookie';

import { extractDomainFromUrl } from '@Utils/helper';

export interface AlloAuthSession {
  accessToken?: string;
  user?: AlloAuthUser;
  expiry?: number;
}

export interface AlloAuthUser {
  name: string;
  phone: string;
  sub: string;
  role: string;
  iat: number;
  iss: string;
}

const authResultCookieName = 'allo::auth0';

export const saveAuthResult = async (authResult: {
  accessToken: string;
  expiresIn: number;
  idTokenPayload?: unknown;
}) => {
  const payload = {
    data: {
      accessToken: authResult.accessToken,
      idTokenPayload: authResult.idTokenPayload,
    },
  };
  const domain = `.${extractDomainFromUrl(process.env.NEXT_PUBLIC_BASE_URL)}`;
  Cookies.set(authResultCookieName, JSON.stringify(payload), {
    expires: new Date(
      authResult.expiresIn * 1000 - 60 * 1000, //expire a minute before actual expiry
    ),
    domain: domain,
    sameSite: 'strict',
    secure: true,
  });
};

export const getAuthResult = () => {
  return !Cookies.get(authResultCookieName) ? undefined : JSON.parse(Cookies.get(authResultCookieName));
};

export const removeAuthResult = async () => {
  const domain = `.${extractDomainFromUrl(process.env.NEXT_PUBLIC_BASE_URL)}`;
  Cookies.remove(authResultCookieName, {
    domain: domain,
  });
};
