export const ActionConsts = {
  Authentication: {
    InitAuthStateSuccess: 'INIT_AUTH_STATE_SUCCESS',
    InitAuthStateFail: 'INIT_AUTH_STATE_FAIL',
    ParseHashInit: 'PARSE_HASH_INIT',
    ParseHashSuccess: 'PARSE_HASH_SUCCESS',
    UserAuthenticatedWithVerifiedOtp: 'USER_AUTHENTCATED_WITH_VERIFIED_OTP',
    LoginFail: 'LOGIN_FAIL',
    Logout: 'LOGOUT',
    LogoutFail: 'LOGOUT_FAIL',
  },
  PatientFulfillment: {
    GetPatientFulFillmentsInit: 'GET_PATIENT_FULFILLMENTS_INIT',
    GetPatientFulFillmentsSuccess: 'GET_PATIENT_FULFILLMENTS_SUCCESS',
    GetPatientFulFillmentsFail: 'GET_PATIENT_FULFILLMENTS_FAIL',
    GetPatientAllFulFillmentsInit: 'GET_PATIENT_ALL_FULFILLMENTS_INIT',
    GetPatientAllFulFillmentsSuccess: 'GET_PATIENT_ALL_FULFILLMENTS_SUCCESS',
    GetPatientAllFulFillmentsFail: 'GET_PATIENT_ALL_FULFILLMENTS_FAIL',
    PostFulfillmentsBillInit: 'POST_FULFILLMENTS_BILL_INIT',
    PostFulfillmentsBillSuccess: 'POST_FULFILLMENTS_BILL_SUCCESS',
    PostFulfillmentsBillFail: 'POST_FULFILLMENTS_BILL_FAIL',
    ResetfulFillmentsBill: 'RESET_FULFILLMENTS_BILL',
  },
  Track: {
    TrackSlotsInit: 'CAPTURE_EVENT_INIT',
    TrackSlotsSuccess: 'CAPTURE_EVENT_SUCCESS',
    TrackSlotsFail: 'CAPTURE_EVENT_FAIL',
    ResetTrackData: 'RESET_TRACK_DATA',
  },
  Patient: {
    UpdateInit: 'UPDATE_PATIENT_INIT',
    UpdateSuccess: 'UPDATE_PATIENT_SUCCESS',
    UpdateFail: 'UPDATE_PATIENT_FAIL',
    ResetPatientData: 'RESET_PATIENT_DATA',
    GetPatientEncountersInit: 'GET_PATIENT_ENCOUNTERS_INIT',
    GetPatientEncountersSuccess: 'GET_PATIENT_ENCOUNTERS_SUCCESS',
    GetPatientEncountersFail: 'GET_PATIENT_ENCOUNTERS_FAIL',
    ResetPatientEncounters: 'RESET_PATIENT_ENCOUNTERS_DATA',
    GetPatientDiagnosesInit: 'GET_PATIENT_DIAGNOSES_INIT',
    GetPatientDiagnosesSuccess: 'GET_PATIENT_DIAGNOSES_SUCCESS',
    GetPatientDiagnosesFail: 'GET_PATIENT_DIAGNOSES_FAIL',
    ResetPatientDiagnoses: 'RESET_PATIENT_DIAGNOSES_DATA',
    GetPatientAssessmentInit: 'GET_PATIENT_ASSESSMENT_INIT',
    GetPatientAssessmentSuccess: 'GET_PATIENT_ASSESSMENT_SUCCESS',
    GetPatientAssessmentFail: 'GET_PATIENT_ASSESSMENT_FAIL',
    ResetPatientAssessment: 'RESET_PATIENT_ASSESSMENT_DATA',
    ResetPatientReports: 'RESET_PATIENT_REPORTS_DATA',
    GetPatientMetadataInit: 'GET_PATIENT_METADATA_INIT',
    GetPatientMetadataSuccess: 'GET_PATIENT_METADATA_SUCCESS',
    GetPatientMetadataFail: 'GET_PATIENT_METADATA_FAIL',
    ResetPatientMetadata: 'RESET_PATIENT_METADATA',
    GetPatientResourcesInit: 'GET_PATIENT_RESOURCES_INIT',
    GetPatientResourcesSuccess: 'GET_PATIENT_RESOURCES_SUCCESS',
    GetPatientResourcesFail: 'GET_PATIENT_RESOURCES_FAIL',
    ResetPatientResources: 'RESET_PATIENT_RESOURCES',
    GetAllPatientEncountersInit: 'GET_ALL_PATIENT_ENCOUNTERS_INIT',
    GetAllPatientEncountersSuccess: 'GET_ALL_PATIENT_ENCOUNTERS_SUCCESS',
    GetAllPatientEncountersFail: 'GET_ALL_PATIENT_ENCOUNTERS_FAIL',
    ResetAllPatientEncounters: 'RESET_ALL_PATIENT_ENCOUNTERS_DATA',
    GetPatientCounsellingReportInit: 'GET_PATIENT_COUNSELLING_REPORT_INIT',
    GetPatientCounsellingReportSuccess: 'GET_PATIENT_COUNSELLING_REPORT_SUCCESS',
    GetPatientCounsellingReportFail: 'GET_PATIENT_COUNSELLING_REPORT_FAIL',
    UpdateXPatientAddressInit: 'UPDATE_X_PATIENT_ADDRESS_INIT',
    UpdateXPatientAddressSuccess: 'UPDATE_X_PATIENT_ADDRESS_SUCCESS',
    UpdateXPatientAddressFail: 'UPDATE_X_PATIENT_ADDRESS_FAIL',
    UpdateEncounterInit: 'UPDATE_ENCOUNTER_INIT',
    UpdateEncounterSuccess: 'UPDATE_ENCOUNTER_SUCCESS',
    UpdateEncounterFail: 'UPDATE_ENCOUNTER_FAIL',
    GetAllAddressInit: 'GET_ALL_ADDRESS_INIT',
    GetAllAddressSuccess: 'GET_ALL_ADDRESS_SUCCESS',
    GetAllAddressFail: 'GET_ALL_ADDRESS_FAIL',
    ResetAllAddressData: 'RESET_ALL_ADDRESS_DATA',
    AddAddressInit: 'ADD_ALL_ADDRESS_INIT',
    AddAddressSuccess: 'ADD_ALL_ADDRESS_SUCCESS',
    AddAddressFail: 'ADD_ALL_ADDRESS_FAIL',
    UpdateAddressInit: 'UPDATE_ADDRESS_INIT',
    UpdateAddressSuccess: 'UPDATE_ADDRESS_SUCCESS',
    UpdateAddressFail: 'UPDATE_ADDRESS_FAIL',
  },
  Order: {
    PaymentIntentInit: 'POST_PAYMENT_INTENT_INIT',
    PaymentIntentSuccess: 'POST_PAYMENT_INTENT_SUCCESS',
    PaymentIntentFail: 'POST_PAYMENT_INTENT_FAIL',
    ResetOrderData: 'RESET_ORDER_DATA',
    GetConsultationDetailsInit: 'GET_CONSULTATION_DETAILS_INIT',
    GetConsultationDetailsSuccess: 'GET_CONSULTATION_DETAILS_SUCCESS',
    GetConsultationDetailsFail: 'GET_CONSULTATION_DETAILS_FAIL',
    ResetConsultationData: 'RESET_CONSULTATION_DATA',
    UpdateOrderConfirmed: 'UPDATE_ORDER_CONFIRMED',
    GetDrugOrdersInit: 'GET_DRUG_ORDERS_INIT',
    GetDrugOrdersSuccess: 'GET_DRUG_ORDERS_SUCCESS',
    GetDrugOrdersFail: 'GET_DRUG_ORDERS_FAIL',
    ResetDrugOrders: 'RESET_DRUG_ORDERS',
    GetPatientInvoicesInit: 'GET_PATIENT_INVOICES_INIT',
    GetPatientInvoicesSuccess: 'GET_PATIENT_INVOICES_SUCCESS',
    GetPatientInvoicesFail: 'GET_PATIENT_INVOICES_FAIL',
    ResetPatientInvoices: 'RESET_PATIENT_INVOICES_DATA',
    GetConsultationOrdersInit: 'GET_CONSULTATION_ORDERS_INIT',
    GetConsultationOrdersSuccess: 'GET_CONSULTATION_ORDERS_SUCCESS',
    GetConsultationOrdersFail: 'GET_CONSULTATION_ORDERS_FAIL',
    ResetConsultationOrders: 'RESET_CONSULTATION_ORDERS_DATA',
    GetLabTestOrdersInit: 'GET_LAB_TEST_ORDERS_INIT',
    GetLabTestOrdersSuccess: 'GET_LAB_TEST_ORDERS_SUCCESS',
    GetLabTestOrdersFail: 'GET_LAB_TEST_ORDERS_FAIL',
    ResetLabTestOrders: 'RESET_LAB_TEST_ORDERS_DATA',
  },
  Common: {
    UpdateQueryData: 'UPDATE_QUERY_DATA',
    ResetQueryData: 'RESET_QUERY_DATA',
    ResetQueryDataExceptIpxId: 'RESET_QUERY_DATA_EXCEPT_IPX_ID',
    ResetbookCallParams: 'RESET_BOOK_CALL_PARAMS',
  },
  Schedule: {
    GetAppointmentInit: 'GET_APPOINTMENT_INIT',
    GetAppointmentSuccess: 'GET_APPOINTMENT_SUCCESS',
    GetAppointmentFail: 'GET_APPOINTMENT_FAIL',
    GetSlotsInit: 'GET_SLOTS_INIT',
    GetSlotsSuccess: 'GET_SLOTS_SUCCESS',
    GetSlotsFail: 'GET_SLOTS_FAIL',
    GetLanguagesInit: 'GET_LANGUAGES_INIT',
    GetLanguagesSuccess: 'GET_LANGUAGES_SUCCESS',
    GetLanguagesFail: 'GET_LANGUAGES_FAIL',
    ResetAvailableLanguages: 'RESET_AVAILABLE_LANGUAGES',
    GetAvailableLanguagesInit: 'GET_AVAILABLE_LANGUAGES_INIT',
    GetAvailableLanguagesSuccess: 'GET_AVAILABLE_LANGUAGES_SUCCESS',
    GetAvailableLanguagesFail: 'GET_AVAILABLE_LANGUAGES_FAIL',
    ResetAppointmentData: 'RESET_APPOINTMENT_DATA',
    ResetSlotsData: 'RESET_SLOTS_DATA',
    UpdateConsultationStateInit: 'UPDATE_CONSULTATION_STATE_INIT',
    UpdateConsultationStateSuccess: 'UPDATE_CONSULTATION_STATE_SUCCESS',
    UpdateConsultationStateFail: 'UPDATE_CONSULTATION_STATE_FAIL',
    RescheduleConsultationInit: 'RESCHEDULE_CONSULTATION_INIT',
    RescheduleConsultationSuccess: 'RESCHEDULE_CONSULTATION_SUCCESS',
    RescheduleConsultationFail: 'RESCHEDULE_CONSULTATION_FAIL',
    ResetRescheduleData: 'RESET_RESCHEDULE_DATA',
    ResetConsultationData: 'RESET_CONSULTATION_DATA',
    AppointmentIVRInit: 'APPOINTMENT_IVR_INIT',
    AppointmentIVRSuccess: 'APPOINTMENT_IVR_SUCCESS',
    AppointmentIVRFail: 'APPOINTMENT_IVR_FAIL',
    ResetIVRData: 'RESET_IVR_DATA',
    ResetAppointmentIVRData: 'RESET_APPOINTMENT_IVR',
    AppointmentGMeetInit: 'APPOINTMENT_GMEET_INIT',
    AppointmentGMeetSuccess: 'APPOINTMENT_GMEET_SUCCESS',
    AppointmentGMeetFail: 'APPOINTMENT_GMEET_FAIL',
    ResetGMeetData: 'RESET_GMEET_DATA',
    ReleaseConsultationSlotInit: 'RELEASE_CONSULTATION_SLOT_INIT',
    ReleaseConsultationSlotSuccess: 'RELEASE_CONSULTATION_SLOT_SUCCESS',
    ReleaseConsultationSlotFail: 'RELEASE_CONSULTATION_SLOT_FAIL',
    ResetReleaseConsultationSlot: 'RESET_RELEASE_CONSULTATION_SLOT',
    SetHideOnlineLocation: 'SET_HIDE_ONLINE_LOCATION',
    SetHideOfflineLocation: 'SET_HIDE_OFFLINE_LOCATION',
  },
  Consultation: {
    GetConsultationInit: 'GET_CONSULTATION_INIT',
    GetConsultationSuccess: 'GET_CONSULTATION_SUCCESS',
    GetConsultationFail: 'GET_CONSULTATION_FAIL',
    ResetRescheduleConsultation: 'RESET_RESCHEDULE_CONSULTATION',
    GetConsultationTypeInit: 'GET_CONSULTATION_TYPE__INIT',
    GetConsultationTypeSuccess: 'GET_CONSULTATION_TYPE_SUCCESS',
    GetConsultationTypeFail: 'GET_CONSULTATION_TYPE_FAIL',
    ResetConsultationTypeData: 'RESET_CONSULTATION_TYPE_DATA',
  },
  Feedback: {
    PostFeedbackInit: 'POST_FEEDBACK_INIT',
    PostFeedbackSuccess: 'POST_FEEDBACK_SUCCESS',
    PostFeedbackFail: 'POST_FEEDBACK_FAIL',
    ResetFeedback: 'RESET_FEEDBACK',
    GetFeedbackInit: 'GET_FEEDBACK_INIT',
    GetFeedbackSuccess: 'GET_FEEDBACK_SUCCESS',
    GetFeedbackFail: 'GET_FEEDBACK_FAIL',
  },
  Checkout: {
    GetAppointmentsInit: 'GET_APPOINTMENTS_INIT',
    GetAppointmentsSuccess: 'GET_APPOINTMENTS_SUCCESS',
    GetAppointmentsFail: 'GET_APPOINTMENTS_FAIL',
    ResetAppointments: 'RESET_APPOINTMENTS',
    GetFilteredAppointmentsInit: 'GET_FILTERED_APPOINTMENTS_INIT',
    GetFilteredAppointmentsSuccess: 'GET_FILTERED_APPOINTMENTS_SUCCESS',
    GetFilteredAppointmentsFail: 'GET_FILTERED_APPOINTMENTS_FAIL',
    GetInvoiceInit: 'GET_INVOICE_INIT',
    GetInvoiceSuccess: 'GET_INVOICE_SUCCESS',
    GetInvoiceFail: 'GET_INVOICE_FAIL',
    ResetInvoice: 'RESET_INVOICE',
    ResetCheckoutData: 'RESET_CHECKOUT_DATA',
    GetEncounterFromAppointmentInit: 'GET_ENCOUNTER_FROM_APPOINTMENT_INIT',
    GetEncounterFromAppointmentSuccess: 'GET_ENCOUNTER_FROM_APPOINTMENT_SUCCESS',
    GetEncounterFromAppointmentFail: 'GET_ENCOUNTER_FROM_APPOINTMENT_FAIL',
    ResetEncounterFromAppointment: 'RESET_ENCOUNTER_FROM_APPOINTMENT',
    PreviewInvoiceInit: 'PREVIEW_INVOICE_INIT',
    PreviewInvoiceSuccess: 'PREVIEW_INVOICE_SUCCESS',
    PreviewInvoiceFail: 'PREVIEW_INVOICE_FAIL',
    ResetInvoicePreview: 'RESET_INVOICE_PREVIEW',
    TogglePauseSlotRelease: 'TOGGLE_PAUSE_SLOT_RELEASE',
  },
  Analytics: {
    GetLeadInit: 'GET_LEAD_INIT',
    GetLeadSuccess: 'GET_LEAD_SUCCESS',
    GetLeadFail: 'GET_LEAD_FAIL',
    AddLeadActivityInit: 'ADD_LEAD_ACTIVITY_INIT',
    AddLeadActivitySuccess: 'ADD_LEAD_ACTIVITY_SUCCESS',
    AddLeadActivityFail: 'ADD_LEAD_ACTIVITY_FAIL',
    UpdateLeadInit: 'UPDATE_LEAD_INIT',
    UpdateLeadSuccess: 'UPDATE_LEAD_SUCCESS',
    UpdateLeadFail: 'UPDATE_LEAD_FAIL',
    CreateOrUpdateLeadInit: 'CREATE_OR_UPDATE_LEAD_INIT',
    CreateOrUpdateLeadSuccess: 'CREATE_OR_UPDATE_LEAD_SUCCESS',
    CreateOrUpdateLeadFail: 'CREATE_OR_UPDATE_LEAD_FAIL',
    PostFbPixelEventInit: 'POST_FB_PIXEL_EVENT_INIT',
    PostFbPixelEventSuccess: 'POST_FB_PIXEL_EVENT_SUCCESS',
    PostFbPixelEventFail: 'POST_FB_PIXEL_EVENT_FAIL',
  },
  User: {
    UpdateInit: 'UPDATE_USER_INIT',
    UpdateSuccess: 'UPDATE_USER_SUCCESS',
    UpdateFail: 'UPDATE_USER_FAIL',
    ResetUserData: 'RESET_USER_DATA',
    GetEvaluationStateInit: 'GET_EVALUATION_STATE_INIT',
    GetEvaluationStateSuccess: 'GET_EVALUATION_STATE_SUCCESS',
    GetEvaluationStateFail: 'GET_EVALUATION_STATE_FAIL',
    ResetEvaluationState: 'RESET_EVALUATION_STATE',
    GetUserInit: 'GET_USER_INIT',
    GetUserSuccess: 'GET_USER_SUCCESS',
    GetUserFail: 'GET_USER_FAIL',
    GetIpCityInit: 'GET_IP_CITY_INIT',
    GetIpCitySuccess: 'GET_IP_CITY_SUCCESS',
    GetIpCityFail: 'GET_IP_CITY_FAIL',
  },
  File: {
    UploadFileInit: 'UPLOAD_FILE_INIT',
    UploadFileSuccess: 'UPLOAD_FILE_SUCCESS',
    UploadFileFail: 'UPLOAD_FILE_FAIL',
    GetPatientfileInit: 'GET_PATIENT_FILE_INIT',
    GetPatientfileSuccess: 'GET_PATIENT_FILE_SUCCESS',
    GetPatientfileFail: 'GET_PATIENT_FILE_FAIL',
    ResetFileUploads: 'RESET_FILE_UPLOADS',
  },
  Toggle: {
    ToggleData: 'TOGGLE_DATA',
    ResetToggleData: 'RESET_TOGGLE_DATA',
  },
  Provider: {
    GetProviderDataInit: 'GET_PROVIDER_DATA_INIT',
    GetProviderDataSuccess: 'GET_PROVIDER_DATA_SUCCESS',
    GetProviderDataFail: 'GET_PROVIDER_DATA_FAIL',
    GetAllProviderDataInit: 'GET_ALL_PROVIDER_DATA_INIT',
    GetAllProviderDataSuccess: 'GET_ALL_PROVIDER_DATA_SUCCESS',
    GetAllProviderDataFail: 'GET_ALL_PROVIDER_DATA_FAIL',
    GetTherapistDataInit: 'GET_THERAPIST_DATA_INIT',
    GetTherapistDataSuccess: 'GET_THERAPIST_DATA_SUCCESS',
    GetTherapistDataFail: 'GET_THERAPIST_DATA_FAIL',
    GetOfflineAvailabilityInit: 'GET_OFFLINE_AVAILABILITY_INIT',
    GetOfflineAvailabilitySuccess: 'GET_OFFLINE_AVAILABILITY_SUCCESS',
    GetOfflineAvailabilityFail: 'GET_OFFLINE_AVAILABILITY_FAIL',
    GetOfflineAvailabilityReset: 'GET_OFFLINE_AVAILABILITY_RESET',
    ResetProviderData: 'RESET_PROVIDER_DATA',
  },
  Encounter: {
    CheckIfEncounterGeneratedInit: 'CHECK_IF_ENCOUNTER_GENERATED_INIT',
    CheckIfEncounterGeneratedSuccess: 'CHECK_IF_ENCOUNTER_GENERATED_SUCCESS',
    CheckIfEncounterGeneratedFail: 'CHECK_IF_ENCOUNTER_GENERATED_FAIL',
    GetMetaDataForDiagnosesInit: 'GET_META_DATA_FOR_DIAGNOSES_INIT',
    GetMetaDataForDiagnosesSuccess: 'GET_META_DATA_FOR_DIAGNOSES_SUCCESS',
    GetMetaDataForDiagnosesFail: 'GET_META_DATA_FOR_DIAGNOSES_FAIL',
    ResetEncounterData: 'RESET_ENCOUNTER_DATA',
    GetAssessmentDataInit: 'GET_ASSESSMENT_DATA_INIT',
    GetAssessmentDataSuccess: 'GET_ASSESSMENT_DATA_SUCCESS',
    GetAssessmentDataFail: 'GET_ASSESSMENT_DATA_FAIL',
    ResetAssessmentData: 'RESET_ASSESSMENT_DATA',
    GetPrescriptionDataInit: 'GET_PRESCRIPTION_DATA_INIT',
    GetPrescriptionDataSuccess: 'GET_PRESCRIPTION_DATA_SUCCESS',
    GetPrescriptionDataFail: 'GET_PRESCRIPTION_DATA_FAIL',
    ResetPrescriptionData: 'RESET_PRESCRIPTION_DATA',
    UpdateLabVenuePreference: 'UPDATE_LAB_VENUE_PREFERENCE',
  },
  MetaData: {
    GetMetaDataInit: 'GET_META_DATA_INIT',
    GetMetaDataSuccess: 'GET_META_DATA_SUCCESS',
    GetMetaDataFail: 'GET_META_DATA_FAIL',
    ResetMetaData: 'RESET_META_DATA',
  },
  Location: {
    GetClinicLocationsInit: 'GET_CLINIC_LOCATIONS_INIT',
    GetClinicLocationsSuccess: 'GET_CLINIC_LOCATIONS_SUCCESS',
    GetClinicLocationsFail: 'GET_CLINIC_LOCATIONS_FAIL',
    ResetClinicLocationsData: 'RESET_CLINIC_LOCATIONS_DATA',
    GetClinicLocationInit: 'GET_CLINIC_LOCATION_INIT',
    GetClinicLocationSuccess: 'GET_CLINIC_LOCATION_SUCCESS',
    GetClinicLocationFail: 'GET_CLINIC_LOCATION_FAIL',
    GetProviderLocationsInit: 'GET_LOCATION_WITH_PROVIDERS_INIT',
    GetProviderLocationsSuccess: 'GET_LOCATION_WITH_PROVIDERS_SUCCESS',
    GetProviderLocationsFail: 'GET_LOCATION_WITH_PROVIDERS_FAIL',
  },
  Billing: {
    GetConsultationInvoiceInit: 'GET_CONSULTATION_INVOICE_INIT',
    GetConsultationInvoiceSuccess: 'GET_CONSULTATION_INVOICE_SUCCESS',
    GetConsultationInvoiceFail: 'GET_CONSULTATION_INVOICE_FAIL',
    ResetConsultationInvoice: 'RESET_CONSULTATION_INVOICE',
    PreviewConsultationInvoiceInit: 'PREVIEW_CONSULTATION_INVOICE_INIT',
    PreviewConsultationInvoiceSuccess: 'PREVIEW_CONSULTATION_INVOICE_SUCCESS',
    PreviewConsultationInvoiceFail: 'PREVIEW_CONSULTATION_INVOICE_FAIL',
    ResetConsultationInvoicePreview: 'RESET_CONSULTATION_INVOICE_PREVIEW',
    //legacy
    PreviewPrescriptionInvoiceInit: 'PREVIEW_PRESCRIPTION_INVOICE_INIT',
    PreviewPrescriptionInvoiceSuccess: 'PREVIEW_PRESCRIPTION_INVOICE_SUCCESS',
    PreviewPrescriptionInvoiceFail: 'PREVIEW_PRESCRIPTION_INVOICE_FAIL',
    ResetPrescriptionInvoicePreview: 'RESET_PRESCRIPTION_INVOICE_PREVIEW',
    //new structure
    PreviewPrescriptionInvoicesInit: 'PREVIEW_PRESCRIPTION_INVOICES_INIT',
    PreviewPrescriptionInvoicesSuccess: 'PREVIEW_PRESCRIPTION_INVOICES_SUCCESS',
    PreviewPrescriptionInvoicesFail: 'PREVIEW_PRESCRIPTION_INVOICES_FAIL',
    ResetPrescriptionInvoicesPreview: 'RESET_PRESCRIPTION_INVOICES_PREVIEW',

    GetPrescriptionInvoiceInit: 'GET_PRESCRIPTION_INVOICE_INIT',
    GetPrescriptionInvoiceSuccess: 'GET_PRESCRIPTION_INVOICE_SUCCESS',
    GetPrescriptionInvoiceFail: 'GET_PRESCRIPTION_INVOICE_FAIL',
    ResetPrescriptionInvoice: 'RESET_PRESCRIPTION_INVOICE',
    HandleInvoiceItemSelection: 'HANDLE_INVOICE_ITEM_SELECTION',

    CreateInventoryItemPurchaseInit: 'CREATE_INVENTORY_ITEM_PURCHASE_INIT',
    CreateInventoryItemPurchaseSuccess: 'CREATE_INVENTORY_ITEM_PURCHASE_SUCCESS',
    CreateInventoryItemPurchaseFail: 'CREATE_INVENTORY_ITEM_PURCHASE_FAIL',
  },
  Slot: {
    //previews
    GetPreviewSlotsInit: 'GET_PREVIEW_SLOTS_INIT',
    GetPreviewSlotsSuccess: 'GET_PREVIEW_SLOTS_SUCCESS',
    GetPreviewSlotsFail: 'GET_PREVIEW_SLOTS_FAIL',
    ResetPreviewSlots: 'RESET_PREVIEW_SLOTS',

    //actual slots
    GetSlotsInit: 'GET_SLOTS_INIT_V2',
    GetSlotsSuccess: 'GET_SLOTS_SUCCESS_V2',
    GetSlotsFail: 'GET_SLOTS_FAIL_V2',
    ResetSlots: 'RESET_SLOTS_V2',

    //providers by slots
    GetProvidersBySlotsInit: 'GET_PROVIDERS_BY_SLOTS_INIT',
    GetProvidersBySlotsSuccess: 'GET_PROVIDERS_BY_SLOTS_SUCCESS',
    GetProvidersBySlotsFail: 'GET_PROVIDERS_BY_SLOTS_FAIL',
  },
};
