import { toast } from 'react-toastify';

import NotificationIcon from './notification-icon';

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export const showNotification = (type, msg, closeDurationInMs = 5000, position?) => {
  switch (type) {
    case NotificationType.SUCCESS:
      toast.success(msg, {
        ...(position && {
          position: position,
        }),
        autoClose: closeDurationInMs,
        icon: <NotificationIcon type={type} />,
        hideProgressBar: true,
      });
      break;
    case NotificationType.ERROR:
      toast.error(msg, {
        ...(position && {
          position: position,
        }),
        autoClose: closeDurationInMs,
        icon: <NotificationIcon type={type} />,
        hideProgressBar: true,
      });
      break;
    case NotificationType.WARNING:
      toast.warning(msg, {
        ...(position && {
          position: position,
        }),
        autoClose: closeDurationInMs,
        icon: <NotificationIcon type={type} />,
        hideProgressBar: true,
      });
      break;
    default:
      toast.info(msg, {
        ...(position && {
          position: position,
        }),
        autoClose: closeDurationInMs,
        icon: <NotificationIcon type={type} />,
        hideProgressBar: true,
      });
      break;
  }
};
