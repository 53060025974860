import Image from 'next/image';
import { useEffect } from 'react';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import LikelinessCard from '@Components/likeliness-card';
import { likelinessData } from '@Utils/constants';
import { useTrans } from '@Utils/hooks/translation';

import CrossWhite from '../public/assets/svgs/cross-white.svg';

const InfoCard = ({ section }: { section: IProblemSection }) => {
  const { t, languageUsed } = useTrans();
  return (
    <div>
      <Text className='mb-4' font={FontType.SEMIBOLD_18_150} color={ColorType.GREYS_900} language={languageUsed}>
        {section?.header}
      </Text>
      <Text className='mb-4' font={FontType.REGULAR_14_150} color={ColorType.GREYS_900} language={languageUsed}>
        {section?.subheader}
      </Text>
      {section?.image && <Image src={`/assets/${section?.image}`} width={288} height={48} alt='allo-icon' />}
      <Text className='mb-6' font={FontType.SEMIBOLD_14_150} color={ColorType.BLUE_GREY_500} language={languageUsed}>
        {section?.imageCaption}
      </Text>
      <div className='mb-6 flex gap-1.5 flex-wrap'>
        {section?.tags?.map((tag) => (
          <div key={tag} className='rounded-full bg-green-50 border border-green-200 px-3 py-1'>
            <Text font={FontType.REGULAR_10_170} color={ColorType.TEAL_600} language={languageUsed}>
              {tag}
            </Text>
          </div>
        ))}
      </div>
      <Text className='mb-4' font={FontType.REGULAR_14_150} color={ColorType.GREYS_900} language={languageUsed}>
        {section?.description}
      </Text>
      <div className='mb-6 w-full h-px bg-gray-200'></div>
    </div>
  );
};

interface IProblemSection {
  header: string;
  subheader: string;
  image: string;
  imageCaption: string;
  tags: string[];
  description: string;
}

interface IProblem {
  title: string;
  likeliness: string;
  sections: Array<IProblemSection>;
}

export default function InfoPopup({
  problem,
  setViewInfo,
}: Readonly<{
  problem: IProblem;
  setViewInfo: (viewInfo: boolean) => void;
}>) {
  function handleClick() {
    setViewInfo(false);
  }
  useEffect(() => {
    if (document.body.style.overflow === 'hidden') return;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <section
      className={'z-50 fixed w-full h-full inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-end'}>
      <div className='flex my-3 w-80 justify-end'>
        <Image
          className='py-2 cursor-pointer'
          onClick={handleClick}
          src={CrossWhite.src}
          width={24}
          height={24}
          alt='allo-icon'
        />
      </div>
      <div className='bg-white p-4 w-80 overflow-y-scroll rounded-t-lg'>
        {problem?.likeliness && (
          <LikelinessCard
            key={problem?.likeliness}
            diagnosis={problem?.title}
            section={likelinessData[problem.likeliness]}
          />
        )}
        {problem?.sections?.map((section) => (
          <InfoCard key={section?.header} section={section} />
        ))}
      </div>
    </section>
  );
}
