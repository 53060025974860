import Image from 'next/image';
import React from 'react';

import { NotificationType } from './notification';

const NotificationIcon = ({ type }) => {
  switch (type) {
    case NotificationType.SUCCESS:
      return <Image src={'/assets/svgs/Check.svg'} height={16} width={16} alt='success' />;
    case NotificationType.ERROR:
      return <Image src={'/assets/svgs/error.svg'} height={16} width={16} alt='error' />;
    case NotificationType.WARNING:
      return <Image src={'/assets/svgs/alert.svg'} height={16} width={16} alt='warning' />;
    default:
      return <Image src={'/assets/svgs/alert.svg'} height={16} width={16} alt='alert' />;
  }
};

export default NotificationIcon;
