import Image from 'next/image';
import React, { useState } from 'react';
import styled from 'styled-components';

import ClinicLight from '@Images/svgs/clinic-light.svg';
import VideoLight from '@Images/svgs/video-light.svg';

import { ColorType } from './design-system/color/types';
import Text from './design-system/text';
import { FontType } from './design-system/text/types';

const ImageCarousel = ({ images, tag }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const previousImage = () => {
    const index = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(index);
  };

  const nextImage = () => {
    const index = (currentIndex + 1) % images.length;
    setCurrentIndex(index);
  };
  return (
    <div className='relative flex justify-center'>
      {images?.length > 0 && (
        <ImageContainer className='rounded-lg'>
          <Image src={images[currentIndex] || images[0]} alt='event-img' layout='fill' className='rounded-lg' />
        </ImageContainer>
      )}
      {images?.length > 1 && (
        <div className='absolute top-0 bottom-0 left-0 right-0 flex justify-between items-center z-10'>
          <button
            className='px-4 py-2 rounded-full bg-white bg-opacity-75 hover:bg-opacity-90 focus:outline-none'
            onClick={previousImage}>
            &lt;
          </button>
          <button
            className='px-4 py-2 rounded-full bg-white bg-opacity-75 hover:bg-opacity-90 focus:outline-none'
            onClick={nextImage}>
            &gt;
          </button>
        </div>
      )}
      <div className='absolute top-4 bottom-0 left-4 right-0 flex justify-between items-start'>
        <button className='h-8 px-3 py-1.5 bg-black bg-opacity-30 rounded-3xl backdrop-blur-lg justify-center items-center gap-1 inline-flex'>
          <Image src={tag !== 'Online' ? ClinicLight.src : VideoLight.src} width={24} height={24} alt='allo icon' />
          <Text font={FontType.SEMIBOLD_12_170} color={ColorType.WHITE}>
            {tag}
          </Text>
        </button>
      </div>
    </div>
  );
};

const ImageContainer = styled.div.attrs({})`
  width: 100%;
  max-width: 400px;
  min-height: 204px;
  background-color: grey;
  position: relative;
  top: 0px;
`;

export default ImageCarousel;
