import { ColorType, FontType } from '@Components/common-exports';
import Text from '@Components/design-system/text';
import HorizontalRectangleScrollWidget from '@Components/horizontal-rectangle-scroll-widget';
import { useTrans } from '@Utils/hooks/translation';

const DoctorsGrid = ({ doctorlist, theme }) => {
  const { t, languageUsed } = useTrans();

  return (
    <div className=''>
      <Text
        font={FontType.SEMIBOLD_14_150}
        color={theme === 'dark' ? ColorType.ORANGE_YELLOW_300 : ColorType.GREYS_700}
        language={languageUsed}
        className='text-center pb-4'>
        &middot; {'  '} {t('doctor-grid.verified-medical-expertise')} {'  '} &middot;
      </Text>
      <div className='mx-2'>
        <HorizontalRectangleScrollWidget
          data={{
            type: 'doctor',
            contents: doctorlist,
          }}
        />
      </div>
    </div>
  );
};

export default DoctorsGrid;
