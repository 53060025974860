import clsx from 'clsx';
import Image from 'next/image';
import { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import useSWR from 'swr';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { Glassy, PurpleCubes, PurpleLight } from '@Components/design-system/themes';
import { Theme } from '@Components/mini-scheduling/mini-scheduling.component';
import { fetcher } from '@Utils/helper';
import { useTrans } from '@Utils/hooks/translation';

import ReviewGrid from './review-grid';

const categorySizes = {
  whatsapp: { width: 212, height: 180 },
  google: { width: 228, height: 136 },
  practo: { width: 320, height: 136 },
};

const Testimonials = ({ theme }: { theme: Theme }) => {
  const { t, languageUsed } = useTrans();
  const { data: testimonialImages } = useSWR(`/api/testimonial-images`, fetcher);
  const [selectedCategory, setSelectedCategory] = useState('whatsapp');
  const reviewItemRef = useRef(null);
  const handleScroll = () => {
    const categoryWidth = categorySizes[selectedCategory].width;
    reviewItemRef.current.scrollLeft += categoryWidth;
    const newIndex = Math.floor(reviewItemRef.current.scrollLeft / categoryWidth) % 3;
    const nextCategory = ['whatsapp', 'google', 'practo'][newIndex];
    if (nextCategory !== selectedCategory) {
      setSelectedCategory(nextCategory);
      reviewItemRef.current.scrollLeft = newIndex * categoryWidth;
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleScroll();
    }, 8000);
    return () => {
      clearInterval(intervalId);
    };
  }, [selectedCategory]);
  const ThemeWrap = theme === Theme.DARK ? PurpleCubes : PurpleLight;
  return (
    <ThemeWrap>
      <div className='flex flex-col items-center gap-1.5 pt-8'>
        <Text
          font={FontType.SEMIBOLD_24_150}
          color={theme === Theme.DARK ? ColorType.WHITE : ColorType.GREYS_900}
          language={languageUsed}>
          {t('testimonials.patient-love-for-allo')}
        </Text>
        <Text
          font={FontType.SEMIBOLD_14_150}
          language={languageUsed}
          color={theme === Theme.DARK ? ColorType.ORANGE_YELLOW_300 : ColorType.PRIMARY_600}>
          &middot;{'  '}
          {t('testimonials.see-what-people-think-about-us')}
          {'  '} &middot;
        </Text>
      </div>
      <div className='flex justify-center gap-16 py-8'>
        {['whatsapp', 'google', 'practo'].map((category) => (
          <div
            className={`cursor-pointer ${
              selectedCategory === category
                ? clsx(
                    'border-2 rounded-full h-11 w-11',
                    'flex items-center justify-center',
                    theme === Theme.DARK ? 'border-ORANGE_YELLOW_400' : 'border-BLUE_GREY_500',
                  )
                : ''
            }`}
            key={category}>
            <Image
              src={`/assets/${category}.png`}
              alt='quotes'
              width={36}
              height={36}
              layout='fixed'
              onClick={() => setSelectedCategory(category)}
            />
          </div>
        ))}
      </div>

      <Glassy className='mx-4' rounded='large'>
        <div className='overflow-x-scroll' ref={reviewItemRef}>
          {testimonialImages?.map((categoryObject) => {
            const [category, images] = Object.entries(categoryObject ?? {})[0];
            return (
              <ScrollTestimonial
                key={category}
                className={`flex flex-col items-start ${selectedCategory === category ? 'visible' : 'hidden'}`}>
                <div className='flex justify-center gap-3 p-3'>
                  {(images as string[])?.map((imagePath, imageIndex) => (
                    <Image
                      key={imageIndex}
                      src={imagePath}
                      alt={`Testimonial ${imageIndex + 1}`}
                      width={categorySizes[selectedCategory].width}
                      height={categorySizes[selectedCategory].height}
                      layout='fixed'
                    />
                  ))}
                </div>
              </ScrollTestimonial>
            );
          })}
        </div>
      </Glassy>
      <div className='py-4'>
        <ReviewGrid fontColor={theme === Theme.DARK ? ColorType.GREYS_100 : ColorType.GREYS_900} />
      </div>
    </ThemeWrap>
  );
};

export default Testimonials;

const move = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const ScrollTestimonial = styled.div`
  animation: ${move} 25s linear infinite;
  flex-shrink: 0;
  p-2;
  items-center;
`;
