import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import React, { useEffect, useState } from 'react';

import { CommonActions, UserActions, AnalyticsActions } from '@Actions/index';
import { AlloAuthUser } from '@Components/auth/utils';
import { IQueryData, updateCommonIsLoading } from '@Reducers/common';
import { IUser, IUserData } from '@Reducers/user';
import { useAppSelector, useAppDispatch } from '@Redux/hooks';
import { embeddedRoutes, UserRole } from '@Utils/constants';
import {
  clarityLogger,
  consultationCodeTransform,
  decodeCookie,
  getCurrentPage,
  getQueryParamFromCookies,
  isTransitionPage,
  setCurrentPage,
  setQueryDataInCookies,
} from '@Utils/helper';
import { LOG_ERROR } from '@Utils/logger';

import { getLocal } from '../../core/storage/local-storage';

export const AuthorizeContext = React.createContext({});

const { Provider } = AuthorizeContext;

export interface PatientAuthData {
  id?: string;
  email?: string;
  phoneNumber?: string;
  name?: string;
  sub?: string;
  isLoading?: boolean;
  isAuthenticated?: boolean;
  preferredLanguage?: string[];
  therapistId?: string;
  physicianId?: string;
}

export function PatientAuthProvider({ children }) {
  const { isLoading, isAuthenticated } = useAppSelector((state) => state.auth);
  const auth: AlloAuthUser = useAppSelector((state) => state.auth.user);
  const [patientAuthData, setPatientAuthData] = useState<PatientAuthData>({});
  const common = useAppSelector((state) => state.common);
  const queryData: IQueryData = common.queryData;
  const user: IUser = useAppSelector((state) => state.user);
  const userData: IUserData = user.userData;
  const ipCityData = user.ipCity;
  const dispatch = useAppDispatch();
  const router = useRouter();
  const currentPage = getCurrentPage();
  const isTransitionPageEmbedded = window.location.href?.includes('embedded=true') && isTransitionPage(currentPage);

  useEffect(() => {
    if (ipCityData?.city) return;
    dispatch(UserActions.GetIpCity());
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    const utmCampaign = decodeCookie(Cookies.get('utm_campaign'))?.toLowerCase();
    if (utmCampaign == 'null' || utmCampaign == 'undefined') {
      Cookies.remove('utm_campaign');
    }
  }, [router.isReady]);

  const setQueryData = () => {
    if (router.query) {
      const { utm_campaign, ...payload } = router.query;
      const utmCampaign = utm_campaign && utm_campaign != 'null' ? utm_campaign : '';
      const utmParams = {
        utm_campaign: utmCampaign,
        utm_medium: payload?.utm_medium,
        utm_source: payload?.utm_source,
        utm_content: payload?.utm_content,
        utm_term: payload?.utm_term,
        gclid: payload?.gclid,
        fbclid: payload?.fbclid,
        fbc: payload?.fbc,
        fbp: payload?.fbp,
        source_url: payload?.source_url,
      };
      dispatch(CommonActions.UpdateQueryData(payload));
      dispatch(updateCommonIsLoading(false));
      setQueryDataInCookies(payload);

      for (const key of Object.keys(utmParams)) {
        if (utmParams[key]) {
          try {
            Cookies.set(key, window?.btoa(utmParams[key]?.toString()) || '', {
              expires: new Date(
                Date.now() + 30 * 86_400 * 1000, //30 days
              ),
            });
          } catch {}
        }
      }
    }
  };

  useEffect(() => {
    if (!router.isReady) return;
    setQueryData();
  }, [router.isReady, router.query]);

  useEffect(() => {
    if (userData?.status) {
      try {
        window?.['clarity']('set', 'user_state', userData?.status);
      } catch {}
    }
  }, [userData]);

  useEffect(() => {
    if (!router.isReady) return;
    setCurrentPage();
  }, [router.isReady, router?.pathname]);

  useEffect(() => {
    if (isLoading || !isAuthenticated) return;
    try {
      const currentPage = getCurrentPage();
      const currentUrl = window?.location?.href;
      if (!isTransitionPage(currentPage) && currentUrl?.includes('embedded=true'))
        window.top.location.href = currentUrl?.replace('embedded=true', '');
    } catch (error) {
      LOG_ERROR('Error in Embedded', error);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isLoading && isAuthenticated && userData?.id) {
      setPatientAuthData({
        ...userData,
        sub: auth?.sub,
        isLoading,
        isAuthenticated,
      });
      // Identify the user in PostHog
      if (auth?.role !== UserRole.PATIENT) return;
      posthog?.identify(userData?.id, {
        phoneNumber: userData?.phoneNumber,
        name: userData?.verifiedName,
        physicianId: userData?.physicianId,
        therapistId: userData?.therapistId,
        leadId: userData?.leadId,
        isActor: userData?.isActor,
        alloIpCity: ipCityData?.city,
      });
    }
  }, [userData, auth, isLoading, isAuthenticated]);

  useEffect(() => {
    if (!isLoading && auth && isAuthenticated && !user.isLoading && !userData?.id && !isTransitionPageEmbedded) {
      const queryParam = Cookies.get('queryParam') ? JSON.parse(Cookies.get('queryParam')) : {};
      const analytical = {
        Origin: queryData?.pageSource,
        mx_Logged_In: 'Yes',
        mx_utm_medium: decodeCookie(Cookies.get('utm_medium')) || queryParam?.utm_medium,
        mx_utm_source: decodeCookie(Cookies.get('utm_source')),
        mx_utm_campaign: decodeCookie(Cookies.get('utm_campaign')) || queryParam?.utm_campaign,
        Source: decodeCookie(Cookies.get('utm_source')),
        mx_utm_content: decodeCookie(Cookies.get('utm_content')),
        mx_utm_term: decodeCookie(Cookies.get('utm_term')),
        mx_GCLID: decodeCookie(Cookies.get('gclid')),
        mx_FBCLID: decodeCookie(Cookies.get('fbclid')),
        mx_Source_URL:
          queryData?.sourceUrl || decodeCookie(Cookies.get('source_url')) || 'https://patient.allohealth.care/login',
      };
      const fetchData = async function fetchData() {
        const payload: any = {
          auth0Id: auth?.sub,
          gender: 'M',
          firstName: queryData.name || getLocal('pf_full_name') || auth?.name,
          ipxId: queryData.ipxId,
        };
        if (queryData.email || getLocal('email') || getLocal('pf_email')) {
          payload.email = queryData.email || getLocal('email') || getLocal('pf_email');
        }
        if (!!auth?.sub) {
          payload.phoneNumber = auth?.name; // returns phone number
          if (queryData.phone != payload.phoneNumber) {
            dispatch(
              CommonActions.UpdateQueryData({
                phone: payload.phoneNumber,
              }),
            );
          }
        }
        payload.analyticalAttr = analytical;
        dispatch(UserActions.UpdateUser(payload));
        clarityLogger('user_phone', auth?.name);
      };
      fetchData();
    }
  }, [auth, isLoading, isAuthenticated, userData?.id, isTransitionPageEmbedded]);

  //udpate lead with ipCity
  useEffect(() => {
    if (!auth?.name || !ipCityData?.city || queryData?.ipxId || !userData?.id || userData?.city || userData?.isActor)
      return;
    dispatch(
      AnalyticsActions.UpdateLead({
        phoneNo: auth.name,
        city: ipCityData.city,
      }),
    );
  }, [auth?.name, ipCityData?.city, userData?.id]);

  useEffect(() => {
    if (common.isLoading || embeddedRoutes.some((route) => router.pathname.includes(route))) {
      return;
    }
    const queryCookieData = getQueryParamFromCookies();
    if (
      queryCookieData?.product_code &&
      consultationCodeTransform(queryCookieData?.product_code) != queryData?.consultationCode
    ) {
      dispatch(CommonActions.UpdateQueryData(queryCookieData));
    }
  }, [common]);

  return <Provider value={patientAuthData}>{children}</Provider>;
}
