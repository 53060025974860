import Image from 'next/image';
import { useMemo, useState } from 'react';

import MidPopUp from '@Components/core/mid-popup';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { IAilments, IDiagnosis } from '@Reducers/encounter';
import { getSortedMetadata } from '@Utils/Helpers/AssessmentReport/helper';
import { useTrans } from '@Utils/hooks/translation';

import { CollapsableListTypes } from '../../pages/assessment-report/v2';
import CausesSection from './causes-section';
import CollapsableList, { causeTypes } from './collapsable-list';
import StickyCta from './sticky-cta';

const InfoCard = ({
  problemDefination,
  problemName,
}: {
  problemDefination: Record<string, string[]>;
  problemName: string;
}) => {
  const { t, languageUsed } = useTrans('ax');
  return (
    <div>
      {Object.keys(problemDefination)?.map((definationTitle) => (
        <>
          {problemName && (
            <Text className='mb-4' font={FontType.SEMIBOLD_18_150} color={ColorType.GREYS_900} language={languageUsed}>
              {t('what-is', { condition: problemName })}
            </Text>
          )}
          {problemDefination?.[definationTitle]?.map((definationDescriptions) => (
            <Text
              key={definationDescriptions}
              className='mb-4'
              font={FontType.REGULAR_14_150}
              color={ColorType.GREYS_900}
              language={languageUsed}>
              {definationDescriptions}
            </Text>
          ))}
        </>
      ))}
      <div className='mb-6 w-full h-px bg-gray-200'></div>
    </div>
  );
};
export const InfoQuestion = ({ onClickHandler, text }) => {
  const { languageUsed } = useTrans('ax');
  return (
    <div onClick={onClickHandler} onKeyDown={onClickHandler} className='flex justify-between'>
      <Text font={FontType.SEMIBOLD_14_150} color={ColorType.INDIGO_600} language={languageUsed}>
        {text}
      </Text>
      <Image
        className='transform -rotate-90'
        src={'/assets/svgs/chevron-indigo.svg'}
        height={20}
        width={20}
        alt='click'
      />
    </div>
  );
};

export default function InformationPopup({
  problem,
  setViewInfo,
  metadata,
  metadataForDiagnosis,
  handleBooking,
  mainNav,
  ailments,
}: Readonly<{
  problem: any;
  setViewInfo: (viewInfo: boolean) => void;
  metadataForDiagnosis: any[];
  metadata: Record<string, any[]>;
  handleBooking: () => void;
  mainNav: Record<string, { nav_item: string; scroll_to: any }>;
  diagnoses: IDiagnosis[];
  ailments: IAilments[];
}>) {
  const { t, languageUsed } = useTrans('ax');
  const [viewDetailedContent, setViewDetailedContent] = useState(false);
  const [listType, setListType] = useState<CollapsableListTypes>();
  const [categorizeInformationBy, setCategorizeInformationBy] = useState<string>('');
  const [titleizeBy, setTitleizeBy] = useState<string>('');
  const mediaLink = useMemo(() => {
    return metadataForDiagnosis?.find((item) => item?.metadataType === 'external_url')?.description;
  }, [metadataForDiagnosis]);
  const causes = useMemo(() => {
    const causeSet = new Set(Object.values(causeTypes));
    return metadataForDiagnosis?.filter((data) => causeSet.has(data?.metadataType));
  }, [metadataForDiagnosis]);

  const treatments = useMemo(() => {
    return metadataForDiagnosis?.filter((data) => data?.metadataType === 'treatment');
  }, [metadataForDiagnosis]);
  const problemDefination = useMemo(() => {
    return getSortedMetadata(metadataForDiagnosis?.filter((data) => data?.metadataType === 'basic_info'));
  }, [metadataForDiagnosis]);
  const onCloseModal = () => {
    setViewInfo(false);
  };
  const handleViewDetailedContent = (listType, categorizeBy, titleizeBy) => {
    setListType(listType);
    setViewDetailedContent(true);
    setCategorizeInformationBy(categorizeBy);
    setTitleizeBy(titleizeBy);
  };
  return (
    <>
      {viewDetailedContent ? (
        <CollapsableList
          listType={listType}
          information={listType === CollapsableListTypes.causes ? causes : treatments}
          informationFor={problem?.medicalCondition?.languageNameMap?.[languageUsed] || problem?.medicalCondition?.name}
          closeModal={() => setViewDetailedContent(false)}
          categorizeInformationBy={categorizeInformationBy}
          titleizeBy={titleizeBy}
          handleViewDetailedContent={handleViewDetailedContent}
          handleBooking={handleBooking}
          mainNav={mainNav}
          onCloseModal={onCloseModal}
        />
      ) : (
        <MidPopUp closeModal={onCloseModal}>
          <div className='p-4 overflow-y-scroll rounded-t-lg '>
            {mediaLink && (
              <div className='mt-1 mb-4'>
                <iframe
                  className='rounded-lg'
                  width={'100%'}
                  height={'182'}
                  src={mediaLink}
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'></iframe>
              </div>
            )}
            <InfoCard
              problemDefination={problemDefination}
              problemName={
                problem?.medicalCondition?.languageNameMap?.[languageUsed] || problem?.medicalCondition?.name
              }
            />
            <div className='-mx-4'>
              {problem?.medicalCondition?.name && causes?.length > 0 && (
                <CausesSection
                  heading={t('diagnosis.causes-for', {
                    condition:
                      problem?.medicalCondition?.languageNameMap?.[languageUsed] || problem?.medicalCondition?.name,
                  })}
                  ailments={ailments}
                  problem={problem}
                  metadata={metadata}
                  handleBooking={handleBooking}
                />
              )}
            </div>
            {problem?.medicalCondition?.name && (
              <section className='flex flex-col gap-5 pb-10'>
                <InfoQuestion
                  onClickHandler={() => handleViewDetailedContent(CollapsableListTypes.treatments, 'title', 'subTitle')}
                  text={t(`diagnosis.possible-treatment-options`)}
                />
              </section>
            )}
            <div className='-my-4'>
              <StickyCta heading={t('need-a-doctors-help-to-explain-this-better')} handleBooking={handleBooking} />
            </div>
          </div>
        </MidPopUp>
      )}
    </>
  );
}
