export enum ColorType {
  PRIMARY_GREEN = 'PRIMARY_GREEN',
  PRIMARY_GREEN_900 = 'PRIMARY_GREEN_900',
  PRIMARY_GREEN_800 = 'PRIMARY_GREEN_800',
  PRIMARY_GREEN_700 = 'PRIMARY_GREEN_700',
  PRIMARY_GREEN_600 = 'PRIMARY_GREEN_600',
  PRIMARY_GREEN_500 = 'PRIMARY_GREEN_500',
  PRIMARY_GREEN_400 = 'PRIMARY_GREEN_400',
  PRIMARY_GREEN_300 = 'PRIMARY_GREEN_300',
  PRIMARY_GREEN_200 = 'PRIMARY_GREEN_200',
  PRIMARY_GREEN_100 = 'PRIMARY_GREEN_100',

  PRIMARY_1000 = 'PRIMARY_1000',
  PRIMARY_900 = 'PRIMARY_900',
  PRIMARY_800 = 'PRIMARY_800',
  PRIMARY_700 = 'PRIMARY_700',
  PRIMARY_600 = 'PRIMARY_600',
  PRIMARY_500 = 'PRIMARY_500',
  PRIMARY_400 = 'PRIMARY_400',
  PRIMARY_300 = 'PRIMARY_300',
  PRIMARY_200 = 'PRIMARY_200',
  PRIMARY_100 = 'PRIMARY_100',
  PRIMARY_75 = 'PRIMARY_75',
  PRIMARY_50 = 'PRIMARY_50',
  PRIMARY_25 = 'PRIMARY_25',

  SECONDARY_ORANGE = 'SECONDARY_ORANGE',
  SECONDARY_ORANGE_900 = 'SECONDARY_ORANGE_900',
  SECONDARY_ORANGE_800 = 'SECONDARY_ORANGE_800',
  SECONDARY_ORANGE_700 = 'SECONDARY_ORANGE_700',
  SECONDARY_ORANGE_600 = 'SECONDARY_ORANGE_600',
  SECONDARY_ORANGE_500 = 'SECONDARY_ORANGE_500',
  SECONDARY_ORANGE_400 = 'SECONDARY_ORANGE_400',
  SECONDARY_ORANGE_300 = 'SECONDARY_ORANGE_300',
  SECONDARY_ORANGE_200 = 'SECONDARY_ORANGE_200',
  SECONDARY_ORANGE_100 = 'SECONDARY_ORANGE_100',

  SECONDARY_BLUE = 'SECONDARY_BLUE',
  SECONDARY_BLUE_900 = 'SECONDARY_BLUE_900',
  SECONDARY_BLUE_800 = 'SECONDARY_BLUE_800',
  SECONDARY_BLUE_700 = 'SECONDARY_BLUE_700',
  SECONDARY_BLUE_600 = 'SECONDARY_BLUE_600',
  SECONDARY_BLUE_500 = 'SECONDARY_BLUE_500',
  SECONDARY_BLUE_400 = 'SECONDARY_BLUE_400',
  SECONDARY_BLUE_300 = 'SECONDARY_BLUE_300',
  SECONDARY_BLUE_200 = 'SECONDARY_BLUE_200',
  SECONDARY_BLUE_100 = 'SECONDARY_BLUE_100',

  SECONDARY_RED = 'SECONDARY_RED',
  SECONDARY_RED_900 = 'SECONDARY_RED_900',
  SECONDARY_RED_800 = 'SECONDARY_RED_800',
  SECONDARY_RED_700 = 'SECONDARY_RED_700',
  SECONDARY_RED_600 = 'SECONDARY_RED_600',
  SECONDARY_RED_500 = 'SECONDARY_RED_500',
  SECONDARY_RED_400 = 'SECONDARY_RED_400',
  SECONDARY_RED_300 = 'SECONDARY_RED_300',
  SECONDARY_RED_200 = 'SECONDARY_RED_200',
  SECONDARY_RED_100 = 'SECONDARY_RED_100',

  SECONDARY_YELLOW = 'SECONDARY_YELLOW',
  SECONDARY_YELLOW_900 = 'SECONDARY_YELLOW_900',
  SECONDARY_YELLOW_800 = 'SECONDARY_YELLOW_800',
  SECONDARY_YELLOW_700 = 'SECONDARY_YELLOW_700',
  SECONDARY_YELLOW_600 = 'SECONDARY_YELLOW_600',
  SECONDARY_YELLOW_500 = 'SECONDARY_YELLOW_500',
  SECONDARY_YELLOW_400 = 'SECONDARY_YELLOW_400',
  SECONDARY_YELLOW_300 = 'SECONDARY_YELLOW_300',
  SECONDARY_YELLOW_200 = 'SECONDARY_YELLOW_200',
  SECONDARY_YELLOW_100 = 'SECONDARY_YELLOW_100',

  SECONDARY_CYAN_100 = 'SECONDARY_CYAN_100',
  SECONDARY_CYAN_300 = 'SECONDARY_CYAN_300',
  SECONDARY_CYAN_600 = 'SECONDARY_CYAN_600',
  SECONDARY_CYAN_900 = 'SECONDARY_CYAN_900',

  GREYS_1100 = 'GREYS_1100',
  GREYS_1000 = 'GREYS_1000',
  GREYS_900 = 'GREYS_900',
  GREYS_800 = 'GREYS_800',
  GREYS_700 = 'GREYS_700',
  GREYS_600 = 'GREYS_600',
  GREYS_500 = 'GREYS_500',
  GREYS_400 = 'GREYS_400',
  GREYS_300 = 'GREYS_300',
  GREYS_200 = 'GREYS_200',
  GREYS_100 = 'GREYS_100',

  COOL_GREY_50 = 'COOL_GREY_50',
  COOL_GREY_75 = 'COOL_GREY_75',
  COOL_GREY_100 = 'COOL_GREY_100',
  COOL_GREY_200 = 'COOL_GREY_200',
  COOL_GREY_300 = 'COOL_GREY_300',
  COOL_GREY_400 = 'COOL_GREY_400',
  COOL_GREY_500 = 'COOL_GREY_500',
  COOL_GREY_600 = 'COOL_GREY_600',
  COOL_GREY_700 = 'COOL_GREY_700',
  COOL_GREY_800 = 'COOL_GREY_800',
  COOL_GREY_900 = 'COOL_GREY_900',

  WHITE = 'WHITE',

  TEAL_100 = 'TEAL_100',
  TEAL_200 = 'TEAL_200',
  TEAL_600 = 'TEAL_600',

  CYAN_50 = 'CYAN_50',
  CYAN_75 = 'CYAN_75',
  CYAN_100 = 'CYAN_100',
  CYAN_200 = 'CYAN_200',
  CYAN_300 = 'CYAN_300',
  CYAN_400 = 'CYAN_400',
  CYAN_500 = 'CYAN_500',
  CYAN_600 = 'CYAN_600',
  CYAN_700 = 'CYAN_700',
  CYAN_800 = 'CYAN_800',
  CYAN_900 = 'CYAN_900',

  PURPLE_V2 = 'PURPLE_V2',

  ORANGE_YELLOW_50 = 'ORANGE_YELLOW_50',
  ORANGE_YELLOW_75 = 'ORANGE_YELLOW_75',
  ORANGE_YELLOW_100 = 'ORANGE_YELLOW_100',
  ORANGE_YELLOW_200 = 'ORANGE_YELLOW_200',
  ORANGE_YELLOW_300 = 'ORANGE_YELLOW_300',
  ORANGE_YELLOW_400 = 'ORANGE_YELLOW_400',
  ORANGE_YELLOW_500 = 'ORANGE_YELLOW_500',
  ORANGE_YELLOW_600 = 'ORANGE_YELLOW_600',
  ORANGE_YELLOW_700 = 'ORANGE_YELLOW_700',
  ORANGE_YELLOW_800 = 'ORANGE_YELLOW_800',
  ORANGE_YELLOW_900 = 'ORANGE_YELLOW_900',

  BLUE_50 = 'BLUE_50',
  BLUE_75 = 'BLUE_75',
  BLUE_100 = 'BLUE_100',
  BLUE_200 = 'BLUE_200',
  BLUE_300 = 'BLUE_300',
  BLUE_400 = 'BLUE_400',
  BLUE_500 = 'BLUE_500',
  BLUE_600 = 'BLUE_600',
  BLUE_700 = 'BLUE_700',
  BLUE_800 = 'BLUE_800',
  BLUE_900 = 'BLUE_900',

  BLUE_GREY_50 = 'BLUE_GREY_50',
  BLUE_GREY_75 = 'BLUE_GREY_75',
  BLUE_GREY_100 = 'BLUE_GREY_100',
  BLUE_GREY_200 = 'BLUE_GREY_200',
  BLUE_GREY_300 = 'BLUE_GREY_300',
  BLUE_GREY_400 = 'BLUE_GREY_400',
  BLUE_GREY_500 = 'BLUE_GREY_500',
  BLUE_GREY_600 = 'BLUE_GREY_600',
  BLUE_GREY_700 = 'BLUE_GREY_700',
  BLUE_GREY_800 = 'BLUE_GREY_800',
  BLUE_GREY_900 = 'BLUE_GREY_900',

  SUCCESS_50 = 'SUCCESS_50',
  SUCCESS_75 = 'SUCCESS_75',
  SUCCESS_100 = 'SUCCESS_100',
  SUCCESS_200 = 'SUCCESS_200',
  SUCCESS_300 = 'SUCCESS_300',
  SUCCESS_400 = 'SUCCESS_400',
  SUCCESS_500 = 'SUCCESS_500',
  SUCCESS_600 = 'SUCCESS_600',
  SUCCESS_700 = 'SUCCESS_700',
  SUCCESS_800 = 'SUCCESS_800',
  SUCCESS_900 = 'SUCCESS_900',

  WARNING_50 = 'WARNING_50',
  WARNING_75 = 'WARNING_75',
  WARNING_100 = 'WARNING_100',
  WARNING_200 = 'WARNING_200',
  WARNING_300 = 'WARNING_300',
  WARNING_400 = 'WARNING_400',
  WARNING_500 = 'WARNING_500',
  WARNING_600 = 'WARNING_600',
  WARNING_700 = 'WARNING_700',
  WARNING_800 = 'WARNING_800',
  WARNING_900 = 'WARNING_900',

  ERROR_50 = 'ERROR_50',
  ERROR_75 = 'ERROR_75',
  ERROR_100 = 'ERROR_100',
  ERROR_200 = 'ERROR_200',
  ERROR_300 = 'ERROR_300',
  ERROR_400 = 'ERROR_400',
  ERROR_500 = 'ERROR_500',
  ERROR_600 = 'ERROR_600',
  ERROR_700 = 'ERROR_700',
  ERROR_800 = 'ERROR_800',
  ERROR_900 = 'ERROR_900',

  ROSE_50 = 'ROSE_50',
  ROSE_75 = 'ROSE_75',
  ROSE_100 = 'ROSE_100',
  ROSE_200 = 'ROSE_200',
  ROSE_300 = 'ROSE_300',
  ROSE_400 = 'ROSE_400',
  ROSE_500 = 'ROSE_500',
  ROSE_600 = 'ROSE_600',
  ROSE_700 = 'ROSE_700',
  ROSE_800 = 'ROSE_800',
  ROSE_900 = 'ROSE_900',

  INDIGO_50 = 'INDIGO_50',
  INDIGO_75 = 'INDIGO_75',
  INDIGO_100 = 'INDIGO_100',
  INDIGO_200 = 'INDIGO_200',
  INDIGO_300 = 'INDIGO_300',
  INDIGO_400 = 'INDIGO_400',
  INDIGO_500 = 'INDIGO_500',
  INDIGO_600 = 'INDIGO_600',
  INDIGO_700 = 'INDIGO_700',
  INDIGO_800 = 'INDIGO_800',
  INDIGO_900 = 'INDIGO_900',

  ATTENTION_50 = 'ATTENTION_50',
  ATTENTION_100 = 'ATTENTION_100',
  ATTENTION_500 = 'ATTENTION_500',
  ATTENTION_600 = 'ATTENTION_600',
  ATTENTION_700 = 'ATTENTION_700',
  ATTENTION_800 = 'ATTENTION_800',
  ATTENTION_900 = 'ATTENTION_900',

  BACKGROUND_GREY = 'BACKGROUND_GREY',
  HMS_PRIMARY_GREY = 'HMS_PRIMARY_GREY',
}
