import Image from 'next/image';
import styled, { keyframes, css } from 'styled-components';

const rotate = keyframes`
  from {
    transform:  rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
`;
const toggleButtonMediaCss = (isOpen) => {
  return (
    isOpen &&
    css`
      @media (max-width: 600px) {
        display: none;
      }
    `
  );
};

const ToggleButton = styled.button`
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 55px;
  height: 55px;
  background-color: #4e5ba6;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: width 0.05s ease-in-out, height 0.05s ease-in-out;
  animation: ${(props) => (props.isOpen ? rotate : undefined)} 0.3s ease-out;
  &:hover {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 600px) {
    /* Hide button when isOpen is true */
    ${(props) => toggleButtonMediaCss(props.isOpen)}
  }
`;

export const ChatbotToggleButton = ({ isOpen, onClick }) => {
  const closeButton = (
    <ToggleButton key='closed' isOpen={false} onClick={onClick}>
      <Image
        className='mb-1 absolute top-3 right-4 toggle-icon'
        src={'/assets/svgs/sexpertai-bubble.svg'}
        alt='allo-icon'
        height={20}
        width={20}
      />
    </ToggleButton>
  );

  const openButton = (
    <ToggleButton key='open' isOpen={true} onClick={onClick}>
      <Image
        className='mb-1 absolute top-3 right-4 toggle-icon'
        src={'/assets/svgs/chevron-white-rounded.svg'}
        alt='allo-icon'
        height={20}
        width={20}
      />
    </ToggleButton>
  );
  return isOpen ? openButton : closeButton;
};
