import { ActionConsts } from '@Definitions/index';
import { IPrice } from '@Reducers/patient';
import { IInvoiceItem } from '@Utils/Helpers/Checkout/helper';

export interface ICheckout {
  isLoading: boolean;
  appointments: IAppointments;
  filteredAppointments: Array<any>;
  billingInvoice: any;
  previewInvoice: any;
  encounterId?: string;
  pauseSlotRelease: boolean; // doesnt actually pause the slot release, just makes sure that the release timer is not running when payment is being made
}

export interface IAppointments {
  id?: string;
  consultationId?: string;
  status?: string;
  type?: string;
  startTime?: string;
  patientId?: string;
  providerId?: string;
  consultationCode?: string;
  category?: string;
  patientMeetingLink?: string;
  locationId?: string;
  alternateLink?: string;
  hmsToken?: string;
}

const INITIAL_STATE: ICheckout = {
  isLoading: false,
  appointments: {},
  filteredAppointments: [],
  billingInvoice: {},
  previewInvoice: {},
  pauseSlotRelease: false,
};

export enum BillType {
  PREVIEW = 'PREVIEW',
  PAID = 'PAID',
  PARTIAL = 'PARTIAL',
  MODIFIED = 'MODIFIED',
}

export interface IbillingData {
  amount?: number;
  listingAmount?: number;
  items?: any;
  drugsPrice?: IPrice;
  labTestsPrice?: IPrice;
  counsellingsPrice?: IPrice;
  followupsPrice?: IPrice;
  type?: BillType;
  previewItems?: IInvoiceItem[];
}

export const CheckoutReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Checkout.GetAppointmentsInit:
      return {
        ...state,
        appointments: INITIAL_STATE.appointments,
        isLoading: true,
      };
    case ActionConsts.Checkout.GetAppointmentsSuccess:
      return {
        ...state,
        appointments: action.appointments,
        isLoading: false,
      };
    case ActionConsts.Checkout.GetAppointmentsFail:
    case ActionConsts.Checkout.ResetAppointments:
      return {
        ...state,
        appointments: INITIAL_STATE.appointments,
        isLoading: false,
      };
    case ActionConsts.Checkout.GetFilteredAppointmentsInit:
      return {
        ...state,
        filteredAppointments: INITIAL_STATE.filteredAppointments,
        isLoading: true,
      };
    case ActionConsts.Checkout.GetFilteredAppointmentsSuccess:
      return {
        ...state,
        filteredAppointments: action.filteredAppointments,
        isLoading: false,
      };
    case ActionConsts.Checkout.GetFilteredAppointmentsFail:
      return {
        ...state,
        filteredAppointments: INITIAL_STATE.filteredAppointments,
        isLoading: false,
      };
    case ActionConsts.Checkout.GetInvoiceInit:
      return {
        ...state,
        billingInvoice: INITIAL_STATE.billingInvoice,
        isLoading: true,
      };
    case ActionConsts.Checkout.GetInvoiceSuccess:
      return {
        ...state,
        billingInvoice: action.billingInvoice,
        isLoading: false,
      };
    case ActionConsts.Checkout.GetInvoiceFail:
    case ActionConsts.Checkout.ResetInvoice:
      return {
        ...state,
        billingInvoice: INITIAL_STATE.billingInvoice,
        isLoading: false,
      };
    case ActionConsts.Checkout.ResetCheckoutData:
      return {
        ...state,
        filteredAppointments: INITIAL_STATE.filteredAppointments,
        appointments: INITIAL_STATE.appointments,
        billingInvoice: INITIAL_STATE.billingInvoice,
        isLoading: false,
      };
    case ActionConsts.Checkout.GetEncounterFromAppointmentInit:
      return {
        ...state,
        encounterId: INITIAL_STATE.encounterId,
        isLoading: true,
      };
    case ActionConsts.Checkout.GetEncounterFromAppointmentSuccess:
      return {
        ...state,
        encounterId: action.encounterId,
        isLoading: false,
      };
    case ActionConsts.Checkout.GetEncounterFromAppointmentFail:
    case ActionConsts.Checkout.ResetEncounterFromAppointment:
      return {
        ...state,
        encounterId: INITIAL_STATE.encounterId,
        isLoading: false,
      };
    case ActionConsts.Checkout.PreviewInvoiceInit:
      return {
        ...state,
        previewInvoice: INITIAL_STATE.previewInvoice,
        isLoading: true,
      };
    case ActionConsts.Checkout.PreviewInvoiceSuccess:
      return {
        ...state,
        previewInvoice: action.previewInvoice,
        isLoading: false,
      };
    case ActionConsts.Checkout.PreviewInvoiceFail:
    case ActionConsts.Checkout.ResetInvoicePreview:
      return {
        ...state,
        previewInvoice: INITIAL_STATE.previewInvoice,
        isLoading: false,
      };
    case ActionConsts.Checkout.TogglePauseSlotRelease:
      return {
        ...state,
        pauseSlotRelease: !state.pauseSlotRelease,
      };
    default:
      return state;
  }
};
