import axios, { AxiosRequestConfig, ResponseType } from 'axios';

import { store } from '@Redux/index';
import { IutmHeaders } from '@Services/Analytics/analytics-payload';
import { getAccessTokenSilently } from '@Services/Auth';
import { UserRole } from '@Utils/constants';
import { getUtmHeaders, isNetworkError } from '@Utils/helper';
import { LOG_ERROR } from '@Utils/logger';

const getBaseUrl = (service) => {
  switch (service) {
    case 'NEXT_PUBLIC_BASE_URL':
      return process.env.NEXT_PUBLIC_BASE_URL;
    default:
      return process.env.NEXT_PUBLIC_API_SERVER_BASE_URL;
  }
};

const requestBuilder = (
  methodType: string,
  url: string,
  token: string,
  payload?: any,
  params?: any,
  service?: string,
  contentType = 'application/json',
  responseType?: ResponseType,
): AxiosRequestConfig => {
  const baseUrl = getBaseUrl(service);
  const { ipxId, ...param } = params || {};
  const utmParams: IutmHeaders = getUtmHeaders();

  return {
    method: methodType,
    url: `${baseUrl}/${url}`,
    headers: {
      'content-type': contentType,
      Authorization: `Bearer ${token}`,
      'x-client-id': `patient${window?.location?.pathname?.replace('/', '-') || ''}`,
      ...(ipxId && { 'x-patient-id': ipxId }),
      ...utmParams,
    },
    params: param,
    responseType: responseType || 'json',
    data: {
      ...payload,
    },
    validateStatus: function (status) {
      return (status >= 200 && status < 300) || status == 422;
    },
  };
};

export const Axios = {
  Request: async (
    methodType: string,
    url: string,
    payload?: any,
    params?: any,
    service?: string,
    contentType?: string,
    responseType?: ResponseType,
  ): Promise<any> => {
    const token = await getAccessTokenSilently().then((response) => response?.authToken);
    const userRole = store.getState()?.auth?.user?.role;
    return axios
      .request(requestBuilder(methodType, url, token, payload, params, service, contentType, responseType))
      .catch((error) => {
        const responseData =
          typeof error?.response?.data === 'string' ? { responseData: error?.response?.data } : error?.response?.data;
        const loggingData = {
          error: {
            code: error?.code,
            message: error?.message,
            name: error?.name,
            status: error?.response?.status,
          },
          hasToken: !!token,
          method: methodType,
          ...(payload && {
            payload: payload,
          }),
          ...responseData,
        };
        if (!isNetworkError(error) && error?.response?.status != 401) {
          LOG_ERROR(`Axios: ${url}`, JSON.stringify(loggingData));
        }
        if (error?.response?.status == 401) {
          loggingData.token = token;
          if (userRole == UserRole.PROVIDER) {
            LOG_ERROR(`Axios 401 error: ${url}`, JSON.stringify(loggingData));
          }
          window.top.location.href = '/logout?relogin=true';
        }
        return error?.response ?? {};
      });
  },
};
