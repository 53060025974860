import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/ActionConsts';
import { LanguageCode } from '@Reducers/metadata';
import { PatientService } from '@Services/index';
import { groupMetaDataById } from '@Utils/helper';
import { LOG_ERROR } from '@Utils/logger';

export const MetaDataActions = {
  GetMetaDataForReferenceIds:
    (requestPayload: { referenceIds: Array<string>; languageCode: LanguageCode }) => async (dispatch: Dispatch) => {
      try {
        dispatch({ type: ActionConsts.MetaData.GetMetaDataInit });
        const result = await PatientService.GetPatientMetadata({
          referenceIds: requestPayload?.referenceIds,
          languageCode: requestPayload?.languageCode,
        });
        if (result?.status === 200) {
          const categorizedDiagnoses = groupMetaDataById(result?.data);
          dispatch({
            type: ActionConsts.MetaData.GetMetaDataSuccess,
            metadata: categorizedDiagnoses,
          });
        } else {
          dispatch({
            type: ActionConsts.MetaData.GetMetaDataFail,
          });
        }
      } catch (error) {
        LOG_ERROR('Error: MetaDataActions>>GetMetaDataForReferenceIds', {
          error,
        });
        dispatch({
          type: ActionConsts.MetaData.GetMetaDataFail,
        });
      }
    },
};
