import SUMO_LOGGER from '@Utils/sumo-logger';
import { useEffect, useMemo, useRef } from 'react';

const TIME_OUT = 6000;

const useLoader = (loadingStates) => {
  const loadingStartRef = useRef(null);
  const loader = useMemo(() => {
    return Object.values(loadingStates || {}).some((loading) => loading);
  }, [loadingStates]);

  useEffect(() => {
    if (loader && loadingStartRef.current === null) {
      loadingStartRef.current = Date.now();
    } else if (!loader) {
      loadingStartRef.current = null;
    }
  }, [loader]);

  useEffect(() => {
    if (loadingStartRef.current) {
      const timer = setTimeout(() => {
        const elapsed = Date.now() - loadingStartRef.current;
        if (elapsed >= TIME_OUT) {
          SUMO_LOGGER('log', 'Loader timeout', { elapsed, loadingStates });
        }
      }, TIME_OUT);

      return () => clearTimeout(timer);
    }
  }, [loader, loadingStates, TIME_OUT]);

  return loader;
};

export default useLoader;
