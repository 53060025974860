import { ActionConsts } from '@Definitions/index';

export interface ITrackSlotsData {
  isLoading?: boolean;
  isSent?: boolean;
}

export interface ITrack {
  trackSlotsData: ITrackSlotsData;
}

const INITIAL_STATE: ITrack = {
  trackSlotsData: {
    isLoading: false,
  },
};

export const TrackReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Track.TrackSlotsInit:
      return {
        ...state,
        trackSlotsData: {
          isLoading: true,
        },
      };
    case ActionConsts.Track.TrackSlotsSuccess:
      return {
        ...state,
        trackSlotsData: {
          isLoading: false,
          isSent: true,
        },
      };
    case ActionConsts.Track.TrackSlotsFail:
      return {
        ...state,
        trackSlotsData: {
          isLoading: false,
          isSent: false,
        },
      };
    case ActionConsts.Track.ResetTrackData:
      return {
        ...state,
        trackSlotsData: INITIAL_STATE.trackSlotsData,
      };
    default:
      return state;
  }
};
