import { AxiosResponse } from 'axios';

import { UpdateLeadPayload, SendActivityPayload } from '@Services/Analytics/analytics-payload';

import { Axios } from '../AxiosApiServerWrapper';

export const AnalyticsService = {
  Get: async (leadId: string): Promise<AxiosResponse> => {
    if (!leadId) return;
    const url = `leads/${leadId}`;
    return Axios.Request('GET', url);
  },
  Update: async (payload: UpdateLeadPayload, leadId: string): Promise<AxiosResponse> => {
    if (!leadId) return;
    payload.phoneNo = `+91${payload.phoneNo.slice(-10)}`;
    const url = `leads/${leadId}`;
    return Axios.Request('Patch', url, payload);
  },
  TrackEncounter: async (dto: { encounterType: string; status: string }): Promise<void> => {
    const url = 'encounters/track';
    return Axios.Request('POST', url, {
      status: dto.status,
      type: dto.encounterType,
    });
  },
  SendActivity: async (payload: SendActivityPayload): Promise<AxiosResponse> => {
    const url = `outbound/activity/send`;
    return Axios.Request('POST', url, payload);
  },
};
