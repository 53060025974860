import Image from 'next/image';

import Accordion from '@Components/accordion';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { IAilments } from '@Reducers/encounter';
import { useTrans } from '@Utils/hooks/translation';

import IssueDetails from './issue-details';

const IssueGrid = ({
  heading,
  issues,
  metadata,
  handleBooking,
  showAccordion,
}: {
  heading?: string;
  issues: IAilments[];
  metadata: Record<string, any[]>;
  handleBooking: () => void;
  showAccordion: boolean;
}) => {
  const { languageUsed } = useTrans('ax');
  return (
    <main>
      <Accordion
        expanded={true}
        className='px-4 z-0'
        backgroundColor={''}
        header={
          <Text font={FontType.SEMIBOLD_18_150} color={ColorType.GREYS_900} language={languageUsed}>
            {heading}
          </Text>
        }
        headerClasses='py-3 px-1'
        openComponent={
          showAccordion && (
            <Image
              src='/assets/svgs/chevron-black.svg'
              width={28}
              height={28}
              alt='allo-icon'
              objectFit='contain'
              className='rotate-90'
            />
          )
        }
        closeComponent={
          showAccordion && (
            <Image
              src='/assets/svgs/chevron-black.svg'
              width={28}
              height={28}
              alt='allo-icon'
              objectFit='contain'
              className='rotate-90'
            />
          )
        }
        mobile={true}>
        <section className='grid grid-cols-2 gap-2 mb-6 '>
          {issues?.map((issue) => (
            <IssueDetails
              key={issue?.ailment?.id}
              issueDetails={issue}
              metadata={metadata}
              handleBooking={handleBooking}
            />
          ))}
        </section>
      </Accordion>
    </main>
  );
};

export default IssueGrid;
