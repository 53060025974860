import { AxiosResponse } from 'axios';

import { GetLocationProvidersMap } from '@Services/Schedule/schedule-payload';

import { Axios } from '../AxiosApiServerWrapper';

export const LocationService = {
  GetProviderLocations: async (payload: GetLocationProvidersMap): Promise<AxiosResponse> => {
    const url = `scheduling/blocks/providers/locations`;
    return Axios.Request('GET', url, {}, payload);
  },
};
