import CognitoClient from '@Components/auth/cognito/client';
import { getAuthResult, removeAuthResult, saveAuthResult } from '@Components/auth/utils';
import { AlloAuthSession } from '@Components/auth/utils';
import { createPostRequest } from '@Utils/api/core';
import { getBaseUrl } from '@Utils/helper';
import SUMO_LOGGER from '@Utils/sumo-logger';

import { LogoutPayload, SendOtpPayload, VerifyOtpPayload } from './auth-payload';

// HACK: This is a hack since send otp and verify otp need to use the same instance of the client.
// This is because of in memory session sent across requests. Find a better solution for the same.
let otpClient: CognitoClient;

export const AuthenticationService = {
  InitAuthState: async (): Promise<any> => {
    return await getAccessTokenSilently().then((response) => {
      return response?.user;
    }); // It resets auth data internally if auth token is expired but session is valid
  },
  SendOtp: async (payload: SendOtpPayload): Promise<any> => {
    otpClient = new CognitoClient(payload.phone);
    return otpClient.passwordlessStartAsync(payload.phone);
  },
  VerifyOtp: async (payload: VerifyOtpPayload): Promise<any> => {
    if (!otpClient) return undefined;
    return await otpClient
      .passwordlessLoginAsync(payload.phone, payload.otp)
      .then(async (session: AlloAuthSession) => {
        if (!session?.accessToken) return;
        await saveAuthResult({
          accessToken: session.accessToken,
          expiresIn: session.expiry,
          idTokenPayload: session.user,
        });
        return session.user;
      })
      .catch(async (error) => {
        if (error.message === 'Incorrect username or password.') {
          throw new Error('Incorrect OTP. Please try again.');
        } else if (error.message === 'Invalid session for the user.') {
          await AuthenticationService.SendOtp({ phone: payload.phone });
          throw new Error('OTP expired. Please enter a new otp.');
        } else {
          throw error;
        }
      });
  },
  Logout: async (payload?: LogoutPayload): Promise<any> => {
    await removeAuthResult();
    const returnToURL = payload?.relogin ? `${window.location.origin}/login` : getBaseUrl();
    const client = CognitoClient.reinitiateClient();
    if (client) {
      await client.logout();
    }
    window.top.location = returnToURL;
  },
};

// This can throw error if session is expired
export const getAccessTokenSilently = async (): Promise<any> => {
  const authPayload = getAuthResult();
  const authToken = authPayload?.data?.accessToken;
  const user = authPayload?.data?.idTokenPayload;
  // Case 1: User already logged in and no need to
  if (authToken && user) return { authToken, user };

  try {
    // Case 2: Check if user login can be refreshed
    const refreshedRes = await refreshActiveSession();
    if (refreshedRes?.authToken) return refreshedRes;

    // Case 3: Check if user can be authenticated silently via ekacare
    const ekacareUserDetails = await isEkacareUser();
    if (ekacareUserDetails?.data && ekacareUserDetails?.phone) {
      const ekacareloginres = await silentlyLoginUsingEkacare(
        ekacareUserDetails.phone,
        JSON.stringify(ekacareUserDetails.data),
      );
      if (ekacareloginres?.authToken) return ekacareloginres;
    }
  } catch (error) {
    if (error.code !== 'NetworkError') SUMO_LOGGER('log', 'GET_ACCESS_TOKEN_SILENTLY>>FAILED', error);
  }

  return {
    authToken: undefined,
    user: undefined,
  };
};

const isEkacareUser = async (): Promise<any> => {
  const ekaWindow: Window & typeof globalThis & { Eka?: any; getiOSHeader?: any } = window;
  let ekaHeaders: { auth?: string } = {};
  if (ekaWindow?.Eka) {
    ekaHeaders = JSON.parse((window as any)?.Eka?.getHeaders?.() || '{}');
  } else if (ekaWindow?.getiOSHeader) {
    ekaHeaders = ekaWindow.getiOSHeader();
  }
  const token = ekaHeaders.auth;
  const { phone } = token
    ? await createPostRequest('/api/verify-ekacare-token', ekaHeaders)
        .then((res) => {
          return {
            phone: res.status == 200 && res.data.success ? res.data.profile.mobile : undefined,
          };
        })
        .catch((_) => {
          return {
            phone: undefined,
          };
        })
    : { phone: undefined };

  return {
    data: ekaHeaders,
    phone,
  };
};

const silentlyLoginUsingEkacare = async (phonenumber: string, token: string): Promise<any> => {
  const client = new CognitoClient(phonenumber, true);
  await client.silentAuthEkacare(phonenumber, token);
  const authDetails = await client.passwordlessGetNewTokenAsync().then(async (session: AlloAuthSession) => {
    if (!session?.accessToken) return;
    await saveAuthResult({
      accessToken: session.accessToken,
      expiresIn: session.expiry,
      idTokenPayload: session.user,
    });
    return {
      accessToken: session.accessToken,
      user: session.user,
    };
  });
  const authToken = authDetails?.accessToken;
  const user = authDetails?.user;
  return { authToken, user };
};

const refreshActiveSession = async (): Promise<any> => {
  const client: CognitoClient = CognitoClient.reinitiateClient();
  if (!client) return;
  const authDetails = await client.passwordlessGetNewTokenAsync().then(async (session: AlloAuthSession) => {
    if (!session?.accessToken) return;
    await saveAuthResult({
      accessToken: session.accessToken,
      expiresIn: session.expiry,
      idTokenPayload: session.user,
    });
    return {
      accessToken: session.accessToken,
      user: session.user,
    };
  });
  const authToken = authDetails?.accessToken;
  const user = authDetails?.user;
  return { authToken, user };
};
