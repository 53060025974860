import dayjs from 'dayjs';
import Image from 'next/image';
import { useState } from 'react';

import { IPrice } from '@Reducers/patient';
import { IClinicLocationsData, ISlot, ISlotData } from '@Reducers/schedule';
import { checkIfTodayOrTomorrow } from '@Utils/helper';
import { flatMapSlotsData } from '@Utils/Helpers/slot.helper';
import { useTrans } from '@Utils/hooks/translation';

import { FontType, ColorType } from './common-exports';
import Text from './design-system/text';
import LocationImageCarousel from './location-images-carousel';

const ListingOffline = ({
  slotsData,
  selectedDate,
  setSelectedDate,
  allSlotDates,
  selectedSlot,
  handleSlotChange,
  price,
  locationData,
}: {
  slotsData: ISlotData;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  allSlotDates: string[];
  selectedSlot: ISlot;
  handleSlotChange: (slot: ISlot) => void;
  price: IPrice;
  locationData: IClinicLocationsData;
}) => {
  const { t, languageUsed } = useTrans('ax');
  const [showFullAddress, setShowFullAddress] = useState(false);

  const allSlots = flatMapSlotsData(slotsData?.[selectedDate]);
  return (
    <>
      <div className='bg-BLUE_GREY_75 px-3 py-2 rounded-t-lg flex justify-between'>
        <div className='flex gap-0.5 items-center'>
          <Image src='/assets/svgs/star.svg' alt='location' width={16} height={16} />
          <Text font={FontType.REGULAR_12_170} color={ColorType.GREYS_700} language={languageUsed}>
            <span className='font-bold text-black'>4.7/5 </span>
            {t('location-card.thousand-patients')}
          </Text>
        </div>
        <Text font={FontType.SEMIBOLD_12_170} color={ColorType.GREYS_900} language={languageUsed}>
          {t(`slots.fees`, {
            amount: price.sellingPrice,
          })}
        </Text>
      </div>
      <div className='flex justify-between w-full px-3 py-2'>
        <div className='w-11/12 pr-2'>
          <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900}>
            {locationData?.locality}
          </Text>
          <Text
            font={FontType.REGULAR_11_170}
            color={ColorType.INDIGO_600}
            language={languageUsed}
            onClick={() => setShowFullAddress(!showFullAddress)}>
            {t('slots.view-address')}
          </Text>
          {showFullAddress && (
            <Text color={ColorType.GREYS_600} font={FontType.REGULAR_11_170}>
              {locationData?.address}
            </Text>
          )}
          <Text color={ColorType.GREYS_600} font={FontType.REGULAR_10_170}>
            {locationData?.city}
          </Text>
          <Text color={ColorType.GREYS_600} font={FontType.REGULAR_10_170} language={languageUsed}>
            <span className='text-SUCCESS_500'> Open </span>
            &middot; {locationData?.workingHours}
          </Text>
        </div>
        {locationData?.imageLinks?.length > 0 && (
          <div className='w-full'>
            <LocationImageCarousel images={locationData?.imageLinks} />
          </div>
        )}
      </div>
      <div className='flex overflow-x-scroll w-full border border-[0.5px] px-3'>
        {allSlotDates?.map((date) => (
          <button
            key={date}
            className={` cursor-pointer p-2.5  flex gap-1  ${
              date === selectedDate ? 'border-b-2 border-PRIMARY_600 rounded-none ' : ''
            }`}
            onClick={() => setSelectedDate(date)}
            onKeyDown={() => setSelectedDate(date)}>
            <Text font={FontType.SEMIBOLD_11_170} className='text-center whitespace-nowrap' color={ColorType.GREYS_900}>
              {checkIfTodayOrTomorrow(date, t)}
            </Text>
            <Text
              font={FontType.REGULAR_11_170}
              color={Object.keys(slotsData?.[date] ?? {})?.length > 0 ? ColorType.SUCCESS_500 : ColorType.GREYS_500}
              className='whitespace-nowrap'>
              {flatMapSlotsData(slotsData?.[date])?.length > 0
                ? `${flatMapSlotsData(slotsData?.[date])?.length} slots`
                : 'No slots'}
            </Text>
          </button>
        ))}
      </div>
      {selectedDate && (
        <div className='flex gap-2 overflow-x-scroll py-2 w-full'>
          {!allSlots?.length && Object.keys(slotsData)?.length > 0 ? (
            <div className='text-center w-full'>
              <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_700} language={languageUsed}>
                {t('slots.no-slots-available-for-this-date')}
              </Text>
            </div>
          ) : (
            <div className='flex flex-col w-full'>
              {allSlots?.length > 0 && (
                <Text
                  className='pb-1 px-4'
                  font={FontType.SEMIBOLD_10_170}
                  color={ColorType.GREYS_600}
                  language={languageUsed}>
                  {t('book-appointment.available-slots')}
                </Text>
              )}
              <div className='grid grid-cols-5 gap-2 py-2 px-4 w-full'>
                {allSlots?.map((slot) => (
                  <button
                    key={slot?.startDate}
                    onClick={() => handleSlotChange(slot)}
                    className={
                      `${
                        slot?.startDate === selectedSlot?.startDate
                          ? 'bg-PRIMARY_50 border border-PRIMARY_600'
                          : 'border border-GREYS_300'
                      }` +
                      ' cursor-pointer whitespace-nowrap px-2 py-1.5 flex justify-center rounded bg-GREYS_200 shadow-slot'
                    }>
                    <Text font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_800}>
                      {dayjs(slot?.startDate)?.format('h:mm A')}
                    </Text>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default ListingOffline;
