import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { GENERAL_CONSULTATION_STATES } from '@Components/design-system/message-card';
import { getLocal, setLocal } from '@Core/storage/local-storage';
import { IRx, LabVenue } from '@Reducers/encounter';
import { LanguageCode } from '@Reducers/metadata';
import { IConsultationOrder } from '@Reducers/order';
import { IDrugData, IMetadataData } from '@Reducers/patient';
import { IProviderData } from '@Reducers/provider';
import { AppointmentStatus, ISlotData } from '@Reducers/schedule';
import { IUserData } from '@Reducers/user';
import { IutmHeaders } from '@Services/Analytics/analytics-payload';
import { UpdateQueryDataPayload } from '@Services/Common/common-payload';
import { IAddress } from '@Services/Patient/patient-payload';

import {
  validDiagnosis,
  validComboPlans,
  adviceTypes,
  appointmentTypes,
  metaDataSequence,
  CONSULTATION_TYPE,
  rxTypes,
  UserRole,
  frequencyValues,
  PAGE,
  TRANSITION_PAGES,
  WeekDays,
  DEFAULT_LOCATION_CODE,
  ItemType,
  ONLINE_LOCATION_ID,
} from './constants';
import { sortFollowUpOrdersOnAppointment } from './Helpers/Order/helper';
import { LOG_ERROR } from './logger';

export const getReferredRoute = () => {
  const referredRoute = Cookies.get('referred_route') || getLocal('referred_route') || '';

  return referredRoute && referredRoute !== PAGE.LOGIN.ROUTE ? referredRoute : PAGE.DASHBOARD.ROUTE;
};

const isValidRoute = (route: string) => {
  return Object.values(PAGE).some((page) => route.includes(page.ROUTE));
};

const getPageByRoute = (route: string) => {
  return Object.values(PAGE).find((page) => route.includes(page.ROUTE));
};

const setReferredPage = (page: any) => {
  Cookies.set('referred_route', page.ROUTE, {
    expires: new Date(Date.now() + 6_000_000),
    domain: `.${extractDomainFromUrl(process.env.NEXT_PUBLIC_BASE_URL)}`,
    sameSite: 'strict',
    secure: true,
  });
  setLocal('referred_route', page.ROUTE);
};

export const setReferredPageCookie = (experimentType: string) => {
  switch (experimentType) {
    case 'SC':
    case 'HIGH':
    case 'DIRECT_LINK':
    case 'NEW_SCHEDULE':
      setReferredPage(PAGE.APPOINTMENT);
      break;
    case 'AR':
      setReferredPage(PAGE.ASSESSMENT);
      break;
    case 'CX':
      setReferredPage(PAGE.CX_JOURNEY);
      break;
    case 'AR_VIEW':
      setReferredPage(PAGE.ASSESSMENT_REPORT);
      break;
    case 'SR':
      setReferredPage(PAGE.REPORT);
      break;
    case 'PR_VIEW':
      setReferredPage(PAGE.PREPORT);
      break;
    case 'HMS':
      setReferredPage(PAGE.HMS);
      break;
    case 'PHMS':
      setReferredPage(PAGE.PHMS);
      break;
    case 'PRINT':
      setReferredPage(PAGE.PRINT_PRESCRIPTION);
      break;
    case 'RECORDS':
      setReferredPage(PAGE.RECORDS);
      break;
    default:
      setReferredPage(PAGE.DASHBOARD);
      break;
  }
};

const getCurrentRoute = () => {
  return window?.location?.pathname?.split('/').slice(1).join('/') || '';
};

export const getCurrentPage = () => {
  try {
    const currentRoute = getCurrentRoute();
    return isValidRoute(currentRoute) ? getPageByRoute(currentRoute) : PAGE.DASHBOARD;
  } catch {
    return PAGE.DASHBOARD;
  }
};

export const isTransitionPage = (currentPage) => {
  return TRANSITION_PAGES.some((page) => page.NAME === currentPage.NAME);
};

export const setCurrentPage = () => {
  const currentPage = getCurrentPage();
  if (isTransitionPage(currentPage)) return;
  setReferredPage(currentPage);
};

export const redirectTo = (page: any, params = '') => {
  window.top.location.href = `/${page?.ROUTE}?${params}`;
};

export const transformAmount = (amountInPaise: number): number => {
  try {
    if (amountInPaise) {
      const amountInDecimal = (amountInPaise / 100).toFixed(2);
      const rupee = amountInDecimal.slice(0, Math.max(0, amountInDecimal.length - 3));
      const paise = amountInDecimal.slice(-2);
      return paise == '00' ? +rupee : +amountInDecimal;
    } else {
      return 0;
    }
  } catch (error) {
    LOG_ERROR('Error: helper|transformAmount', error);
    return 0;
  }
};

const isRecoringEnabled = (appointmentTypeId: string, providerConfig?: any) => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  return !!providerConfig?.[appointmentTypeId]?.recordingDays?.includes(WeekDays[dayOfWeek]);
};

export const calculatePercentageDiscount = (numerator: number, denominator: number) => {
  try {
    if (numerator == denominator || denominator == 0) {
      return '';
    } else {
      const percentage = 100 - Math.round((numerator / denominator) * 100);
      return percentage.toString();
    }
  } catch (error) {
    LOG_ERROR('DEBUG: helper|calculatePercentage', error);
    return '0';
  }
};

export const calculatePercentage = (numerator: number, denominator: number): number => {
  try {
    if (numerator == denominator || denominator == 0) {
      return 0;
    } else {
      const percentage = 100 - Math.round((numerator / denominator) * 100);
      return percentage;
    }
  } catch (error) {
    LOG_ERROR('DEBUG: helper|calculatePercentage', error);
    return 0;
  }
};

export const getformattedPhoneNumber = (
  phone: string,
  withInCode = false,
  returnNumberWithoutInCode = false,
): string => {
  //Add international code if it doesn't exists
  if (withInCode && phone) {
    phone = `+91${phone?.replace(/\+91-|\+91/g, '')}`;
  }
  if (returnNumberWithoutInCode) {
    phone = `${phone?.replace(/\+91-|\+91/g, '')}`;
  }
  return phone;
};

const getVersionBasedFormId = (assessmentLanguage?: LanguageCode) => {
  return assessmentLanguage === LanguageCode.Hindi
    ? process.env.NEXT_PUBLIC_AR_V2_FORM_ID_HI
    : process.env.NEXT_PUBLIC_AR_FORM_ID;
};

export const getFormId = (formType, assessmentLanguage?: LanguageCode) => {
  switch (formType) {
    case 'SC':
      return process.env.NEXT_PUBLIC_SC_FORM_ID;
    case 'FOLLOW_UP':
    case 'CARE_DELIVERY':
      return process.env.NEXT_PUBLIC_FOLLOW_UP_FORM_ID;
    case 'PROFILE':
    case 'PROFILE_FOLLOW_UP':
      return process.env.NEXT_PUBLIC_PROFILE_FORM_ID;
    case 'OA':
      return process.env.NEXT_PUBLIC_OA_FORM_ID;
    case 'OPTIONAL_ASSESSMENT':
    case 'AR_SC':
      return process.env.NEXT_PUBLIC_AF_FORM_ID;
    case 'HIGH':
    case 'HHA':
      return process.env.NEXT_PUBLIC_HIGH_FLOW_FORM_ID;
    default:
      return getVersionBasedFormId(assessmentLanguage);
  }
};

export const isNetworkError = (data: any) =>
  data?.code === 'ECONNABORTED' ||
  data?.message == 'Network data' ||
  data?.error?.message == 'Network Error' ||
  data?.response?.status === 0 ||
  data?.error?.code === 'ECONNABORTED';

export const extractDomainFromUrl = (url: string) => {
  return url
    .replace(/(^\w+:|^)\/\//, '')
    .replace(/^www\./g, '')
    .replace(/:\d+/, '');
};

export const setQueryDataInCookies = (queryParam: UpdateQueryDataPayload) => {
  Cookies.set('queryParam', JSON.stringify(queryParam), {
    expires: new Date(Date.now() + 600_000),
    domain: `.${extractDomainFromUrl(process.env.NEXT_PUBLIC_BASE_URL)}`,
    sameSite: 'strict',
    secure: true,
  });
};

export const getQueryParamFromCookies = () => {
  return !Cookies.get('queryParam') ? {} : JSON.parse(Cookies.get('queryParam'));
};

const replaceSpacesWithHyphenLowercase = (string: string) => {
  return string?.replace(/\s+/g, '-')?.toLowerCase();
};

export const getValidTreatmentPlanFileName = (diagnosis: Array<string>, medicineOnly: boolean) => {
  let filename = 'non-eligible';
  let validDiagnosisArray = diagnosis.filter((value) => validDiagnosis.includes(value));
  // Bussiness Logic for Therapy Preference
  if (validDiagnosisArray.includes('Counselling Sessions')) {
    validDiagnosisArray = ['Counselling Sessions'];
  }
  if (diagnosis.length === 0 || validDiagnosisArray.length === 0) {
    //If no diagnosis is valid
    filename = 'non-eligible';
  } else {
    const diagnosisFiles = validDiagnosisArray?.map((diagnoses) => replaceSpacesWithHyphenLowercase(diagnoses));
    filename =
      diagnosisFiles.length == 1 ? diagnosisFiles[0] : getValidComboDiagnosis(validDiagnosisArray) ?? diagnosisFiles[0];
  }
  return medicineOnly ? `${filename}-medicine` : filename;
};

const generatePossibleDualPlans = (validDiagnosisArray) => {
  const dualPlans = [];
  for (let i = 0; i < validDiagnosisArray.length; i++) {
    for (let j = i + 1; j < validDiagnosisArray.length; j++) {
      dualPlans.push([validDiagnosisArray[i], validDiagnosisArray[j]].sort());
    }
  }
  return dualPlans;
};

const getValidComboDiagnosis = (validDiagnosisArray) => {
  const serializedValidDiagnosisArray = JSON.stringify(validDiagnosisArray.sort());
  let filename;
  validComboPlans.every((comboPlan) => {
    if (JSON.stringify(comboPlan) === serializedValidDiagnosisArray) {
      filename = replaceSpacesWithHyphenLowercase(comboPlan.join('-'));
      return false;
    }
    return true;
  });
  if (!filename) {
    const possibleDualPlans = generatePossibleDualPlans(validDiagnosisArray);
    for (const comboPlan of validComboPlans) {
      for (const dualPlan of possibleDualPlans) {
        if (JSON.stringify(comboPlan) === JSON.stringify(dualPlan) && !filename) {
          filename = replaceSpacesWithHyphenLowercase(comboPlan.join('-'));
        }
      }
    }
  }
  return filename;
};

export const formatName = (name = '') => {
  return name
    ?.toLowerCase()
    ?.replace(/"null"/g, '')
    ?.replace(/"undefined"/g, '')
    ?.replace(/_/g, ' ')
    ?.split(' ')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');
};

export const SentenceCase = (name = '') => {
  return name
    ?.toLowerCase()
    ?.replace(/_/g, ' ')
    ?.replace(/^\w/, (c) => c.toUpperCase())
    ?.trim();
};

export const checkIfValidName = (value: string) => {
  return /^[ A-Za-z]+$/.test(value);
};

export const hasTranslation = (key: string) => {
  return !key.includes('.');
};

export const getBaseUrl = () => {
  return process.env.NEXT_PUBLIC_BASE_URL === 'https://patient.allohealth.care'
    ? 'https://www.allohealth.care'
    : 'https://www.alpha.allohealth.care';
};

export const getMappedImage = (name = '') => {
  return name ? `/assets/svgs/${name.toLowerCase().replace(/" "/g, '-')}.svg` : '';
};

export const getUniqueValues = (array: Array<any>, key: string) => {
  return [...new Set(array?.map((item) => item?.[key]))];
};

export const checkIfTodayOrTomorrow = (date: string, translate?) => {
  const d = dayjs(date);
  const today = dayjs();
  const tomorrow = dayjs().add(1, 'day');
  if (d.isSame(today, 'day')) return !translate ? 'Today' : translate('today');
  if (d.isSame(tomorrow, 'day')) return !translate ? 'Tomorrow' : translate('tomorrow');
  return d.format('dddd');
};

export const checkAppointmentExpiry = (date: string) => {
  const currentDate = dayjs();
  const diff = Math.abs(currentDate.diff(date, 'minute'));
  return diff < 120;
};

export const getLatestAppointment = (appointments: Array<any>) => {
  let mostRecentItem;
  for (const appointment of appointments || []) {
    if (
      appointment?.status === AppointmentStatus.SCHEDULED ||
      appointment?.status === AppointmentStatus.IN_PROGRESS ||
      appointment?.status === AppointmentStatus.PATIENT_JOINED ||
      appointment?.status === AppointmentStatus.PROVIDER_JOINED ||
      appointment?.status === AppointmentStatus.MISSED
    ) {
      const startTime = new Date(appointment?.startTime)?.getTime();
      if (!mostRecentItem || startTime < new Date(mostRecentItem?.startTime)?.getTime()) {
        mostRecentItem = appointment;
      }
    }
  }
  return mostRecentItem || {};
};

export const getMatchingAppointment = (appointments: Array<any>, date: string) => {
  const matchingAppointment =
    appointments?.find(
      (appointment) =>
        dayjs(appointment?.startTime).isSame(dayjs(date)) && appointment?.status === AppointmentStatus.SCHEDULED,
    ) || {};
  return matchingAppointment;
};

export const getFullGenderName = (gender = '') => {
  switch (gender) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    default:
      return 'Other';
  }
};

export const extractPatientDetails = (reportData: any) => {
  const patientName =
    reportData?.patient?.verifiedName ||
    (reportData?.patient?.firstName || '') +
      (reportData?.patient?.lastName ? ' ' + reportData?.patient?.lastName : '') ||
    '';
  const bmi = reportData?.vitals?.find((vital) => vital?.type === 'bmi')?.value;
  const age = reportData?.patient?.age;
  const gender = reportData?.patient?.gender;
  const relationshipStatus = reportData?.patient?.relationshipStatus;
  const patientEmail = reportData?.patient?.email || '';
  const patientPhone = reportData?.patient?.phoneNumber || '';
  return {
    patientName,
    patientEmail,
    patientPhone,
    bmi,
    age,
    gender,
    relationshipStatus,
  };
};

export const getDiagnosis = (report = []) => {
  const diagnosis = [];
  for (const diag of report) {
    diagnosis.push({
      name: diag?.description,
      severity: diag?.severity,
    });
  }
  return diagnosis;
};

export const consultationCodeTransform = (code: string) => {
  const transformedCode = code ? code?.toString()?.toUpperCase()?.replace(/_F$/, '')?.replace(/_E$/, '') : '';
  switch (transformedCode) {
    case 'OF':
      return 'FC';
    case 'OFT':
      return 'TH';
    default:
      return transformedCode;
  }
};

export const getMedHistory = (report = []) => {
  const medicalHistory = [];
  for (const clinicalHistory of report) {
    if (clinicalHistory?.issueType === 'illness') {
      medicalHistory.push(
        clinicalHistory?.issue !== 'other_illness'
          ? clinicalHistory?.issue.replace('_', ' ')
          : clinicalHistory?.description.replace('_', ' ') || '',
      );
    }
  }
  if (medicalHistory?.length > 1) medicalHistory[0] = formatName(medicalHistory[0]);
  return medicalHistory;
};

export const getAdvices = (report) => {
  const adviceLists = {};
  if (!report) {
    return adviceLists;
  }
  for (const summary of report) {
    const type = summary?.type;
    const description = summary?.description;
    if (!type) {
      continue;
    }
    const adviceType = adviceTypes[type];
    if (adviceType) {
      if (!adviceLists[adviceType]) adviceLists[adviceType] = [];
      adviceLists[adviceType].push(description);
    }
  }
  return adviceLists;
};

export const getResources = (report = []) => {
  const resources = [];
  for (const resource of report) {
    if (resource?.type?.startsWith('leaflet')) {
      resources.push(resource?.description);
    }
  }
  return resources;
};

export const getUniqueValuesCount = (array: Array<any>, key: string) => {
  if (!array) return [];
  const uniqueValues = [...new Set(array?.map((item) => item?.[key]))];
  const countMap = {};

  for (const item of array) {
    const value = item?.[key];
    countMap[value] = countMap[value] ? countMap[value] + 1 : 1;
  }

  const uniqueValuesWithCount = uniqueValues?.map((value) => {
    return { value, count: countMap[value] };
  });

  return uniqueValuesWithCount;
};

export const filterAppointments = (
  consultationsList: Array<any>,
  types: string[] = [],
  statuses: string[] = [],
  rxTypes: string[] = [],
) => {
  const validConsultationForReport = [];
  for (const consultationData of consultationsList || []) {
    if (
      (types?.length > 0
        ? types?.some((type) => type === consultationData?.consultation?.consultationType?.name)
        : true) &&
      (statuses?.length > 0 ? statuses?.some((status) => status === consultationData?.status) : true) &&
      (rxTypes?.length > 0
        ? rxTypes?.some(
            (rxType) =>
              rxType === consultationData?.attributes?.rxType ||
              (consultationData?.attributes?.rxType?.length === 0 &&
                consultationData?.consultation?.consultationType?.name === appointmentTypes.SCREENING_CALL),
          )
        : true)
    ) {
      validConsultationForReport?.push(consultationData);
    }
  }
  return validConsultationForReport?.sort((a: any, b: any): any => {
    return new Date(b?.startTime)?.getTime() - new Date(a?.startTime)?.getTime();
  });
};

export const formatNumbertoRupee = (amount: number) => {
  const formatter = new Intl.NumberFormat('en-IN');
  return formatter.format(amount);
};

export const mapMetaData = (metaData: any) => {
  const mappedMetaData = new Map<string, any[]>();
  metaData?.forEach((metaDataItem: { referenceId: string }) => {
    const referenceId = metaDataItem?.referenceId;
    if (referenceId) {
      const existingData = mappedMetaData.get(referenceId) ?? [];
      mappedMetaData.set(referenceId, [...existingData, metaDataItem]);
    }
  });
  return mappedMetaData;
};

export const groupMetaDataById = (metaData: any) => {
  const mappedMetaData = {};
  if (metaData)
    for (const metaDataItem of metaData) {
      const referenceId = metaDataItem?.referenceId;
      if (!mappedMetaData[referenceId]) {
        mappedMetaData[referenceId] = [metaDataItem];
      } else {
        mappedMetaData[referenceId].push(metaDataItem);
      }
    }
  return mappedMetaData;
};

export const groupMetaDataByTitle = (metaData: any) => {
  const groupedMetaData: {
    [key: string]: { seqNo: number; description: string }[];
  } = {};

  metaData?.forEach((metaDataItem: { title: string; description: string; seqNo: number }) => {
    const title = metaDataItem?.title?.trim() || 'General';
    const { seqNo, description } = metaDataItem;
    if (!groupedMetaData?.[title]) {
      groupedMetaData[title] = [];
    }
    groupedMetaData?.[title].push({ seqNo, description });
  });

  const sortedGroupedMetaData = {};

  for (const [title, data] of Object.entries(groupedMetaData).sort(([aTitle], [bTitle]) =>
    aTitle.localeCompare(bTitle),
  )) {
    sortedGroupedMetaData[title] = data?.slice().sort((a, b) => a?.seqNo - b?.seqNo);
  }

  return sortedGroupedMetaData;
};

export const getSortedEnquiries = (metaData: any) => {
  const enquiries: {
    [key: string]: {
      seqNo: number;
      content: IMetadataData[];
    };
  } = {};

  const metadataTypes = metaData?.map((enquiry) => enquiry?.metadataType);
  const sortedMetadataTypes = metaDataSequence;
  //if type not present in sequence, add it to the end
  for (const type of metadataTypes) {
    if (!sortedMetadataTypes[type]) {
      sortedMetadataTypes[type] = Object.keys(sortedMetadataTypes)?.length + 1;
    }
  }

  for (const enquiry of metaData) {
    if (!enquiries?.[enquiry?.metadataType]) {
      enquiries[enquiry?.metadataType] = {
        seqNo: sortedMetadataTypes?.[enquiry?.metadataType],
        content: [],
      };
    }
    enquiries?.[enquiry?.metadataType]?.content.push(enquiry);
  }
  const sortedEnquiries = Object.keys(enquiries)
    .sort((a, b) => enquiries[a]?.seqNo - enquiries[b]?.seqNo)
    .reduce((obj, key) => {
      obj[key] = enquiries?.[key];
      return obj;
    }, {});
  return sortedEnquiries;
};

export const checkIfConsultationDay = (appointmentDate: string) => {
  const today = dayjs();
  return dayjs(appointmentDate).isSame(today, 'day');
};

export const getRefreshTime = (count: number) => {
  return count > 2 ? 10_000 : 60_000;
};

export const formatAddress = (address: IAddress) => {
  if (!address) return '';
  const formattedAddress = [];
  const { street, city, state, pincode, country } = address;
  if (street) formattedAddress.push(formatName(street));
  if (city) formattedAddress.push(formatName(city));
  if (state && pincode) {
    formattedAddress.push(`${formatName(state)}-${formatName(pincode)}`);
  }
  if (country) formattedAddress.push('\n' + formatName(country));
  return formattedAddress.join(', ');
};

export const getMeetingLink = (
  hmsToken: string,
  name: string,
  appointmentTypeId: string,
  appointmentId?: string,
  providerConfig?: any,
) => {
  const env = process.env.NEXT_PUBLIC_ENVIRONMENT;
  const dev = env !== 'production';
  const rec = isRecoringEnabled(appointmentTypeId, providerConfig);
  return `https://meet.${
    dev ? 'alpha.' : ''
  }allohealth.care/meeting?token=${hmsToken}&rec=${rec}&name=${name}&appointment_id=${appointmentId}`;
};

export const generateRandomUniqueColor = (idx, range = 600) => {
  const colors = ['ERROR', 'ORANGE_YELLOW', 'SECONDARY_BLUE', 'CYAN', 'SUCCESS'];
  return `${colors[Math.floor(idx % colors?.length)]}_${range}`;
};

export const decodeCookie = (cookie = '') => {
  try {
    return window.atob(cookie);
  } catch {
    try {
      return decodeURIComponent(cookie);
    } catch {
      return cookie;
    }
  }
};

export const generateRandomNumber = (): number => {
  const randomBytes = crypto.getRandomValues(new Uint8Array(4));
  const randomValue =
    ((randomBytes[0] << 24) | (randomBytes[1] << 16) | (randomBytes[2] << 8) | randomBytes[3]) / 0xff_ff_ff_ff;
  const randomNumber = 4.5 + Math.abs(randomValue) * 0.5;
  return Number.parseFloat(randomNumber.toFixed(1));
};

export const getEncounterName = (encounterType: string) => {
  switch (encounterType) {
    case rxTypes.CONSULTATION_RX:
      return 'Follow Up';
    case rxTypes.SCREENING_RX:
      return 'Screening Call';
    case rxTypes.MERGE_RX:
      return 'Screening Call';
    case rxTypes.TAKE_AX:
      return 'Assessment Report';
    case rxTypes.COUNSELLING_RX:
      return 'Counselling Session';
  }
};

export const PaperformIdEncounterTypeMap = (paperformId: string) => {
  switch (paperformId) {
    case process.env.NEXT_PUBLIC_AR_FORM_ID:
      return rxTypes.TAKE_AX;
  }
};

export const maskPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';

  const lastFourDigits = phoneNumber.slice(-4);
  const xCoveredDigits = 'X'.repeat(phoneNumber.length - 7);

  return xCoveredDigits + lastFourDigits;
};

export const getSevenDayRange = (date: string) => {
  const startDate = dayjs(date);
  const endDate = startDate.add(7, 'day');
  return endDate.month() !== startDate.month()
    ? `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`
    : `${startDate.format('MMM D')} - ${endDate.format('D')}`;
};

export const checkLanguageModalEligibility = (
  consultationCode: string,
  userData: IUserData,
  isOffline = false,
  hasProviderId = false,
): boolean => {
  if (isOffline || hasProviderId) {
    return false;
  }
  if (consultationCode === CONSULTATION_TYPE.TH && !userData?.therapistId) {
    return true;
  } else if (consultationCode !== CONSULTATION_TYPE.TH && !userData?.physicianId) {
    return true;
  } else {
    return false;
  }
};

export const getHighestPriorityConsultationOrder = (consultationOrders: IConsultationOrder[]) => {
  const CONSULTATION_ORDER_STATES_PRIORITY = [
    GENERAL_CONSULTATION_STATES.JOIN_SESSION,
    GENERAL_CONSULTATION_STATES.UPCOMING,
    GENERAL_CONSULTATION_STATES.RECONSULT,
    GENERAL_CONSULTATION_STATES.SCHEDULE_SESSION,
  ];

  let priorityOrder;

  for (const status of CONSULTATION_ORDER_STATES_PRIORITY) {
    const priorityOrders = consultationOrders?.filter((consultation) => consultation?.status === status);
    priorityOrders?.sort((a, b) => {
      const startTimeA = new Date(a?.appointmentData?.startTime)?.getTime();
      const startTimeB = new Date(b?.appointmentData?.startTime)?.getTime();
      return startTimeA - startTimeB;
    });
    priorityOrder = priorityOrders?.[0];
    if (priorityOrder) {
      break;
    }
  }
  return priorityOrder;
};

export const fetcher = (url) => fetch(url).then((res) => res.json());

export const scrollToSection = (section, topGap = 0) => {
  if (!section) return;
  const elem = section.current;
  if (!elem) return;
  window.scrollTo({
    behavior: 'smooth',
    top: elem.offsetTop - topGap,
  });
};

export const prescriptionHasSomethingToBuy = (prescription: IRx) => {
  const { drugs, labTests, counsellings } = prescription;
  const hasDrugs = drugs?.length > 0;
  const hasLabTests = labTests?.length > 0;
  const hasConsultation = counsellings?.length > 0;
  return hasDrugs || hasLabTests || hasConsultation;
};

export const getShowcaseFollowupOrders = (followupOrders: IConsultationOrder[]) => {
  //followup orders dashboard -> if any followup order is not completed then show that order else show the latest followup order
  return followupOrders?.some((order) => order?.status !== GENERAL_CONSULTATION_STATES.COMPLETED)
    ? followupOrders?.filter((order) => order?.status !== GENERAL_CONSULTATION_STATES.COMPLETED)
    : sortFollowUpOrdersOnAppointment(followupOrders, 'createdAt');
};

export const getEndDate = (consultationCode, locationCode, userRole) => {
  // values + 1 will reflected
  if (consultationCode?.startsWith('TH') && userRole !== UserRole.PATIENT) return 29;
  else if (locationCode === 'DEL_GUR-1') return 9;
  else if (consultationCode?.startsWith('FU')) return 9;
  else if (consultationCode === CONSULTATION_TYPE.SC && (locationCode === DEFAULT_LOCATION_CODE || !locationCode))
    return 1;
  else return 4;
};

export const canBookConsultation = (consultationOrder: IConsultationOrder, isAgentOrProvider: boolean) => {
  return dayjs().isAfter(dayjs(consultationOrder?.timestamps?.prescribed)) || isAgentOrProvider;
};

export const getShortendedInstructions = (drug: IDrugData) => {
  try {
    if (drug?.applicationEvents?.length === 0) return '';
    const areEventsSame = drug?.applicationEvents?.every((event) => event?.type === drug?.applicationEvents[0]?.type);
    const { condition, duration, durationUnit } = drug;
    const frequency = drug?.frequency?.replace(/_/g, ' ');
    if (frequency !== frequencyValues.ondemand) {
      return `${frequency} for ${duration} ${durationUnit}${duration > 1 ? 's' : ''} ${
        areEventsSame ? drug?.applicationEvents[0]?.type?.replace('_', ' ') : ''
      }.`;
    } else if (frequency && frequency === frequencyValues.ondemand) {
      return `${condition}`;
    } else {
      return '';
    }
  } catch (error) {
    const log = {
      event: error,
      drug,
    };
    LOG_ERROR('Error formatting instructions', log);
    return '';
  }
};

export const formatDate = (date: string, format: string) => {
  return date ? dayjs(date).format(format) : '';
};

export const getCNDLink = (patientId = '', providerId = '', appointmentId = '') =>
  `https://consultation-not-done.paperform.co/?patient_id=${patientId}&provider_id=${providerId}&appointment_id=${appointmentId}`;

export const getProvider = (allProviderData: any[], providerId: string) => {
  const defaultProviderData = {
    name: 'Doctor',
    designation: 'MBBS, MD (Psychiatry)',
    certification: '',
    signature: '/assets/svgs/s-default.svg',
    image: '/assets/svgs/avatar.svg',
    linkName: '',
    experience: '4 years of experience',
    display: false,
    regno: '',
    email: 'hello@allohealth.care',
  };
  if (providerId && allProviderData?.[providerId]) {
    return allProviderData?.[providerId];
  }
  return defaultProviderData;
};

export const getUtmHeaders = () => {
  const utmSource = decodeCookie(Cookies.get('utm_source'));
  const utmMedium = decodeCookie(Cookies.get('utm_medium'));
  const utmCampaign = decodeCookie(Cookies.get('utm_campaign'));
  const utmTerm = decodeCookie(Cookies.get('utm_term'));
  const utmContent = decodeCookie(Cookies.get('utm_content'));
  const gclid = decodeCookie(Cookies.get('gclid'));
  const fbclid = decodeCookie(Cookies.get('fbclid'));
  const fbc = decodeCookie(Cookies.get('fbc'));

  const utmParams: IutmHeaders = {
    ...(utmSource && { 'x-utm-source': utmSource }),
    ...(utmMedium && { 'x-utm-medium': utmMedium }),
    ...(utmCampaign && { 'x-utm-campaign': utmCampaign }),
    ...(utmTerm && { 'x-utm-term': utmTerm }),
    ...(utmContent && { 'x-utm-content': utmContent }),
    ...(gclid && { 'x-gclid': gclid }),
    ...(fbclid && { 'x-fbclid': fbclid }),
    ...(fbc && { 'x-fbc': fbc }),
  };
  return utmParams;
};

export const filterShowcaseDoctors = (doctors: IProviderData[]) => {
  const filteredDoctors = doctors?.filter((doctor) => doctor?.display);
  return filteredDoctors.sort((a, b) => Number.parseInt(b?.experience) - Number.parseInt(a?.experience));
};

export const groupByTitle = (data: { title: string }[]) => {
  const groupedData = {};
  if (data)
    for (const dataItem of data) {
      const title = dataItem?.title;
      if (!groupedData[title]) {
        groupedData[title] = [dataItem];
      } else {
        groupedData[title].push(dataItem);
      }
    }
  return groupedData;
};

export const checkIfYoutubeLink = (url: string) => {
  return url?.includes('youtube');
};

export const generateMessageForDisableSlots = ({ date, slotsData }: { date: string; slotsData: ISlotData }) => {
  const todayHasSlot = Object.keys(slotsData?.[dayjs().format('YYYY-MM-DD')] ?? {})?.length > 0;
  const tomorrowHasSlots = Object.keys(slotsData?.[dayjs().add(1, 'day').format('YYYY-MM-DD')] ?? {}).length > 0;
  const previousDate = dayjs(date).subtract(1, 'day').format('YYYY-MM-DD');
  const nextDate = dayjs(date).add(1, 'day').format('YYYY-MM-DD');
  const hasSlotsBeforeDate = Object.keys(slotsData?.[previousDate] ?? {})?.length > 0;
  const hasSlotsAfterDate = Object.keys(slotsData?.[nextDate] ?? {})?.length > 0;
  const hasSlots = Object.keys(slotsData?.[date] ?? {})?.length > 0;
  const anySlots = Object.keys(slotsData ?? {}).length > 0;

  let heading = '';
  let subtext = '';

  switch (true) {
    case hasSlotsAfterDate && hasSlotsBeforeDate && !hasSlots:
      heading = `All slots booked for ${dayjs(date).format('dddd, DD')}!!`;
      subtext = 'Continue booking with other available slots.';
      break;
    case !hasSlotsAfterDate && !hasSlots && todayHasSlot && tomorrowHasSlots:
      heading = 'Slots will open 18 hrs before the bookable time!';
      subtext = 'Continue booking with today or tomorrow';
      break;
    case !hasSlotsAfterDate && !hasSlots && !todayHasSlot && tomorrowHasSlots:
      heading = 'Slots will open 18 hrs before the bookable time!';
      subtext = 'Continue booking for tomorrow';
      break;
    case !hasSlotsAfterDate && !hasSlots && todayHasSlot && !tomorrowHasSlots:
      heading = 'Slots will open 18 hrs before the bookable time!';
      subtext = 'Continue booking for today';
      break;
    case !anySlots:
      heading = 'No slots available for your selection';
      break;
  }

  return {
    heading: heading,
    subtext: subtext,
  };
};

export const redirectToExternalResource = (url: string) => {
  if (!url) return;
  const externalLink = getBaseUrl() + (url.startsWith('/') ? url : `/${url}`);
  return url.startsWith('http') ? window.open(url, '_blank') : window.open(externalLink, '_blank');
};

export const getSessionId = () => {
  const sessionId = sessionStorage.getItem('session_id');
  if (sessionId) {
    return sessionId;
  } else {
    const newSessionId = uuidv4();
    sessionStorage.setItem('session_id', newSessionId);
    return newSessionId;
  }
};

export const clarityLogger = (key: string, value: string) => {
  try {
    window?.['clarity']('set', key, value);
  } catch {
    return;
  }
};

export const CallRatingFeedbackTexts = (rating: number) => {
  let ratingText = '';
  let heading = '';
  let textAreaPlaceholder = '';
  switch (rating) {
    case 5:
      ratingText = 'Excellent';
      heading = 'What did you like about the consultation experience?';
      textAreaPlaceholder = 'Please tell us what made your experience great.';
      break;
    case 4:
      ratingText = 'Average';
      heading = 'What could have been better in the consultation experience?';
      textAreaPlaceholder = 'Please tell us how we can improve to enhance your experience?';
      break;
    default:
      ratingText = 'Not Great';
      heading = 'What did you not like in the consultation experience?';
      textAreaPlaceholder =
        "We're sorry your experience wasn't satisfactory. Please help us understand what we can improve.";
      break;
  }
  return { ratingText, heading, textAreaPlaceholder };
};

export const feedbackSubHeading = (rating: number, dispositon: string) => {
  let subHeading = '';
  if (rating === 5) {
    switch (dispositon) {
      case 'Clinician':
        subHeading = 'What did you like about the clinician?';
        break;
      case 'Treatment Offered':
        subHeading = 'What did you like about the treatment offered?';
        break;
      case 'Prescription report':
        subHeading = 'What did you like about the prescription report?';
        break;
      case 'Patient Support':
        subHeading = 'What did you like about the patient support?';
        break;
    }
  } else if (rating === 4) {
    switch (dispositon) {
      case 'Clinician':
        subHeading = 'What could the clinician have done better?';
        break;
      case 'Treatment Offered':
        subHeading = 'What could have been better about the Treatment Offered?';
        break;
      case 'Prescription report':
        subHeading = 'What did you like about the Prescription?';
        break;
      case 'Patient Support':
        subHeading = 'What can be better about the Patient Support?';
        break;
    }
  } else {
    switch (dispositon) {
      case 'Clinician':
        subHeading = 'What about the clinician did you not like?';
        break;
      case 'Treatment Offered':
        subHeading = 'What aspects of the treatment offered were unsatisfactory?';
        break;
      case 'Prescription report':
        subHeading = 'What did you disliked about the Prescription?';
        break;
      case 'Patient Support':
        subHeading = 'What did you disliked about the Patient Support?';
        break;
    }
  }
  return subHeading;
};

export const getActivityNameAndIcon = (name: string) => {
  let icon = '';
  let activityName = '';
  switch (name) {
    case 'Medicines':
      icon = '/assets/Medicines.png';
      break;
    case 'Diagnostic Tests':
      icon = '/assets/svgs/labtest-primary.svg';
      break;
    case 'Screening Call':
      activityName = 'Consultation';
      icon = '/assets/svgs/consultation-primary.svg';
      break;
    case 'Follow Up':
      icon = '/assets/svgs/stethoscope-primary.svg';
      break;
    case 'Therapy':
      activityName = 'Counselling Session';
      icon = '/assets/svgs/counselling-hand.svg';
      break;
    default:
      icon = '/assets/svgs/consultation-primary.svg';
      break;
  }
  return { activityName, icon };
};

export const getVenue = (itemType: ItemType, rx: IRx): LabVenue => {
  switch (itemType) {
    case ItemType.DRUG:
      return rx?.locationId ? (rx?.locationId !== ONLINE_LOCATION_ID ? LabVenue.CLINIC : LabVenue.HOME) : undefined;
    case ItemType.LAB:
      return rx?.preferredLabVenue;
    default:
      return undefined;
  }
};

export const getHeightInFeet = (height = 0) => {
  return Math.floor(height / 30.48);
};

export const getHeightInInches = (height = 0) => {
  const inch = ((height % 30.48) / 2.54).toFixed(0);
  if (inch === '12') return '0';
  return inch;
};

export const convertHeightToCm = (heightString) => {
  const [feet, inches] = heightString.split("'");
  const totalInches = Number.parseInt(feet) * 12 + Number.parseInt(inches);
  return totalInches * 2.54;
};

export const getRelationshipStatus = [
  'Single',
  'Married / living together',
  'Married but separated',
  'In a relationship',
  'Widowed',
  'Divorced',
];

export const getHeightOptions = () => {
  const options = [];
  for (let feet = 4; feet <= 7; feet++) {
    const maxInches = feet === 7 ? 0 : 11;
    for (let inches = feet === 4 ? 1 : 0; inches <= maxInches; inches++) {
      options.push({
        label: `${feet}'${inches}"`,
        value: `${feet}'${inches}"`,
      });
    }
  }
  return options;
};

export const truncateByScreen = (name, isMobile) => {
  if (isMobile && name.length > 20) {
    return `${name.slice(0, 20)}...`;
  }
  return name;
};
