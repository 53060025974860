import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextFluent from 'i18next-fluent';
import HttpBackend from 'i18next-http-backend';

//fluent plugin does not support double nested keys, so we need to preprocess the keys to make them single nested
const preprocessKeys = (resources) => {
  const processedResources = {};
  for (const lang in resources) {
    processedResources[lang] = {};

    for (const ns in resources[lang]) {
      processedResources[lang][ns] = {};

      for (const key in resources[lang][ns]) {
        if (typeof resources[lang][ns][key] !== 'object') {
          const fluentKey = key.replace(/\s+/g, '_');
          processedResources[lang][ns][fluentKey] = resources[lang][ns][key];
          continue;
        } else {
          for (const subkey in resources[lang][ns][key]) {
            const fluentKey = key.replace(/\s+/g, '_');
            if (!processedResources[lang][ns][fluentKey]) {
              processedResources[lang][ns][fluentKey] = {};
            }
            const fluentSubkey = subkey.replace(/\s+/g, '_');
            processedResources[lang][ns][fluentKey][fluentSubkey] = resources[lang][ns][key][subkey];
          }
        }
      }
    }
  }
  return processedResources;
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(i18nextFluent)
  .use(HttpBackend)
  .init({
    supportedLngs: ['en', 'hi'],
    ns: ['common', 'rx', 'ax', 'dx'],
    defaultNS: 'common',
    fallbackNS: 'common',
    resources: preprocessKeys({
      en: {
        common: require('./public/locales/en/common.json'),
        ax: require('./public/locales/en/ax.json'),
        rx: require('./public/locales/en/rx.json'),
        dx: require('./public/locales/en/dx.json'),
      },
      hi: {
        common: require('./public/locales/hi/common.json'),
        ax: require('./public/locales/hi/ax.json'),
        rx: require('./public/locales/hi/rx.json'),
        dx: require('./public/locales/hi/dx.json'),
      },
    }),
    fallbackLng: 'en',
    debug: process.env.NEXT_PUBLIC_ENVIRONMENT === 'development',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export default i18n;
