import { AxiosResponse } from 'axios';

import {
  GetAppointmentPayload,
  GetAvailableLanguagesPayload,
  GetSlotsPayload,
  HoldSlotPayload,
  ReleaseConsultationSlotPayload,
  RescheduleConsultationPayload,
  UpdateConsultationPayload,
} from '@Services/Schedule/schedule-payload';

import { Axios } from '../AxiosApiServerWrapper';

export const ScheduleService = {
  GetHMSToken: async (payload: GetAppointmentPayload): Promise<AxiosResponse> => {
    const { appointmentId, ipxId } = payload;
    const url = `appointments/${appointmentId}/token`;
    return Axios.Request('GET', url, {}, { ipxId });
  },
  GetAppointment: async (payload: GetAppointmentPayload): Promise<AxiosResponse> => {
    const { appointmentId, ipxId } = payload;
    const url = `appointments/${appointmentId}`;
    return Axios.Request('GET', url, {}, { ipxId });
  },
  GetSlots: async (payload: GetSlotsPayload): Promise<AxiosResponse> => {
    const url = `scheduling/slots`;
    return Axios.Request('GET', url, {}, payload);
  },
  GetLocationId: async (locationCode: string): Promise<AxiosResponse> => {
    const url = `core/locations/code/${locationCode}`;
    return Axios.Request('GET', url, {});
  },
  GetClinicLocations: async (): Promise<AxiosResponse> => {
    const url = `core/locations`;
    return Axios.Request('GET', url, {});
  },
  GetClinicLocation: async (locationId: string): Promise<AxiosResponse> => {
    const url = `core/locations/${locationId}`;
    return Axios.Request('GET', url, {});
  },
  GetLanguages: async (): Promise<AxiosResponse> => {
    const url = `core/providers/languages`;
    return Axios.Request('GET', url);
  },
  GetAvailableLanguages: async (payload: GetAvailableLanguagesPayload): Promise<AxiosResponse> => {
    const url = `scheduling/slots/languages`;
    return Axios.Request('GET', url, {}, payload);
  },
  UpdateConsultation: async (payload: UpdateConsultationPayload): Promise<AxiosResponse> => {
    const { consultationId, consultationState, ipxId, ...body } = payload;
    const url = `consultations/${consultationId}/${consultationState}`;
    return Axios.Request('POST', url, body, { ipxId });
  },
  RescheduleConsultation: async (payload: RescheduleConsultationPayload): Promise<AxiosResponse> => {
    const { consultationId, ...body } = payload;
    const url = `consultations/${consultationId}/reschedule/send_link`;
    return Axios.Request('POST', url, {}, body);
  },
  ReleaseConsultationSlot: async (payload: ReleaseConsultationSlotPayload): Promise<AxiosResponse> => {
    const { consultationId, ipxId } = payload;
    const url = `consultations/${consultationId}/request-reschedule`;
    const body = {
      reason: 'Webapp Release Slot',
      trigger: 'Auto',
    };
    return Axios.Request('POST', url, body, { ipxId });
  },
  InitiateAppointmentIVR: async (appointmentId: string): Promise<AxiosResponse> => {
    const url = `appointments/${appointmentId}/connect-on-call`;
    return Axios.Request('POST', url, {}, {});
  },
  InitiateGoogleMeet: async (appointmentId: string, ipxId: string): Promise<AxiosResponse> => {
    const url = `appointments/${appointmentId}/connect-on-gmeet`;
    return Axios.Request('POST', url, {}, { ipxId });
  },
  HoldSlot: async (payload: HoldSlotPayload): Promise<AxiosResponse> => {
    const { ipxId, ...body } = payload;
    const url = `scheduling/slots/hold`;
    return Axios.Request('POST', url, body, { ipxId });
  },
};
