import Image from 'next/image';
import styled from 'styled-components';

const Input = styled.input`
  flex-grow: 1;
  border: none;
  padding: 10px;
  border-radius: 10px;
  outline: 'none';
  &:focus {
    outline: none;
  }
`;

const ChatbotResponseBar = ({ setInput, input, handleSendClick, isTyping }) => {
  return isTyping ? undefined : (
    <div className='self-stretch px-5 py-2 bg-white flex-col justify-start items-center gap-[30px] flex border-t-gray border-t-2'>
      <div className='self-stretch pb-1 justify-between items-center inline-flex'>
        <Input
          className="text-left text-neutral-500 text-[15px] font-normal font-['Poppins'] leading-tight"
          type='text'
          value={input}
          onChange={(e) => {
            return setInput(e.target.value);
          }}
          onKeyPress={(e) => e.key === 'Enter' && handleSendClick()}
          placeholder='Ask a question...'
          disabled={isTyping}
        />
        <div className='justify-start items-center gap-3 flex cursor-pointer' onClick={handleSendClick}>
          <Image
            className='mb-1 absolute top-3 right-4'
            src={'/assets/svgs/send-button.svg'}
            alt='allo-icon'
            height={24}
            width={24}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatbotResponseBar;
