import { IConsultationInvoice } from '@Reducers/billing';
import { IreferralData } from '@Reducers/patient';
import { COUNSELLING_CUSTOM_KEYS } from '@Utils/constants';
import { getDiagnosis, getMedHistory, getAdvices, getResources } from '@Utils/helper';
import { extractArray, getSessionGoals } from './Patient/helper';
import { IRx, LabVenue } from '@Reducers/encounter';
import { getInvoiceForEncounter, getInvoiceItemPrices, getInvoiceItemsByCategory } from './invoice.helper';
import { getDrugs } from './drug.helper';
import { getLabTests } from './lab.helper';
import { getConsultations } from './consultation.helper';

export async function processEncounterData(
  encounterData,
  ipxId?,
  version = 1,
): Promise<{
  rx: IRx;
  invoice: IConsultationInvoice;
}> {
  const parsedPaperformData: IPaperformQaData = parsePaperformQaData(encounterData.paperformqa);
  const { sessionGoalsDictionary, pastTreatment, referral, locationId } = parsedPaperformData;

  const diagnoses = getDiagnosis(encounterData?.diagnoses);
  const clinicalHistory = getMedHistory(encounterData?.clinicalHistories);
  const categorizedAdvices = getAdvices(encounterData?.summaries);
  const resources = getResources(encounterData?.summaries);
  const date = encounterData?.createdAt;
  const providerId = encounterData?.providerId;

  const sessionSummary = extractArray(encounterData?.summaries, 'counselling_summary')?.[0]?.split(';');
  const nextSessionPlan = extractArray(encounterData?.summaries, 'next_counselling_plan');
  const sessionGoals = getSessionGoals(encounterData?.summaries, sessionGoalsDictionary);
  const { invoice, billType } =
    version === 1 ? await getInvoiceForEncounter(encounterData.id, '', ipxId) : { invoice: null, billType: null };
  const drugs = getDrugs(encounterData?.drugOrders, invoice);
  const labTests = getLabTests(encounterData?.labTestOrders, invoice, encounterData?.labTestsServiceable);
  const { counsellings, followups } = getConsultations(encounterData?.consultationOrders, invoice);

  const { imageAdvices, otherAdvices } = arrangeAdvices(categorizedAdvices['General Advice']);
  const rx: IRx = {
    encounterId: encounterData.id,
    providerId,
    patientId: encounterData.patientId,
    appointmentDate: date,
    appointmentType: encounterData.attributes?.appointmentType,
    locationId,
    drugs,
    labTests,
    diagnoses,
    counsellings,
    followups,
    clinicalHistory,
    advices: otherAdvices,
    categorizedAdvices,
    imageAdvices,
    resources,
    type: encounterData.type,
    consultationType: encounterData?.attributes?.consultationType,
    sessionGoals,
    sessionSummary,
    nextSessionPlan,
    counsellingOptional: (encounterData.counsellingOptional as boolean) && counsellings.length > 0,
    drugsOptional: (encounterData.drugsOptional as boolean) && drugs.length > 0,
    labTestsOptional:
      (encounterData.labTestsOptional as boolean) && labTests.length > 0 && encounterData?.labTestsServiceable,
    labTestsServiceable: (encounterData.labTestsServiceable as boolean) && labTests.length > 0,
    billType,
    pastTreatment,
    referral: referral,
    labVenues: encounterData?.attributes?.labTest?.venue || [],
    preferredLabVenue: Object.values(LabVenue).find(
      (venue) => venue.toLowerCase() === encounterData?.attributes?.labTest?.patientPreferredVenue?.toLowerCase(),
    ),
  };
  return { rx, invoice };
}

export interface IPaperformQaData {
  sessionGoalsDictionary?: any;
  referral?: IreferralData;
  pastTreatment?: boolean;
  locationId?: string;
}

export const parsePaperformQaData = (paperformQaResultData): IPaperformQaData => {
  const parsedData = {};
  const referral: IreferralData = {
    referedTo: '',
    referalReason: '',
  };
  if (paperformQaResultData?.[COUNSELLING_CUSTOM_KEYS.COUNSELLING_GOALS_DICTIONARY])
    parsedData['sessionGoalsDictionary'] =
      paperformQaResultData[COUNSELLING_CUSTOM_KEYS.COUNSELLING_GOALS_DICTIONARY].answer;

  parsedData['locationId'] = paperformQaResultData?.['location_id']?.answer;
  parsedData['pastTreatment'] = paperformQaResultData?.['consulted_before']?.answer === 'Yes';

  if (paperformQaResultData?.['referral']) {
    const referedTo = paperformQaResultData['referral'].answer || [];
    for (const referred of referedTo) {
      if (referred != 'No') {
        referral.referedTo += `${referred}. `;
      }
    }
  }
  if (paperformQaResultData?.['referral_reason']) {
    referral.referalReason = paperformQaResultData['referral_reason'].answer;
  }
  parsedData['referral'] = referral;
  return parsedData;
};

const arrangeAdvices = (advices: string[]) => {
  const imageAdvices = [];
  const otherAdvices: string[] = [];
  if (!advices?.length) return { imageAdvices, otherAdvices };
  for (const advice of advices) {
    switch (true) {
      case advice.toLowerCase().includes('aerobic'):
        imageAdvices.push({
          description: advice,
          image: `/assets/advice/aerobic-exercise.png`,
        });
        break;
      case advice.toLowerCase().includes('pelvic floor'):
        imageAdvices.push({
          description: advice,
          image: `/assets/advice/pelvic-exercise.png`,
        });
        break;
      case advice.toLowerCase().includes('exercise'):
        imageAdvices.push({
          description: advice,
          image: `/assets/advice/exercise.png`,
        });
        break;
      case advice.toLowerCase().includes('foreplay'):
        imageAdvices.push({
          description: advice,
          image: `/assets/advice/foreplay.png`,
        });
        break;
      case advice.toLowerCase().includes('sleep'):
        imageAdvices.push({
          description: advice,
          image: `/assets/advice/sleep.png`,
        });
        break;
      case advice.toLowerCase().includes('stress'):
        imageAdvices.push({
          description: advice,
          image: `/assets/advice/peace.png`,
        });
        break;
      case advice.toLowerCase().includes('balanced diet'):
        imageAdvices.push({
          description: advice,
          image: `/assets/advice/healthy-diet.png`,
        });
        break;
      case advice.toLowerCase().includes('water'):
        imageAdvices.push({
          description: advice,
          image: `/assets/advice/drink-water.png`,
        });
        break;
      case advice.toLowerCase().includes('vegetables'):
        imageAdvices.push({
          description: advice,
          image: `/assets/advice/green-vegetables.png`,
        });
        break;
      case advice.toLowerCase().includes('smoking'):
        imageAdvices.push({
          description: advice,
          image: `/assets/advice/smoking.png`,
        });
        break;
      case advice.toLowerCase().includes('weight'):
        imageAdvices.push({
          description: advice,
          image: `/assets/advice/reduce-weight.png`,
        });
        break;
      default:
        otherAdvices.push(advice);
        break;
    }
  }
  return { imageAdvices, otherAdvices };
};

export const countOptionalItems = (rx: IRx) => {
  let count = 0;
  if (rx.drugsOptional) count += 1;
  if (rx.labTestsOptional) count += 1;
  if (rx.counsellingOptional) count += 1;
  return count;
};

export const getOptionalItem = (rx: IRx, invoice: IConsultationInvoice) => {
  const { drugsPrice, labtestsPrice, counsellingsPrice } = getInvoiceItemPrices(invoice?.items);
  if (rx.drugsOptional) return { item: 'medicine', price: drugsPrice, name: 'Medicines' };
  if (rx.labTestsOptional) return { item: 'labtests', price: labtestsPrice, name: 'Diagnostic Tests' };
  if (rx.counsellingOptional)
    return {
      item: 'counsellings',
      price: counsellingsPrice,
      name: 'Counsellings',
    };
  return {};
};

export const countItemsToBuy = (invoice: IConsultationInvoice) => {
  const { drugs, labtests, counsellings } = getInvoiceItemsByCategory(invoice?.items);
  let count = 0;
  if (drugs.length) count += 1;
  if (labtests.length) count += 1;
  if (counsellings.length) count += 1;
  return count;
};
