import Image from 'next/image';
import { useEffect, useMemo, useState } from 'react';

import { ProviderActions } from '@Actions/index';
import { IQueryData } from '@Reducers/common';
import { IConsultationOrder } from '@Reducers/order';
import { IProviderData } from '@Reducers/provider';
import { useAppDispatch, useAppSelector } from '@Redux/hooks';
import { CONSULTATION_STATE, PAGE, RESCHEDULE_REASON } from '@Utils/constants';
import { getProvider } from '@Utils/helper';
import useCustomRouter from '@Utils/hooks/custom-router';

import ChevronRightPurple from '../public/assets/svgs/chevron-right-purple.svg';
import CallInstructions from './call-instructions';
import CancellationInstructions from './cancellation-instructions';
import DoctorImage from './core/doctor-image/v1';
import { Button } from './design-system/button/button';
import { colors } from './design-system/color';
import { ColorType } from './design-system/color/types';
import { IMessage, getScreeningCallMessage } from './design-system/message-card';
import Text from './design-system/text';
import { FontType } from './design-system/text/types';
import { DoctorWidget } from './join-call-widget';

const AppointmentStatusWidget = ({
  consultationOrder,
  launchAction,
  queryData,
  allProviderData,
}: {
  consultationOrder: IConsultationOrder;
  launchAction: any;
  queryData: IQueryData;
  allProviderData: any[];
}) => {
  const customRouter = useCustomRouter();
  const [viewInstructions, setViewInstructions] = useState(false);
  const appointment = consultationOrder?.appointmentData;
  const handleRescheduleWithAnyDoctor = () => {
    customRouter({
      pathname: PAGE.APPOINTMENT.ROUTE,
      query: {
        appointment_state: CONSULTATION_STATE.RESCHEDULE,
        form_type: 'DIRECT_LINK',
        consultation_id: appointment?.consultationId,
        product_code: appointment?.consultationCode,
        reason: RESCHEDULE_REASON,
        selected_languages: queryData?.selectedLanguages,
        valid_user_city: queryData?.validUserCity,
        ...(queryData?.ipxId && { ipx_id: queryData?.ipxId }),
      },
    });
  };
  const MessageInstruction = useMemo(() => {
    return {
      'Join Session': <CallInstructions closeModal={() => setViewInstructions(false)} />,
      Cancelled: <CancellationInstructions closeModal={() => setViewInstructions(false)} />,
    };
  }, []);
  const message: IMessage = useMemo(() => {
    if (!consultationOrder || !launchAction) return {};
    return getScreeningCallMessage(consultationOrder?.status, consultationOrder, launchAction);
  }, [consultationOrder]);

  const providerData = getProvider(allProviderData, consultationOrder?.appointmentData?.providerId);

  return (
    Object.keys(message)?.length > 0 && (
      <>
        {viewInstructions && MessageInstruction[consultationOrder?.status]}
        <main
          id='appointment-status-widget'
          className='w-full rounded-lg overflow-hidden px-3 grid grid-cols-1 divide-y divide-GREYS_400 bg-white shadow mb-4'>
          <section className='flex justify-between py-4 items-center'>
            <div className='flex pl-2 gap-2 items-center h-full'>
              {message?.icon && (
                <div className='w-6 h-full flex items-start'>
                  <Image src={message.icon} width={24} height={24} alt='tick' layout='fixed' />
                </div>
              )}
              <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_1000}>
                {message?.title}
              </Text>
            </div>
            <div>
              {message?.enableReschedule && (
                <a
                  id='reschedule-appointment'
                  onClick={message?.action}
                  className='flex justify-self-end items-center gap-1'>
                  <Text font={FontType.SEMIBOLD_12_170} color={ColorType.INDIGO_600}>
                    Reschedule
                  </Text>
                  <Image src={ChevronRightPurple.src} width={8} height={8} alt='allo-icon' />
                </a>
              )}
            </div>
          </section>
          <section className='pb-4'>
            {message?.midWidget}
            {message?.showDoctorInfo && (
              <section className='rounded-lg bg-BLUE_GREY_75 grid grid-cols-1 divide-y divide-GREYS_400 px-3'>
                <DoctorWidget doctorData={providerData} />
                {message?.enableReschedule && (
                  <div className='flex flex-col gap-3 py-3'>
                    <div className='flex justify-between'>
                      <div className='flex gap-1'>
                        <Image width={20} height={20} layout='fixed' src={'/assets/svgs/clock.svg'} alt='allo icon' />
                        <Text font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_900}>
                          Languages spoken
                        </Text>
                      </div>
                      <a onClick={handleRescheduleWithAnyDoctor} className='flex justify-self-end items-center gap-1'>
                        <Text font={FontType.SEMIBOLD_12_170} color={ColorType.INDIGO_600}>
                          change preference
                        </Text>
                        <Image src={ChevronRightPurple.src} width={8} height={8} alt='allo-icon' />
                      </a>
                    </div>
                    <div className='flex flex-wrap gap-2'>
                      {providerData?.preferredLanguages?.map((language) => (
                        <div
                          key={language}
                          className='flex justify-center items-center bg-BLUE_GREY_100 rounded-full px-2 py-1'>
                          <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_800}>
                            {language}
                          </Text>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </section>
            )}
            {message?.buttonLabel && (
              <>
                {message?.label && (
                  <Text className='py-3' font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_1000}>
                    {message?.label}
                  </Text>
                )}
                <Button
                  onPress={message?.action}
                  label={message?.buttonLabel}
                  disabled={!message?.action}
                  secondaryBackgroundColour={colors[ColorType.GREYS_100]}
                  primaryColour={colors[ColorType.GREYS_900]}
                  justifyContent='center'
                  fullWidth={true}
                  disabledBackgroundColour={colors[ColorType.GREYS_600]}
                  size='large'
                  borderRadius={50}
                />
              </>
            )}
            {message?.tertioaryActionLine && (
              <div onClick={() => setViewInstructions(true)} className='flex gap-1 pt-4'>
                <Text font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_900}>
                  {message?.tertioaryActionLine}
                </Text>
                <Image width={16} height={16} layout='fixed' src={'/assets/svgs/info-purple.svg'} alt='allo icon' />
              </div>
            )}
          </section>
        </main>
      </>
    )
  );
};

export default AppointmentStatusWidget;

export const DefaultScreeningCallStatusWidget = ({ message }: { message: IMessage }) => {
  return (
    <div className='flex justify-between py-4'>
      <div className='flex gap-1 items-center'>
        <Image width={20} height={20} layout='fixed' src={'/assets/svgs/calendar-black.svg'} alt='allo icon' />
        <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_800}>
          {message?.primaryActionLine}
        </Text>
      </div>
      <div className='flex gap-1 items-center'>
        <Image width={20} height={20} layout='fixed' src={'/assets/svgs/clock.svg'} alt='allo icon' />
        <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_800}>
          {message?.secondaryActionLine}
        </Text>
      </div>
      <div className='flex gap-1 items-center'>
        <div
          style={{ backgroundColor: colors[message?.bulletColor] }}
          className='w-3 h-3 rounded-full bg-SUCCESS_600'
        />
        <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_800}>
          {message?.description}
        </Text>
      </div>
    </div>
  );
};

export const DoctorsShowCaseWidget = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (Object.keys(provider?.allProviderData).length > 0) return;
    dispatch(ProviderActions.GetAllProviderData());
  }, []);

  const provider = useAppSelector((state) => state.provider);
  const allDoctorData = provider?.allProviderData;
  const doctorList = Object.values(allDoctorData)?.slice(0, 4);
  return (
    <section className='flex flex-col gap-2 py-4'>
      <div className='flex gap-1 items-center'>
        <Image src={'/assets/svgs/diamond-purple.svg'} height={24} width={24} alt='doctor' />
        <Text font={FontType.REGULAR_10_170} color={ColorType.GREYS_1000}>
          We will select the doctor, best suited to your needs.
        </Text>
      </div>
      <section className='bg-BLUE_GREY_75 px-4 py-3 flex justify-between rounded-lg'>
        <div className='flex'>
          {doctorList?.map((doctor: IProviderData, idx) => (
            <div key={doctor?.name} className={`${idx > 0 ? '-ml-2' : ''}`}>
              <DoctorImage doctorImage={doctor?.image} size={40} backgroundColor={ColorType.BLUE_GREY_100} />
            </div>
          ))}
        </div>
        <div className='flex items-center'>
          <div className='flex px-2 py-1 rounded-full bg-ATTENTION_50 items-center justify-center gap-1 shadow-md'>
            <Image src={'/assets/svgs/star.svg'} height={16} width={16} alt='rating' />
            <Text font={FontType.SEMIBOLD_12_170} color={ColorType.GREYS_800}>
              4.7/5 <span className='font-light font-serif'>(Avg. ratings)</span>
            </Text>
          </div>
        </div>
      </section>
    </section>
  );
};
