import { ActionConsts } from '@Definitions/index';
import { feedbackPayload } from '@Services/Feedback/feedback-payload';

export interface IFeedbackData {
  feedback?: feedbackPayload;
  isLoading?: boolean;
  isSent?: boolean;
}

interface IFeedback {
  feedbackData: IFeedbackData;
}

const INITIAL_STATE: IFeedback = {
  feedbackData: {
    isLoading: false,
  },
};

export const FeedbackReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Feedback.PostFeedbackInit:
    case ActionConsts.Feedback.GetFeedbackInit:
      return {
        ...state,
        feedbackData: {
          isLoading: true,
        },
      };
    case ActionConsts.Feedback.PostFeedbackSuccess:
    case ActionConsts.Feedback.GetFeedbackSuccess:
      return {
        ...state,
        feedbackData: {
          isLoading: false,
          ...action.feedbackData,
        },
      };
    case ActionConsts.Feedback.ResetFeedback:
    case ActionConsts.Feedback.PostFeedbackFail:
    case ActionConsts.Feedback.GetFeedbackFail:
      return {
        ...state,
        feedbackData: INITIAL_STATE.feedbackData,
      };
    default:
      return state;
  }
};
