import Image from 'next/image';

import { ColorType, FontType } from '@Components/common-exports';
import Text from '@Components/design-system/text';
import { TRUST_MAKERS } from '@Utils/constants';
import { useTrans } from '@Utils/hooks/translation';
const TrustMakers = () => {
  const { t, languageUsed } = useTrans('ax');
  return (
    <div className='flex justify-between items-center'>
      {TRUST_MAKERS.map((trustMaker) => {
        return (
          <div key={trustMaker?.id} className='flex flex-col items-center gap-2'>
            <Image src={trustMaker?.image} alt='trust-icons' width={60} height={35} />
            <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_1000} language={languageUsed}>
              {trustMaker?.rating}
            </Text>
            <Text
              font={FontType.SEMIBOLD_14_150}
              color={ColorType.GREYS_800}
              language={languageUsed}
              className='-mt-1.5 text-center'>
              {t(trustMaker?.title)}
            </Text>
          </div>
        );
      })}
    </div>
  );
};

export default TrustMakers;
