import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/ActionConsts';
import { IConsultationInvoice } from '@Reducers/billing';
import { updatePseudoCoupon } from '@Reducers/common';
import { IRx, LabVenue } from '@Reducers/encounter';
import { LanguageCode } from '@Reducers/metadata';
import { PatientService } from '@Services/index';
import { GetPatientEncounterWithInvoice } from '@Services/Patient/patient-payload';
import { ERROR_MESSAGES, UserRole, assessmentPaperformCustomKey, rxTypes } from '@Utils/constants';
import { extractPatientDetails, getRefreshTime } from '@Utils/helper';
import { sortDiagnoses } from '@Utils/Helpers/AssessmentReport/helper';
import { processEncounterData } from '@Utils/Helpers/encounter.helper';
import { sortEncounters } from '@Utils/Helpers/Patient/helper';
import { LOG_ERROR } from '@Utils/logger';

export const EncounterActions = {
  CheckIfEncounterGenerated: (payload: { type: string; ipxId?: string }) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: ActionConsts.Encounter.CheckIfEncounterGeneratedInit,
      });
      const response = await PatientService.GetPatientEncounters(payload);
      if (response.status === 200 && response?.data?.length > 0) {
        dispatch({
          type: ActionConsts.Encounter.CheckIfEncounterGeneratedSuccess,
          encounterGenerated: true,
        });
      } else {
        dispatch({
          type: ActionConsts.Encounter.CheckIfEncounterGeneratedSuccess,
          encounterGenerated: false,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: EncounterActions>>CheckIfEncounterGenerated', { error });
      dispatch({
        type: ActionConsts.Encounter.CheckIfEncounterGeneratedFail,
      });
    }
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  GetAssessmentData:
    (
      requestPayload: {
        ipxId?: string;
        retryCount?: number;
        reference?: any;
        patientId?: string;
      },
      encounterData?,
    ) =>
    async (dispatch: Dispatch) => {
      try {
        dispatch({ type: ActionConsts.Encounter.GetAssessmentDataInit });
        const { reference, retryCount = 8 } = requestPayload;
        let fetchCount = retryCount;
        const fetchEncounterData = async () => {
          const result =
            encounterData ||
            (await PatientService.GetPatientEncountersByType({
              ...(requestPayload?.ipxId && { ipxId: requestPayload.ipxId }),
              type: 'take-ax',
            }));
          const encounterResult = Array.isArray(result?.data) ? result?.data?.[0] : result?.data;

          if (result?.status !== 200) {
            //error fetching encounter
            dispatch({ type: ActionConsts.Encounter.GetAssessmentDataFail });
            return;
          }

          if (!encounterResult?.id && fetchCount > 0) {
            //encounter not found
            const timer = getRefreshTime(fetchCount);
            setTimeout(() => {
              if (reference?.current?.id) return;
              //try to fetch encounter again
              fetchCount--;
              fetchEncounterData().catch((error) => catchError(error));
            }, timer);
          } else if (!encounterResult?.id && fetchCount === 0) {
            if (reference?.current?.id) return;
            //encounter not found after all retries
            dispatch({
              assessmentData: {},
              type: ActionConsts.Encounter.GetAssessmentDataSuccess,
            });
          } else {
            //encounter found
            processFurther(encounterResult).catch((error) => catchError(error));
          }
        };

        const processFurther = async (encounterResult) => {
          const paperformQaResults = await PatientService.GetPaperformData({
            encounterId: encounterResult?.id,
            ...(requestPayload?.ipxId && { ipxId: requestPayload.ipxId }),
            customKey: ['take_ax_version', 'assessment_language', ...assessmentPaperformCustomKey],
          });
          let takeAxVersion = '1.0'; // default version 1
          let assessmentLanguage = LanguageCode.English; // default language english
          if (paperformQaResults?.status === 200) {
            for (const item of paperformQaResults?.data || []) {
              if (item?.customKey === 'take_ax_version' && JSON.parse(item?.value) == '2.0') {
                takeAxVersion = '2.0';
              } else if (item?.customKey === 'assessment_language') {
                assessmentLanguage = Object.values(LanguageCode)?.includes(item?.value)
                  ? item?.value
                  : LanguageCode.English;
              }
            }
          }
          if (takeAxVersion === '2.0') {
            // version 2.0
            const { patientName, bmi, age, gender, relationshipStatus } = extractPatientDetails(encounterResult);
            const assessmentData = {
              id: encounterResult?.id,
              patientName,
              bmi: bmi,
              date: encounterResult?.createdAt,
              age: age,
              gender: gender,
              relationshipStatus: relationshipStatus,
              type: encounterResult?.type,
              diagnoses: sortDiagnoses(encounterResult?.diagnoses),
              ailments: encounterResult?.clinicalHistories?.filter((clinicalHistory) => clinicalHistory?.ailment),
              treatmentReferences: encounterResult?.summaries?.map((summary) => summary?.description),
              version: takeAxVersion,
              language: assessmentLanguage,
            };
            dispatch({
              type: ActionConsts.Encounter.GetAssessmentDataSuccess,
              assessmentData: assessmentData,
            });
          } else {
            // version 1.0
            const rootCauses = [];
            const diagnoses = [];
            let config = '';
            if (paperformQaResults?.status === 200 && paperformQaResults?.data?.length > 0) {
              for (const item of paperformQaResults?.data || []) {
                switch (item?.customKey) {
                  case 'root_causes': {
                    try {
                      const causes = JSON.parse(item?.value);
                      for (const rootCause of Object.keys(causes)) {
                        if (causes[rootCause]) {
                          rootCauses.push(rootCause);
                        }
                      }
                    } catch (error) {
                      LOG_ERROR('Error: Parsing Assessment Report Data', error);
                    }
                    break;
                  }
                  case 'diagnosis_likeliness': {
                    try {
                      const diagnosisLikeliness = JSON.parse(item?.value);
                      diagnosisLikeliness?.map((likeliness) => {
                        if (likeliness?.likeliness !== '0') {
                          diagnoses.push(likeliness);
                        }
                      });
                    } catch (error) {
                      LOG_ERROR('Error: Parsing Assessment Report Data', error);
                    }
                    break;
                  }
                  case 'plan_config': {
                    try {
                      config = JSON.parse(item?.value)['config'] || '';
                    } catch (error) {
                      LOG_ERROR('Error: Parsing Assessment Report Data', error);
                    }
                    break;
                  }
                }
              }
            }
            const { patientName, bmi, age, gender, relationshipStatus } = extractPatientDetails(encounterResult?.data);
            dispatch({
              assessmentData: {
                patientName: patientName,
                date: encounterResult?.createdAt,
                bmi: bmi,
                age: age,
                gender: gender,
                relationshipStatus: relationshipStatus,
                diagnoses: diagnoses,
                rootCause: rootCauses,
                config: config,
                type: encounterResult?.type,
                version: takeAxVersion,
                language: LanguageCode.English,
              },
              type: ActionConsts.Encounter.GetAssessmentDataSuccess,
            });
          }
        };
        const catchError = (error) => {
          const referenceData = {
            error: error,
          };
          LOG_ERROR('Error: EncounterActinos>>GetAssessementReport>>PromiseFailed', referenceData);
          dispatch({
            type: ActionConsts.Encounter.GetAssessmentDataFail,
          });
        };

        await fetchEncounterData().catch((error) => catchError(error));
      } catch (error) {
        LOG_ERROR('Error: EncounterActions>>GetAssessmentData', { error });
        dispatch({
          type: ActionConsts.Encounter.GetAssessmentDataFail,
        });
      }
    },
  GetPrescriptionData: (requestPayload: GetPatientEncounterWithInvoice, encounter?) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Encounter.GetPrescriptionDataInit });
      const { reference, retryCount = 8, ...payload } = requestPayload;
      const fetchCount = reference ? retryCount : 0;
      await fetchEncounterData({ payload, reference, fetchCount, encounter })
        .then((response) => {
          const { rx, invoice } = response;
          if (
            requestPayload?.userId &&
            rx.patientId !== requestPayload.userId &&
            requestPayload?.role === UserRole.PATIENT
          ) {
            LOG_ERROR('Error: EncounterActions>>GetPrescriptionData>>PatientIdMismatch', {
              rxPatientId: rx.patientId,
              requestPayloadUserId: requestPayload.userId,
              ipxId: requestPayload.ipxId,
              isActor: requestPayload.isActor,
            });
          }
          dispatch({
            prescription: rx,
            type: ActionConsts.Encounter.GetPrescriptionDataSuccess,
          });
          dispatch({
            invoice,
            type: ActionConsts.Billing.PreviewPrescriptionInvoicesSuccess,
          });
          dispatch(updatePseudoCoupon(true));
          return;
        })
        .catch(() => {
          dispatch({
            type: ActionConsts.Encounter.GetPrescriptionDataFail,
          });
        });
    } catch (error) {
      const referenceData = {
        encounterRequestPayload: requestPayload,
        error: error,
      };
      LOG_ERROR('Error: EncounterActions>>GetPrescriptionData', referenceData);
      dispatch({
        message: ERROR_MESSAGES.update,
        type: ActionConsts.Encounter.GetPrescriptionDataFail,
      });
    }
  },
  GetRx: (requestPayload: GetPatientEncounterWithInvoice) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Encounter.GetPrescriptionDataInit });
      const { reference, retryCount = 8, ...payload } = requestPayload;
      const encounterResult =
        payload?.id === 'latest'
          ? await PatientService.GetPatientEncounters({
              ...payload,
              id: undefined,
              types: [rxTypes.MERGE_RX, rxTypes.CONSULTATION_RX],
            })
          : await PatientService.GetPatientEncounters({
              ...payload,
              types: [rxTypes.MERGE_RX, rxTypes.CONSULTATION_RX, rxTypes.COUNSELLING_RX],
            });
      if (encounterResult?.status !== 200) {
        dispatch({ type: ActionConsts.Encounter.GetPrescriptionDataFail });
        return;
      }
      const encounterData = Array.isArray(encounterResult?.data)
        ? sortEncounters(encounterResult?.data)?.[0] || {}
        : encounterResult?.data || {};
      const { rx } = await processEncounterData(encounterData, payload.ipxId, 2); //version 2
      dispatch({
        prescription: rx,
        type: ActionConsts.Encounter.GetPrescriptionDataSuccess,
      });
    } catch (error) {
      const referenceData = {
        encounterRequestPayload: requestPayload,
        error: error,
      };
      LOG_ERROR('Error: EncounterActions>>GetPrescriptionData', referenceData);
      dispatch({
        message: ERROR_MESSAGES.update,
        type: ActionConsts.Encounter.GetPrescriptionDataFail,
      });
    }
  },
  UpdateLabVenuePreference:
    (requestPayload: { encounterId: string; venue: LabVenue }) => async (dispatch: Dispatch) => {
      try {
        const response = await PatientService.UpdateLabVenuePreference(requestPayload);
        if (response.status === 200) dispatch({ type: ActionConsts.Encounter.UpdateLabVenuePreference });
      } catch (error) {
        LOG_ERROR('Error: EncounterActions>>UpdateLabVenuePreference', { error });
      }
    },
  //eslint-disable-next-line unicorn/consistent-function-scoping
  ResetPrescriptionData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Encounter.ResetPrescriptionData,
    });
    dispatch({
      type: ActionConsts.Billing.ResetPrescriptionInvoicesPreview,
    });
  },
  //eslint-disable-next-line unicorn/consistent-function-scoping
  ResetEncounterData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Encounter.ResetEncounterData,
    });
  },
};

interface IFetchEncounterData {
  payload: GetPatientEncounterWithInvoice;
  reference: any;
  fetchCount: number;
  encounter?: any;
}

const fetchEncounterData = async ({
  payload,
  reference,
  fetchCount,
  encounter,
}: IFetchEncounterData): Promise<{
  rx: IRx;
  invoice: IConsultationInvoice;
}> => {
  return new Promise(async (resolve, reject) => {
    //if encounter id is latest, fetch latest encounter
    const encounterResult =
      encounter ||
      (payload?.id === 'latest'
        ? await PatientService.GetPatientEncounters({
            ...payload,
            id: undefined,
            types: [rxTypes.MERGE_RX, rxTypes.CONSULTATION_RX],
          })
        : await PatientService.GetPatientEncounters({
            ...payload,
            types: [rxTypes.MERGE_RX, rxTypes.CONSULTATION_RX, rxTypes.COUNSELLING_RX],
          }));
    if (encounterResult?.status !== 200) {
      reject();
    }
    const encounterData = Array.isArray(encounterResult?.data)
      ? sortEncounters(encounterResult?.data)?.[0] || {}
      : encounterResult?.data || {};
    if (Object.keys(encounterData)?.length === 0 && fetchCount > 0) {
      const timer = getRefreshTime(fetchCount);
      setTimeout(() => {
        if (reference && Object.keys(reference?.current)?.length !== 0) return;
        fetchCount--;
        fetchEncounterData({ payload, reference, fetchCount, encounter }).then(resolve).catch(reject);
      }, timer);
    } else if (Object.keys(encounterData)?.length === 0 && fetchCount === 0) {
      if (reference && Object.keys(reference?.current)?.length !== 0) return;
      reject();
    } else {
      const { rx, invoice } = await processEncounterData(encounterData, payload.ipxId);
      if (rx) {
        resolve({ rx, invoice });
      } else {
        reject();
      }
    }
  });
};
