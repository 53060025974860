import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { useTrans } from '@Utils/hooks/translation';

const SectionHeader = ({
  title,
  description,
  classes = '',
}: {
  title: string;
  description?: string;
  classes?: string;
}) => {
  const { languageUsed } = useTrans();
  return (
    <section className={'flex flex-col gap-2 ' + classes}>
      <Text font={FontType.SEMIBOLD_24_150} color={ColorType.GREYS_900} language={languageUsed}>
        {title}
      </Text>
      {description && (
        <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_700} language={languageUsed}>
          {description}
        </Text>
      )}
    </section>
  );
};

export default SectionHeader;
