import React from 'react';
import '../styles/globals.css';
import '../styles/legacyJourneyOverviewStyles.css';
import '../styles/legacyFooterStyles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';

import AppStartupProvider from '@Components/core/app-startup-wrapper';
import { PatientAuthProvider } from '@Components/core/patient-auth-provider';
import Responsive from '@Components/core/responsive';
import DeniedStoragePermisson from '@Components/denied-storage-permission';
import Loader from '@Components/design-system/loader';
import { ToastWrapper } from '@Components/design-system/notification/styled';
import { wrapper, persistor } from '@Redux/store';

import styles from '../styles/Authentication.module.css';

import { ErrorBoundary } from '@sentry/nextjs';
import NextErrorComponent from 'next/error';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useRouter } from 'next/router';

const queryClient = new QueryClient();
const pageLoader = (
  <div className={styles.loaderWrapper}>
    <Loader size={3} />
  </div>
);

const ErrorBoundaryComponent = ({ children }) => {
  const [errorMessage, setErrorMessage] = React.useState(
    <NextErrorComponent statusCode={500} title={'Oops an error has occoured'} />,
  );

  return (
    <ErrorBoundary
      fallback={errorMessage}
      onError={(error) => {
        if (error.message === 'storage_access_denied') {
          setErrorMessage(<DeniedStoragePermisson />);
        }
      }}>
      {children}
    </ErrorBoundary>
  );
};

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
    loaded: (posthog) => {
      if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'development') posthog.debug();
    },
  });
}

function MyApp({ Component, pageProps, userAgent }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <PersistGate persistor={persistor} loading={pageLoader}>
      <ErrorBoundaryComponent>
        <PostHogProvider client={posthog}>
          <AppStartupProvider>
            <div className={'outerWrapAuto ' + Component.name}>
              <QueryClientProvider client={queryClient} contextSharing={true}>
                <PatientAuthProvider>
                  <Responsive userAgent={userAgent}>
                    <Component {...pageProps} />
                  </Responsive>
                </PatientAuthProvider>
              </QueryClientProvider>
            </div>
            <ToastWrapper />
          </AppStartupProvider>
        </PostHogProvider>
      </ErrorBoundaryComponent>
    </PersistGate>
  );
}

MyApp.getInitialProps = async ({ ctx }, pageProps) => {
  const userAgent = typeof window === 'undefined' ? ctx?.req?.headers['user-agent'] : window?.navigator?.userAgent;
  return { userAgent: userAgent, pageProps: { ...pageProps } };
};

export default wrapper.withRedux(MyApp);
