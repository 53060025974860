import { combineReducers } from '@reduxjs/toolkit';

import { AnalyticsReducer } from './analytics';
import { AuthReducer } from './auth';
import { BillingReducer } from './billing';
import { CheckoutReducer } from './checkout';
import { CommonReducer } from './common';
import { ConsultationReducer } from './consultation';
import { EncounterReducer } from './encounter';
import { FeedbackReducer } from './feedback';
import { FileReducer } from './file';
import { LocationReducer } from './location';
import { MetadataReducer } from './metadata';
import { OrderReducer } from './order';
import { PatientReducer } from './patient';
import { PatientFulfillmentReducer } from './patientFulfillments';
import { ProviderReducer } from './provider';
import { ScheduleReducer } from './schedule';
import SlotReducer from './slot';
import { ToggleReducer } from './toggle';
import { TrackReducer } from './track';
import { UserReducer } from './user';

export default combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  patient: PatientReducer,
  common: CommonReducer,
  order: OrderReducer,
  schedule: ScheduleReducer,
  consultation: ConsultationReducer,
  feedback: FeedbackReducer,
  checkout: CheckoutReducer,
  analytics: AnalyticsReducer,
  provider: ProviderReducer,
  encounter: EncounterReducer,
  metadata: MetadataReducer,
  location: LocationReducer,
  file: FileReducer,
  toggle: ToggleReducer,
  billing: BillingReducer,
  track: TrackReducer,
  patientFulfillment: PatientFulfillmentReducer,
  slot: SlotReducer,
});
