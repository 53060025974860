import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/index';
import { feedbackPayload } from '@Services/Feedback/feedback-payload';
import { FeedbackService } from '@Services/index';
import { LOG_ERROR } from '@Utils/logger';

export const FeedbackActions = {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  PostFeedback: (payload: feedbackPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Feedback.PostFeedbackInit });
      const result = await FeedbackService.PostFeedback(payload);
      if (result?.status === 201) {
        const feedback: feedbackPayload = {
          feedbackId: result?.data?.id,
          userId: result?.data?.userId,
          createdAt: result?.data?.createdAt,
          rating: result?.data?.rating,
          referenceId: result?.data?.referenceId,
          referenceEntity: result?.data?.referenceEntity,
          description: result?.data?.description,
          dispositions: result?.data?.dispositions,
        };
        dispatch({
          feedbackData: {
            feedback,
            isSent: true,
          },
          type: ActionConsts.Feedback.PostFeedbackSuccess,
        });
      } else {
        dispatch({
          type: ActionConsts.Feedback.PostFeedbackFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: FeedbackActions>>PostFeedback', error);
      dispatch({
        type: ActionConsts.Feedback.PostFeedbackFail,
      });
    }
  },

  GetFeedback: (payload: feedbackPayload) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Feedback.GetFeedbackInit });
      const result = await FeedbackService.GetFeedback(payload);
      let feedback: feedbackPayload[] = [];
      if (result?.status === 200) {
        feedback =
          result?.data?.data?.map((feedbackItem) => ({
            feedbackId: feedbackItem?.id,
            userId: feedbackItem?.userId,
            createdAt: feedbackItem?.createdAt,
            rating: feedbackItem?.rating,
            referenceId: feedbackItem?.referenceId,
            referenceEntity: feedbackItem?.referenceEntity,
            description: feedbackItem?.description,
            dispositions: feedbackItem?.dispositions,
          })) || [];
      }
      dispatch({
        feedbackData: {
          feedback,
        },
        type: ActionConsts.Feedback.GetFeedbackSuccess,
      });
    } catch (error) {
      LOG_ERROR('Error: FeedbackActions>>GetFeedback', error);
      dispatch({
        type: ActionConsts.Feedback.GetFeedbackFail,
      });
    }
  },

  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetFeedbackData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Feedback.ResetFeedback,
    });
  },
};
