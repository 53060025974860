export interface ICreateConsultationInvoicePayload {
  typeId: string;
  externalPaymentId?: string;
  locationId?: string;
  voucherCode?: string;
  mode?: InvoiceMode;
  blockId?: string;
  startTime?: string;
  endTime?: string;
  providerIds: string[];
  excludedProviderIds?: string[];
  preferredLanguages?: string[];
  attributes?: {
    createInvoice?: true;
    surchargeRate?: number;
  };
  billingSource?: BillingSource;
}

export enum BillingSource {
  PRACTO = 'practo',
  ALLO_INSTANT = 'allo_instant',
}

export enum InvoiceMode {
  Prepaid = 'prepaid',
  Postpaid = 'postpaid',
}

export enum Vendor {
  RAZORPAY = 'razorpay',
  EZETAP = 'ezetap',
  CASH = 'cash',
}

export interface ICreatePrescriptionInvoicePayload {
  voucherCode?: string;
  mode?: InvoiceMode;
  encounterId: string;
  items?: {
    type: string;
    typeId: string;
    quantity: number;
  }[];
  showPseudoCoupon?: boolean;
  ipxId?: string;
  ignoreWhenNoItems?: boolean;
  attributes?: {
    includePaidItems?: boolean;
  };
}

export interface IInventoryItemPurchase {
  vendor?: string;
  mode?: InvoiceMode;
  attributes?: {
    createInvoice: boolean;
  };
  items?: {
    type: string;
    typeId: string;
    quantity: number;
  }[];
}
