import Image from 'next/image';
import { useState } from 'react';

import { IPrice } from '@Reducers/patient';
import { IClinicLocationsData, ISlot, ISlotData } from '@Reducers/schedule';
import { defaultBookingAmount } from '@Utils/constants';
import { useTrans } from '@Utils/hooks/translation';

import ShieldCheck from '../public/assets/svgs/shield-check.svg';
import PayAtClinicPopup from './assessment-report/pay-at-clinic-popup';
import RupeeSymbol from './core/rupee-symbol';
import { Button } from './design-system/button/button';
import { colors } from './design-system/color';
import { ColorType } from './design-system/color/types';
import Loader from './design-system/loader';
import Text from './design-system/text';
import { FontType } from './design-system/text/types';
import ListingOffline from './listing-offline';
import ListingOnline from './listing-online';

const SlotListingModal = ({
  slotsData,
  selectedSlot,
  selectedDate,
  setSelectedDate,
  allSlotDates,
  handleBookAppointment,
  handleSlotChange,
  isOffline,
  isLoading,
  isDarkTheme,
  handleLaunchLanguageModal,
  handleRedirection,
  setConsultationModeToOnline,
  price,
  locationData,
}: {
  slotsData: ISlotData;
  selectedSlot: ISlot;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  allSlotDates: string[];
  handleBookAppointment: (selectedSlot: any, payOnArrival: boolean) => void;
  handleSlotChange: (slot: any) => void;
  isOffline: boolean;
  isLoading: boolean;
  isDarkTheme: boolean;
  handleLaunchLanguageModal: (val: boolean) => void;
  handleRedirection: () => void;
  price: IPrice;
  locationData: IClinicLocationsData;
  setConsultationModeToOnline: () => void;
}) => {
  const { t, languageUsed } = useTrans('ax');
  const [viewInstructions, setViewInstructions] = useState(false);
  const handlePopup = () => {
    setViewInstructions(true);
  };
  return !isLoading ? (
    <section
      className={`w-full bg-white rounded-lg relative ${
        isDarkTheme ? '' : 'shadow-evenly border border-COOL_GREY_100'
      }`}>
      <div>
        {isOffline ? (
          <ListingOffline
            slotsData={slotsData}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            allSlotDates={allSlotDates}
            selectedSlot={selectedSlot}
            handleSlotChange={handleSlotChange}
            price={price}
            locationData={locationData}
          />
        ) : (
          <ListingOnline
            slotsData={slotsData}
            selectedSlot={selectedSlot}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleSlotChange={handleSlotChange}
            isDarkTheme={isDarkTheme}
          />
        )}
        {Object.keys(slotsData)?.length === 0 ? (
          <NoSlotsAvailable
            isOffline={isOffline}
            setIsOnline={setConsultationModeToOnline}
            handleRedirection={handleRedirection}
            handleLaunchLanguageModal={handleLaunchLanguageModal}
          />
        ) : (
          <div className='bg-GREYS_100 px-3 py-4 flex flex-col justify-between items-start rounded-b-lg'>
            <div className='flex flex-col items-center gap-2 w-full'>
              {!isDarkTheme && !isOffline && (
                <div
                  onClick={handleRedirection}
                  onKeyDown={handleRedirection}
                  className='rounded bg-PRIMARY_25 flex px-4 py-3 w-full mb-6'>
                  <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_700} language={languageUsed}>
                    {t('book-appointment.cant-find-slot')}
                  </Text>
                  <Text font={FontType.SEMIBOLD_11_170} color={ColorType.PRIMARY_900} language={languageUsed}>
                    &nbsp; {t('book-appointment.view-more-slots')} &nbsp; &#8250;
                  </Text>
                </div>
              )}
              {isDarkTheme && !isOffline && (
                <div onClick={handleRedirection} onKeyDown={handleRedirection} className='flex mb-2 gap-2'>
                  <Text font={FontType.SEMIBOLD_11_170} color={ColorType.INDIGO_600} language={languageUsed}>
                    {t('slots.view-more-slots')}
                  </Text>
                  <Image
                    src='/assets/svgs/left-arrow-primary.svg'
                    alt='location'
                    width={6}
                    height={6}
                    className='rotate-180'
                  />
                </div>
              )}
              <div className='flex gap-4 w-full'>
                {isOffline && (
                  <Button
                    label={
                      <Text
                        language={languageUsed}
                        font={FontType.SEMIBOLD_14_150}
                        color={ColorType.GREYS_900}
                        className='flex items-center'>
                        {t('pay-at-clinic-popup.pay-at-clinic')}
                      </Text>
                    }
                    labelColour={colors[ColorType.GREYS_900]}
                    className='whitespace-nowrap '
                    primaryColour={colors[ColorType.GREYS_100]}
                    onPress={handlePopup}
                    fullWidth={true}
                    size='medium'
                    align='center'
                    borderRadius={50}
                  />
                )}
                {isDarkTheme || isOffline ? (
                  <Button
                    label={
                      <Text
                        language={languageUsed}
                        font={FontType.SEMIBOLD_14_150}
                        color={ColorType.GREYS_100}
                        className='flex items-center'>
                        {isOffline
                          ? t('pay-at-clinic-popup.pay-online')
                          : t(`slots.confirm-appointment-at`, {
                              amount: price.sellingPrice,
                            })}
                      </Text>
                    }
                    labelColour={colors[ColorType.GREYS_100]}
                    type='secondary'
                    secondaryBackgroundColour={``}
                    className='whitespace-nowrap '
                    onPress={() => handleBookAppointment(selectedSlot, false)}
                    fullWidth={true}
                    size='medium'
                    align='center'
                    borderRadius={50}
                  />
                ) : (
                  <div className='flex w-full gap-2 justify-between'>
                    <div className='w-2/3 flex gap-1 items-end'>
                      <Text font={FontType.REGULAR_32_150} color={ColorType.PRIMARY_600}>
                        <RupeeSymbol />
                      </Text>
                      <Text font={FontType.SEMIBOLD_32_150} color={ColorType.GREYS_900}>
                        {price.sellingPrice}
                      </Text>
                      <Text className='line-through pb-2' font={FontType.REGULAR_12_170} color={ColorType.GREYS_600}>
                        {price.listingPrice}
                      </Text>
                    </div>
                    <Text
                      onClick={() => handleBookAppointment(selectedSlot, false)}
                      font={FontType.SEMIBOLD_14_150}
                      color={ColorType.WHITE}
                      language={languageUsed}
                      className={
                        'bg-GREYS_900 rounded-lg flex items-center px-10 text-center py-4 whitespace-nowrap cursor-pointer md:w-full justify-center'
                      }>
                      {t('book-appointment.book-appointment')}
                    </Text>
                  </div>
                )}
              </div>
              <RefundDisclaimer amount={price.sellingPrice} />
            </div>
            {viewInstructions && selectedDate && selectedSlot && (
              <PayAtClinicPopup
                closeModal={() => setViewInstructions(false)}
                selectedSlot={selectedSlot}
                selectedDate={selectedDate}
                locality={locationData.locality}
                handleBookAppointment={handleBookAppointment}
                feeAmount={price.sellingPrice}
              />
            )}
          </div>
        )}
      </div>
    </section>
  ) : (
    <div className='flex w-full items-center justify-center'>
      <Loader size={4} />
    </div>
  );
};

export default SlotListingModal;

const RefundDisclaimer = ({ amount }: { amount: number }) => {
  const { t, languageUsed } = useTrans();
  return (
    <section className='flex gap-2 mt-3 bg-white justify-center w-full'>
      <Image src={ShieldCheck.src} height={16} width={16} alt='allo-icon' />
      <Text color={ColorType.GREYS_900} font={FontType.SEMIBOLD_11_170} language={languageUsed}>
        {t('book-appointment.not-satisfied')}
      </Text>
      <Text color={ColorType.SUCCESS_700} font={FontType.SEMIBOLD_11_170} language={languageUsed}>
        {t('book-appointment.refund-statement', { amount: amount })}
      </Text>
      <Text color={ColorType.GREYS_900} font={FontType.REGULAR_11_170} language={languageUsed}>
        {t('book-appointment.no-questions-asked', { amount: amount })}
      </Text>
    </section>
  );
};

const NoSlotsAvailable = ({
  isOffline,
  setIsOnline,
  handleRedirection,
  handleLaunchLanguageModal,
}: {
  isOffline: boolean;
  setIsOnline: () => void;
  handleRedirection: () => void;
  handleLaunchLanguageModal: (val: boolean) => void;
}) => {
  const { t, languageUsed } = useTrans();
  return isOffline ? (
    <div className='flex flex-col items-center justify-center pb-6 pt-1'>
      <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_500} className='text-center pb-4'>
        {t('slots.no-slots-available-for-this-date')}
      </Text>
      <button onClick={setIsOnline} className='flex gap-2'>
        <Text font={FontType.SEMIBOLD_14_150} color={ColorType.INDIGO_600}>
          {t('slots.continue-with-online')} @{defaultBookingAmount} &gt;
        </Text>
      </button>
    </div>
  ) : (
    <section className='rounded text-center py-6'>
      <Text className='pb-2' font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900} language={languageUsed}>
        {t('book-appointment.no-slots-available-for-your-selection')}
      </Text>
      <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_900} language={languageUsed}>
        <Text
          font={FontType.SEMIBOLD_14_150}
          color={ColorType.PRIMARY_800}
          language={languageUsed}
          onClick={() => handleLaunchLanguageModal(true)}
          className='cursor-pointer inline-flex mx-1'>
          {t('book-appointment.selected-languages')}
        </Text>
        {isOffline ? t('book-appointment.not-available-at-clinic') : t('book-appointment.not-available-online')}
      </Text>
      <Text
        onClick={handleRedirection}
        className='pt-4'
        font={FontType.SEMIBOLD_11_170}
        color={ColorType.PRIMARY_900}
        language={languageUsed}>
        &nbsp; {t('book-appointment.view-more-slots')} &nbsp; &#8250;
      </Text>
    </section>
  );
};
