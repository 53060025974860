import Image from 'next/image';

import { ColorType } from '@Components/design-system/color/types';
import Text, { FontFamily } from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { REVIEWS } from '@Utils/constants';

const ReviewGrid = ({ fontColor }: { fontColor: ColorType }) => {
  return (
    <div className='flex m-2 px-2 justify-between'>
      {REVIEWS.map((review) => (
        <div key={review?.title} className='flex mt-2 items-center'>
          <div>
            <Image src={review?.icon} width={40} height={40} alt={review?.title} />
          </div>
          <div className='px-2'>
            <Text className='whitespace-nowrap' font={FontType.REGULAR_14_150} color={fontColor}>
              {review?.title}
            </Text>
            <div>
              <Text
                className='whitespace-nowrap flex gap-1 '
                font={FontType.SEMIBOLD_16_150}
                color={fontColor}
                family={FontFamily.RECOLETA}>
                {review?.rating}/5
                {Array.from({ length: review?.rating }).map((_, index) => (
                  <Image key={index} src={review?.svg} width={16} height={16} alt='Star' />
                ))}
              </Text>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReviewGrid;
