import axios from 'axios';

// Core layer for calling any http api from the app
// Enables us to move to any other api client in future
// Axios is the current client in use Ref - https://github.com/axios/axios

const getHeaders = (token: string) => {
  const headers: any = {
    'content-type': 'application/json',
  };
  if (token) headers.authorization = `Bearer  ${token}`;
  return headers;
};

export function createGetRequest(uri: string, body: any = null, token?: string) {
  return axios.get(uri, { params: body, headers: getHeaders(token) });
}

export function createPostRequest(uri, payload, token?: string) {
  return axios.post(uri, payload, { headers: getHeaders(token) });
}

export function handleResponse(queryResponse, successCallback, failureCallback) {
  const { isFetched, isLoading, error, data } = queryResponse;
  if (isFetched && !isLoading && !error) {
    successCallback(data);
  }
  if (error) {
    failureCallback(error);
  }
}
