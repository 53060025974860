import { ToastContainer } from 'react-toastify';
import styled, { createGlobalStyle } from 'styled-components';

const ToasterGlobalStyle = createGlobalStyle`
  :root {
    --toastify-color-success: #65bf73 !important;
    --toastify-color-warning: #ffb057 !important;
    --toastify-color-error: #ea4335 !important;
  }
`;
const StyledToastWrapper = styled(ToastContainer)`
 
  .Toastify__toast-icon{
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
  .Toastify__toast--error {
    border: 1px solid var(--toastify-color-error) !important;

    .Toastify__close-button {
        color: var(--toastify-color-error) !important;
        border-left: 1px solid var(--toastify-color-error) !important;
    }

    .Toastify__toast-body {
        color: var(--toastify-color-error) !important;
    }
  }

  .Toastify__toast--success {
    border: 1px solid var(--toastify-color-success) !important;

    .Toastify__close-button {
        color: var(--toastify-color-success) !important;
        border-left: 1px solid var(--toastify-color-success) !important;
    }

    .Toastify__toast-body {
        color: var(--toastify-color-success) !important;
    }
  }

  .Toastify__toast--warning {
    border: 1px solid var(--toastify-color-warning) !important;

    .Toastify__close-button {
        color: var(--toastify-color-warning) !important;
        border-left: 1px solid var(--toastify-color-warning) !important;
    }

    .Toastify__toast-body {
        color: var(--toastify-color-warning) !important;
    }
  }

    .Toastify__toast {
      padding: 18px 16px !important;
      border-left-width: 8px !important;
    }

    .Toastify__close-button {
      align-self: center !important;
      opacity: 1 !important;
      padding: 3px 0px 3px 20px !important;
    }

    .Toastify__toast-body {
      font-weight: 400;
      font-size: 12px;
    }
  }
`;

export const ToastWrapper = () => (
  <>
    <StyledToastWrapper />
    <ToasterGlobalStyle />
  </>
);
