import { TEST_STATES } from '@Components/design-system/message-card';
import { ActionConsts } from '@Definitions/index';
import { IAppointments } from '@Reducers/checkout';
import { IClinicLocationsData } from '@Reducers/schedule';
import { CONSULTATION_CODE_DEFAULT, CONSULTATION_NAME_DEFAULT } from '@Utils/constants';

export interface IPatientInvoice {
  id?: string;
  status?: string;
  encounterId?: string;
  createdAt?: string;
  items?: {
    id: string;
    type: string;
    quantity: number;
    typeId: string;
  }[];
}
export interface IConsultationData {
  consultationTypeId: string;
  name: string;
  description: string;
  consultationCode: string;
  amount: number;
  isBookable: boolean;
  duration?: number;
  originalAmount?: number;
  isLoading?: boolean;
}

export interface IOrder {
  consultationData: IConsultationData;
  orderConfirmed: boolean;
  drugOrders?: IDrugOrder[];
  consultationOrders?: {
    counsellingOrders?: IConsultationOrder[];
    followUpOrders?: IConsultationOrder[];
    patientQueryOrders?: IConsultationOrder[];
    reportReadingOrders?: IConsultationOrder[];
    screeningCallOrders?: IConsultationOrder[];
  };
  labTestOrders?: ILabTestOrder[];
  patientInvoice?: IPatientInvoice;
  isLoading: boolean;
  locationId?: string;
  locationData?: IClinicLocationsData;
}

export interface IDrugOrder {
  invoiceId: string;
  status: string;
  vendor: string;
  shippingOrderId: string;
  address: string;
  pincode: string;
  trackingUrl: string;
  isCod: boolean;
  estimatedDate?: string;
  timeStamps: {
    prescribed?: string;
    received?: string;
    packed?: string;
    dispatched?: string;
    delivered?: string;
  };
  drugs: {
    id: string;
    name: string; // name here will be combination of drug name and salt in brackets
    quantity: number;
  }[];
}

export interface ILabTestOrder {
  id: string;
  status: TEST_STATES;
  vendor: string;
  vendorReferenceId: string;
  timeStamps: {
    prescribed?: string;
    scheduled?: string;
    collected?: string;
    reported?: string;
  };
  address: string;
  labTests: {
    id: string;
    name: string;
    preparation: string;
  }[];
}

export enum Fulfilment {
  PENDING = 'pending',
  COLLECTED = 'collected',
  SAMPLE_SYNCED = 'sampleSynced',
  REPORT_SHARED = 'reportShared',
  SCHEDULED = 'scheduled',
  CANCELLED = 'cancelled',
  DELAYED = 'reportGenerationDelayed',
}

export interface IConsultationOrder {
  serialNo: number;
  consultationId: string;
  consultationTypeId: string;
  status: string;
  invoiceItemId: string;
  consultationType: {
    name: string;
    code: string;
    duration: number;
    sellingPrice: number;
    listingPrice: number;
  };
  appointmentData: IAppointments;
  timestamps: {
    prescribed?: string;
    sessionDate?: string;
  };
}

const DEFAULT = {
  consultationData: {
    consultationTypeId: '',
    consultationCode: CONSULTATION_CODE_DEFAULT,
    name: CONSULTATION_NAME_DEFAULT,
    description: '',
    amount: undefined,
    isBookable: true,
    duration: undefined,
    isLoading: undefined,
  },
};

const INITIAL_STATE: IOrder = {
  consultationData: DEFAULT.consultationData,
  orderConfirmed: undefined,
  drugOrders: undefined,
  consultationOrders: {
    counsellingOrders: [],
    followUpOrders: [],
    patientQueryOrders: [],
    reportReadingOrders: [],
  },
  patientInvoice: {},
  isLoading: false,
  locationId: '',
};

export const OrderReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Order.ResetOrderData:
      return {
        ...state,
        orderConfirmed: INITIAL_STATE.orderConfirmed,
        isLoading: false,
      };
    case ActionConsts.Order.ResetConsultationData:
      return {
        ...state,
        consultationData: INITIAL_STATE.consultationData,
        locationId: INITIAL_STATE.locationId,
        isLoading: false,
      };
    case ActionConsts.Order.GetConsultationDetailsInit:
      return {
        ...state,
        consultationData: {
          ...INITIAL_STATE.consultationData,
          isLoading: true,
        },
        locationData: INITIAL_STATE.locationData,
        locationId: INITIAL_STATE.locationId,
        isLoading: true,
      };
    case ActionConsts.Order.GetConsultationDetailsSuccess:
      return {
        ...state,
        consultationData: {
          ...action.consultationData,
          isLoading: false,
        },
        locationData: action.locationData,
        locationId: action.locationId,
        isLoading: false,
      };
    case ActionConsts.Order.GetConsultationDetailsFail:
      return {
        ...state,
        consultationData: {
          ...DEFAULT.consultationData,
          isLoading: false,
        },
        locationData: INITIAL_STATE.locationData,
        locationId: INITIAL_STATE.locationId,
        isLoading: false,
      };
    case ActionConsts.Order.UpdateOrderConfirmed:
      return {
        ...state,
        orderConfirmed: action.orderConfirmed,
        isLoading: true,
      };
    case ActionConsts.Order.GetDrugOrdersInit:
      return {
        ...state,
        drugOrders: INITIAL_STATE.drugOrders,
        isLoading: true,
      };
    case ActionConsts.Order.GetDrugOrdersSuccess:
      return {
        ...state,
        drugOrders: action.drugOrders,
        isLoading: false,
      };
    case ActionConsts.Order.ResetDrugOrders:
    case ActionConsts.Order.GetDrugOrdersFail:
      return {
        ...state,
        drugOrders: INITIAL_STATE.drugOrders,
        isLoading: false,
      };
    case ActionConsts.Order.GetPatientInvoicesInit:
      return {
        ...state,
        patientInvoice: INITIAL_STATE.patientInvoice,
        isLoading: true,
      };
    case ActionConsts.Order.GetPatientInvoicesSuccess:
      return {
        ...state,
        patientInvoice: action.patientInvoice,
        isLoading: false,
      };
    case ActionConsts.Order.ResetPatientInvoices:
    case ActionConsts.Order.GetPatientInvoicesFail:
      return {
        ...state,
        patientInvoice: INITIAL_STATE.patientInvoice,
        isLoading: false,
      };
    case ActionConsts.Order.GetConsultationOrdersInit:
      return {
        ...state,
        consultationOrders: INITIAL_STATE.consultationOrders,
        isLoading: true,
      };
    case ActionConsts.Order.GetConsultationOrdersSuccess:
      return {
        ...state,
        consultationOrders: action.consultationOrders,
        isLoading: false,
      };
    case ActionConsts.Order.ResetConsultationOrders:
    case ActionConsts.Order.GetConsultationOrdersFail:
      return {
        ...state,
        consultationOrders: INITIAL_STATE.consultationOrders,
        isLoading: false,
      };
    case ActionConsts.Order.GetLabTestOrdersInit:
      return {
        ...state,
        labTestOrders: INITIAL_STATE.labTestOrders,
        isLoading: true,
      };
    case ActionConsts.Order.GetLabTestOrdersSuccess:
      return {
        ...state,
        labTestOrders: action.labTestOrders,
        isLoading: false,
      };
    case ActionConsts.Order.ResetLabTestOrders:
    case ActionConsts.Order.GetLabTestOrdersFail:
      return {
        ...state,
        labTestOrders: INITIAL_STATE.labTestOrders,
        isLoading: false,
      };
    default:
      return state;
  }
};
