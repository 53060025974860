import { createSlice } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/index';
import { IClinicLocationsData } from '@Reducers/schedule';

export interface ILocation {
  offlineClinicLocations: any;
  offlineClinicLocation: any;
  clinicLocationToCity?: Record<string, string>;
  locationProvidersMap?: ILocationProvidersMap;
  onlineLocation?: {
    id: string;
    code: string;
  };
  isSorted: boolean;
  isLoading: boolean;
}

export interface ILocationProvidersMap {
  [key: string]: string[];
}

const INITIAL_STATE = {
  offlineClinicLocations: {},
  offlineClinicLocation: {},
  clinicLocationToCity: {},
  locationProvidersMap: {},
  onlineLocation: {},
  isSorted: false,
  isLoading: false,
};

export const Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionConsts.Location.GetClinicLocationsInit:
      return {
        ...state,
        offlineClinicLocations: INITIAL_STATE.offlineClinicLocations,
        clinicLocationToCity: INITIAL_STATE.clinicLocationToCity,
        onlineLocation: INITIAL_STATE.onlineLocation,
        isLoading: true,
      };
    case ActionConsts.Location.GetClinicLocationsSuccess:
      return {
        ...state,
        offlineClinicLocations: action.offlineClinicLocations,
        clinicLocationToCity: action.clinicLocationToCity,
        onlineLocation: action.onlineLocation,
        isLoading: false,
      };
    case ActionConsts.Location.GetClinicLocationsFail:
    case ActionConsts.Location.ResetClinicLocationsData:
      return {
        ...state,
        offlineClinicLocations: INITIAL_STATE.offlineClinicLocations,
        clinicLocationToCity: INITIAL_STATE.clinicLocationToCity,
        onlineLocation: INITIAL_STATE.onlineLocation,
        isLoading: false,
      };
    case ActionConsts.Location.GetClinicLocationInit:
      return {
        ...state,
        offlineClinicLocation: INITIAL_STATE.offlineClinicLocation,
        isLoading: true,
      };
    case ActionConsts.Location.GetClinicLocationSuccess:
      return {
        ...state,
        offlineClinicLocation: action.offlineClinicLocation,
        isLoading: false,
      };
    case ActionConsts.Location.GetClinicLocationFail:
      return {
        ...state,
        offlineClinicLocation: INITIAL_STATE.offlineClinicLocation,
        isLoading: false,
      };
    case ActionConsts.Location.GetProviderLocationsInit:
      return {
        ...state,
        locationProvidersMap: INITIAL_STATE.locationProvidersMap,
        isLoading: true,
      };
    case ActionConsts.Location.GetProviderLocationsSuccess:
      return {
        ...state,
        locationProvidersMap: action.locationsWithProviders,
        isLoading: false,
      };
    case ActionConsts.Location.GetProviderLocationsFail:
      return {
        ...state,
        locationProvidersMap: INITIAL_STATE.locationProvidersMap,
        isLoading: false,
      };
    default:
      return state;
  }
};

const locationSlice = createSlice({
  name: 'common',
  initialState: INITIAL_STATE,
  reducers: {
    updateSortedClinicLocations: (
      state,
      action: {
        payload: Record<string, IClinicLocationsData[]>;
      },
    ) => {
      state.offlineClinicLocations = action.payload;
      state.isSorted = true;
    },
  },
});

export const LocationReducer = (state = INITIAL_STATE, action) => {
  const stateAfterSlice = locationSlice.reducer(state, action);
  return Reducer(stateAfterSlice, action);
};

export const { updateSortedClinicLocations } = locationSlice.actions;
