import { AxiosResponse } from 'axios';

import { Axios } from '../AxiosApiServerWrapper';
import { feedbackPayload } from './feedback-payload';

export const FeedbackService = {
  PostFeedback: async (body: feedbackPayload): Promise<AxiosResponse> => {
    const url = `feedback`;
    return Axios.Request('POST', url, body);
  },
  GetFeedback: async (payload: feedbackPayload): Promise<AxiosResponse> => {
    const url = `feedback`;
    return Axios.Request('GET', url, {}, payload);
  },
};
