import React from 'react';

import { ColorType, FontType } from './common-exports';
import Bullet from './core/bullet';
import Layout from './core/layout';
import Text from './design-system/text';

const DeniedStoragePermisson = () => {
  return (
    <Layout
      screenBackgroundColor={ColorType.ATTENTION_700}
      backgroundColor={ColorType.ATTENTION_700}
      enableHeader={true}>
      <main className=' p-6'>
        <Text className='text-center' color={ColorType.GREYS_600} font={FontType.SEMIBOLD_68_130}>
          Error
        </Text>
        <Text className='pb-2' color={ColorType.GREYS_900} font={FontType.SEMIBOLD_14_150}>
          Cookies are blocked
        </Text>
        <Bullet>
          It seems that cookies are blocked on your current browser, which may affect the functionality of this website.
        </Bullet>
        <Bullet>
          Recommendation: For the best experience, please enable cookies or use Google Chrome, which supports essential
          website features.
        </Bullet>
      </main>
    </Layout>
  );
};

export default DeniedStoragePermisson;
