import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
0%, 100% {
  transform: translateY(-10%);
  animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
}
50% {
  transform: translateY(0);
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
`;

const ChatbotNudgeContainer = styled.div`
  position: fixed;
  bottom: 150px;
  right: 20px;
  max-width: 350px;
  max-height: 300px;
  opacity: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 10px;
  border: 1.4px solid ${(props) => props.textColor || '#4e5ba6'};
  padding: 10px 16px;
  flex-direction: column;
  transform-origin: bottom right;
  align-items: center;
  overflow: hidden;
  transition: height 0.3s ease;
  z-index: 1000;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  animation: ${bounce} 4s infinite;
  color: ${(props) => props.textColor || '#293056'}; // default to black
  background-color: ${(props) => props.bgColor || '#FFFFFF'};
  &.closed {
    height: 0;
    opacity: 0;
  }
`;

export default ChatbotNudgeContainer;
