import { ColorType } from './types';

type ColorKeys = keyof typeof ColorType;
export const colors: { [key in ColorKeys]: string } = {
  PRIMARY_GREEN: '#66BF73',
  PRIMARY_GREEN_900: '#001F09',
  PRIMARY_GREEN_800: '#124418',
  PRIMARY_GREEN_700: '#4F963C',
  PRIMARY_GREEN_600: '#20BC32',
  PRIMARY_GREEN_500: '#66BF73',
  PRIMARY_GREEN_400: '#6AD01B',
  PRIMARY_GREEN_300: '#D2F9B8',
  PRIMARY_GREEN_200: '#ECF7E8',
  PRIMARY_GREEN_100: '#F5FFF8',

  PRIMARY_1000: '#1F1B46',
  PRIMARY_900: '#3E1C96',
  PRIMARY_800: '#573FC5',
  PRIMARY_700: '#5F4AD5',
  PRIMARY_600: '#6F61BE',
  PRIMARY_500: '#9E87E6',
  PRIMARY_400: '#A99EF8',
  PRIMARY_300: '#AFADEF',
  PRIMARY_200: '#C7CEF8',
  PRIMARY_100: '#D9D6FE',
  PRIMARY_75: '#EBE9FE',
  PRIMARY_50: '#F4F3FF',
  PRIMARY_25: '#FAFAFF',

  SECONDARY_ORANGE: '#FF7F58',
  SECONDARY_ORANGE_900: '#451208',
  SECONDARY_ORANGE_800: '#5F2C14',
  SECONDARY_ORANGE_700: '#8E421F',
  SECONDARY_ORANGE_600: '#DF5630',
  SECONDARY_ORANGE_500: '#FF7F58',
  SECONDARY_ORANGE_400: '#FFB68F',
  SECONDARY_ORANGE_300: '#F7E8D9',
  SECONDARY_ORANGE_200: '#F5F1EF',
  SECONDARY_ORANGE_100: '#FDF6EF',

  SECONDARY_BLUE: '#2165F2',
  SECONDARY_BLUE_900: '#071940',
  SECONDARY_BLUE_800: '#0D3387',
  SECONDARY_BLUE_700: '#2051BB',
  SECONDARY_BLUE_600: '#3B6EDE',
  SECONDARY_BLUE_500: '#2165F2',
  SECONDARY_BLUE_400: '#96B8F8',
  SECONDARY_BLUE_300: '#D7E8FF',
  SECONDARY_BLUE_200: '#EBF2FE',
  SECONDARY_BLUE_100: '#F5F9FF',

  SECONDARY_RED: '#EA4335',
  SECONDARY_RED_900: '#460F06',
  SECONDARY_RED_800: '#912218',
  SECONDARY_RED_700: '#96433C',
  SECONDARY_RED_600: '#AC261B',
  SECONDARY_RED_500: '#EA4335',
  SECONDARY_RED_400: '#FF6E79',
  SECONDARY_RED_300: '#FAB9BE',
  SECONDARY_RED_200: '#F7E9E9',
  SECONDARY_RED_100: '#FFF1F0',

  SECONDARY_YELLOW: '#FFE97C',
  SECONDARY_YELLOW_900: '#462407',
  SECONDARY_YELLOW_800: '#986010',
  SECONDARY_YELLOW_700: '#C78529',
  SECONDARY_YELLOW_600: '#FFB057',
  SECONDARY_YELLOW_500: '#FFD37C',
  SECONDARY_YELLOW_400: '#FFDF6B',
  SECONDARY_YELLOW_300: '#F9F0B3',
  SECONDARY_YELLOW_200: '#FEFBE1',
  SECONDARY_YELLOW_100: '#FEFBE1',

  SECONDARY_CYAN_100: '#E0F2FE',
  SECONDARY_CYAN_300: '#7CD7FD',
  SECONDARY_CYAN_600: '#049BD2',
  SECONDARY_CYAN_900: '#0B4A6F',

  GREYS_1100: '#000000',
  GREYS_1000: '#141414',
  GREYS_900: '#222222',
  GREYS_800: '#343734',
  GREYS_700: '#656765',
  GREYS_600: '#A3A3A3',
  GREYS_500: '#CCCCCC',
  GREYS_400: '#E0E1E1',
  GREYS_300: '#F6F6F6',
  GREYS_200: '#FCFCFC',
  GREYS_100: '#FFFFFF',

  COOL_GREY_50: '#FCFDFD',
  COOL_GREY_75: '#F9FAFB',
  COOL_GREY_100: '#F2F4F7',
  COOL_GREY_200: '#EAECF0',
  COOL_GREY_300: '#DFE1E6',
  COOL_GREY_400: '#D0D5DD',
  COOL_GREY_500: '#98A2B3',
  COOL_GREY_600: '#667085',
  COOL_GREY_700: '#708090',
  COOL_GREY_800: '#475467',
  COOL_GREY_900: '#1D2939',

  WHITE: '#FFFFFF',

  TEAL_100: '#F7FCFA',
  TEAL_200: '#E8F7F1',
  TEAL_600: '#0D3A36',

  PURPLE_V2: '#6F61BE',

  ORANGE_YELLOW_50: '#FFFAF5',
  ORANGE_YELLOW_75: '#FDF8F0',
  ORANGE_YELLOW_100: '#FFF6ED',
  ORANGE_YELLOW_200: '#FFF1D6',
  ORANGE_YELLOW_300: '#FFEAD5',
  ORANGE_YELLOW_400: '#F6C39C',
  ORANGE_YELLOW_500: '#FFDB94',
  ORANGE_YELLOW_600: '#F8C45D',
  ORANGE_YELLOW_700: '#FEC84B',
  ORANGE_YELLOW_800: '#6B2D00',
  ORANGE_YELLOW_900: '#3D2800',

  BLUE_50: '#F5FAFF',
  BLUE_75: '#EFF8FF',
  BLUE_100: '#D1E9FF',
  BLUE_200: 'EBF2FE',
  BLUE_300: '#84CAFF',
  BLUE_400: '#53B1FD',
  BLUE_500: '#2E90FA',
  BLUE_600: '#1570EF',
  BLUE_700: '#175CD3',
  BLUE_800: '#1849A9',
  BLUE_900: '#194185',

  BLUE_GREY_50: '#FCFDFD',
  BLUE_GREY_75: '#F8F9FC',
  BLUE_GREY_100: '#EAECF5',
  BLUE_GREY_200: '#D5D9EB',
  BLUE_GREY_300: '#AFB5D9',
  BLUE_GREY_400: '#717BBC',
  BLUE_GREY_500: '#4E5BA6',
  BLUE_GREY_600: '#3E4784',
  BLUE_GREY_700: '#363F72',
  BLUE_GREY_800: '#293056',
  BLUE_GREY_900: '#0B1F31',

  SUCCESS_50: '#F6FEF9',
  SUCCESS_75: '#ECFDF3',
  SUCCESS_100: '#D1FADF',
  SUCCESS_200: '#A6F4C5',
  SUCCESS_300: '#6CE9A6',
  SUCCESS_400: '#32D583',
  SUCCESS_500: '#12B76A',
  SUCCESS_600: '#039855',
  SUCCESS_700: '#027A48',
  SUCCESS_800: '#054F31',
  SUCCESS_900: '#001F09',

  WARNING_50: '#FFFCF5',
  WARNING_75: '#FFFAEB',
  WARNING_100: '#FEF0C7',
  WARNING_200: '#FEDF89',
  WARNING_300: '#FEC84B',
  WARNING_400: '#FDB022',
  WARNING_500: '#F79009',
  WARNING_600: '#DC6803',
  WARNING_700: '#B54708',
  WARNING_800: '#93370D',
  WARNING_900: '#7A2E0E',

  ERROR_50: '#FFFBFA',
  ERROR_75: '#FEF3F2',
  ERROR_100: '#FEE4E2',
  ERROR_200: '#FFCDCA',
  ERROR_300: '#FDA29B',
  ERROR_400: '#FA7066',
  ERROR_500: '#EA4335',
  ERROR_600: '#D92D20',
  ERROR_700: '#AC261B',
  ERROR_800: '#912218',
  ERROR_900: '#460F06',

  ROSE_50: '#FFF5F6',
  ROSE_75: '#FFF1F3',
  ROSE_100: '#FFE4E8',
  ROSE_200: '#FFCCD6',
  ROSE_300: '#FEA3B4',
  ROSE_400: '#FD6F8E',
  ROSE_500: '#F63D68',
  ROSE_600: '#E31B54',
  ROSE_700: '#C01048',
  ROSE_800: '#A11043',
  ROSE_900: '#89123E',

  CYAN_50: '#F5FBFF',
  CYAN_75: '#F0F9FF',
  CYAN_100: '#E0F2FE',
  CYAN_200: '#B9E6FE',
  CYAN_300: '#7CD4FD',
  CYAN_400: '#66E1FF',
  CYAN_500: '#0BA5EC',
  CYAN_600: '#0086C9',
  CYAN_700: '#026AA2',
  CYAN_800: '#065986',
  CYAN_900: '#0B4A6F',

  INDIGO_50: '#F5F8FF',
  INDIGO_75: '#EEF4FF',
  INDIGO_100: '#E0EAFF',
  INDIGO_200: '#C7D7FE',
  INDIGO_300: '#A4BCFD',
  INDIGO_400: '#8098F9',
  INDIGO_500: '#6172F3',
  INDIGO_600: '#444CE7',
  INDIGO_700: '#2D31A6',
  INDIGO_800: '#2D3282',
  INDIGO_900: '#041C53',

  ATTENTION_50: '#FFF5F6',
  ATTENTION_100: '#FEF0C7',
  ATTENTION_500: '#F79009',
  ATTENTION_600: '#DC6803',
  ATTENTION_700: '#B54708',
  ATTENTION_800: '#93370D',
  ATTENTION_900: '#7A2E0E',

  BACKGROUND_GREY: '#E5E5E5',
  HMS_PRIMARY_GREY: '#2D3440',
};
