import { AuthenticationDetails, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

import { UserRole } from '@Utils/constants';
import { generateRandomPassword, formatPhoneNumber } from '@Utils/string-helpers';

import { AlloAuthSession } from '../utils';
import { AuthClientInterface } from './auth-client.interface';
import { CognitoAsyncClient } from './cognito-async-client';

const cognitoOptions = {
  UserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
};

const ekacareCognitoOptions = {
  UserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  ClientId: process.env.NEXT_PUBLIC_EKA_COGNITO_CLIENT_ID,
};

export default class CognitoClient extends CognitoAsyncClient implements AuthClientInterface {
  constructor(phone: string, useEkacareclient = false) {
    const options = useEkacareclient ? ekacareCognitoOptions : cognitoOptions;
    super(options, formatPhoneNumber(phone));
  }

  passwordlessStartAsync = async (number: string): Promise<void> => {
    const userName = formatPhoneNumber(number);
    const password = generateRandomPassword();
    await this.signupAsync(userName, password).catch((error) => {
      // This error just means that user already exists.
      if (error.name !== 'UsernameExistsException') {
        throw error;
      }
    });
    await this.initiateAuthAsync(
      new AuthenticationDetails({
        Username: userName,
        Password: password,
      }),
    );
  };

  passwordlessLoginAsync = async (phone: string, otp: string): Promise<AlloAuthSession> => {
    return this._modifyCognitoSession(await this.sendCustomChallengeAnswerAsync(otp));
  };

  passwordlessGetNewTokenAsync = async (): Promise<AlloAuthSession> => {
    let session: void | CognitoUserSession = await this.getSessionAsync().catch((error) => {
      if (!error?.message?.toLowerCase()?.includes('please authenticate')) {
        throw error;
      }
      return;
    });

    if (session && !session.isValid()) {
      const refreshToken = session.getRefreshToken();
      session = await this.refreshTokenAsync(refreshToken);
    }

    return this._modifyCognitoSession(session);
  };

  logout = async (): Promise<unknown> => {
    return this.signoutAsync();
  };

  silentAuthEkacare = async (number: string, ekacareData: string): Promise<void> => {
    const userName = formatPhoneNumber(number);
    const password = generateRandomPassword();
    await this.signupAsync(userName, password).catch((error) => {
      // This error just means that user already exists.
      if (error.name !== 'UsernameExistsException') {
        throw error;
      }
    });
    await this.initiateAuthAsync(
      new AuthenticationDetails({
        Username: userName,
        Password: password,
      }),
    );
    await this.sendCustomChallengeAnswerAsync(ekacareData);
  };

  static reinitiateClient = (): CognitoClient => {
    const normalUserPool = new CognitoUserPool(cognitoOptions);
    const ekaCarePool = new CognitoUserPool(ekacareCognitoOptions);
    const normalUser = normalUserPool.getCurrentUser()?.getUsername();
    const ekaUser = ekaCarePool.getCurrentUser()?.getUsername();

    if (!normalUser && !ekaUser) return;

    return normalUser ? new CognitoClient(normalUser) : new CognitoClient(ekaUser, true);
  };

  private _modifyCognitoSession = (session: void | CognitoUserSession): AlloAuthSession => {
    if (!session) return {};
    const alloAuthSession: AlloAuthSession = {
      accessToken: session.getAccessToken()?.getJwtToken(),
      user: {
        name: session.getIdToken()?.payload?.phone_number as string,
        phone: session.getIdToken()?.payload?.phone_number as string,
        sub: session.getIdToken()?.payload?.sub as string,
        role: (session.getAccessToken()?.payload?.['cognito:groups']?.[0] ?? UserRole.PATIENT) as string,
        iat: session.getIdToken()?.payload?.iat as number,
        iss: session.getIdToken()?.payload?.iss as string,
      },
      expiry: session.getAccessToken()?.getExpiration(),
    };
    return alloAuthSession;
  };
}
