import { ColorType, FontType } from '@Components/common-exports';
import { Button } from '@Components/design-system/button/button';
import Text from '@Components/design-system/text';
import { useTrans } from '@Utils/hooks/translation';

const StickyCta = ({ heading, handleBooking }: { heading?: string; handleBooking?: () => void }) => {
  const { t, languageUsed } = useTrans('ax');
  return (
    <div className='flex flex-col gap-3 py-3 px-4 bg-white -mx-4 text-center border-t border-GREYS_300'>
      <Text font={FontType.REGULAR_12_170} color={ColorType.GREYS_700} language={languageUsed}>
        {heading}
      </Text>
      <Button
        label={
          <Text language={languageUsed} font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_100}>
            {t('talk-to-doctor')}
          </Text>
        }
        onPress={handleBooking}
        type='secondary'
        secondaryBackgroundColour={``}
        size='medium'
        align='center'
        borderRadius={50}
      />
    </div>
  );
};

export default StickyCta;
