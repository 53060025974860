import { ActionConsts } from '@Definitions/ActionConsts';
import { BillType } from '@Reducers/checkout';
import { LanguageCode } from '@Reducers/metadata';
import { IDrugData, ILabTestData, IreferralData, IConsultationData } from '@Reducers/patient';

const INITIAL_STATE = {
  encounterGenerated: undefined,
  assessmentData: {},
  prescriptions: [],
  isLoading: false,
};

export interface IMedicalCondition {
  id: string;
  code: string;
  name: string;
  languageNameMap: Record<LanguageCode, string>;
}
export interface IDiagnosis {
  id: string;
  severity: string;
  medicalCondition: IMedicalCondition;
}
export interface IAilments {
  id: string;
  issueType: string;
  description: string;
  ailment: {
    id: string;
    name: string;
    code: string;
    type: string;
    severity: string;
    languageNameMap: Record<LanguageCode, string>;
    medicalCondition: IMedicalCondition;
  };
}
export interface IAssessmentData {
  id: string;
  patientName: string;
  date: string;
  age: string;
  gender: string;
  relationshipStatus: string;
  type: string;
  diagnoses: IDiagnosis[];
  ailments: IAilments[];
  treatmentReferences: any[];
  version?: string;
  language?: LanguageCode;
}

export interface IAssessmentDataV1 {
  id?: string;
  config?: string;
  diagnoses?: Array<any>;
  rootCause?: Array<string>;
  patientName?: string;
  bmi?: string;
  age?: number;
  date: string;
  gender?: string;
  relationshipStatus?: string;
  patientEmail?: string;
  patientPhone?: string;
  version?: string;
}

export interface IRecommendation {
  title: string;
  description: string;
  image: string;
}

export enum LabVenue {
  HOME = 'Home',
  CLINIC = 'Clinic',
}

export interface IRx {
  encounterId?: string;
  appointmentType?: string;
  locationId?: string;
  patientId?: string;
  clinicalHistory?: string[];
  appointmentDate?: string;
  diagnoses?: { name: string; severity: string }[];
  providerId?: string;
  drugs?: IDrugData[];
  labTests?: ILabTestData[];
  counsellings?: IConsultationData[];
  followups?: IConsultationData[];
  advices?: string[];
  categorizedAdvices?: Record<string, string[]>;
  resources?: Array<any>;
  referral?: IreferralData;
  sessionSummary?: string[];
  sessionGoals?: {
    title: string;
    description: string;
  }[];
  nextSessionPlan?: string[];
  counsellingOptional?: boolean;
  drugsOptional?: boolean;
  labTestsOptional?: boolean;
  labTestsServiceable?: boolean;
  rxType?: string;
  consultationType?: string;
  type?: string;
  billType?: BillType;
  pastTreatment?: boolean;
  foodRecommendations?: IRecommendation[];
  imageAdvices?: IRecommendation[];
  labVenues: LabVenue[];
  preferredLabVenue?: LabVenue;
}

export const EncounterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionConsts.Encounter.CheckIfEncounterGeneratedInit:
      return {
        ...state,
        isLoading: true,
      };
    case ActionConsts.Encounter.CheckIfEncounterGeneratedSuccess:
      return {
        ...state,
        isLoading: false,
        encounterGenerated: action.encounterGenerated,
      };
    case ActionConsts.Encounter.CheckIfEncounterGeneratedFail:
      return {
        ...state,
        isLoading: false,
        encounterGenerated: undefined,
      };
    case ActionConsts.Encounter.GetAssessmentDataInit:
      return {
        ...state,
        assessmentData: INITIAL_STATE.assessmentData,
        isLoading: true,
      };
    case ActionConsts.Encounter.GetAssessmentDataSuccess:
      return {
        ...state,
        assessmentData: action.assessmentData,
        isLoading: false,
      };
    case ActionConsts.Encounter.GetAssessmentDataFail:
    case ActionConsts.Encounter.ResetAssessmentData:
      return {
        ...state,
        assessmentData: INITIAL_STATE.assessmentData,
        isLoading: false,
      };
    case ActionConsts.Encounter.ResetEncounterData:
      return INITIAL_STATE;
    case ActionConsts.Encounter.GetPrescriptionDataInit:
      return {
        ...state,
        isLoading: true,
      };
    case ActionConsts.Encounter.GetPrescriptionDataSuccess: {
      const prescriptionAlreadyExists: boolean = state.prescriptions?.some(
        (prescription) => prescription.encounterId === action.prescription.encounterId,
      );
      return {
        ...state,
        ...(!prescriptionAlreadyExists
          ? {
              prescriptions: [...state.prescriptions, action.prescription],
            }
          : {
              prescriptions: state.prescriptions?.map((prescription) =>
                prescription.encounterId === action.prescription.encounterId ? action.prescription : prescription,
              ),
            }),
        isLoading: false,
      };
    }
    case ActionConsts.Encounter.GetPrescriptionDataFail:
      return state.prescriptions?.length === 0
        ? {
            ...state,
            prescriptions: INITIAL_STATE.prescriptions,
            isLoading: false,
          }
        : {
            ...state,
            isLoading: false,
          };
    case ActionConsts.Encounter.ResetPrescriptionData:
      return {
        ...state,
        prescriptions: INITIAL_STATE.prescriptions,
        isLoading: false,
      };
    default:
      return state;
  }
};
