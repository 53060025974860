import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { ActionConsts } from '@Definitions/index';
import { TrackService } from '@Services/index';
import { TrackSlotsPayload, TrackSlotsPayloadFuture } from '@Services/Track/track-payload';
import {
  flatMapSlotsData,
  getSlotsBetween15MinsTo30Mins,
  getSlotsBetween2HoursTo4Hours,
  getSlotsBetween30MinsTo60Mins,
  getSlotsBetween4HoursTo6Hours,
  getSlotsBetween60MinsTo120Mins,
  getSlotsBetween6HoursToEod,
  getSlotsNext15Mins,
  getSlotsNext2Hours,
  getSlotsForSpecificDay,
  getSlotsSameDay,
  getSlotsTotal,
  getSlotsAfterDay,
} from '@Utils/Helpers/slot.helper';

export const TrackActions = {
  TrackSlots: (captureEvent: TrackSlotsPayloadFuture) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Track.TrackSlotsInit });
      const { slotsData, ...rest } = captureEvent;
      const todaysSlot = flatMapSlotsData(slotsData?.[dayjs().format('YYYY-MM-DD')]);
      const payload: TrackSlotsPayload = {
        ...rest,
        slotsNext2Hours: getSlotsNext2Hours(todaysSlot),
        slotsSameDay: getSlotsSameDay(todaysSlot),
        slotsNextDay: getSlotsForSpecificDay(slotsData, 2), //day 2
        slotsNext15Mins: getSlotsNext15Mins(todaysSlot),
        slotsNext15To30Mins: getSlotsBetween15MinsTo30Mins(todaysSlot),
        slotsNext30To60Mins: getSlotsBetween30MinsTo60Mins(todaysSlot),
        slotsNext60To120Mins: getSlotsBetween60MinsTo120Mins(todaysSlot),
        slotsNext2To4Hours: getSlotsBetween2HoursTo4Hours(todaysSlot),
        slotsNext4To6Hours: getSlotsBetween4HoursTo6Hours(todaysSlot),
        slotsNext6ToEod: getSlotsBetween6HoursToEod(todaysSlot),
        slotsDay3: getSlotsForSpecificDay(slotsData, 3), //day 3
        slotsDay4: getSlotsForSpecificDay(slotsData, 4), //day 4
        slotsAfterDay4: getSlotsAfterDay(slotsData, 4), //beyond day 4
        slotsTotal: getSlotsTotal(slotsData),
        speciality: captureEvent?.speciality || 'sexual-wellness',
      };
      const response = await TrackService.TrackSlots(payload);
      if (response?.status === 201) {
        dispatch({
          type: ActionConsts.Track.TrackSlotsSuccess,
        });
      } else {
        dispatch({
          type: ActionConsts.Track.TrackSlotsFail,
        });
      }
    } catch {
      dispatch({
        type: ActionConsts.Track.TrackSlotsFail,
      });
    }
  },
};
