import Image from 'next/image';
import styled from 'styled-components';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';

const UserCard = ({ doctorData }) => (
  <UserCardStyle>
    <div className='w-20 flex justify-center'>
      <Image className='rounded bg-PRIMARY_50' src={`${doctorData?.image}`} width={75} height={75} alt='allo-icon' />
    </div>
    <div style={{ width: 'calc(100% - 80px)' }} className='flex flex-col gap-1 px-3 whitespace-nowrap '>
      <Text font={FontType.SEMIBOLD_11_170} color={ColorType.PRIMARY_600}>
        {doctorData?.experience}
      </Text>
      <Text className='pt-0.5 whitespace-nowrap' font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_1000}>
        {doctorData?.name}
      </Text>
      <Text className='pt-1 whitespace-nowrap' font={FontType.REGULAR_11_170} color={ColorType.GREYS_700}>
        {doctorData?.designation.length >= 25 ? doctorData?.designation.slice(0, 25) + '...' : doctorData?.designation}
      </Text>
    </div>
  </UserCardStyle>
);

const UserCardStyle = styled.div.attrs({
  className: 'flex h-24 justify-start p-2 rounded bg-WHITE shadow items-center',
})`
  box-shadow: 0px 4px 12px rgba(18, 18, 18, 0.04), 0px 0px 1px rgba(34, 34, 34, 0.2);
`;

export default UserCard;
