import Image from 'next/image';
import { useEffect } from 'react';

import CrossWhite from '@Images/svgs/cross-white.svg';

const MidPopUp = ({
  children,
  closeModal,
  classes,
}: {
  children: React.ReactNode;
  closeModal: () => void;
  classes?: string;
}) => {
  useEffect(() => {
    if (document.body.style.overflow === 'hidden') return;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <section className={'z-50 w-full fixed inset-0 bg-black bg-opacity-70 flex flex-col justify-center items-center'}>
      <div className={`flex my-2 md:w-128 w-full mr-12 justify-end`}>
        <Image
          onClick={closeModal}
          className='py-2 cursor-pointer'
          src={CrossWhite.src}
          width={24}
          height={24}
          alt='allo-icon'
        />
      </div>
      <div
        className={
          'mx-4 bg-white flex flex-col justify-between md:w-128 max-w-[90%] min-h-80 max-h-[80%] overflow-y-scroll rounded-lg ' +
          classes
        }>
        {children}
      </div>
    </section>
  );
};

export default MidPopUp;
