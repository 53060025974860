import { ActionConsts } from '@Definitions/index';
import { ILoading } from '@Reducers/provider';
import { IAddress } from '@Services/Patient/patient-payload';

const INITIAL_STATE: IUser = {
  evaluationState: '',
  userData: {} as IUserData,
  ipCity: {},
  isLoading: false,
};

export interface IUser {
  evaluationState: string;
  userData: IUserData;
  ipCity: {
    city?: string;
    coordinates?: {
      latitude: number;
      longitude: number;
    };
  } & ILoading;
  isLoading: boolean;
}

export enum PatientStatus {
  Created = 'created',
  FirstEvaluationBooked = 'eval1Booked',
  FirstEvaluationCompleted = 'eval1Completed',
  FirstEvaluationPrescriptionPurchased = 'eval1PrescriptionPurchased',
}

export interface IUserData {
  id: string;
  firstName?: string;
  lastName?: string;
  verifiedName: string;
  phoneNumber: string;
  email?: string;
  gender?: string;
  age?: string;
  therapistId?: string;
  physicianId?: string;
  isAuthenticated?: boolean;
  sub?: string;
  isActor: boolean;
  preferredLanguage?: string[];
  defaultLanguageAdded?: boolean; //temporary
  birthDate?: string;
  address?: IAddress;
  status?: PatientStatus;
  createdAt?: string;
  city?: string;
  relationshipStatus?: string;
  sexualOrientation?: string;
  height?: number;
  weight?: number;
  bmi?: number;
  leadId?: string;
  //actor data
  actorId?: string;
  actorFirstName?: string;
  actorLastName?: string;
  actorVerifiedName?: string;
  actorPhoneNumber?: string;
  actorEmail?: string;
  actorGender?: string;
  actorAge?: string;
  actorTherapistId?: string;
  actorPhysicianId?: string;
  actorPreferredLanguage?: string[];
  actorBirthDate?: string;
  actorAddress?: IAddress;
  actorCity?: string;
  actorRelationshipStatus?: string;
  actorSexualOrientation?: string;
  actorHeight?: number;
  actorWeight?: number;
  actorBmi?: number;
  isNew?: boolean;
}

export const UserReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.User.UpdateInit:
      return {
        ...state,
        userData: INITIAL_STATE.userData,
        isLoading: true,
      };
    case ActionConsts.User.UpdateSuccess:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.userData,
        },
        isLoading: false,
      };
    case ActionConsts.User.UpdateFail:
    case ActionConsts.User.ResetUserData:
      return {
        ...state,
        userData: INITIAL_STATE.userData,
        isLoading: false,
      };
    case ActionConsts.User.GetUserInit:
      return {
        ...state,
        isLoading: true,
      };
    case ActionConsts.User.GetUserSuccess:
      return {
        ...state,
        userData: { ...state.userData, ...action.userData },
        isLoading: false,
      };
    case ActionConsts.User.GetUserFail:
      return {
        ...state,
        isLoading: false,
      };
    case ActionConsts.User.GetEvaluationStateInit:
      return {
        ...state,
        evaluationState: INITIAL_STATE.evaluationState,
        isLoading: true,
      };
    case ActionConsts.User.GetEvaluationStateSuccess:
      return {
        ...state,
        evaluationState: action.evaluationState,
        isLoading: false,
      };
    case ActionConsts.User.ResetEvaluationState:
    case ActionConsts.User.GetEvaluationStateFail:
      return {
        ...state,
        evaluationState: INITIAL_STATE.evaluationState,
        isLoading: false,
      };
    case ActionConsts.User.GetIpCityInit:
      return {
        ...state,
        ipCity: {
          isLoading: true,
        },
      };
    case ActionConsts.User.GetIpCitySuccess:
      return {
        ...state,
        ipCity: {
          city: action.city,
          coordinates: action.coordinates,
          isLoading: false,
        },
      };
    case ActionConsts.User.GetIpCityFail:
      return {
        ...state,
        ipCity: {
          isLoading: false,
        },
      };
    default:
      return state;
  }
};
