import Image from 'next/image';
import { useState } from 'react';

const LocationImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images?.length - 1 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images?.length - 1 : prevIndex - 1));
  };

  return (
    <div className='flex items-center relative'>
      <Image
        src='/assets/svgs/chevron-right-purple.svg'
        alt='chevron-black'
        width={12}
        height={12}
        className='rotate-180 cursor-pointer'
        onClick={prevSlide}
      />
      <div className='flex overflow-hidden rounded-lg'>
        {images?.map((image, index) => (
          <div
            key={image}
            className={`flex-shrink-0 relative transition-transform duration-300 ease-in-out transform ${
              currentIndex === index ? 'translate-x-0' : 'translate-x-44'
            }`}>
            {currentIndex === index && (
              <Image src={image} alt={`location-${index}`} width={160} height={96} className='object-fit' />
            )}
          </div>
        ))}
      </div>

      <Image
        src='/assets/svgs/chevron-right-purple.svg'
        alt='chevron-black'
        width={12}
        height={12}
        className='cursor-pointer'
        onClick={nextSlide}
      />
    </div>
  );
};

export default LocationImageCarousel;
