import SUMOLOGGER from 'sumo-logger';

// eslint-disable-next-line import/no-unused-modules
export type LogLevel = 'log' | 'error' | 'warn' | 'debug' | 'verbose' | 'fatal' | 'info' | 'success';

const sumoLogger = new SUMOLOGGER({
  endpoint: process.env.NEXT_PUBLIC_SUMOLOGIC_ENDPOINT,
  sourceName: process.env.NEXT_PUBLIC_SUMOLOGIC_SOURCE_NAME,
  sourceCategory: process.env.NEXT_PUBLIC_SUMOLOGIC_SOURCE_CATEGORY,
  interval: 20000, // 20 seconds
  batchSize: 100000, // or when the log length reaches 100k characters
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onError: () => {},
});
// eslint-disable-next-line import/no-unused-modules
export function SUMO_LOGGER(level: LogLevel, message: any, optionalDetails?: unknown) {
  try {
    sumoLogger.log({
      message: {
        level: level,
        message: message,
        optionalDetails,
      },
      url: window.location.href,
    });
  } catch {}
}

// eslint-disable-next-line import/no-unused-modules
export default SUMO_LOGGER;
