import { FontType, ColorType } from '@Components/common-exports';
import ConsultationModeSelection from '@Components/consultation-mode-selection';
import Text from '@Components/design-system/text';
import SelectedLanguages from '@Components/selected-languages';
import Slots from '@Components/slots';
import { IQueryData } from '@Reducers/common';
import { IPrice } from '@Reducers/patient';
import { IClinicLocationsData, ISlot, ISlotData } from '@Reducers/schedule';
import { DEFAULT_CITY, DEFAULT_LOCATION_CODE } from '@Utils/constants';
import { useTrans } from '@Utils/hooks/translation';

import SchedulingSectionHeader from './scheduling-section-header.util';

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

interface MiniSchedulerComponentProps {
  theme: Theme;
  selectedLanguages: string[];
  isOffline: boolean;
  slotsData: ISlotData;
  price: IPrice;
  locationData: IClinicLocationsData;
  offlineClinicLocations: Record<string, IClinicLocationsData[]>;
  queryData: IQueryData;
  changeLocation: (val: string) => void;
  handleLaunchLanguageModal: () => void;
  handleBookAppointment: (selectedSlot: any, payOnArrival: boolean) => void;
  isLoading: boolean;
  selectedSlot: ISlot;
  setSelectedSlot: (slot: ISlot) => void;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  handleRedirection: () => void;
  consultationModeEligibility: boolean;
}

const MiniSchedulerComponent = (props: MiniSchedulerComponentProps) => {
  const { t, languageUsed } = useTrans();
  const setConsultationModeToOffline = () => {
    if (props.queryData.validUserCity) {
      props.changeLocation(props.offlineClinicLocations?.[props.queryData.validUserCity]?.[0]?.code);
    } else {
      props.changeLocation(props.offlineClinicLocations?.[DEFAULT_CITY]?.[0]?.code);
    }
  };
  const setConsultationModeToOnline = () => {
    props.changeLocation(DEFAULT_LOCATION_CODE);
  };
  return (
    <>
      <div className={`flex flex-col gap-2 items-center text-center`}>
        <Text
          font={FontType.SEMIBOLD_24_150}
          color={props.theme === Theme.DARK ? ColorType.GREYS_100 : ColorType.GREYS_900}
          language={languageUsed}>
          {t('appointment-scheduling.your-next-step')}
        </Text>
        <Text
          font={FontType.REGULAR_14_150}
          color={props.theme === Theme.DARK ? ColorType.ORANGE_YELLOW_300 : ColorType.GREYS_700}
          language={languageUsed}>
          &middot; {'  '}
          {t('appointment-scheduling.report-discussion-with-doctor')} {'  '} &middot;
        </Text>
        {props.consultationModeEligibility && (
          <ConsultationModeSelection
            isOffline={props.isOffline}
            setConsultationModeToOffline={setConsultationModeToOffline}
            setConsultationModeToOnline={setConsultationModeToOnline}
            isThemeDark={props.theme === Theme.DARK}
          />
        )}
      </div>
      {/* language selection should be visible only when consultation mode is online */}
      {!props.isOffline && (
        <div className='flex flex-col mt-4 mx-4'>
          {props.theme === Theme.DARK && (
            <SchedulingSectionHeader
              heading={t('general.consultation-language')}
              classes='mb-4'
              isThemeDark={props.theme === Theme.DARK}
            />
          )}
          <SelectedLanguages
            selectedLanguages={props.selectedLanguages}
            isThemeDark={props.theme === Theme.DARK}
            handleLaunchLanguageModal={props.handleLaunchLanguageModal}
          />
          {props.theme === Theme.DARK && (
            <SchedulingSectionHeader
              heading={t('appointment-details')}
              classes='mt-8'
              isThemeDark={props.theme === Theme.DARK}
            />
          )}
        </div>
      )}
      <div className='rounded-lg overflow-hidden mx-4 mb-4'>
        <Slots
          slotsData={props.slotsData}
          handleLaunchLanguageModal={props.handleLaunchLanguageModal}
          handleBookAppointment={props.handleBookAppointment}
          handleRedirection={props.handleRedirection}
          isOffline={props.isOffline}
          isLoading={props.isLoading}
          offlineClinicLocations={props.offlineClinicLocations}
          price={props.price}
          isDarkTheme={props.theme === Theme.DARK}
          locationData={props.locationData}
          setConsultationModeToOnline={setConsultationModeToOnline}
          changeLocation={props.changeLocation}
          hasSelectedLanguages={props.selectedLanguages?.length > 0}
          selectedSlot={props.selectedSlot}
          setSelectedSlot={props.setSelectedSlot}
          selectedDate={props.selectedDate}
          setSelectedDate={props.setSelectedDate}
        />
      </div>
    </>
  );
};

export default MiniSchedulerComponent;
