export enum FontType {
  REGULAR_8_170 = 'regular_8_170',
  REGULAR_10_170 = 'regular_10_170',
  REGULAR_11_170 = 'regular_11_170',
  REGULAR_12_170 = 'regular_12_170',
  REGULAR_14_180 = 'regular_14_180',
  REGULAR_14_150 = 'regular_14_150',
  REGULAR_16_150 = 'regular_16_150',
  REGULAR_18_150 = 'regular_18_150',
  REGULAR_20_150 = 'regular_20_150',
  REGULAR_22_150 = 'regular_22_150',
  REGULAR_25_150 = 'regular_25_150',
  REGULAR_32_150 = 'regular_32_150',
  REGULAR_45_150 = 'regular_45_150',
  REGULAR_55_130 = 'regular_55_130',
  REGULAR_68_130 = 'regular_68_130',

  SEMIBOLD_8_170 = 'semibold_8_170',
  SEMIBOLD_10_170 = 'semibold_10_170',
  SEMIBOLD_11_170 = 'semibold_11_170',
  SEMIBOLD_12_170 = 'semibold_12_170',
  SEMIBOLD_14_150 = 'semibold_14_150',
  SEMIBOLD_16_150 = 'semibold_16_150',
  SEMIBOLD_18_150 = 'semibold_18_150',
  SEMIBOLD_20_150 = 'semibold_20_150',
  SEMIBOLD_22_150 = 'semibold_22_150',
  SEMIBOLD_24_150 = 'semibold_24_150',
  SEMIBOLD_25_150 = 'semibold_25_150',
  SEMIBOLD_30_150 = 'semibold_30_150',
  SEMIBOLD_32_150 = 'semibold_32_150',
  SEMIBOLD_45_150 = 'semibold_45_150',
  SEMIBOLD_55_130 = 'semibold_55_130',
  SEMIBOLD_68_130 = 'semibold_68_130',

  DISPLAY_16_150 = 'display_16_150',
  DISPLAY_18_150 = 'display_18_150',
  DISPLAY_20_150 = 'display_20_150',
  DISPLAY_32_150 = 'display_32_150',
  DISPLAY_36_150 = 'display_36_150',
  DISPLAY_64_130 = 'display_64_130',
  DISPLAY_55_130 = 'display_55_130',
  DISPLAY_72_120 = 'display_72_120',
}
