import dayjs from 'dayjs';

import { ISlot, ISlotData, TimePeriod } from '@Reducers/schedule';
import { AssessmentSlotConfig } from '@Reducers/schedule/slot-config';

export const getFirstSlotForSelectedDate = (
  selectedDateSlots: Record<'Morning' | 'Afternoon' | 'Evening' | 'Instant', ISlot[]>,
): ISlot => {
  for (const timePeriod of Object.values(TimePeriod)) {
    if (selectedDateSlots?.[timePeriod]?.length > 0) return selectedDateSlots?.[timePeriod]?.[0];
  }
  return {} as ISlot;
};

export const getFirstSlot = (slotData: ISlotData) => {
  const firstDay = Object.keys(slotData ?? {})?.[0];

  if (!firstDay) return {} as ISlot;

  const slots = slotData[firstDay];

  return getFirstSlotForSelectedDate(slots);
};

export const getSlotCountForSelectedDate = (
  selectedDateSlots: Record<'Morning' | 'Afternoon' | 'Evening' | 'Instant', ISlot[]>,
): number => {
  return Object.values(selectedDateSlots ?? {}).flatMap((slotArray) => slotArray).length;
};

export const flatMapSlotsData = (slots: Record<'Morning' | 'Afternoon' | 'Evening' | 'Instant', ISlot[]>) => {
  return Object.values(slots ?? {}).flatMap((daysSlots) => daysSlots);
};

export const pickSlotsForAssessment = (slotsData: ISlotData) => {
  const today = dayjs().startOf('day').format('YYYY-MM-DD');
  const tomorrow = dayjs(today).add(1, 'day').format('YYYY-MM-DD');
  const instantSlots = slotsData?.[today]?.Instant ?? [];
  const currentDaySlots = [
    ...(slotsData?.[today]?.Morning ?? []),
    ...(slotsData?.[today]?.Afternoon ?? []),
    ...(slotsData?.[today]?.Evening ?? []),
  ]?.slice(0, AssessmentSlotConfig.MAX_SLOTS - instantSlots?.length);

  const tomorrowSlots = [
    ...(slotsData?.[tomorrow]?.Morning ?? []),
    ...(slotsData?.[tomorrow]?.Afternoon ?? []),
    ...(slotsData?.[tomorrow]?.Evening ?? []),
  ]?.slice(0, AssessmentSlotConfig.MAX_SLOTS);

  const allSlots =
    currentDaySlots?.length + instantSlots?.length > 0
      ? {
          [today]: currentDaySlots,
          [tomorrow]: tomorrowSlots,
        }
      : {
          [tomorrow]: tomorrowSlots,
        };

  return {
    instantSlots,
    allSlots,
  };
};

export const countSlots = (slotData: ISlotData, stoppingTimeperiod: TimePeriod, stoppingDay: string) => {
  let count = 0;
  for (const [day, block] of Object.entries(slotData)) {
    for (const time of Object.values(TimePeriod)) {
      if (day === stoppingDay && time === stoppingTimeperiod) break;
      count += block?.[time]?.length;
    }
  }
  return count;
};

export const checkIfHourSlotPresent = (slots: ISlot[] = [], hour: number) => {
  return slots.some((slot) => dayjs(slot.startDate).hour() === hour);
};

//for tracking purposes

export const getSlotsNext2Hours = (todaySlotsData: ISlot[]) => {
  const today = new Date();
  return todaySlotsData?.filter((slot) => dayjs(slot?.startDate).isBefore(dayjs(today).add(2, 'hour')))?.length || 0;
};

export const getSlotsNext15Mins = (todaySlotsData: ISlot[]) => {
  const now = new Date();
  return todaySlotsData?.filter((slot) => dayjs(slot?.startDate).isBefore(dayjs(now).add(15, 'minute')))?.length || 0;
};

export const getSlotsBetween15MinsTo30Mins = (todaySlotsData: ISlot[]) => {
  const now = new Date();
  return (
    (todaySlotsData?.filter((slot) => dayjs(slot?.startDate).isBefore(dayjs(now).add(30, 'minute')))?.length || 0) -
    getSlotsNext15Mins(todaySlotsData)
  );
};

export const getSlotsBetween30MinsTo60Mins = (todaySlotsData: ISlot[]) => {
  const now = new Date();
  return (
    (todaySlotsData?.filter((slot) => dayjs(slot?.startDate).isBefore(dayjs(now).add(60, 'minute')))?.length || 0) -
    getSlotsBetween15MinsTo30Mins(todaySlotsData) -
    getSlotsNext15Mins(todaySlotsData)
  );
};

export const getSlotsBetween60MinsTo120Mins = (todaySlotsData: ISlot[]) => {
  const now = new Date();
  return (
    (todaySlotsData?.filter((slot) => dayjs(slot?.startDate).isBefore(dayjs(now).add(120, 'minute')))?.length || 0) -
    getSlotsBetween30MinsTo60Mins(todaySlotsData) -
    getSlotsBetween15MinsTo30Mins(todaySlotsData) -
    getSlotsNext15Mins(todaySlotsData)
  );
};

export const getSlotsBetween2HoursTo4Hours = (todaySlotsData: ISlot[]) => {
  const now = new Date();
  return (
    (todaySlotsData?.filter((slot) => dayjs(slot?.startDate).isBefore(dayjs(now).add(4, 'hour')))?.length || 0) -
    getSlotsNext2Hours(todaySlotsData)
  );
};

export const getSlotsBetween4HoursTo6Hours = (todaySlotsData: ISlot[]) => {
  const now = new Date();
  return (
    (todaySlotsData?.filter((slot) => dayjs(slot?.startDate).isBefore(dayjs(now).add(6, 'hour')))?.length || 0) -
    getSlotsBetween2HoursTo4Hours(todaySlotsData) -
    getSlotsNext2Hours(todaySlotsData)
  );
};

export const getSlotsBetween6HoursToEod = (todaySlotsData: ISlot[]) => {
  return (
    (todaySlotsData?.filter((slot) => dayjs(slot?.startDate))?.length || 0) -
    getSlotsBetween4HoursTo6Hours(todaySlotsData) -
    getSlotsBetween2HoursTo4Hours(todaySlotsData) -
    getSlotsNext2Hours(todaySlotsData)
  );
};

export const getSlotsSameDay = (todaySlotsData: ISlot[]) => {
  return todaySlotsData?.length || 0;
};
export const getSlotsTotal = (slotsData: ISlotData) => {
  return Object.values(slotsData ?? {}).reduce(
    (sum, timePeriod) => sum + Object.values(timePeriod).reduce((innerSum, slots) => innerSum + slots?.length, 0),
    0,
  );
};

export const getSlotsForSpecificDay = (slotsData: ISlotData, day: number) => {
  const today = new Date();
  return (
    Object.values(
      slotsData?.[
        dayjs(today)
          .add(day - 1, 'day')
          .format('YYYY-MM-DD')
      ] ?? {},
    )?.reduce((sum: number, slots) => slots?.length + sum, 0) || 0
  );
};

//day passed not included
export const getSlotsAfterDay = (slotsData: ISlotData, day: number) => {
  const today = new Date();
  let sum = 0;
  Object.entries(slotsData ?? {}).forEach(([date, slots]) => {
    if (dayjs(date).isAfter(dayjs(today).add(day - 1, 'day'))) {
      sum += flatMapSlotsData(slots).length;
    }
  });
  return sum;
};

export const getProvidersBySlots = (
  slotsData: ISlotData,
  selectedDate: string,
): { providerId: string; startTime: string; endTime: string }[] => {
  const map = new Map<string, { startTime: string; endTime: string }>();
  Object.entries(slotsData).forEach(([day, slotsForDay]) => {
    if (day !== selectedDate) return;
    Object.entries(slotsForDay).forEach(([timePeriod, slotsForTimePeriod]) => {
      slotsForTimePeriod.forEach((slot) => {
        if (map.has(slot.providerId)) {
          const { startTime, endTime } = map.get(slot.providerId);
          map.set(slot.providerId, { startTime, endTime: dayjs(slot.endDate).format('h A') });
        } else {
          map.set(slot.providerId, {
            startTime: dayjs(slot.startDate).format('h A'),
            endTime: dayjs(slot.endDate).format('h A'),
          });
        }
      });
    });
  });
  return Array.from(map).map(([providerId, { startTime, endTime }]) => ({ providerId, startTime, endTime }));
};
