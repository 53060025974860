import React, { useMemo } from 'react';

import SectionHeader from '@Components/core/section-header';
import Dumbell from '@Images/svgs/dumbell.svg';
import Drug from '@Images/svgs/prescription/tablet.svg';
import Lab from '@Images/svgs/prescription/test.svg';
import Counselling from '@Images/svgs/prescription/therapy.svg';
import { segregateTreatmentResources } from '@Utils/Helpers/AssessmentReport/helper';
import { useTrans } from '@Utils/hooks/translation';

import SingleTreatmentSuggestion from './single-treatment-suggestion';

const TreatmentSuggestions = ({ diagnoses, resources, handleBooking }) => {
  const { t } = useTrans('ax');
  const { drugResources, labtestResources, counsellingResources, lifeStyleResources } = segregateTreatmentResources(
    resources,
    diagnoses,
  );
  const diagnosesList: Map<string, Record<string, string>> = useMemo(() => {
    const newMap = new Map();
    for (const diagnosis of diagnoses) {
      const diagnosisName = diagnosis?.medicalCondition?.name.toLowerCase();
      if (diagnosisName) {
        newMap.set(diagnosisName, {
          en: diagnosis?.medicalCondition?.name,
          ...diagnosis?.medicalCondition?.languageNameMap,
        });
      }
    }
    return newMap;
  }, [diagnoses]);

  return (
    <main>
      <SectionHeader
        classes='px-4 pb-6 pt-8'
        title={t('suggested-treatment-title')}
        description={t('suggested-treatment-description')}
      />
      <section className='mx-4 p-3 flex flex-col gap-10 bg-white rounded-lg relative'>
        {lifeStyleResources?.length > 0 && (
          <SingleTreatmentSuggestion
            icon={Dumbell}
            title={t('lifestyle-title')}
            suggestionTitle={t('lifestyle-suggested-title')}
            suggestion={[]}
            explanationTitle={t('lifestyle-explanation-title')}
            explanation={t('lifestyle-explanation')}
            reasonTitle={t('lifestyle-reason-title')}
            reasons={[]}
            resources={lifeStyleResources}
            questions={t('lifestyle-questions')}
            heading={t('lifestyle-heading')}
            subHeading={t('lifestyle-sub-heading')}
            targetArea={t('lifestyle-target-area')}
            diagnosesList={diagnosesList}
            handleBooking={handleBooking}
          />
        )}
        {labtestResources?.length > 0 && (
          <SingleTreatmentSuggestion
            icon={Lab}
            title={t('diagnostic-tests-explanation-title')}
            suggestionTitle={t('diagnostic-tests-suggested-title')}
            suggestion={[]}
            explanationTitle={t('diagnostic-tests-explanation-title')}
            explanation={t('diagnostic-tests-explanation')}
            reasonTitle={t('diagnostic-tests-reason-title')}
            reasons={[]}
            resources={labtestResources}
            diagnosesList={diagnosesList}
            handleBooking={handleBooking}
          />
        )}
        {drugResources?.length > 0 && (
          <SingleTreatmentSuggestion
            icon={Drug}
            title={t('drugs-title')}
            suggestionTitle={t('drugs-suggested-title')}
            suggestion={[]}
            explanationTitle={t('drugs-explanation-title')}
            explanation={t('drugs-explanation')}
            reasonTitle={t('drugs-reason-title')}
            reasons={[]}
            resources={drugResources}
            diagnosesList={diagnosesList}
            handleBooking={handleBooking}
          />
        )}
        {counsellingResources?.length > 0 && (
          <SingleTreatmentSuggestion
            icon={Counselling}
            title={t('counselling-title')}
            suggestionTitle={t('counselling-suggested-title')}
            suggestion={[]}
            explanationTitle={t('counselling-explanation-title')}
            explanation={t('counselling-explanation')}
            reasonTitle={t('counselling-reason-title')}
            reasons={[]}
            resources={counsellingResources}
            questions={t('counselling-questions')}
            heading={t('counselling-heading')}
            subHeading={t('counselling-sub-heading')}
            targetArea={t('counselling-target-area')}
            diagnosesList={diagnosesList}
            handleBooking={handleBooking}
          />
        )}
      </section>
    </main>
  );
};

export default TreatmentSuggestions;
