import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import BannerOnline from '@Images/banner-online.png';
import { ISlot, ISlotData } from '@Reducers/schedule';
import { checkIfTodayOrTomorrow, generateMessageForDisableSlots } from '@Utils/helper';
import { pickSlotsForAssessment } from '@Utils/Helpers/slot.helper';
import { useTrans } from '@Utils/hooks/translation';

import InstantSlotListing from './assessment-report/instant-listing';
import { FontType, ColorType } from './common-exports';
import Text from './design-system/text';
import ImageCarousel from './image-carousel';
import SlotVisibilityDisclaimer from './slot-visibility-disclaimer';

const ListingOnline = ({
  slotsData,
  selectedSlot,
  handleSlotChange,
  selectedDate,
  setSelectedDate,
  isDarkTheme,
}: {
  slotsData: ISlotData;
  selectedSlot: ISlot;
  handleSlotChange: (val) => void;
  selectedDate: string;
  setSelectedDate: (val) => void;
  isDarkTheme: boolean;
}) => {
  const today = dayjs().format('YYYY-MM-DD');
  const [showText, setShowText] = useState<Record<string, string>>({});
  const [clickedIndex, setClickedIndex] = useState<number | ''>();
  const { t, languageUsed } = useTrans('ax');
  const { t: tCommon } = useTrans('common');
  const { instantSlots, allSlots } = pickSlotsForAssessment(slotsData);
  const allSlotDates = useMemo(() => {
    return Object.keys(allSlots || {});
  }, [allSlots]);
  const handleSelectDate = (date: string, index: number) => {
    const { heading, subtext } = generateMessageForDisableSlots({
      date,
      slotsData,
    });
    if (!Object.keys(slotsData?.[date] ?? {})?.length) {
      setShowText({ heading, subtext });
      setClickedIndex(index);
      setTimeout(() => {
        setShowText({});
        setClickedIndex('');
      }, 6000);
    } else {
      setShowText({});
      setClickedIndex('');
      setSelectedDate(date);
    }
  };

  return (
    <>
      {!isDarkTheme && (
        <div className='p-3'>
          <ImageCarousel images={[BannerOnline]} tag={'Online'} />
        </div>
      )}
      <div
        className={`flex overflow-x-scroll w-full border border-[0.5px] px-3 ${
          !!isDarkTheme ? 'rounded-lg' : 'rounded-none'
        }`}>
        {allSlotDates?.map((date, index) => (
          <button
            key={date}
            className={` cursor-pointer p-2.5  flex gap-1  ${
              date === selectedDate ? 'border-b-2 border-PRIMARY_600 rounded-none ' : ''
            }`}
            onClick={() => handleSelectDate(date, index)}
            onKeyDown={() => setSelectedDate(date)}>
            <Text font={FontType.SEMIBOLD_11_170} className='text-center whitespace-nowrap' color={ColorType.GREYS_900}>
              {checkIfTodayOrTomorrow(date, tCommon)}
            </Text>
            <Text
              font={FontType.REGULAR_11_170}
              color={Object.keys(allSlots?.[date] ?? {}).length > 0 ? ColorType.SUCCESS_500 : ColorType.GREYS_500}
              className='whitespace-nowrap'>
              {(allSlots?.[date]).length > 0
                ? date === today
                  ? `${allSlots?.[date]?.length + instantSlots?.length} slots`
                  : `${allSlots?.[date]?.length} slots`
                : 'No slots'}
            </Text>
            {showText && clickedIndex === index && (
              <SlotVisibilityDisclaimer showText={showText} index={index} margin={8} />
            )}
          </button>
        ))}
      </div>
      {selectedDate === today && instantSlots?.length ? (
        <InstantSlotListing
          instantSlots={instantSlots}
          handleSlotChange={handleSlotChange}
          selectedSlot={selectedSlot}
        />
      ) : (
        ''
      )}
      {selectedDate && (
        <div className='flex gap-2 overflow-x-scroll py-2 w-full'>
          {!allSlots?.[selectedDate]?.length && Object.keys(slotsData)?.length > 0 ? (
            <div className='text-center w-full'>
              <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_700} language={languageUsed}>
                {t('slots.no-slots-available-for-this-date')}
              </Text>
            </div>
          ) : (
            <div className='flex flex-col w-full'>
              {allSlots?.[selectedDate]?.length > 0 && (
                <Text
                  className='pb-1 px-4'
                  font={FontType.SEMIBOLD_10_170}
                  color={ColorType.GREYS_600}
                  language={languageUsed}>
                  {t('book-appointment.available-slots')}
                </Text>
              )}
              <div className='grid grid-cols-5 gap-2 py-2 px-4 w-full'>
                {allSlots?.[selectedDate]?.map((slot) => (
                  <button
                    key={slot?.startDate}
                    onClick={() => handleSlotChange(slot)}
                    className={
                      `${
                        slot?.startDate === selectedSlot?.startDate
                          ? 'bg-PRIMARY_50 border border-PRIMARY_600'
                          : 'border border-GREYS_300'
                      }` +
                      ' cursor-pointer whitespace-nowrap px-2 py-1.5 flex justify-center rounded bg-GREYS_200 shadow-slot'
                    }>
                    <Text font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_800}>
                      {dayjs(slot?.startDate)?.format('h:mm A')}
                    </Text>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default ListingOnline;
