import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { colors } from '../color';

const StyledButton = styled.button.attrs((props) => ({
  className: 'flex ' + props.className,
  id: props.id,
}))`
  position: relative;
  padding: ${(props) =>
    props.size === 'small' ? '4.5px 16px' : props.size === 'medium' ? '8.5px 16px' : '12.5px 16px'};
  width: ${(props) => (props.fullWidth ? '100%' : 'inherit')};
  pointer-events: ${(props) => (props.disabled || props.isLoading ? 'none' : 'auto')};
  font-family: "Inter", sans-serif;
  font-size: ${(props) => (props.size === 'small' ? '12px' : props.size === 'medium' ? '14px' : '16px')};
  font-weight: 600;
  line-height: 22px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: ${(props) => (props.borderRadius ?? '4') + 'px'};
  border: ${(props) => {
    const labelColour = props?.labelColour || colors.GREYS_100;
    const primaryColour = props.primaryColour ?? colors.PRIMARY_1000;
    return props.disabled
      ? `1px solid ${props.disabledBackgroundColour || colors.PRIMARY_500}`
      : `1px solid ${labelColour || primaryColour}`;
  }};
  background: ${(props) => {
    const primaryColour = props.primaryColour ?? 'linear-gradient(180deg, #725FA5 0%, #3D2966 100%)';
    const secondaryBackgroundColour =
      props.secondaryBackgroundColour ||
      `linear-gradient(to right, #111, #444);
    border: 1px solid black;
    box-shadow:
      0px 10px 82px 0px rgba(255, 255, 255, 0.1) inset,
      20px 4px 20px 0px rgba(0, 0, 0, 1) inset,
      92px 0px 22px 0px rgba(255, 255, 255, 0.1) inset,
      -20px -8px 20px 0px rgba(0, 0, 0, 1) inset;`;

    const nonDisabledStyle = props.type == 'primary' ? primaryColour : secondaryBackgroundColour;
    return props.disabled ? props.disabledBackgroundColour || colors.PRIMARY_300 : nonDisabledStyle;
  }};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => {
    return props?.justifyContent ?? 'center';
  }};
  color: ${(props) => {
    const primaryColour = props.primaryColour ?? colors.PRIMARY_1000;
    const nonDisabledStyle = props.type == 'primary' ? colors.PRIMARY_50 : primaryColour;
    return props.disabled ? '#FFFFFF' : nonDisabledStyle;
  }};

  color: ${(props) => {
    const labelColour = props.labelColour || colors.GREYS_100;
    return props.disabled ? '#FFFFFF' : labelColour;
  }};

height: ${(props) => (props.size === 'small' ? '32px' : props.size === 'medium' ? '40px' : '48px')};

  &:hover,
  &:active {
    background: ${(props) => {
      const primaryColour = props?.primaryColour ?? '#65BF73';
      return props.type == 'primary' ? primaryColour : colors.PRIMARY_200;
    }}

  &:active {
    box-shadow: ${(props) =>
      props.type === 'primary' ? 'inset 0px 1px 2px rgba(0, 0, 0, 0.15)' : '0px 1px 2px rgba(0, 0, 0, 0.1)'};
  }

`;

const Loader = styled.div`
  position: absolute;
  visibility: ${(props) => (props.isLoading ? 'visible' : 'hidden')};

  border: ${(props) => (props.type == 'primary' ? '2px solid #ecf7e8 ' : '2px solid #65BF73')};
  border-bottom-color: transparent;
  border-radius: 50%;
  width: ${(props) => (props.size === 'small' ? '13.5px' : '20px')};
  height: ${(props) => (props.size === 'small' ? '13.5px' : '20px')};
  animation: spin 1.5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ButtonIconWrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin: ${(props) => (props.type === 'prefix' ? `0px 4px 0px 0px` : `0px 0px 0px 4px`)};
  height: ${(props) => (props.size === 'small' ? '16px' : '20px')};
  width: ${(props) => (props.size === 'small' ? '16px' : 'auto')};

  svg {
    width: 100%;
    height: 100%;
  }
`;

interface ButtonProps {
  label: string | ReactNode;
  labelColour?: string;
  className?: string;
  id?: string;
  disabled?: boolean;
  size?: 'small' | 'large' | 'medium';
  type?: 'primary' | 'secondary' | 'anchor';
  align?: 'left' | 'center';
  isLoading?: boolean;
  fullWidth?: boolean;
  prefixIcon?: ReactNode;
  postfixIcon?: ReactNode;
  onPress?: (e?) => void;
  primaryColour?: string;
  secondaryBackgroundColour?: string;
  justifyContent?: string;
  disabledBackgroundColour?: string;
  borderRadius?: number;
}

export const Button = ({
  id,
  className,
  label,
  labelColour,
  onPress,
  prefixIcon,
  postfixIcon,
  size = 'small',
  type = 'primary',
  align = 'left',
  isLoading = false,
  disabled = false,
  fullWidth = false,
  primaryColour,
  justifyContent,
  secondaryBackgroundColour,
  disabledBackgroundColour,
  borderRadius,
}: ButtonProps) => {
  let labelToRender = typeof label === 'string' ? label?.[0].toUpperCase() + label.slice(1) : label;
  labelToRender = typeof label === 'function' ? label() : labelToRender;
  const parsedPostfixIcon = typeof postfixIcon === 'function' ? postfixIcon() : postfixIcon;
  const childrens = (
    <div
      style={{
        visibility: isLoading ? 'hidden' : 'visible',
        justifyContent: justifyContent,
      }}
      className={`flex items-center ${align == 'center' ? 'flex-0' : 'flex-1'} `}>
      {prefixIcon ? (
        <ButtonIconWrapper size={size} type='prefix'>
          {prefixIcon}
        </ButtonIconWrapper>
      ) : undefined}
      {labelToRender}
      {parsedPostfixIcon ? (
        <ButtonIconWrapper size={size} type='postfix'>
          {parsedPostfixIcon}
        </ButtonIconWrapper>
      ) : undefined}
    </div>
  );
  return (
    <StyledButton
      className={className}
      id={id}
      type={type}
      size={size}
      disabled={disabled}
      labelColour={labelColour}
      onClick={onPress}
      fullWidth={fullWidth}
      primaryColour={primaryColour}
      secondaryBackgroundColour={secondaryBackgroundColour}
      justifyContent={justifyContent}
      isLoading={isLoading}
      disabledBackgroundColour={disabledBackgroundColour}
      borderRadius={borderRadius}>
      <Loader type={type} isLoading={isLoading} size={size} />
      {childrens}
    </StyledButton>
  );
};
