import { AxiosResponse } from 'axios';

import { IGetOfflineAvailabilityPayload } from '@Reducers/provider';

import { Axios } from '../AxiosApiServerWrapper';

export const ProviderService = {
  GetProviderData: async (providerId: string): Promise<AxiosResponse> => {
    const url = `core/providers/${providerId}`;
    return Axios.Request('GET', url);
  },
  GetAllProviderData: async (): Promise<AxiosResponse> => {
    const url = `core/providers`;
    return Axios.Request('GET', url);
  },
  GetOfflineAvailabilityForProvider: async (payload: IGetOfflineAvailabilityPayload): Promise<AxiosResponse> => {
    const url = `scheduling/blocks/providers/${payload?.id}/offline-availability`;
    return Axios.Request('GET', url, {}, payload);
  },
};
