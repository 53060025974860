import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { BiLogOut } from 'react-icons/bi';

import { AuthActions } from '@Actions/index';
import { colors } from '@Components/design-system/color';
import Text from '@Components/design-system/text';
import { useAppDispatch } from '@Redux/hooks';
import { whatsappContactLink } from '@Utils/constants';

import styles from '../styles/Header.module.css';
import { Button } from './design-system/button/button';
import { ColorType } from './design-system/color/types';
import { FontType } from './design-system/text/types';

interface HeaderProps {
  disableMockShadow?: boolean;
  isAuthenticated?: boolean;
  showSupport?: boolean;
  showLogout?: boolean;
}

const Header = (props: HeaderProps) => {
  const router = useRouter();
  const [viewSupport, setViewSupport] = useState(false);

  const MyAccountDropdown = () => (
    <>
      <div className='relative flex flex-row gap-4 items-center'>
        {props.showSupport && (
          <div
            onClick={() => setViewSupport(!viewSupport)}
            className={`flex gap-2 cursor-pointer items-center ${props.showLogout ? '' : ' mr-4 '}}`}>
            <Text font={FontType.SEMIBOLD_11_170} color={ColorType.SUCCESS_600}>
              Ask Support
            </Text>
            <Image src={'/assets/svgs/whatsapp.svg'} height={18} width={18} alt={'allo-icon'} layout={'fixed'} />
          </div>
        )}
        {props.showLogout && (
          <div className={'cursor-pointer ' + styles.profile} onClick={() => router.push('/profile')}></div>
        )}
      </div>
    </>
  );
  return (
    <>
      <div className={'flex flex-row justify-between headerContainer relative ' + styles.headerContainer}>
        <div className={'headerLogo ' + styles.logo} />
        <div className='flex justify-center items-center'>
          {viewSupport && <SupportWidget handleClose={() => setViewSupport(false)} />}
          {props.isAuthenticated ? <MyAccountDropdown /> : undefined}
        </div>
      </div>
      {props.disableMockShadow ? undefined : <div className={'mockShadow ' + styles.mockShadow}></div>}
    </>
  );
};

function OptionsOverlay() {
  const dispatch = useAppDispatch();
  return (
    <div className={styles.OptionsOverlay + ' OptionsOverlay flex flex-col justify-end'}>
      <div
        className={styles.logoutRow + ' logoutRow flex flex-row items-center'}
        onClick={() => {
          dispatch(AuthActions.Logout({ relogin: false }));
        }}>
        <BiLogOut size={16} />
        <div className={styles.logoutText + ' ml-2'}>Logout</div>
      </div>
    </div>
  );
}

export const SupportWidget = ({ handleClose }) => {
  return (
    <div className='rounded bg-white p-6 shadow-xl absolute top-16 right-4 w-80 flex flex-col z-30 shadow-2xl'>
      <Text font={FontType.SEMIBOLD_18_150} color={ColorType.GREYS_900}>
        Ask Allo Support..
      </Text>
      <Text className='pb-6' font={FontType.REGULAR_14_150} color={ColorType.GREYS_900}>
        You will be redirected to WhatsApp for help and support.
      </Text>
      <div className='grid grid-cols-2 gap-2'>
        <div
          onClick={handleClose}
          className='border bg-white border-PRIMARY_1000 flex rounded items-center justify-center'>
          <Text font={FontType.SEMIBOLD_12_170} color={ColorType.PRIMARY_1000}>
            Cancel
          </Text>
        </div>
        <Button
          label={'Open whatsapp'}
          primaryColour={colors[ColorType.PRIMARY_1000]}
          onPress={() => {
            window.open(whatsappContactLink);
          }}
        />
      </div>
    </div>
  );
};

export default Header;
