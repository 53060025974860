import Image from 'next/image';
import React, { useState } from 'react';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { useTrans } from '@Utils/hooks/translation';

const BlogThumbnail = ({
  backgroundImage,
  title,
  classes = '',
}: {
  backgroundImage?: string;
  title?: string;
  classes?: string;
}) => {
  const { languageUsed } = useTrans();
  const [imageError, setImageError] = useState(false);
  return (
    <div className={'rounded-lg bg-no-repeat bg-cover overflow-hidden relative ' + classes}>
      {!imageError ? (
        <Image
          src={backgroundImage}
          onError={() => setImageError(true)}
          alt='blog-thumbnail'
          layout='fill'
          objectFit='cover'
          className='rounded-lg'
        />
      ) : (
        <div className='w-full h-full rounded-lg bg-PRIMARY_300' />
      )}
      <div className='rounded-lg flex flex-col items-center justify-center text-center px-2 backdrop-brightness-50 w-full h-full'>
        <Text className='uppercase' font={FontType.SEMIBOLD_11_170} color={ColorType.GREYS_100} language={languageUsed}>
          {title}
        </Text>
        <div className='w-4 my-1 h-px bg-GREYS_400' />
      </div>
    </div>
  );
};

export default BlogThumbnail;
