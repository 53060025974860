import { IConsultationInvoice, IInvoiceItem } from '@Reducers/billing';

export const getLabTests = (labTestOrders = [], invoice: IConsultationInvoice, areLabTestsServicable: boolean) => {
  const labTests = [];
  const unVerifiedLabTests = [];
  for (const labTest of labTestOrders) {
    const invoiceLabTest: IInvoiceItem =
      invoice?.items?.find((item) => item?.typeId === labTest?.labTest?.id) ||
      invoice?.paidItems?.find((item) => item?.typeId === labTest?.labTest?.id);
    const shouldAdd = invoiceLabTest || labTest?.labTest?.isVerified === false || areLabTestsServicable === false;

    if (!shouldAdd && invoice) continue;
    const formattedLabTest = {
      id: labTest?.labTest?.id,
      name: labTest?.labTest?.name,
      type: labTest?.labTest?.type,
      instructions: labTest?.instructions,
      inclusions: labTest?.labTest?.inclusions,
      preparation: labTest?.labTest?.preparation,
      note:
        (labTest?.labTest?.notes && JSON.parse(labTest?.labTest?.notes)) ||
        'Your required diagnostic tests will be scheduled for you as per your convenience.',
      totalQuantity: invoiceLabTest?.quantity,
      isVerified: labTest?.labTest?.isVerified || Boolean(invoiceLabTest), // drug can be marked as unverified after purchase
      venue: labTest?.labTest?.venue,
      dueDate: labTest?.dueDate,
    };
    labTest?.labTest?.isVerified === false
      ? unVerifiedLabTests.push(formattedLabTest)
      : labTests.push(formattedLabTest);
  }
  return [...labTests, ...unVerifiedLabTests];
};
