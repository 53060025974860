import Image from 'next/image';
import { useContext } from 'react';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { useTrans } from '@Utils/hooks/translation';

import EmptyData from '../public/assets/product-banners/EmptyData.png';
import { DeviceContext } from './core/responsive';

export default function EmptyDataSection({ message }: { message: string }) {
  const { mobile } = useContext(DeviceContext);
  const { languageUsed } = useTrans();
  return (
    <>
      <section className={`${mobile ? ' mt-16' : ''}` + ' relative'}>
        <Text
          className='text-center py-10'
          color={ColorType.GREYS_1000}
          font={FontType.SEMIBOLD_24_150}
          language={languageUsed}>
          {message}
        </Text>
        <Image src={EmptyData.src} width={750} height={850} alt='allo-icon' />
        <Text
          className='bg-PURPLE_V2 absolute bottom-16 md:bottom-36 md:w-128 w-full text-center'
          color={ColorType.GREYS_100}
          font={FontType.SEMIBOLD_14_150}>
          We are working on it...
        </Text>
      </section>
    </>
  );
}
