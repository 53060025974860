import Image from 'next/image';
import { useState } from 'react';

import MidPopUp from '@Components/core/mid-popup';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { formatName, groupByTitle } from '@Utils/helper';
import { filterTags, getReasons } from '@Utils/Helpers/AssessmentReport/helper';
import { useTrans } from '@Utils/hooks/translation';

import { CollapsableListTypes } from '../../pages/assessment-report/v2';
import CollapsableList from './collapsable-list';
import StickyCta from './sticky-cta';

const AilmentSummary = ({ ailment, closeModal, metadata, handleBooking }) => {
  const { t, languageUsed } = useTrans('ax');
  const [viewDetailedContent, setViewDetailedContent] = useState(false);
  const [listType, setListType] = useState<CollapsableListTypes>();
  const image = metadata?.['external_url']?.[0]?.description;
  const affects = groupByTitle(metadata?.['affects']);
  const solutions = metadata?.['solutions'];
  const tags = filterTags(metadata?.['tags']);
  const handleViewDetailedContent = (listType) => {
    setListType(listType);
    setViewDetailedContent(true);
  };
  return viewDetailedContent ? (
    <CollapsableList
      listType={listType}
      information={solutions}
      informationFor={ailment?.ailment?.name}
      closeModal={() => setViewDetailedContent(false)}
      categorizeInformationBy='title'
      titleizeBy='subTitle'
    />
  ) : (
    <MidPopUp closeModal={closeModal}>
      <main className='grid grid-cols-1 divide-y divide-GREYS_400 px-4 pt-6'>
        <section>
          <Text className='pb-1' font={FontType.SEMIBOLD_18_150} color={ColorType.GREYS_900} language={languageUsed}>
            {ailment?.ailment?.languageNameMap?.[languageUsed] || ailment?.ailment?.name}
          </Text>
          {ailment?.value && (
            <Text
              className='pb-4'
              font={FontType.SEMIBOLD_14_150}
              color={ColorType.BLUE_GREY_400}
              language={languageUsed}>
              {formatName(ailment.valueType)}: {ailment.value}
            </Text>
          )}
          {tags?.length > 0 && (
            <>
              <div className='flex gap-1 mb-3'>
                <Image src={'/assets/svgs/assessment-report/factors-grey.svg'} alt='allo-icon' width={16} height={16} />
                <Text font={FontType.SEMIBOLD_11_170} color={ColorType.COOL_GREY_500} language={languageUsed}>
                  {t('contributing-factors')}
                </Text>
              </div>
              <div className='flex flex-wrap gap-3 mb-4'>
                {tags?.map((tag) => (
                  <div key={tag?.description} className='px-2 py-1 gap-1 rounded-full flex items-center bg-ROSE_50'>
                    {getReasons(tag?.description?.toLowerCase()) && (
                      <Image src={getReasons(tag?.description.toLowerCase())} alt='allo-icon' width={16} height={16} />
                    )}
                    <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_900} language={languageUsed}>
                      {formatName(tag?.description)}
                    </Text>
                  </div>
                ))}
              </div>
            </>
          )}
        </section>
        <section className='pt-4'>
          {image && <Image src={image} width={335} height={176} alt='allo-icon' />}
          {Object.keys(affects)?.length > 1 ? (
            <>
              <Text
                className='pb-4'
                font={FontType.SEMIBOLD_18_150}
                color={ColorType.GREYS_900}
                language={languageUsed}>
                {ailment?.ailment?.type === 'cause'
                  ? t('how-does-it-affect-sexual-health')
                  : t('why-you-might-be-facing-these-symptoms')}
              </Text>
              <div className='flex gap-4 pt-2 px-2 overflow-x-scroll bg-white'>
                {Object.keys(affects)?.map((affect) => (
                  <div key={affect} className='mb-4 shadow-evenly p-4 pt-3 bg-white rounded-lg min-w-[80%]'>
                    <Text
                      className='pb-2'
                      font={FontType.SEMIBOLD_14_150}
                      color={ColorType.GREYS_900}
                      language={languageUsed}>
                      {affect}
                    </Text>
                    {affects[affect]?.map((affect) => (
                      <Text
                        key={affect?.description}
                        font={FontType.REGULAR_14_150}
                        color={ColorType.COOL_GREY_600}
                        language={languageUsed}>
                        <strong>{affect?.subTitle}</strong> {affect?.description}
                      </Text>
                    ))}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <Text
                className='pb-2'
                font={FontType.SEMIBOLD_18_150}
                color={ColorType.GREYS_900}
                language={languageUsed}>
                {Object.keys(affects)?.[0]}
              </Text>
              <div className='pb-4'>
                {affects?.[Object.keys(affects)?.[0]]?.map((affect) => (
                  <Text
                    key={affect?.description}
                    font={FontType.REGULAR_14_150}
                    color={ColorType.COOL_GREY_600}
                    language={languageUsed}>
                    {affect?.description}
                  </Text>
                ))}
              </div>
            </>
          )}
        </section>
        {solutions?.length > 0 && (
          <section
            onClick={(e) => {
              e.stopPropagation();
              handleViewDetailedContent(CollapsableListTypes.solutions);
            }}
            className='flex justify-between py-4'>
            <Text font={FontType.SEMIBOLD_14_150} color={ColorType.INDIGO_600} language={languageUsed}>
              {t('how-can-you-address-this')}
            </Text>
            <Image
              className='transform -rotate-90'
              src={'/assets/svgs/chevron-indigo.svg'}
              height={20}
              width={20}
              alt='click'
            />
          </section>
        )}
        <StickyCta heading={t('have-doubts-about-your-report')} handleBooking={handleBooking} />
      </main>
    </MidPopUp>
  );
};

export default AilmentSummary;
