import React from 'react';
import styled from 'styled-components';

import { LanguageCode } from '@Reducers/metadata';

import { colors } from '../color';
import { ColorType } from '../color/types';
import { HiFontType } from './hi-types';
import { FontType } from './types';

type TextType = keyof Pick<
  JSX.IntrinsicElements,
  'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'label' | 'td'
>;

export enum FontFamily {
  POPPINS = 'Poppins',
  INTER = 'Inter',
  RECOLETA = 'Recoleta',
  NotoSansDevanagari = 'Noto Sans Devanagari',
}

type TextProps = {
  font?: FontType;
  color?: ColorType;
  family?: FontFamily;
  weight?: number;
  className?: string;
  id?: string;
  required?: boolean;
  type?: TextType;
  onClick?: (e?) => void;
  style?: React.CSSProperties;
  labelFor?: string;
  children?: React.ReactElement | React.ReactNode;
  language?: LanguageCode;
};

const Text: React.FC<TextProps> = ({
  color = ColorType.GREYS_900,
  font = FontType.DISPLAY_16_150,
  family = FontFamily.POPPINS,
  required = false,
  type = 'div',
  children,
  id,
  className,
  onClick,
  style,
  labelFor,
  weight,
  language = LanguageCode.English,
}) => {
  const TextComponent = (() => {
    const islanguageEnglish = language === LanguageCode.English;
    const fontString = islanguageEnglish ? font.split('_') : HiFontType[font].split('_');
    const fontType = fontString[0];
    const fontSize = +fontString[1];
    const lineHeight = +fontString[2];
    const textColor = colors[color] || colors[ColorType.SECONDARY_RED];
    const fontFamily = islanguageEnglish ? family : FontFamily.NotoSansDevanagari;

    let fontWeight;
    if (weight !== undefined) fontWeight = weight;
    else {
      switch (fontType) {
        case 'regular': {
          fontWeight = 400;
          break;
        }
        case 'semibold': {
          fontWeight = 600;
          break;
        }
        case 'medium': {
          fontWeight = 500;
          break;
        }
        case 'bold':
        case 'display':
          {
            fontWeight = 700;
            // No default
          }
          break;
      }
    }

    return styled(`${type}`).attrs({ className: className, id: id })`
      font-family: ${fontFamily};
      font-size: ${fontSize}px;
      font-weight: ${fontWeight};
      line-height: ${lineHeight}%;
      color: ${textColor};
      letter-spacing: ${islanguageEnglish ? '0em' : '0.02em'};
    `;
  })();

  return (
    <TextComponent onClick={onClick} style={style} htmlFor={labelFor}>
      {children}
      {required ? ' *' : ''}
    </TextComponent>
  );
};

export default React.memo(Text);
