import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { LanguageCode } from '@Reducers/metadata';
import { getAccessTokenSilently } from '@Services/Auth';
import { useTrans } from '@Utils/hooks/translation';
import { LOG_ERROR } from '@Utils/logger';

import ChatbotBody from './chatbot-body';
import ChatbotContainer from './chatbot-container';
import ChatbotHeader from './chatbot-header';
import ChatbotNudgeContainer from './chatbot-nudge';
import ChatbotNudgeHeader from './chatbot-nudge-header';
import ChatbotResponseBar from './chatbot-response-bar';
import { ChatbotToggleButton } from './toggle-button';

const getQueryResponse = async (text) => {
  const errorResponse = 'Sorry, I am facing some issues. Please try later.';
  let response;
  try {
    const token = await getAccessTokenSilently().then((response) => response?.authToken);
    const apiResponse = await axios.post(
      `${process.env.NEXT_PUBLIC_API_SERVER_BASE_URL}/chatbot`,
      {
        query: text,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );
    if (apiResponse?.data?.status == 200) {
      response = apiResponse?.data?.message;
    } else {
      const referenceData = { apiResponse };
      LOG_ERROR('Error: Chatbot>>GetQueryResponse', referenceData);
    }
  } catch (error) {
    const referenceData = { error };
    LOG_ERROR('Error: Chatbot>>GetQueryResponse', referenceData);
  }
  return response || errorResponse;
};

// React component
const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isNudgeClosed, setIsNudgeClosed] = useState(true);
  const [input, setInput] = useState('');
  const [welcomeText, setWelcomeText] = useState('');
  const { languageUsed } = useTrans();

  const getQuickReplies = () => {
    const englishReplies = ['How can you help me?', 'I would like to book appointment'];
    const hindiReplies = ['आप मेरी कैसे मदद कर सकते हैं?', 'मैं अपॉइंटमेंट बुक करना चाहूंगा'];

    return languageUsed === LanguageCode.Hindi ? hindiReplies : englishReplies;
  };

  const getFirstNudge = async () => {
    const englishResponse =
      "👋 Welcome to Allo Health. I'm here to assist you with any queries or issues you might have. How can I help you today?";
    const hindiResponse =
      '👋 Allo Health में आपका स्वागत है। मैं आपकी किसी भी प्रश्न या समस्या के साथ आपकी सहायता करने के लिए यहां हूं। मैं आज आपकी कैसे मदद कर सकता हूं?';
    const response = languageUsed === LanguageCode.Hindi ? hindiResponse : englishResponse;
    setWelcomeText(response);
    setIsNudgeClosed(false);
  };

  const generateResponse = async (text) => {
    const response = {
      text: await getQueryResponse(text),
      isUser: false,
    };
    setIsTyping(false);
    setMessages((messageList) => [...messageList, response]);
  };

  const quickReplyClick = (text) => {
    setMessages((messageList) => [...messageList, { text, isUser: true }]);
    setIsTyping(true);
    generateResponse(text);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getFirstNudge();
      // eslint-disable-next-line unicorn/numeric-separators-style
    }, 30000);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleSendClick = () => {
    if (input.trim()) {
      setMessages((messageList) => [...messageList, { text: input, isUser: true }]);
      setIsTyping(true);
      generateResponse(input);
      setInput('');
    }
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
    setIsNudgeClosed(true);
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  return (
    <>
      <ChatbotToggleButton isOpen={isOpen} onClick={toggleChat} />
      <ChatbotNudgeContainer className={isOpen || isNudgeClosed ? 'closed' : ''}>
        <ChatbotNudgeHeader toggleChat={() => setIsNudgeClosed(true)} />
        <div>{welcomeText}</div>
      </ChatbotNudgeContainer>
      <ChatbotContainer className={isOpen ? 'open' : ''}>
        <ChatbotHeader toggleChat={toggleChat} />
        <ChatbotBody
          messages={messages}
          quickReplies={getQuickReplies()}
          quickReplyClick={quickReplyClick}
          isTyping={isTyping}
        />
        <ChatbotResponseBar setInput={setInput} input={input} handleSendClick={handleSendClick} isTyping={isTyping} />
      </ChatbotContainer>
    </>
  );
};

export default ChatBot;
