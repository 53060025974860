import dayjs from 'dayjs';
import Image from 'next/image';
import styled from 'styled-components';

import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { IAppointments } from '@Reducers/checkout';
import { IQueryData } from '@Reducers/common';
import { RESCHEDULE_REASON, CONSULTATION_STATE, PAGE } from '@Utils/constants';
import { checkAppointmentExpiry } from '@Utils/helper';
import useCustomRouter from '@Utils/hooks/custom-router';

import { ColorType } from './design-system/color/types';

export default function Reschedule({
  color,
  calendarIcon,
  chevronIcon,
  appointment,
  appointmentDate,
}: {
  color: ColorType;
  calendarIcon: string;
  chevronIcon: string;
  appointment: IAppointments;
  appointmentDate: string;
}) {
  return (
    <GradientCard>
      <RescheduleUtil
        color={color}
        calendarIcon={calendarIcon}
        chevronIcon={chevronIcon}
        appointment={appointment}
        appointmentDate={appointmentDate}
      />
    </GradientCard>
  );
}

export const RescheduleUtil = ({
  color,
  calendarIcon,
  chevronIcon,
  appointment,
  appointmentDate,
  queryData,
  launchAction,
}: {
  color: ColorType;
  calendarIcon: string;
  chevronIcon: string;
  appointment: IAppointments;
  appointmentDate: string;
  queryData?: IQueryData;
  launchAction?: () => void;
}) => {
  const customRouter = useCustomRouter();
  const rescheduleRedirection = () => {
    customRouter({
      pathname: PAGE.APPOINTMENT.ROUTE,
      query: {
        appointment_state: CONSULTATION_STATE.RESCHEDULE,
        form_type: 'DIRECT_LINK',
        consultation_id: appointment?.consultationId,
        product_code: appointment?.consultationCode,
        reason: RESCHEDULE_REASON,
        selected_languages: queryData?.selectedLanguages,
        valid_user_city: queryData?.validUserCity,
        ...(queryData?.ipxId && { ipx_id: queryData?.ipxId }),
      },
    });
  };
  return (
    <>
      <div className='flex justify-self-start gap-2 col-span-2'>
        <div className='h-full flex flex-col justify-start items-start'>
          <Image src={calendarIcon || '/assets/svgs/calendar.svg'} width={24} height={24} alt='allo-icon' />
        </div>
        <div>
          <Text className='pb-1' font={FontType.SEMIBOLD_14_150} color={color}>
            {appointmentDate && dayjs(appointmentDate).format('ddd, DD MMM, YYYY')}
          </Text>
          {!checkAppointmentExpiry(appointmentDate) && (
            <Text className='flex justify-start items-center' font={FontType.REGULAR_10_170} color={color}>
              {appointmentDate && dayjs(appointmentDate).format('h:mm A')}
            </Text>
          )}
        </div>
      </div>
      {!checkAppointmentExpiry(appointmentDate) ? (
        <div onClick={launchAction || rescheduleRedirection} className='flex justify-self-end items-center gap-1'>
          <Text font={FontType.SEMIBOLD_12_170} color={color}>
            Reschedule
          </Text>
          <Image src={chevronIcon || '/assets/svgs/chevron-right-purple.svg'} width={8} height={8} alt='allo-icon' />
        </div>
      ) : (
        <Text className='flex justify-end items-center' font={FontType.REGULAR_10_170} color={color}>
          {appointmentDate && dayjs(appointmentDate).format('h:mm A')}
        </Text>
      )}
    </>
  );
};

const GradientCard = styled.div.attrs({
  className: 'grid grid-cols-3 md:w-80 w-full justify-items-stretch rounded-lg shadow-slot px-5 py-4',
})`
  background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.4) 3.01%, rgba(255, 255, 255, 0) 103.3%);
  backdrop-filter: blur(21px);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
`;
