import Image from 'next/image';
import { useState } from 'react';

import { ColorType } from '@Components/common-exports';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import InfoPopup from '@Components/info-popup';
import { IDiagnosis } from '@Reducers/encounter';
import { useTrans } from '@Utils/hooks/translation';

import ArrowNext from '../../public/assets/svgs/arrow-next.svg';
import InformationPopup from './information-popup';

const getLikelinessTheme = (likeliness: string) => {
  switch (likeliness) {
    case 'severely':
      return {
        title: 'highly likely',
        icon: 'bg-red-700',
        text: 'text-red-700',
        border: 'border-red-700',
      };
    case 'moderately':
      return {
        title: 'likely',
        icon: 'bg-yellow-500',
        text: 'text-yellow-500',
        border: 'border-yellow-500',
      };
    case 'normal':
      return {
        title: 'less likely',
        icon: 'bg-SUCCESS_600',
        text: 'text-SUCCESS_600',
        border: 'border-SUCCESS_600',
      };
    default:
      return {
        title: '',
        icon: 'bg-yellow-500',
        text: 'text-yellow-500',
        border: 'border-yellow-500',
      };
  }
};

const DiagnosisSummaryCard = ({
  diagnosis,
  metadata,
  handleBooking,
  mainNav,
  diagnoses,
  ailments,
}: {
  diagnosis: any;
  metadata: Record<string, any[]>;
  handleBooking?: () => void;
  mainNav?: Record<string, { nav_item: string; scroll_to: any }>;
  diagnoses?: IDiagnosis[];
  ailments?: any[];
}) => {
  const { t, languageUsed } = useTrans('ax');
  const [viewInfo, setViewInfo] = useState(false);
  const likelinessTheme = getLikelinessTheme(diagnosis?.severity);

  const handleOnClick = () => {
    setViewInfo(!viewInfo);
  };
  return (
    <>
      <div
        className='pt-4 bg-violet-50 rounded-lg flex-col justify-start items-start gap-3 min-w-full relative'
        onClick={handleOnClick}>
        {diagnosis?.severity && (
          <div
            className={
              'px-2 py-1 bg-white rounded-bl-lg rounded-tr-lg border-l border-b justify-start items-center gap-1 inline-flex absolute right-0 top-0 ' +
              likelinessTheme?.border
            }>
            <div className={'w-2 h-2 rounded-full ' + likelinessTheme?.icon} />
            <div className={'text-xs font-semibold ' + likelinessTheme?.text}>
              {t(`likeliness.${likelinessTheme?.title?.toLowerCase()?.replace(/ /g, '-')}`)}
            </div>
          </div>
        )}
        <div className='self-stretch px-4 justify-start items-center gap-2 inline-flex pb-4'>
          {/**TODO: Image change by likeliness **/}
          <Image src='/assets/svgs/diagnosis-ed.svg' width={32} height={32} layout='fixed' alt='allo-icon' />
          <div className='flex-col justify-start items-start inline-flex gap-1'>
            <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_1000} language={languageUsed}>
              {diagnosis?.medicalCondition?.languageNameMap?.[languageUsed] ||
                diagnosis?.medicalCondition?.name ||
                t(`general.undetermined`)}
            </Text>
          </div>
        </div>
        {diagnosis?.medicalCondition?.name && (
          <div className='self-stretch px-4 py-2 bg-neutral-50 rounded-tl-sm rounded-tr-sm rounded-bl-lg rounded-br-lg border border-neutral-100 justify-between items-center inline-flex min-w-full'>
            <Text font={FontType.REGULAR_12_170} language={languageUsed}>
              {t(`read-about`, {
                condition:
                  diagnosis?.medicalCondition?.languageNameMap?.[languageUsed] || diagnosis?.medicalCondition?.name,
              })}
            </Text>
            <div className='flex justify-center gap-2'>
              <Image className='cursor-pointer' src={ArrowNext.src} width={10} height={10} alt='allo icon' />
            </div>
          </div>
        )}
      </div>

      {/* Info Popup */}
      {viewInfo && metadata && (
        <InformationPopup
          problem={diagnosis}
          setViewInfo={setViewInfo}
          metadataForDiagnosis={metadata?.[diagnosis?.medicalCondition?.id] || []}
          metadata={metadata}
          handleBooking={handleBooking}
          mainNav={mainNav}
          diagnoses={diagnoses}
          ailments={ailments}
        />
      )}
      {!diagnosis?.medicalCondition?.name && viewInfo && (
        <InfoPopup
          problem={{
            title: t(`need-further-assessment.title`),
            likeliness: '',
            sections: [
              {
                header: t(`need-further-assessment.title`),
                subheader: t(`need-further-assessment.description`),
                image: '',
                imageCaption: '',
                tags: [],
                description: '',
              },
            ],
          }}
          setViewInfo={setViewInfo}
        />
      )}
    </>
  );
};

export default DiagnosisSummaryCard;
