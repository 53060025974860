import { IConsultationInvoice, IInvoiceItem } from '@Reducers/billing';
import { IDrugData } from '@Reducers/patient';
import { frequencyValues } from '@Utils/constants';
import { LOG_ERROR } from '@Utils/logger';

export const getDrugs = (drugOrders = [], invoice?: IConsultationInvoice) => {
  if (!drugOrders?.length) return [];
  const drugs: IDrugData[] = [];
  const unVerifiedDrugs: IDrugData[] = [];
  for (const drug of drugOrders) {
    const invoiceDrug: IInvoiceItem =
      invoice?.items?.find((item: IInvoiceItem) => item?.typeId === drug?.drug?.id) ||
      invoice?.paidItems?.find((item: IInvoiceItem) => item?.typeId === drug?.drug?.id);
    const shouldAdd = invoiceDrug || drug?.drug?.isVerified === false;

    if (!shouldAdd && invoice) continue;

    const instructions = formatInstructions(drug);
    const formattedDrug = {
      id: drug?.drug?.id,
      name: drug?.drug?.name,
      brandName: drug?.drug?.brandName,
      type: drug?.drug?.form,
      duration: drug?.duration,
      frequency: drug?.frequency,
      durationUnit: drug?.durationUnit,
      instructions: drug?.originalDrugId ? instructions : undefined,
      note: drug?.instructions,
      condition: drug?.asNeededCondition,
      dispense: drug?.dispense,
      packageSize: drug?.drug?.packSize,
      totalQuantity: invoiceDrug?.quantity,
      isVerified: drug?.drug?.isVerified || Boolean(invoiceDrug), // drug can be marked as unverified after purchase
      applicationEvents: drug?.applicationEvents?.map((event) => {
        return {
          quantity: event?.quantity,
          type: event?.eventType,
          time: event?.eventTime,
          offset: event?.timingOffset,
          offsetUnit: event?.timingOffsetUnit,
        };
      }),
    };
    drug?.drug?.isVerified === false ? unVerifiedDrugs.push(formattedDrug) : drugs.push(formattedDrug);
  }
  return [...drugs, ...unVerifiedDrugs];
};

export const formatInstructions = (drug) => {
  const {
    frequency,
    applicationEvents,
    duration,
    durationUnit,
    asNeededCondition,
    drug: { form },
  } = drug || {};
  try {
    if (applicationEvents?.length === 0) return '';
    const frequencySpaces = frequency?.replace(/_/g, ' ');
    const quantityPerDay = (applicationEvents || []).reduce((a, b) => a + (b?.quantity || 0), 0);
    const punctuation = (idx) => (idx !== applicationEvents?.length - 2 ? ',' : ' and');
    const event =
      applicationEvents
        ?.map((event, idx) => {
          return (
            event?.eventTime.replace('_', ' ') +
            ' ' +
            event?.eventType.replace('_', ' ') +
            `${idx + 1 !== applicationEvents?.length ? punctuation(idx) : ''}`
          );
        })
        .join(' ') || '';

    if (frequencySpaces && frequencySpaces !== frequencyValues.ondemand) {
      switch (form) {
        case 'cream':
        case 'gel':
        case 'ointment':
          return `Apply ${frequencySpaces} for ${duration} ${durationUnit}${duration > 1 ? 's' : ''} in ${event}.`;
        case 'spray':
          return `Spray ${frequencySpaces} for ${duration} ${durationUnit}${duration > 1 ? 's' : ''} in ${event}.`;
        default:
          return `Take ${quantityPerDay} ${form}${
            quantityPerDay > 1 ? 's' : ''
          } ${frequencySpaces} for ${duration} ${durationUnit}${duration > 1 ? 's' : ''} at ${event}.`;
      }
    } else if (frequencySpaces && frequencySpaces === frequencyValues.ondemand) {
      switch (form) {
        case 'cream':
        case 'gel':
        case 'ointment':
          return `Apply ${asNeededCondition}`;
        case 'spray':
          return `Spray ${asNeededCondition}`;
        default:
          return `Take ${quantityPerDay} ${form}${quantityPerDay > 1 ? 's' : ''} ${asNeededCondition}`;
      }
    }
    return '';
  } catch (e) {
    const log = {
      event: e,
      frequency,
      applicationEvents,
      duration,
      durationUnit,
      asNeededCondition,
      form,
    };
    LOG_ERROR('Error formatting instructions', log);
    return '';
  }
};

export const roundoffQuantity = (quantity: number, packageSize: number) => {
  const packSize = packageSize || 1;
  const roundedOffQuantity = Math.ceil(quantity / packSize) * packSize;
  return roundedOffQuantity;
};
