import { Dispatch } from '@reduxjs/toolkit';

import createAsyncAction from '@Actions/create-async-action';
import { ActionConsts } from '@Definitions/index';
import { updateLocationCode } from '@Reducers/common';
import { IConsultationType, IPurchasedConsultationData } from '@Reducers/consultation';
import { ConsultationService, ScheduleService } from '@Services/index';
import { CONSULTATION_TYPE, DEFAULT_LOCATION_CODE, ERROR_MESSAGES, ONLINE_LOCATION_ID } from '@Utils/constants';
import { transformAmount } from '@Utils/helper';
import { LOG_ERROR } from '@Utils/logger';

import ActionInputs from './payload';

export const ConsultationActions = {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  GetConsultationDetails: (consultationId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Consultation.GetConsultationInit });
      const result = await ConsultationService.GetConsultationDetails(consultationId);
      if (result?.status === 200) {
        if (result.data.appointment && result.data.appointment.locationId !== ONLINE_LOCATION_ID) {
          dispatch({
            hideOnlineLocation: true,
            type: ActionConsts.Schedule.SetHideOnlineLocation,
          });
          const locationData = await ScheduleService.GetClinicLocation(result.data.appointment.locationId);
          if (locationData?.data?.isActive) dispatch(updateLocationCode(locationData?.data?.code));
        } else if (result.data.appointment && result.data.appointment.locationId === ONLINE_LOCATION_ID) {
          dispatch(updateLocationCode(DEFAULT_LOCATION_CODE));
          dispatch({
            hideOfflineLocation: true,
            type: ActionConsts.Schedule.SetHideOfflineLocation,
          });
        }
        dispatch({
          rescheduleConsultationData: {
            appointmentStatus: result?.data?.appointment?.status,
            consultationId: result?.data?.id,
            date: result?.data?.appointment?.startTime,
            consultationType: result?.data?.consultationType?.name,
            providerName: result?.data?.appointment?.provider?.name,
          },
          type: ActionConsts.Consultation.GetConsultationSuccess,
        });
      } else {
        dispatch({
          message: result?.data?.message,
          type: ActionConsts.Consultation.GetConsultationFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: ConsultationActions>>GetConsultationDetails', error);
      dispatch({
        message: ERROR_MESSAGES.get,
        type: ActionConsts.Consultation.GetConsultationFail,
      });
    }
  },
  GetConsultationTypeDetails: (consultationCode: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Consultation.GetConsultationTypeInit });
      const result = await ConsultationService.GetConsultationType(consultationCode);
      if (result?.status === 200) {
        dispatch({
          consultationTypeData: {
            consultationTypeId: result?.data?.id,
            name: result?.data?.code === CONSULTATION_TYPE.TH ? 'Counselling Session' : result?.data?.name,
            description: result?.data?.description,
            consultationCode: result?.data?.code,
            duration: result?.data?.config?.duration,
            staticPrice: transformAmount(result?.data?.price?.sellingPrice),
          } as IConsultationType,
          type: ActionConsts.Consultation.GetConsultationTypeSuccess,
        });
      } else {
        dispatch({
          type: ActionConsts.Consultation.GetConsultationTypeFail,
        });
      }
    } catch (error) {
      LOG_ERROR('Error: ConsultationActions>>GetConsultationType', error);
      dispatch({
        type: ActionConsts.Consultation.GetConsultationTypeFail,
      });
    }
  },
  GetConsultation: createAsyncAction({
    initAction: ActionConsts.Consultation.GetConsultationInit,
    successAction: ActionConsts.Consultation.GetConsultationSuccess,
    failAction: ActionConsts.Consultation.GetConsultationFail,
    returnResult: true,

    asyncFn: async (requestPayload: ActionInputs['GetConsultation']) => {
      const consultation = await ConsultationService.GetConsultationDetails(requestPayload.consultationId);
      if (consultation?.status === 200) {
        return {
          result: {
            rescheduleConsultationData: {
              consultationId: consultation?.data?.id,
              consultationType: consultation?.data?.consultationType?.name,
              appointmentStatus: consultation?.data?.appointment?.status,
              providerName: consultation?.data?.appointment?.provider?.name,
              date: consultation?.data?.appointment?.startTime,
              locationId: consultation?.data?.appointment?.locationId,
            } as IPurchasedConsultationData,
          },
          type: 'success',
        };
      }
      return { result: {}, type: 'fail' };
    },
  }),
  GetConsultationTypeDetailsFuture: createAsyncAction({
    initAction: ActionConsts.Consultation.GetConsultationTypeInit,
    successAction: ActionConsts.Consultation.GetConsultationTypeSuccess,
    failAction: ActionConsts.Consultation.GetConsultationTypeFail,
    returnResult: true,

    asyncFn: async (requestPayload: ActionInputs['GetConsultationTypeDetails']) => {
      const response = await ConsultationService.GetConsultationType(requestPayload.consultationCode);
      const consultationTypeData: IConsultationType = {
        consultationTypeId: response?.data?.id,
        name: response?.data?.code === CONSULTATION_TYPE.TH ? 'Counselling Session' : response?.data?.name,
        description: response?.data?.description,
        consultationCode: response?.data?.code,
        duration: response?.data?.config?.duration,
        staticPrice: transformAmount(response?.data?.price?.sellingPrice),
      };
      return response?.status === 200
        ? { result: { consultationTypeData }, type: 'success' }
        : { result: {}, type: 'fail' };
    },
  }),
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetConsultationData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Consultation.ResetRescheduleConsultation,
    });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetConsultationTypeData: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ActionConsts.Consultation.ResetConsultationTypeData,
    });
  },
};
