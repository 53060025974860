import Image from 'next/image';

const ChatbotNudgeHeader = ({ toggleChat }) => {
  return (
    <div
      className='self-stretch p-5 flex justify-between items-center'
      style={{ height: '10px', paddingLeft: '0px', paddingRight: '0px' }}>
      <div className='w-10 rounded-full border-0 flex justify-center items-center'>
        <Image src={'/assets/svgs/sexpertai-main.svg'} alt='chat-icon' height={40} width={40} />
      </div>
      <div
        className='w-10 h-10 rounded-full border-0 flex justify-center items-center cursor-pointer'
        onClick={toggleChat}>
        <Image src={'/assets/svgs/cross.svg'} alt='close-icon' height={20} width={20} />
      </div>
    </div>
  );
};

export default ChatbotNudgeHeader;
