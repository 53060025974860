export const removeSpecialCharacters = (str) => {
  return str.replace(/[^ A-Za-z]/g, '');
};

export const underScoreToCamelCase = (str: string) => {
  return str.replace(/_([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
};

// Function to generate a random index based on the length of the character set
const getRandomIndex = (charSetLength: number) => {
  const randomBuffer = new Uint8Array(1);
  window.crypto.getRandomValues(randomBuffer);
  return randomBuffer[0] % charSetLength;
};

export const generateRandomPassword = (length: number = 10) => {
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialCharacters = '!@#$%^&*()_+-=[]{}|;:<>?,.';
  const allCharacters = uppercaseLetters + lowercaseLetters + numbers + specialCharacters;

  // Ensure at least one character from each category
  let randomString =
    uppercaseLetters[getRandomIndex(uppercaseLetters.length)] +
    lowercaseLetters[getRandomIndex(lowercaseLetters.length)] +
    numbers[getRandomIndex(numbers.length)] +
    specialCharacters[getRandomIndex(specialCharacters.length)];

  // Fill the rest of the string length with random characters from all categories
  for (let i = 4; i < length; i++) {
    randomString += allCharacters[getRandomIndex(allCharacters.length)];
  }

  // Shuffle the string to ensure randomness
  randomString = [...randomString].sort(() => 0.5 - crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32).join('');

  return randomString;
};

export const formatPhoneNumber = (phone: string): string => {
  return `+91${phone?.replace(/^\+91-|^\+91/g, '')}`;
};
