import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/index';
import { AuthenticationService } from '@Services/index';
import { LOG_ERROR } from '@Utils/logger';

export const AuthActions = {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  InitAuthState: () => async (dispatch: Dispatch) => {
    try {
      const user = await AuthenticationService.InitAuthState();
      dispatch({
        type: ActionConsts.Authentication.InitAuthStateSuccess,
        user,
      });
    } catch (error) {
      if (error?.code === 'NetworkError') return;
      const referenceData = { error };
      LOG_ERROR('Error: AuthActions>>AuthenticationService>>InitAuthState', referenceData);
      dispatch({ type: ActionConsts.Authentication.InitAuthStateFail });
    }
  },
  SendOtp: (requestPayload: any) => async (dispatch: Dispatch) => {
    try {
      return await AuthenticationService.SendOtp(requestPayload);
    } catch (error) {
      throwSendOtpError(error);
      if (error?.code === 'NetworkError') return;
      const referenceData = { error };
      LOG_ERROR('Error: AuthActions>>AuthenticationService>>SendOtp', referenceData);
      dispatch({ type: ActionConsts.Authentication.LoginFail });
    }
  },
  VerifyOtp: (requestPayload: any) => async (dispatch: Dispatch) => {
    try {
      const res = await AuthenticationService.VerifyOtp(requestPayload);
      if (res?.hasOwnProperty('name'))
        dispatch({
          type: ActionConsts.Authentication.UserAuthenticatedWithVerifiedOtp,
          user: res,
        });
      return res;
    } catch (error) {
      const referenceData = { error, phone: requestPayload.phone };
      if (
        error.code !== 'NetworkError' &&
        error.code !== 'NotAuthorizedException' &&
        error.message !== 'Incorrect OTP. Please try again.' &&
        error.message !== 'OTP expired. Please enter a new otp.'
      ) {
        const errorMsg = 'Error: AuthActions>>AuthenticationService>>VerifyOtp';
        LOG_ERROR(errorMsg, referenceData);
      }
      dispatch({ type: ActionConsts.Authentication.LoginFail });
      throwVerifyOtpError(error);
    }
  },
  Logout: (requestPayload: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionConsts.Authentication.Logout });
      await AuthenticationService.Logout(requestPayload);
    } catch (error) {
      if (error.code === 'NetworkError') return;
      const referenceData = { error };
      LOG_ERROR('Error: AuthActions>>AuthenticationService>>Logout', referenceData);
      dispatch({ type: ActionConsts.Authentication.LogoutFail });
    }
  },
};

const throwVerifyOtpError = (error) => {
  let errorMessage: string;
  switch (error.code) {
    case 'NotAuthorizedException':
      errorMessage = 'Incorrect OTP';
    default:
      errorMessage = error?.description ?? error?.message;
  }

  throw new Error(errorMessage);
};

const throwSendOtpError = (error) => {
  const errorMessage = error?.description ?? error?.message;
  throw new Error(errorMessage);
};
