import { FontType, ColorType } from '@Components/common-exports';
import Text from '@Components/design-system/text';
import { useTrans } from '@Utils/hooks/translation';

interface SchedulingSectionHeaderProps {
  heading: string;
  classes?: string;
  isThemeDark: boolean;
}

const SchedulingSectionHeader = ({ heading, classes = '', isThemeDark }: SchedulingSectionHeaderProps) => {
  const { languageUsed } = useTrans();
  return (
    <div className={'flex gap-2 ' + classes}>
      <Text
        font={FontType.REGULAR_14_150}
        color={isThemeDark ? ColorType.BLUE_GREY_75 : ColorType.GREYS_900}
        language={languageUsed}
        className='whitespace-nowrap'>
        {heading}
      </Text>
      <div className='flex w-full items-center'>
        <div className='w-1  overflow-hidden inline-block'>
          <div className={`h-2 ${isThemeDark ? 'bg-white' : 'bg-GREYS_900'} rotate-45 transform origin-top-left`} />
        </div>
        <div className={`w-full h-px ${isThemeDark ? 'bg-white' : 'bg-GREYS_900'} -ml-1`} />
      </div>
    </div>
  );
};

export default SchedulingSectionHeader;
