import Image from 'next/image';
import { useState } from 'react';

import Accordion from '@Components/accordion';
import { FontType, ColorType } from '@Components/common-exports';
import Text from '@Components/design-system/text';
import { IAilments } from '@Reducers/encounter';
import { formatName } from '@Utils/helper';
import { categorizeMetadata, getFilteredAilments } from '@Utils/Helpers/AssessmentReport/helper';
import { useTrans } from '@Utils/hooks/translation';

import AilmentSummary from './ailment-summary';

const CausesSection = ({
  heading,
  ailments,
  problem,
  handleBooking,
  metadata,
}: {
  heading: string;
  ailments: IAilments[];
  problem: any;
  handleBooking: () => void;
  metadata: Record<string, any[]>;
}) => {
  const { t, languageUsed } = useTrans('ax');
  const [closeModal, setCloseModal] = useState(false);
  const [viewInfo, setViewInfo] = useState(false);
  const handleViewInfo = (causeIndex) => {
    setViewInfo((prevIndex) => (prevIndex === causeIndex ? undefined : causeIndex));
  };

  const handleCloseModal = () => {
    setCloseModal(!closeModal);
  };
  const causes = getFilteredAilments(ailments, 'cause', problem?.medicalCondition?.id);

  return (
    <main>
      <Accordion
        expanded={true}
        className='px-4'
        backgroundColor={''}
        header={
          <Text font={FontType.SEMIBOLD_18_150} color={ColorType.GREYS_900} language={languageUsed}>
            {heading}
          </Text>
        }
        headerClasses='py-3 px-1'
        openComponent={
          <Image
            src='/assets/svgs/chevron-black.svg'
            width={28}
            height={28}
            alt='allo-icon'
            objectFit='contain'
            className='rotate-90'
          />
        }
        closeComponent={
          <Image
            src='/assets/svgs/chevron-black.svg'
            width={28}
            height={28}
            alt='allo-icon'
            objectFit='contain'
            className='-rotate-90'
          />
        }
        mobile={true}>
        <ol className='list-decimal list-inside bg-BLUE_GREY_50 flex flex-col gap-2 mt-2 mb-6'>
          {causes?.map((cause, idx) => (
            <div
              key={cause?.id}
              className='flex justify-between py-3 border-b border-b-GREYS_400 rounded-b-lg'
              onClick={() => handleViewInfo(cause?.id)}>
              <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_900} language={languageUsed}>
                {idx + 1}.&nbsp;
                {cause?.ailment?.languageNameMap?.[languageUsed] || cause?.ailment?.name}
                {cause?.description && (
                  <span className='text-xs bg-ORANGE_YELLOW_300 whitespace-nowrap h-5 px-1 rounded-lg'>
                    {t('your-value')} {formatName(cause?.description)}
                  </span>
                )}
              </Text>
              <button className='flex items-center whitespace-nowrap'>
                <Text font={FontType.REGULAR_10_170} color={ColorType.INDIGO_600}>
                  {t('know-more')}
                </Text>
                <Image
                  src='/assets/svgs/chevron-indigo.svg'
                  alt='allo-icon'
                  width={10}
                  height={10}
                  className='-rotate-90 mt-0.5'
                />
              </button>
              {viewInfo === cause?.id && (
                <AilmentSummary
                  ailment={cause}
                  closeModal={handleCloseModal}
                  metadata={categorizeMetadata(metadata?.[cause?.ailment?.id], 'metadataType')}
                  handleBooking={handleBooking}
                />
              )}
            </div>
          ))}
        </ol>
      </Accordion>
    </main>
  );
};

export default CausesSection;
