import Image from 'next/image';

import SectionHeader from '@Components/core/section-header';
import { Button } from '@Components/design-system/button/button';
import { colors } from '@Components/design-system/color';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { SexpertAILink } from '@Utils/constants';
import { useTrans } from '@Utils/hooks/translation';

const AskSexpertAI = () => {
  const { t, languageUsed } = useTrans();
  return (
    <div>
      <div className='flex flex-col gap-2'>
        <Text font={FontType.REGULAR_14_150} color={ColorType.PRIMARY_600} language={languageUsed}>
          {t('sexpert-ai.have-questions')}
        </Text>
        <SectionHeader title={t('sexpert-ai.title')} />
        <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_700} language={languageUsed}>
          {t('sexpert-ai.description')}
        </Text>
      </div>
      <div className='mt-4 px-4 py-8 bg-indigo-950 rounded-2xl shadow gap-4'>
        <div className='relative'>
          <div className='flex flex-col gap-4'>
            <div className='mx-auto'>
              <Image
                className='right-0 bottom-0'
                src={'/assets/svgs/allo-sexpert.svg'}
                width={48}
                height={48}
                layout='fixed'
                alt='allo logo'
              />
            </div>
            <Text font={FontType.SEMIBOLD_24_150} color={ColorType.WHITE} className='text-center'>
              Allo SexpertAI
            </Text>
          </div>
          <div className='pt-6 flex flex-col'>
            <Button
              label={
                <Text language={languageUsed} font={FontType.SEMIBOLD_14_150} color={ColorType.INDIGO_900}>
                  {t('sexpert-ai.chat-now')}
                </Text>
              }
              labelColour={colors[ColorType.INDIGO_900]}
              className='mx-auto'
              primaryColour={colors[ColorType.WHITE]}
              onPress={() => {
                window.open(SexpertAILink);
              }}
              fullWidth={true}
              size='large'
              align='center'
              borderRadius={50}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskSexpertAI;
