import { ActionConsts } from '@Definitions/ActionConsts';

const INITIAL_STATE = {
  uploadFile: undefined,
  patientFiles: [],
  isLoading: false,
};

export interface IPatientLabFiles {
  patientId: string;
  name: string;
  remarks: string;
  updatedAt: string;
  fileId: string;
  fileType: string;
}

export interface IUploadFile {
  file: File;
  attachmentType: string;
  fileName?: string;
  remarks?: string;
}

export const FileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionConsts.File.UploadFileInit:
      return {
        ...state,
        uploadFile: INITIAL_STATE.uploadFile,
        isLoading: true,
      };
    case ActionConsts.File.UploadFileSuccess:
      return {
        ...state,
        uploadFile: action.uploadFile,
        isLoading: false,
      };
    case ActionConsts.File.ResetFileUploads:
    case ActionConsts.File.UploadFileFail:
      return {
        ...state,
        uploadFile: INITIAL_STATE.uploadFile,
        isLoading: false,
      };
    case ActionConsts.File.GetPatientfileInit:
      return {
        ...state,
        patientFiles: [],
        isLoading: true,
      };
    case ActionConsts.File.GetPatientfileSuccess:
      return {
        ...state,
        patientFiles: action.files,
        isLoading: false,
      };
    case ActionConsts.File.GetPatientfileFail:
      return {
        ...state,
        patientFiles: [],
        isLoading: false,
      };
    default:
      return state;
  }
};
