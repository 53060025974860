import dayjs from 'dayjs';
import Image from 'next/image';

import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { IAppointments } from '@Reducers/checkout';
import { IQueryData } from '@Reducers/common';
import { CONSULTATION_STATE, PAGE } from '@Utils/constants';
import useCustomRouter from '@Utils/hooks/custom-router';

import { ColorType } from './design-system/color/types';

export const ReconsultWidget = ({
  appointment,
  appointmentDate,
  queryData,
}: {
  appointment: IAppointments;
  appointmentDate: string;
  queryData?: IQueryData;
}) => {
  const customRouter = useCustomRouter();
  const rescheduleRedirection = () => {
    customRouter({
      pathname: PAGE.APPOINTMENT.ROUTE,
      query: {
        appointment_state: CONSULTATION_STATE.RECONSULT,
        form_type: 'DIRECT_LINK',
        consultation_id: appointment?.consultationId,
        provider_id: appointment?.providerId,
        product_code: appointment?.consultationCode,
        selected_languages: queryData?.selectedLanguages,
        valid_user_city: queryData?.validUserCity,
        ...(queryData?.ipxId && { ipx_id: queryData?.ipxId }),
      },
    });
  };
  return (
    <>
      <div className='flex justify-self-start gap-2 col-span-2'>
        <div className='h-full flex flex-col justify-start items-start'>
          <Image src={'/assets/svgs/calendar.svg'} width={24} height={24} alt='allo-icon' />
        </div>
        <div>
          <Text className='pb-1' font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900}>
            {appointmentDate && dayjs(appointmentDate).format('ddd, DD MMM, YYYY')}
          </Text>
          <Text className='flex justify-start items-center' font={FontType.REGULAR_10_170} color={ColorType.GREYS_900}>
            {appointmentDate && dayjs(appointmentDate).format('h:mm A')}
          </Text>
        </div>
      </div>
      <div onClick={rescheduleRedirection} className='flex justify-self-end items-center gap-1'>
        <Text font={FontType.SEMIBOLD_12_170} color={ColorType.GREYS_900}>
          Reconsult
        </Text>
        <Image src={'/assets/svgs/chevron-right-purple.svg'} width={8} height={8} alt='allo-icon' />
      </div>
    </>
  );
};
