import { Dispatch } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/ActionConsts';

export const ToggleActions = {
  ToggleData: (updatePayload) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionConsts.Toggle.ToggleData, payload: updatePayload });
  },
  // eslint-disable-next-line unicorn/consistent-function-scoping
  ResetToggleData: () => async (dispatch: Dispatch) => {
    dispatch({ type: ActionConsts.Toggle.ResetToggleData });
  },
};
