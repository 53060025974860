import Image from 'next/image';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { useTrans } from '@Utils/hooks/translation';

const getLikelinessTheme = (likeliness: string) => {
  switch (likeliness) {
    case 'Highly Likely':
      return ' bg-ERROR_75 border-ERROR_700';
    case 'Likely':
    case 'Less Likely':
      return ' bg-ATTENTION_50 border-ATTENTION_400';
    default:
      return ' bg-SUCCESS_50 border-SUCCESS_200';
  }
};

interface ILikelinessData {
  likeliness: string;
  subheader: string;
  image: string;
  description: string;
}

export default function LikelinessCard({ section, diagnosis }: { section: ILikelinessData; diagnosis: string }) {
  const { t, languageUsed } = useTrans();
  return (
    section?.likeliness &&
    diagnosis && (
      <div className={'p-2 mb-4 border rounded' + getLikelinessTheme(section?.likeliness)}>
        <Text className='mb-2' font={FontType.SEMIBOLD_10_170} color={ColorType.GREYS_900}>
          YOUR DIAGNOSIS
        </Text>
        <Text className='mb-2' font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900} language={languageUsed}>
          {section?.subheader.replace('diagnosis', diagnosis)}
        </Text>
        <Text className='mb-2' font={FontType.REGULAR_14_150} color={ColorType.GREYS_900} language={languageUsed}>
          {section?.description}
        </Text>
        {section?.image && <Image src={`/assets/${section?.image}`} width={288} height={48} alt='allo-icon' />}
      </div>
    )
  );
}
