import { useEffect, useRef, useState } from 'react';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { useTrans } from '@Utils/hooks/translation';

const AvailableOptions = ({
  availableOptions,
  optionDetails,
  defaultSelectedOption,
  onSelectionChange,
  filterProp,
}: {
  availableOptions: string[];
  optionDetails: any[];
  defaultSelectedOption: string;
  onSelectionChange: (any) => void;
  filterProp: string;
}) => {
  const { languageUsed } = useTrans();
  const [selectedCategory, setSelectedCategory] = useState(defaultSelectedOption);
  const selectedCategoryRef = useRef(null);
  useEffect(() => {
    if (selectedCategoryRef.current)
      selectedCategoryRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
  }, [selectedCategory]);

  const handleCategorySelection = (category: string) => {
    category === selectedCategory ? setSelectedCategory('') : setSelectedCategory(category);
    onSelectionChange(
      category && selectedCategory !== category
        ? optionDetails?.filter((detail) => detail?.badge === category || detail?.label === category)
        : optionDetails,
    );
  };

  const checkIfCategorySelected = (category: string) => category === selectedCategory;
  return (
    <main>
      <div className='overflow-x-scroll flex gap-3 pb-4'>
        {availableOptions &&
          availableOptions.length > 0 &&
          availableOptions?.map(
            (option) =>
              option.length > 0 && (
                <div key={option} ref={checkIfCategorySelected(option) ? selectedCategoryRef : undefined}>
                  <Text
                    font={checkIfCategorySelected(option) ? FontType.SEMIBOLD_11_170 : FontType.REGULAR_11_170}
                    color={checkIfCategorySelected(option) ? ColorType.GREYS_100 : ColorType.GREYS_600}
                    className={
                      'rounded-full py-1 px-3 border text-GREYS_100 border-GREYS_400 hover:bg-GREYS_900 hover:text-white cursor-pointer whitespace-nowrap' +
                      (checkIfCategorySelected(option) ? ' bg-GREYS_900' : '')
                    }
                    language={languageUsed}
                    onClick={() => handleCategorySelection(option)}>
                    {option}
                  </Text>
                </div>
              ),
          )}
      </div>
    </main>
  );
};

export default AvailableOptions;
