import Image from 'next/image';
import { useState } from 'react';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { SentenceCase, formatName } from '@Utils/helper';
import { getReasons, categorizeMetadata, filterTags } from '@Utils/Helpers/AssessmentReport/helper';
import { useTrans } from '@Utils/hooks/translation';

import AilmentSummary from './ailment-summary';

const IssueDetails = ({ issueDetails, metadata, handleBooking }) => {
  const { t, languageUsed } = useTrans('ax');
  const [viewInfo, setViewInfo] = useState(false);
  const handleViewInfo = () => {
    setViewInfo(!viewInfo);
  };
  const metadataByType = categorizeMetadata(metadata?.[issueDetails?.ailment?.id], 'metadataType');
  const tags = filterTags(metadataByType?.['tags']);
  const affectsExisits = metadataByType?.['affects']?.length > 0;
  return (
    <>
      {viewInfo && affectsExisits && (
        <AilmentSummary
          ailment={issueDetails}
          closeModal={handleViewInfo}
          metadata={metadataByType}
          handleBooking={handleBooking}
        />
      )}
      <main
        onClick={handleViewInfo}
        className='flex flex-col justify-between rounded-lg border-2 border-GREYS_400 bg-white overflow-hidden relative'>
        <section className='p-3'>
          <Text className='mb-4' font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900} language={languageUsed}>
            {SentenceCase(issueDetails?.ailment?.languageNameMap?.[languageUsed] || issueDetails?.ailment?.name)}
          </Text>
          {tags?.length > 0 && (
            <>
              <div className='flex gap-1 mb-3'>
                <Text font={FontType.SEMIBOLD_11_170} color={ColorType.COOL_GREY_500} language={languageUsed}>
                  {t('contributing-factors')}
                </Text>
              </div>
              <div className='flex flex-wrap gap-3 mb-4 whitespace-nowrap'>
                {tags?.slice(0, 2)?.map((reason) => (
                  <div key={reason?.description} className='flex px-1.5 py-1 gap-0.5 rounded-full bg-ROSE_50'>
                    {getReasons(reason?.description?.toLowerCase()) && (
                      <div className='w-4'>
                        <Image
                          src={getReasons(reason?.description?.toLowerCase())}
                          alt='allo-icon'
                          width={16}
                          height={16}
                        />
                      </div>
                    )}
                    <Text font={FontType.REGULAR_10_170} color={ColorType.GREYS_900} language={languageUsed}>
                      {formatName(reason?.description)}
                    </Text>
                  </div>
                ))}
                {tags?.length > 2 && (
                  <Text className='flex items-center' font={FontType.SEMIBOLD_11_170} color={ColorType.INDIGO_600}>
                    +{tags?.length - 2}
                  </Text>
                )}
              </div>
              <div className='flex items-center'>
                <Text font={FontType.REGULAR_11_170} color={ColorType.INDIGO_600}>
                  {t('see-more')}
                </Text>
                <Image
                  src='/assets/svgs/chevron-indigo.svg'
                  alt='allo-icon'
                  width={12}
                  height={12}
                  className='-rotate-90 mt-0.5'
                />
              </div>
            </>
          )}
        </section>
        {issueDetails?.description && (
          <section className='px-4 py-2 bg-ORANGE_YELLOW_50 w-full'>
            <Text
              font={FontType.REGULAR_11_170}
              color={ColorType.GREYS_700}
              language={languageUsed}
              className='opacity-80'>
              {t('your-value')}
            </Text>
            <Text font={FontType.SEMIBOLD_11_170} color={ColorType.ORANGE_YELLOW_900} language={languageUsed}>
              {formatName(issueDetails?.description)}
            </Text>
          </section>
        )}
      </main>
    </>
  );
};

export default IssueDetails;
