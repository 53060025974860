import { ActionConsts } from '@Definitions/index';
import { IConsultationOrder } from '@Reducers/order';
import { AppointmentStatus } from '@Reducers/schedule';
import { CONSULTATION_CODE_DEFAULT, CONSULTATION_NAME_DEFAULT } from '@Utils/constants';

export interface IRescheduleConsultationData {
  status?: AppointmentStatus;
  consultationId?: string;
  date?: string;
  consultationType?: string;
  providerName?: string;
  isLoading?: boolean;
}

export interface IPurchasedConsultationData {
  consultationId: string;
  consultationType: string;
  appointmentStatus: AppointmentStatus;
  providerName: string;
  date: string;
  locationId: string;
  isLoading?: boolean;
}

export interface IConsultationType {
  consultationTypeId: string;
  name: string;
  description: string;
  consultationCode: string;
  staticPrice?: number;
  duration?: number;
  isLoading?: boolean;
}

export interface IConsultation {
  rescheduleConsultationData: IRescheduleConsultationData;
  consultationType: IConsultationType;
  consultationOrder?: IConsultationOrder;
}

const DEFAULT = {
  consultationData: {
    consultationTypeId: '',
    consultationCode: CONSULTATION_CODE_DEFAULT,
    name: CONSULTATION_NAME_DEFAULT,
    description: '',
    duration: undefined,
    isLoading: undefined,
  },
};

const INITIAL_STATE: IConsultation = {
  rescheduleConsultationData: {},
  consultationType: DEFAULT.consultationData,
};

export const ConsultationReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Consultation.GetConsultationInit:
      return {
        ...state,
        rescheduleConsultationData: {
          isLoading: true,
          ...INITIAL_STATE.rescheduleConsultationData,
        },
      };
    case ActionConsts.Consultation.GetConsultationSuccess:
      return {
        ...state,
        rescheduleConsultationData: {
          isLoading: false,
          ...action.rescheduleConsultationData,
        },
      };
    case ActionConsts.Consultation.ResetRescheduleConsultation:
    case ActionConsts.Consultation.GetConsultationFail:
      return {
        ...state,
        rescheduleConsultationData: {
          isLoading: false,
          ...INITIAL_STATE.rescheduleConsultationData,
        },
      };
    case ActionConsts.Consultation.GetConsultationTypeInit:
      return {
        ...state,
        consultationType: {
          isLoading: true,
          ...DEFAULT.consultationData,
        },
      };
    case ActionConsts.Consultation.GetConsultationTypeSuccess:
      return {
        ...state,
        consultationType: {
          isLoading: false,
          ...action.consultationTypeData,
        },
      };
    case ActionConsts.Consultation.GetConsultationTypeFail:
      return {
        ...state,
        consultationType: {
          isLoading: false,
          ...DEFAULT.consultationData,
        },
      };
    case ActionConsts.Consultation.ResetConsultationTypeData:
      return {
        ...state,
        consultationType: DEFAULT.consultationData,
      };
    default:
      return state;
  }
};
