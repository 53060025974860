import styled from 'styled-components';

const ChatbotContainer = styled.div`
  position: fixed;
  bottom: 95px;
  right: 20px;
  width: 423px;
  max-height: 80%;
  height: 0; /* Start with 0 height */
  opacity: 0; /* Start fully transparent */
  background: white;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: height 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transform-origin: bottom right; /* Ensure growth starts from the bottom right */

  &.open {
    height: 723px; /* Full height when open */
    opacity: 1; /* Fully visible when open */
  }

  @media (max-width: 600px) {
    bottom: 0;
    right: 0;
    width: 100%; /* Full width */
    height: 0; /* Adjusted height */
    max-height: 100%;
    border-radius: 0;

    &.open {
      height: 100%; /* Full height when open */
    }
  }
`;

export default ChatbotContainer;
