import Image from 'next/image';
import { useMemo, useState } from 'react';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { LanguageCode } from '@Reducers/metadata';
import { IPrice } from '@Reducers/patient';
import { IClinicLocationsData, ISlot, ISlotData } from '@Reducers/schedule';
import { useTrans } from '@Utils/hooks/translation';

import SlotListingModal from './slot-listing-modal';

const Slots = ({
  isOffline,
  slotsData,
  handleLaunchLanguageModal,
  handleBookAppointment,
  handleRedirection,
  isLoading,
  offlineClinicLocations,
  isDarkTheme,
  locationData,
  price,
  changeLocation,
  setConsultationModeToOnline,
  hasSelectedLanguages,
  selectedSlot,
  setSelectedSlot,
  selectedDate,
  setSelectedDate,
}: {
  isOffline: boolean;
  slotsData: ISlotData;
  handleLaunchLanguageModal: () => void;
  handleBookAppointment: (selectedSlot: any, payOnArrival: boolean) => void;
  handleRedirection: () => void;
  isLoading: boolean;
  offlineClinicLocations: Record<string, IClinicLocationsData[]>;
  price: IPrice;
  isDarkTheme: boolean;
  locationData: IClinicLocationsData;
  changeLocation: (locationCode: string) => void;
  setConsultationModeToOnline: () => void;
  hasSelectedLanguages: boolean;
  selectedSlot: ISlot;
  setSelectedSlot: (slot: ISlot) => void;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
}) => {
  const { t, languageUsed } = useTrans('ax');
  const [showclinicCity, setShowClinicCity] = useState(false);

  const handleSlotChange = (slot) => {
    if (!hasSelectedLanguages && !isOffline) {
      handleLaunchLanguageModal();
      return;
    }
    setSelectedSlot(slot);
  };

  const allSlotDates = useMemo(() => {
    return Object.keys(slotsData || {});
  }, [slotsData]);

  return (
    <>
      {isOffline && (
        <div className='w-full h-38 flex-col justify-start items-start gap-4 inline-flex pt-4'>
          <div
            className='flex gap-1 items-center cursor-pointer'
            onClick={() => setShowClinicCity(!showclinicCity)}
            onKeyDown={() => setShowClinicCity(!showclinicCity)}>
            {languageUsed === LanguageCode.Hindi && (
              <>
                <Text font={FontType.SEMIBOLD_18_150} color={isDarkTheme ? ColorType.GREYS_100 : ColorType.GREYS_900}>
                  {locationData?.city}
                </Text>
                <Image src='/assets/svgs/chevron-down-yellow.svg' alt='location' width={16} height={16} />
              </>
            )}
            <Text
              font={FontType.REGULAR_18_150}
              color={isDarkTheme ? ColorType.GREYS_100 : ColorType.GREYS_900}
              language={languageUsed}>
              {t('appointment-scheduling.clinics-in')}
            </Text>
            {languageUsed === LanguageCode.English && (
              <>
                <Text font={FontType.SEMIBOLD_18_150} color={isDarkTheme ? ColorType.GREYS_100 : ColorType.GREYS_900}>
                  {locationData?.city}
                </Text>
                <Image src='/assets/svgs/chevron-down-yellow.svg' alt='location' width={16} height={16} />
              </>
            )}
          </div>
          <div className='ml-20 mt-7 absolute z-30 '>
            {showclinicCity && Object.keys(offlineClinicLocations)?.length > 0 && (
              <div className='bg-GREYS_100 flex flex-col rounded-md border border-GREYS_300 w-36 py-2 shadow-lg '>
                {Object.keys(offlineClinicLocations)?.map((offlineCity) => (
                  <Text
                    key={offlineCity}
                    font={FontType.REGULAR_14_150}
                    color={ColorType.GREYS_900}
                    onClick={() => {
                      changeLocation(offlineClinicLocations?.[offlineCity]?.[0]?.code);
                      setShowClinicCity(false);
                    }}
                    className={`${locationData?.city === offlineCity && 'bg-BLUE_GREY_200'} p-2.5 cursor-pointer`}>
                    {offlineCity}
                  </Text>
                ))}
              </div>
            )}
          </div>
          <div className='flex gap-2 w-full overflow-scroll'>
            {offlineClinicLocations?.[locationData?.city]?.map((clinic) => (
              <div
                className={`border ${
                  isDarkTheme ? 'border-GREYS_100' : 'border-GREYS_500'
                } rounded-full text-center px-4 py-1.5
                  ${clinic?.id === locationData?.id ? 'bg-PRIMARY_600 border-none' : ''}
                  `}
                key={clinic?.id}
                onClick={() => {
                  changeLocation(clinic?.code);
                }}>
                <Text
                  color={isDarkTheme || clinic?.id === locationData?.id ? ColorType.GREYS_100 : ColorType.GREYS_800}
                  font={clinic?.id === locationData?.id ? FontType.REGULAR_14_150 : FontType.REGULAR_12_170}
                  className='whitespace-nowrap'>
                  {clinic?.locality}
                </Text>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className='my-6'>
        <SlotListingModal
          slotsData={slotsData}
          selectedSlot={selectedSlot}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          allSlotDates={allSlotDates}
          handleBookAppointment={handleBookAppointment}
          handleSlotChange={handleSlotChange}
          isOffline={isOffline}
          isLoading={isLoading}
          isDarkTheme={isDarkTheme}
          handleLaunchLanguageModal={handleLaunchLanguageModal}
          handleRedirection={handleRedirection}
          setConsultationModeToOnline={setConsultationModeToOnline}
          price={price}
          locationData={locationData}
        />
      </div>
    </>
  );
};

export default Slots;
