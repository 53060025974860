import { ActionConsts } from '@Definitions/index';

export interface IAnalytics {
  leadData: any;
  isLoading: boolean;
  fbPixelData: any;
}

const INITIAL_STATE: IAnalytics = {
  leadData: {},
  fbPixelData: {},
  isLoading: false,
};

export const AnalyticsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Analytics.GetLeadInit:
    case ActionConsts.Analytics.UpdateLeadInit:
    case ActionConsts.Analytics.AddLeadActivityInit:
    case ActionConsts.Analytics.CreateOrUpdateLeadInit:
      return {
        ...state,
        leadData: INITIAL_STATE.leadData,
        isLoading: true,
      };
    case ActionConsts.Analytics.GetLeadSuccess:
    case ActionConsts.Analytics.UpdateLeadSuccess:
    case ActionConsts.Analytics.AddLeadActivitySuccess:
    case ActionConsts.Analytics.CreateOrUpdateLeadSuccess:
      return {
        ...state,
        leadData: action.leadData,
        isLoading: false,
      };
    case ActionConsts.Analytics.GetLeadFail:
    case ActionConsts.Analytics.UpdateLeadFail:
    case ActionConsts.Analytics.AddLeadActivityFail:
    case ActionConsts.Analytics.CreateOrUpdateLeadFail:
      return {
        ...state,
        leadData: INITIAL_STATE.leadData,
        isLoading: false,
      };
    case ActionConsts.Analytics.PostFbPixelEventInit:
      return {
        ...state,
        fbPixelData: INITIAL_STATE.fbPixelData,
        isLoading: true,
      };
    case ActionConsts.Analytics.PostFbPixelEventSuccess:
      return {
        ...state,
        fbPixelData: action.fbPixelData,
        isLoading: false,
      };
    case ActionConsts.Analytics.PostFbPixelEventFail:
      return {
        ...state,
        fbPixelData: INITIAL_STATE.fbPixelData,
        isLoading: false,
      };
    default:
      return state;
  }
};
