import { LEAD_TO_CALL_STATES } from '@Components/design-system/message-card';
import { IUserData, PatientStatus } from '@Reducers/user';
import { PatientService } from '@Services/index';
import { UpdatePatientPayload } from '@Services/Patient/patient-payload';
import { UserRole } from '@Utils/constants';
import { formatName } from '@Utils/helper';

const transformPreferredLanguages = (languages): string[] => {
  return [...new Set(languages?.split(',')?.map((language) => language?.trim()) || [])] as string[];
};

export const getPatientName = (patient: IUserData) => {
  return patient?.verifiedName
    ? formatName(patient?.verifiedName)
    : patient?.firstName
    ? `${formatName(patient?.firstName)} ${patient?.lastName ? formatName(patient?.lastName) : ''}`
    : 'Not Available';
};

export const updateUserBasedOnIpxId = async (payload: UpdatePatientPayload, role: UserRole) => {
  const { ipxId, ...requestPayload } = payload;
  let result: IUserData;
  const allowedToAct: boolean = (role === UserRole.PROVIDER || role === UserRole.AGENT) && Boolean(ipxId);
  if (allowedToAct) {
    const getUserResponse = await PatientService.UpdatePatient(requestPayload);
    const personalityUpdateResponse = await PatientService.GetXPatient(ipxId);
    if (
      getUserResponse?.status === 201 ||
      personalityUpdateResponse.status === 200 ||
      getUserResponse?.status === 200
    ) {
      result = {
        id: personalityUpdateResponse?.data?.id,
        firstName: personalityUpdateResponse?.data?.firstName,
        lastName: personalityUpdateResponse?.data?.lastName,
        verifiedName: personalityUpdateResponse?.data?.verifiedName,
        phoneNumber: personalityUpdateResponse?.data?.phoneNumber,
        email: personalityUpdateResponse?.data?.email,
        gender: personalityUpdateResponse?.data?.gender,
        age: personalityUpdateResponse?.data?.age,
        therapistId: personalityUpdateResponse?.data?.therapistId,
        physicianId: personalityUpdateResponse?.data?.physicianId,
        birthDate: personalityUpdateResponse?.data?.birthDate,
        preferredLanguage: transformPreferredLanguages(
          addDefaultLanguage(
            personalityUpdateResponse?.data?.status,
            personalityUpdateResponse?.data?.preferredLanguage,
          ),
        ).slice(0, 1),
        defaultLanguageAdded: shouldAddDefaultLanguage(
          personalityUpdateResponse?.data?.status,
          personalityUpdateResponse?.data?.preferredLanguage,
        ),
        status: personalityUpdateResponse?.data?.status,
        createdAt: personalityUpdateResponse?.data?.createdAt,
        city: personalityUpdateResponse?.data?.city,
        bmi: personalityUpdateResponse?.data?.bmi ? Number.parseInt(personalityUpdateResponse?.data?.bmi) : undefined,
        weight: personalityUpdateResponse?.data?.weight,
        height: personalityUpdateResponse?.data?.height,
        relationshipStatus: personalityUpdateResponse?.data?.relationshipStatus,
        sexualOrientation: personalityUpdateResponse?.data?.sexualOrientation,
        leadId: personalityUpdateResponse?.data?.leadId,

        sub: getUserResponse?.data?.sub,
        isActor: true,

        actorId: getUserResponse?.data?.id,
        actorFirstName: getUserResponse?.data?.firstName,
        actorLastName: getUserResponse?.data?.lastName,
        actorVerifiedName: getUserResponse?.data?.verifiedName,
        actorPhoneNumber: getUserResponse?.data?.phoneNumber,
        actorEmail: getUserResponse?.data?.email,
        actorGender: getUserResponse?.data?.gender,
        actorAge: getUserResponse?.data?.age,
        actorCity: getUserResponse?.data?.city,
        actorBmi: Number.parseInt(getUserResponse?.data?.bmi),
        actorWeight: getUserResponse?.data?.weight,
        actorHeight: getUserResponse?.data?.height,
        actorRelationshipStatus: getUserResponse?.data?.relationshipStatus,
        actorSexualOrientation: getUserResponse?.data?.sexualOrientation,
      };
    }
    return result;
  }
  const userUpdateResponse = await PatientService.UpdatePatient(requestPayload);
  if (userUpdateResponse?.status === 201 || userUpdateResponse?.status === 200) {
    result = {
      id: userUpdateResponse?.data?.id,
      firstName: userUpdateResponse?.data?.firstName,
      lastName: userUpdateResponse?.data?.lastName,
      verifiedName: userUpdateResponse?.data?.verifiedName,
      phoneNumber: userUpdateResponse?.data?.phoneNumber,
      email: userUpdateResponse?.data?.email,
      gender: userUpdateResponse?.data?.gender,
      age: userUpdateResponse?.data?.age,
      therapistId: userUpdateResponse?.data?.therapistId,
      physicianId: userUpdateResponse?.data?.physicianId,
      birthDate: userUpdateResponse?.data?.birthDate,
      preferredLanguage: transformPreferredLanguages(
        addDefaultLanguage(userUpdateResponse?.data?.status, userUpdateResponse?.data?.preferredLanguage),
      ).slice(0, 1),
      defaultLanguageAdded: shouldAddDefaultLanguage(
        userUpdateResponse?.data?.status,
        userUpdateResponse?.data?.preferredLanguage,
      ),
      status: userUpdateResponse?.data?.status as PatientStatus,
      createdAt: userUpdateResponse?.data?.createdAt,
      sub: userUpdateResponse?.data?.sub,
      city: userUpdateResponse?.data?.city,
      bmi: userUpdateResponse?.data?.bmi ? Number.parseInt(userUpdateResponse?.data?.bmi) : undefined,
      weight: userUpdateResponse?.data?.weight,
      height: userUpdateResponse?.data?.height,
      relationshipStatus: userUpdateResponse?.data?.relationshipStatus,
      sexualOrientation: userUpdateResponse?.data?.sexualOrientation,
      leadId: userUpdateResponse?.data?.leadId,
      isActor: false,
    };
  }
  return result;
};

export const getUserBasedOnIpxId = async (userId: string) => {
  let result: IUserData;
  const userUpdateResponse = await PatientService.GetXPatient(userId);
  if (userUpdateResponse?.status === 201 || userUpdateResponse?.status === 200) {
    result = {
      id: userUpdateResponse?.data?.id,
      firstName: userUpdateResponse?.data?.firstName,
      lastName: userUpdateResponse?.data?.lastName,
      verifiedName: userUpdateResponse?.data?.verifiedName,
      phoneNumber: userUpdateResponse?.data?.phoneNumber,
      email: userUpdateResponse?.data?.email,
      gender: userUpdateResponse?.data?.gender,
      age: userUpdateResponse?.data?.age,
      therapistId: userUpdateResponse?.data?.therapistId,
      physicianId: userUpdateResponse?.data?.physicianId,
      birthDate: userUpdateResponse?.data?.birthDate,
      preferredLanguage: transformPreferredLanguages(
        addDefaultLanguage(userUpdateResponse?.data?.status, userUpdateResponse?.data?.preferredLanguage),
      ).slice(0, 1),
      defaultLanguageAdded: shouldAddDefaultLanguage(
        userUpdateResponse?.data?.status,
        userUpdateResponse?.data?.preferredLanguage,
      ),
      status: userUpdateResponse?.data?.status,
      sub: userUpdateResponse?.data?.sub,
      city: userUpdateResponse?.data?.city,
      bmi: Number.parseInt(userUpdateResponse?.data?.bmi),
      weight: userUpdateResponse?.data?.weight,
      height: userUpdateResponse?.data?.height,
      relationshipStatus: userUpdateResponse?.data?.relationshipStatus,
      sexualOrientation: userUpdateResponse?.data?.sexualOrientation,
      leadId: userUpdateResponse?.data?.leadId,
      isActor: false,
    };
  }
  return result;
};

export const getValidEvaluationState = (evaluationState) => {
  if (Object.values(LEAD_TO_CALL_STATES)?.includes(evaluationState)) {
    return evaluationState;
  } else {
    return LEAD_TO_CALL_STATES.ASSESSMENT_COMPLETED;
  }
};

const addDefaultLanguage = (status, preferredLanguages): string => {
  if (shouldAddDefaultLanguage(status, preferredLanguages)) {
    return 'Hindi';
  }
  return preferredLanguages;
};

const shouldAddDefaultLanguage = (status, preferredLanguages): boolean =>
  status === PatientStatus.Created && !preferredLanguages?.length;
