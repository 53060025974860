import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { LOG_ERROR } from '@Utils/logger';
dayjs.extend(relativeTime);

export function formatDateTime(input, format): string {
  if (dayjs(input).isValid()) {
    return dayjs(input).format(format);
  } else {
    LOG_ERROR('Invalid date time input', input);
    return 'Invalid Date';
  }
}

export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const subtractDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

export function unix(input?: string): number {
  if (dayjs(input).isValid()) {
    return dayjs(input).unix();
  } else {
    LOG_ERROR('Invalid date time input', input);
    return 0;
  }
}

export function fromNow(input): string {
  if (dayjs(input).isValid()) {
    return dayjs(input).fromNow();
  } else {
    LOG_ERROR('Invalid date time input', input);
    return 'Invalid Date';
  }
}

export function formatISOWithoutTimeZone(input: string | any[], format: string) {
  return formatDateTime(input.slice(0, 22), format);
}
