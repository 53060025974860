import React, { useEffect, useState } from 'react';

import useMobileDetect from '../../core/hooks/use-mobile-detect';

export const DeviceContext = React.createContext({
  desktop: false,
  mobile: true,
});

const { Provider } = DeviceContext;

const Responsive = (props) => {
  const [isMobile, setIsMobile] = useState(useMobileDetect(props.userAgent).isMobile());
  const mobile = useMobileDetect(props.userAgent).isMobile();
  useEffect(() => {
    setIsMobile(mobile);
  }, [mobile]);
  return (
    <>
      <div className='flex flex-1 w100'>
        <Provider
          value={{
            mobile: isMobile,
            desktop: !isMobile,
          }}>
          {props.children}
        </Provider>
      </div>
    </>
  );
};

export default Responsive;
