import * as Sentry from '@sentry/nextjs';
import { isNetworkError } from '@Utils/helper';

export function LOG_ERROR(customMessage, error) {
  if (!isNetworkError(error)) {
    Sentry.withScope((scope) => {
      scope.setExtra('data', error);
      Sentry.captureException(customMessage);
    });
  }
}

export function LOG_INFO(customMessage, data) {
  Sentry.withScope((scope) => {
    scope.setExtra('data', data);
    Sentry.captureMessage(customMessage);
  });
}
