export const REDUCER_KEY = 'schedule-direction';

export enum Direction {
  'INITIAL' = 'initial',

  'SELECT_MEDIUM' = 'select medium',
  'SELECT_CITY' = 'select city',
  'SELECT_CLINIC' = 'select clinic',
  'SELECT_LANGUAGE' = 'select language',
  'SELECT_SLOT' = 'select time',
  'CHECKOUT' = 'confirm details',
  'CONFIRM_BOOKING' = 'confirm booking',
}
