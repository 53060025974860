import { ActionConsts } from '@Definitions/index';

const INITIAL_STATE = {
  metadata: {},
  isLoading: false,
};

export enum LanguageCode {
  English = 'en',
  Hindi = 'hi',
}

export const MetadataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionConsts.MetaData.GetMetaDataInit:
      return {
        ...state,
        isLoading: true,
      };
    case ActionConsts.MetaData.GetMetaDataSuccess:
      return {
        ...state,
        metadata: { ...action.metadata },
        isLoading: false,
      };
    case ActionConsts.MetaData.GetMetaDataFail:
      return {
        ...state,
        metadata: INITIAL_STATE.metadata,
        isLoading: false,
      };
    default:
      return state;
  }
};
