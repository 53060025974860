const getMobileDetect = (userAgent: NavigatorID['userAgent']) => {
  const isAndroid = () => Boolean(/android/i.test(userAgent));
  const isIos = () => Boolean(/iphone|ipad|ipod/i.test(userAgent));
  const isSafari = () => Boolean(/safari/i.test(userAgent) && !isChrome());
  const isOpera = () => Boolean(/opera mini/i.test(userAgent));
  const isWindows = () => Boolean(/iemobile/i.test(userAgent));
  const isSSR = () => Boolean(/ssr/i.test(userAgent));
  const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = () => Boolean(!isMobile());
  const isChrome = () => Boolean(/chrome/i.test(userAgent));
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
    isSafari,
  };
};

const useMobileDetect = (uAgent) => {
  const userAgent = typeof navigator === 'undefined' ? uAgent : navigator.userAgent;
  return getMobileDetect(userAgent);
};

export default useMobileDetect;
