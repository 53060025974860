import { ScheduleActions } from '@Actions/ScheduleActions';
import { AlloAuthUser } from '@Components/auth/utils';
import { addDays } from '@Core/dateTime/date-time';
import { IQueryData } from '@Reducers/common';
import { IClinicLocationsData, ISlot, ISlotData } from '@Reducers/schedule';
import { useAppDispatch, useAppSelector } from '@Redux/hooks';
import { checkLanguageModalEligibility, getEndDate, getSessionId } from '@Utils/helper';
import dayjs from 'dayjs';
import { useMemo, useCallback, useRef, useEffect, useState } from 'react';
import { DEFAULT_LOCATION_CODE } from '@Utils/constants';
import { TrackActions } from '@Actions/index';
import { IConsultationType } from '@Reducers/consultation';
import { IUserData } from '@Reducers/user';

const useListSlots = (consultationType: IConsultationType, locationType: IClinicLocationsData) => {
  const dispatch = useAppDispatch();
  const queryData: IQueryData = useAppSelector((state) => state.common.queryData);
  const slots: ISlotData = useAppSelector((state) => state.schedule.slotsData);
  const auth: AlloAuthUser = useAppSelector((state) => state.auth.user);
  const userData: IUserData = useAppSelector((state) => state.user.userData);

  //refs
  const consultationTypeRef = useRef(null);
  consultationTypeRef.current = consultationType;
  const locationTypeRef = useRef(null);
  locationTypeRef.current = locationType;
  const queryDataRef = useRef(null);
  queryDataRef.current = queryData;
  const slotsRef = useRef(null);
  slotsRef.current = slots;

  const [listSlotCalled, setListSlotCalled] = useState<boolean>(false);
  const [shouldListSlots, setShouldListSlots] = useState<boolean>(false);

  const startDate = new Date();
  const endDate = useMemo(() => {
    return addDays(
      new Date(),
      getEndDate(consultationTypeRef.current?.consultationCode, queryDataRef.current?.locationCode, auth?.role),
    );
  }, [consultationTypeRef.current?.consultationCode, queryDataRef.current?.locationCode]);

  const listSlots = useCallback(() => {
    setShouldListSlots(true);
  }, []);

  useEffect(() => {
    if (!shouldListSlots || !consultationType?.consultationTypeId || !locationType?.id) return;
    const eligibleForLanguages = checkLanguageModalEligibility(
      consultationTypeRef.current?.consultationCode,
      userData,
      queryDataRef.current?.locationCode !== DEFAULT_LOCATION_CODE,
      queryDataRef.current?.providerId?.length > 0,
    );
    dispatch(
      ScheduleActions.GetSlots({
        startDate: dayjs(startDate).toISOString(),
        endDate: dayjs(endDate).endOf('day').toISOString(),
        consultationTypeId: consultationTypeRef.current?.consultationTypeId,
        locationId: locationTypeRef.current?.id,
        preferredLanguages: eligibleForLanguages ? queryDataRef.current?.selectedLanguages : undefined,
        providerIds:
          locationTypeRef.current?.code === DEFAULT_LOCATION_CODE ? queryDataRef.current?.providerId : undefined,
        excludedProviderIds:
          locationTypeRef.current?.code === DEFAULT_LOCATION_CODE
            ? queryDataRef.current?.excludedProviderId
            : undefined,
        speciality: queryDataRef.current?.speciality ? queryDataRef.current?.speciality : undefined,
        ipxId: queryDataRef.current?.ipxId,
      }),
    );
    setShouldListSlots(false);
    setListSlotCalled(true);
  }, [shouldListSlots, consultationType?.consultationTypeId, locationType?.id]);

  const trackSlots = useCallback(
    (eventName: string, selectedSlot: ISlot, flow: string) => {
      if (eventName && consultationTypeRef.current?.consultationTypeId) {
        dispatch(
          TrackActions.TrackSlots({
            eventName: eventName,
            providerIds: selectedSlot?.providerId ? [selectedSlot?.providerId] : queryDataRef.current?.providerId,
            excludedProviderIds: queryDataRef.current?.excludedProviderId,
            languages: queryDataRef.current.selectedLanguages,
            locationId: locationTypeRef.current?.id,
            consultationTypeId: consultationTypeRef.current?.consultationTypeId,
            slotTime: selectedSlot?.startDate ? dayjs(selectedSlot?.startDate)?.toISOString() : undefined,
            sessionId: getSessionId(),
            blockId: selectedSlot?.blockId,
            flow: flow,
            speciality: queryData?.speciality,
            slotsData: slotsRef.current,
          }),
        );
      }
    },
    [consultationTypeRef.current, locationTypeRef.current, queryDataRef.current, slotsRef.current, dispatch],
  );

  // List slots every 3 minutes
  useEffect(() => {
    if (!listSlotCalled) return;

    const interval = setInterval(() => {
      listSlots();
    }, 180_000);

    return () => {
      clearInterval(interval);
    };
  }, [listSlotCalled]);

  useEffect(() => {
    if (!consultationType?.consultationTypeId) return;
    dispatch(
      ScheduleActions?.GetAvailableLanguages({
        consultationTypeId: consultationType?.consultationTypeId,
        startTime: dayjs(startDate)?.startOf('day')?.toISOString(),
        endTime: dayjs(endDate)?.endOf('day')?.toISOString(),
      }),
    );
  }, [consultationType?.consultationTypeId]);

  const dateList = [];
  for (let i = startDate; dayjs(i).isBefore(dayjs(addDays(endDate, 1))); i = addDays(i, 1)) {
    dateList.push(dayjs(i).format('YYYY-MM-DD'));
  }

  return { listSlots, dateList, slots, trackSlots };
};

export default useListSlots;
