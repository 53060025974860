export enum HiFontType {
  regular_8_170 = 'medium_10_170',
  regular_10_170 = 'medium_12_170',
  regular_11_170 = 'medium_12_150',
  regular_12_170 = 'medium_12_150',
  regular_14_150 = 'medium_14_150',
  regular_16_150 = 'medium_16_150',
  regular_18_150 = 'medium_18_150',
  regular_20_150 = 'medium_20_150',
  regular_22_150 = 'medium_22_150',
  regular_25_150 = 'medium_25_150',
  regular_32_150 = 'medium_32_150',
  regular_45_150 = 'medium_45_150',
  regular_55_130 = 'medium_55_130',
  regular_68_130 = 'medium_68_130',

  semibold_10_170 = 'bold_12_170',
  semibold_11_170 = 'bold_12_150',
  semibold_12_170 = 'bold_12_150',
  semibold_14_150 = 'bold_14_150',
  semibold_16_150 = 'bold_16_150',
  semibold_18_150 = 'bold_18_150',
  semibold_20_150 = 'bold_20_150',
  semibold_22_150 = 'bold_22_150',
  semibold_24_150 = 'bold_24_150',
  semibold_25_150 = 'bold_25_150',
  semibold_30_150 = 'bold_30_150',
  semibold_32_150 = 'bold_32_150',
  semibold_45_150 = 'bold_45_150',
  semibold_55_130 = 'bold_55_130',
  semibold_68_130 = 'bold_68_130',

  DISPLAY_16_150 = 'display_16_150',
  DISPLAY_18_150 = 'display_18_150',
  DISPLAY_20_150 = 'display_20_150',
  DISPLAY_32_150 = 'display_32_150',
  DISPLAY_36_150 = 'display_36_150',
  DISPLAY_64_130 = 'display_64_130',
  DISPLAY_55_130 = 'display_55_130',
  DISPLAY_72_120 = 'display_72_120',
}
