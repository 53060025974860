import Image from 'next/image';

import SectionHeader from '@Components/core/section-header';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { useTrans } from '@Utils/hooks/translation';

const HowToStart = ({ redirectToBooking }: { redirectToBooking: () => void }) => {
  const { t, languageUsed } = useTrans('ax');
  return (
    <main className=''>
      <SectionHeader
        title={t('how-to-start.title')}
        description={t('how-to-start.take-timely-action')}
        classes='pb-6'
      />
      <section className='flex gap-2 mb-6'>
        <div className='flex flex-col items-center justify-around relative'>
          <div className='rounded-full border-2 border-white w-6 h-6 bg-SUCCESS_500 -mt-3'>
            <div className='absolute w-1 h-1/3 bg-SUCCESS_500 left-[40%] ' />
            <div className='flex items-center justify-center w-full h-full'>
              <Image src={'/assets/svgs/tick.svg'} height={12} width={12} alt='tick' />
            </div>
          </div>
          <div className='rounded-full border-2 border-white w-6 h-6 bg-ATTENTION_400 z-20'>
            <div className='absolute w-5 h-5 bg-ATTENTION_400 left-0.5 rounded-full '></div>
            <div className='absolute w-1 h-1/3 bg-COOL_GREY_300 left-[40%] -z-10' />
          </div>
          <div className='rounded-full border border-COOL_GREY_500 w-6 h-6 bg-white'>
            <div className='absolute w-1 h-1/3 bg-transparent left-[40%]' />
          </div>
        </div>
        <div className='flex flex-col gap-6'>
          <div className='shadow-slot bg-white py-2 px-4 rounded-lg flex flex-col gap-1 w-full'>
            <Text color={ColorType.GREYS_900} font={FontType.SEMIBOLD_14_150} language={languageUsed}>
              {t('how-to-start.take-free-self-assessment')}
            </Text>
            <Text color={ColorType.SUCCESS_500} font={FontType.REGULAR_12_170} language={languageUsed}>
              {t('how-to-start.completed')}
            </Text>
          </div>
          <div
            onClick={() => redirectToBooking()}
            onKeyDown={() => redirectToBooking()}
            className='shadow-slot bg-white py-2 px-4 rounded-lg flex flex-col gap-1 w-full'>
            <Text color={ColorType.GREYS_900} font={FontType.SEMIBOLD_14_150} language={languageUsed}>
              {t('how-to-start.schedule-doctor-consultation')}
            </Text>
            <Text color={ColorType.ATTENTION_500} font={FontType.REGULAR_12_170} language={languageUsed}>
              {t('how-to-start.pending')}
            </Text>
            <div className='flex gap-2 pt-4 items-center'>
              <Text font={FontType.SEMIBOLD_14_150} color={ColorType.INDIGO_600} language={languageUsed}>
                {t('how-to-start.book-a-slot-now')}
              </Text>
              <Image
                src={'/assets/svgs/left-arrow-primary.svg'}
                height={6}
                width={6}
                alt='arrow'
                className='rotate-180'
              />
            </div>
          </div>
          <div className='shadow-slot bg-white py-2 px-4 rounded-lg flex flex-col gap-1 w-full'>
            <Text color={ColorType.COOL_GREY_500} font={FontType.REGULAR_14_150} language={languageUsed}>
              {t('how-to-start.receive-a-personalised-treatment-with-expert-guidance')}
            </Text>
            <Text color={ColorType.ATTENTION_500} font={FontType.REGULAR_12_170} language={languageUsed}>
              {t('how-to-start.pending')}
            </Text>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HowToStart;
