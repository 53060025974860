import { AxiosResponse } from 'axios';

import { IUploadFile } from '@Reducers/file';
import { Axios } from '@Services/AxiosApiServerWrapper';

export const FileService = {
  UploadFile: async (payload: IUploadFile): Promise<AxiosResponse> => {
    const url = `documents`;
    return Axios.Request('POST', url, payload, {}, '', 'multipart/form-data');
  },
  GetPatientFiles: async (payload: { userId: string }): Promise<AxiosResponse<File[]>> => {
    const url = `documents`;
    return Axios.Request('GET', url, {}, { ...payload, ipxId: payload.userId });
  },
  DownloadFile: async (payload: { fileId: string; patientId: string }): Promise<AxiosResponse<File>> => {
    const url = `documents/${payload.fileId}/download`;
    return Axios.Request('GET', url, undefined, { ipxId: payload.patientId, ...payload }, undefined, undefined, 'blob');
  },
};
