import Image from 'next/image';
import React, { useState } from 'react';

import MidPopUp from '@Components/core/mid-popup';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { checkIfYoutubeLink } from '@Utils/helper';
import { getReasons } from '@Utils/Helpers/AssessmentReport/helper';
import { useTrans } from '@Utils/hooks/translation';

import AvailableOptions from './available-options';
import HeaderWithBack from './header-with-back';
import StickyCta from './sticky-cta';

const TreatmentDetails = ({
  icon,
  closeModal,
  title,
  resources,
  defaultSelectedOption,
  reasonTitle,
  reasons,
  question,
  optionDetails,
  heading,
  subHeading,
  targetArea,
  haveBackButton = false,
  handleBooking,
}: {
  icon: string;
  closeModal: () => void;
  title: string;
  resources: any[];
  defaultSelectedOption?: string;
  reasonTitle?: string;
  reasons?: string[];
  question?: string;
  optionDetails?: any[];
  heading?: string;
  subHeading?: string;
  targetArea?: string;
  haveBackButton?: boolean;
  handleBooking?: () => void;
}) => {
  const { t, languageUsed } = useTrans('ax');
  const availableOptions = resources?.map((resource) => {
    return resource?.label || resource?.badge;
  });
  return (
    <MidPopUp closeModal={closeModal}>
      <main className='px-4 pt-6 grid grid-cols-1 divide-y divide-GREYS_400'>
        <section className='flex flex-col gap-2 pb-4 '>
          {haveBackButton ? (
            <HeaderWithBack heading={heading} onBack={closeModal} />
          ) : (
            <div className='flex gap-2'>
              <div className='flex gap-2 items-center'>
                {icon && (
                  <div className='flex items-center justify-center rounded-full h-8 w-8 border border-PRIMARY_500'>
                    <Image src={icon} alt='allo-icon' width={32} height={32} layout='fixed' />
                  </div>
                )}
                <div className='flex flex-col justify-center '>
                  <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900} language={languageUsed}>
                    {title}
                  </Text>
                </div>
              </div>
            </div>
          )}
          {reasons?.length > 0 && (
            <div className='flex flex-col pt-1'>
              <Text font={FontType.REGULAR_11_170} color={ColorType.COOL_GREY_500} language={languageUsed}>
                {reasonTitle}
              </Text>
              <div className='flex gap-3 flex-wrap py-2'>
                {reasons?.map((reason) => (
                  <div key={reason} className='flex px-2 py-1 gap-1 rounded-full bg-ORANGE_YELLOW_100 items-center'>
                    <Image src={getReasons(reason.toLowerCase())} alt='allo-icon' width={16} height={16} />
                    <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_900} language={languageUsed}>
                      {reason}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
          )}
        </section>
        <section className='pt-4'>
          <SelectionScroll
            availableOptions={availableOptions}
            optionDetails={resources}
            defaultSelectedOption={defaultSelectedOption}
          />
        </section>
        <section className='-py-1'>
          <StickyCta heading={t('have-doubts-about-your-report')} handleBooking={handleBooking} />
        </section>
      </main>
    </MidPopUp>
  );
};

export default TreatmentDetails;

const SelectionScroll = ({
  availableOptions,
  optionDetails,
  defaultSelectedOption,
}: {
  availableOptions: string[];
  optionDetails: any[];
  defaultSelectedOption?: string;
}) => {
  const { languageUsed } = useTrans('ax');
  const [filteredOptionDetails, setFilteredOptionDetails] = useState(
    defaultSelectedOption
      ? optionDetails?.filter(
          (detail) => detail?.label === defaultSelectedOption || detail?.badge === defaultSelectedOption,
        )
      : optionDetails,
  );

  return (
    <main>
      <AvailableOptions
        availableOptions={availableOptions}
        optionDetails={optionDetails}
        defaultSelectedOption={defaultSelectedOption}
        onSelectionChange={(optionDetails) => setFilteredOptionDetails(optionDetails)}
        filterProp='badge'
      />
      <div className='flex gap-4 overflow-x-scroll'>
        {filteredOptionDetails?.map((optionDetail) => (
          <div
            key={optionDetail?.id}
            className='bg-CYAN_50 p-3 rounded-lg min-w-[80%] flex flex-col items-center text-left'>
            {checkIfYoutubeLink(optionDetail?.externalUrl || '') && (
              <div className='mt-1 mb-4'>
                <iframe
                  className='rounded-lg'
                  width={'100%'}
                  height={'182'}
                  src={optionDetail?.externalUrl}
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'></iframe>
              </div>
            )}
            {optionDetail?.imageUrl && (
              <div className='pb-2'>
                <Image className='rounded-lg' src={optionDetail?.imageUrl} alt='allo-icon' width={284} height={182} />
              </div>
            )}
            {optionDetail?.title && (
              <Text
                className='w-full pb-2'
                font={FontType.SEMIBOLD_18_150}
                color={ColorType.GREYS_900}
                language={languageUsed}>
                {optionDetail?.title}
                {optionDetail?.title?.split(' ')?.[0] === 'How' ? ' ?' : ''}
              </Text>
            )}
            {optionDetail?.description && (
              <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_900} language={languageUsed}>
                {optionDetail?.description}
              </Text>
            )}
          </div>
        ))}
      </div>
    </main>
  );
};
