import Image from 'next/image';
import { useState } from 'react';

import { colors } from '@Components/design-system/color';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { generateRandomUniqueColor, formatName } from '@Utils/helper';
import { getReasons } from '@Utils/Helpers/AssessmentReport/helper';
import { useTrans } from '@Utils/hooks/translation';

import BlogThumbnail from './blog-thumbnail';
import TreatmentDetails from './treatment-details';

const SingleTreatmentSuggestion = ({
  icon,
  title,
  suggestionTitle,
  suggestion,
  explanationTitle,
  explanation,
  reasonTitle,
  reasons,
  resources,
  questions,
  heading,
  subHeading,
  targetArea,
  diagnosesList,
  handleBooking,
}: {
  icon?: string;
  title?: string;
  suggestionTitle?: string;
  suggestion?: string[];
  explanationTitle?: string;
  explanation?: string;
  reasonTitle?: string;
  reasons?: string[];
  resources?: any[];
  questions?: string;
  heading?: string;
  subHeading?: string;
  targetArea?: string;
  diagnosesList?: Map<string, Record<string, string>>;
  handleBooking?: () => void;
}) => {
  const { t, languageUsed } = useTrans('ax');
  const [viewInfo, setViewInfo] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [showFullExplaination, setShowFullExplaination] = useState(false);
  const suggestionOptions = [
    ...new Set(
      resources?.map((resource) => {
        if (diagnosesList?.has(resource?.category?.toLowerCase())) {
          return diagnosesList.get(resource?.category?.toLowerCase())?.[languageUsed];
        }
      }),
    ),
  ];

  const handleViewInfo = (optionSelected?) => {
    setViewInfo(!viewInfo);
    setSelectedOption(optionSelected);
  };
  return (
    <>
      {viewInfo && (
        <TreatmentDetails
          icon={icon}
          closeModal={() => setViewInfo(false)}
          title={title}
          resources={resources}
          defaultSelectedOption={selectedOption}
          reasonTitle={'SUGGESTED FOR:'}
          reasons={reasons}
          question={questions}
          heading={heading}
          subHeading={subHeading}
          targetArea={targetArea}
          handleBooking={handleBooking}
        />
      )}
      <main className='rounded-lg overflow-hidden border border-PRIMARY_75'>
        <section onClick={() => handleViewInfo()} className='bg-PRIMARY_75 flex gap-2 px-4 py-3 w-full items-center'>
          {icon && (
            <div className='flex items-center justify-center rounded-full h-8 w-8 border border-PRIMARY_500'>
              <Image src={icon} alt='allo-icon' width={32} height={32} layout='fixed' />
            </div>
          )}
          <div className='flex flex-col justify-center'>
            <Text font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_900} language={languageUsed}>
              {title}
            </Text>
          </div>
          <div className='ml-auto'>
            <Image src='/assets/svgs/info-blue-gray.svg' height={20} width={20} layout='fixed' alt='allo icon' />
          </div>
        </section>
        <div className='px-4 pt-1'>
          <Text
            font={FontType.SEMIBOLD_11_170}
            color={ColorType.COOL_GREY_500}
            language={languageUsed}
            className='uppercase'>
            {suggestionTitle}
          </Text>
          <div className='flex gap-3 flex-wrap py-2'>
            {suggestionOptions?.map(
              (suggestion, id) =>
                suggestion && (
                  <div
                    key={id}
                    className='flex px-2 py-1 gap-1 rounded-full items-center border'
                    style={{
                      borderColor: colors[generateRandomUniqueColor(id, 700)],
                    }}>
                    <div
                      className='h-2.5 w-2.5 rounded-full '
                      style={{
                        backgroundColor: colors[generateRandomUniqueColor(id, 700)],
                      }}
                    />
                    <Text
                      font={FontType.SEMIBOLD_11_170}
                      language={languageUsed}
                      style={{
                        color: colors[generateRandomUniqueColor(id, 700)],
                      }}>
                      {formatName(suggestion)}
                    </Text>
                  </div>
                ),
            )}
          </div>
        </div>
        <div className='h-[1px] bg-GREYS_400 mx-4' />
        <section className='p-4'>
          <Text
            className='pb-2'
            font={FontType.SEMIBOLD_11_170}
            color={ColorType.COOL_GREY_500}
            language={languageUsed}>
            {explanationTitle}
          </Text>
          {showFullExplaination ? (
            <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_700} language={languageUsed}>
              {explanation}
            </Text>
          ) : (
            <div>
              <Text font={FontType.REGULAR_14_150} color={ColorType.GREYS_700} language={languageUsed}>
                {explanation?.length > 70 ? `${explanation?.slice(0, 70)}...` : explanation}
              </Text>
              {explanation?.length > 70 && (
                <div className='flex items-center gap-[2px]' onClick={() => setShowFullExplaination(true)}>
                  <Text font={FontType.REGULAR_14_150} color={ColorType.INDIGO_600} language={languageUsed}>
                    {t('general.read-more')}
                  </Text>
                  <Image src='/assets/svgs/chevron-indigo.svg' height={16} width={16} layout='fixed' alt='allo icon' />
                </div>
              )}
            </div>
          )}
          {showFullExplaination && (
            <div className='flex items-center gap-[2px]' onClick={() => setShowFullExplaination(false)}>
              <Text font={FontType.REGULAR_14_150} color={ColorType.INDIGO_600} language={languageUsed}>
                {t('general.read-less')}
              </Text>
              <Image
                src='/assets/svgs/chevron-indigo.svg'
                height={16}
                width={16}
                layout='fixed'
                className='rotate-180'
                alt='allo icon'
              />
            </div>
          )}
        </section>
        <section className='rounded-lg border border-PRIMARY_75 py-1 mx-4 mb-4'>
          <div className='flex overflow-x-scroll h-32 gap-[1px] px-1'>
            {resources?.map((resource) => (
              <div
                key={resource?.id}
                onClick={() => handleViewInfo(resource?.label || resource?.badge)}
                className='flex-shrink-0 w-44 p-2'>
                <BlogThumbnail
                  classes={'min-w-[70%] h-full'}
                  backgroundImage={resource?.imageUrl}
                  title={resource?.badge}
                />
              </div>
            ))}
          </div>
          {reasons?.length > 0 && (
            <div className='px-4 pt-1'>
              <Text font={FontType.SEMIBOLD_11_170} color={ColorType.COOL_GREY_500} language={languageUsed}>
                {reasonTitle}
              </Text>
              <div className='flex gap-3 flex-wrap py-2'>
                {reasons?.map((reason) => (
                  <div key={reason} className='flex px-2 py-1 gap-1 rounded-full bg-ORANGE_YELLOW_100 items-center'>
                    <Image src={getReasons(reason.toLowerCase())} alt='allo-icon' width={16} height={16} />
                    <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_900} language={languageUsed}>
                      {reason}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default SingleTreatmentSuggestion;
