import dynamic from 'next/dynamic';
import React, { Suspense, createContext, useState } from 'react';

import { updateSelectedLanguages } from '@Reducers/common';
import { useAppDispatch } from '@Redux/hooks';

const ConsultationModeSelectionModal = dynamic(() => import('@Components/consultation-mode-selection-modal'), {
  ssr: false,
});
const SelectLanguagesModal = dynamic(() => import('@Components/select-languages-modal'), {
  ssr: false,
});
const UploadDocument = dynamic(() => import('@Components/records/upload-document'), {
  ssr: false,
});
const AppointmentConfirmedModal = dynamic(() => import('@Components/appointment-confirmed-modal'), {
  ssr: false,
});
const SlotReleaseModal = dynamic(() => import('@Components/slot-release-modal'), {
  ssr: false,
});

export const PopupContext = createContext({});
const { Provider } = PopupContext;

export enum PopupModals {
  ChooseConsultationMode = 'CHOOSE_CONSULTATION_MODE',
  ChooseLanguages = 'CHOOSE_LANGUAGES',
  ConsultationConfirmation = 'CONSULTATION_CONFIRMATION',
  SlotRelease = 'SLOT_RELEASE',
  UploadDocuments = 'UPLOAD_DOCUMENTS',
}

export interface PopProviderProps {
  showPopup: (modal: PopupModals, proceedCallback?: () => void, fold?: Fold, props?) => void;
  hidePopup: () => void;
  modalLaunched: boolean;
}

export enum Fold {
  SHEET = 'sheet',
  PAGE = 'PAGE',
}

export const ModalProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const [proceedCallback, setProceedCallback] = useState<() => void>();
  const [props, setProps] = useState<any>();
  const [popupToggle, setPopupToggle] = useState<{
    modal: PopupModals;
    launch: boolean;
  }>({
    modal: undefined,
    launch: false,
  });

  const showPopup = (modal: PopupModals, proceedCallback?: () => void, fold: Fold = Fold.SHEET, props?) => {
    setPopupToggle({
      modal: modal,
      launch: true,
    });
    setProceedCallback(proceedCallback);
    setProps(props);
  };
  const hidePopup = () => {
    setPopupToggle({
      modal: undefined,
      launch: false,
    });
  };

  const modalToRender = (modal: PopupModals, proceedCallback?: () => void, props?) => {
    switch (modal) {
      case PopupModals.ChooseConsultationMode: {
        return (
          <ConsultationModeSelectionModal
            handleContinue={() => {
              hidePopup();
              proceedCallback();
            }}
            {...props}
          />
        );
      }
      case PopupModals.ChooseLanguages: {
        return (
          <SelectLanguagesModal
            handleStateChange={hidePopup}
            addLanguage={(languages: string[]) => dispatch(updateSelectedLanguages(languages))}
            getSlots={proceedCallback}
          />
        );
      }
      case PopupModals.ConsultationConfirmation: {
        return <AppointmentConfirmedModal {...props} />;
      }
      case PopupModals.UploadDocuments: {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <UploadDocument closeModal={hidePopup} {...props} />
          </Suspense>
        );
      }
      case PopupModals.SlotRelease: {
        return (
          <SlotReleaseModal
            releaseConsultationSlot={() => {
              hidePopup();
              proceedCallback();
            }}
            {...props}
          />
        );
      }
    }
  };

  return (
    <Provider value={{ showPopup, hidePopup, modalLaunched: popupToggle.launch }}>
      {children}
      {popupToggle.launch && modalToRender(popupToggle.modal, proceedCallback, props)}
    </Provider>
  );
};
