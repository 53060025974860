import { AxiosResponse } from 'axios';

import { GetAppointmentsPayload } from '@Services/Checkout/checkout-payload';

import { Axios } from '../AxiosApiServerWrapper';

export const CheckoutService = {
  GetAppointments: async (payload: GetAppointmentsPayload): Promise<AxiosResponse> => {
    const url = `appointments?take=200`;
    return Axios.Request('GET', url, undefined, payload);
  },
  GetAllInvoice: async (payload: { encounterId: string; ipxId: string }): Promise<AxiosResponse> => {
    const url = `billing/invoices`;
    return Axios.Request('GET', url, {}, payload);
  },
  GetAllConsultations: async (): Promise<AxiosResponse> => {
    const url = `catalog/consultation`;
    return Axios.Request('GET', url);
  },
};
