import { createSlice } from '@reduxjs/toolkit';

import { ActionConsts } from '@Definitions/index';
import { LabVenue } from '@Reducers/encounter';
import { LanguageCode } from '@Reducers/metadata';
import { InvoiceMode } from '@Services/Billing/billing-payload';
import { CONSULTATION_CODE_DEFAULT, ItemType } from '@Utils/constants';

export interface IQueryData {
  locationCode?: string;
  voucherCode?: string;
  name?: string;
  phone?: string;
  email?: string;
  date?: string;
  providerId?: string[];
  excludedProviderId?: string[];
  consultationCode: string;
  appointmentId?: string;
  consultationId?: string;
  appointmentState?: string;
  ipxId?: string;
  hmsToken?: string;
  encounterId?: string;
  consultationState?: string;
  selectedLanguages?: string[];
  confirmationType?: string;
  formType?: string;
  pageSource?: string;
  assessmentLanguage?: LanguageCode;
  consultationMode?: string;
  invoiceMode?: InvoiceMode;
  sourceUrl?: string;
  embedded?: string;
  validUserCity?: string;
  utmTerm?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmSource?: string;
  gclid?: string;
  fbclid?: string;
  fbc?: string;
  fbp?: string;
  applyPseudoCoupon?: boolean;
  speciality?: string;
  inventoryItemId?: string;
  inventoryItemType?: ItemType;
  isInstant?: boolean;
  surcharge?: number;
  blockId?: string;
  startDate?: string;
  endDate?: string;
  labVenue?: LabVenue;
}

interface IBookCallParams {
  providerId?: string;
  locationId?: string;
  typeId?: string;
  startDate?: string;
  endDate?: string;
  blockId?: string;
  openPaymentModal?: boolean;
}

interface ICommon {
  queryData: IQueryData;
  isLoading: boolean;
  bookCallParams?: IBookCallParams;
}

const INITIAL_STATE: ICommon = {
  queryData: {
    consultationCode: CONSULTATION_CODE_DEFAULT,
  },
  bookCallParams: {},
  isLoading: true,
};

const Reducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Common.UpdateQueryData:
      return {
        ...state,
        queryData: { ...state.queryData, ...action.queryData },
        bookCallParams: { ...state.bookCallParams, ...action.bookCallParams },
      };
    case ActionConsts.Common.ResetQueryData:
      return {
        ...state,
        queryData: INITIAL_STATE.queryData,
      };

    case ActionConsts.Common.ResetbookCallParams:
      return {
        ...state,
        bookCallParams: INITIAL_STATE.bookCallParams,
      };
    case ActionConsts.Common.ResetQueryDataExceptIpxId:
      return {
        ...state,
        queryData: {
          ...INITIAL_STATE.queryData,
          ipxId: state.queryData.ipxId,
        },
      };
    default:
      return state;
  }
};

const commonSlice = createSlice({
  name: 'common',
  initialState: INITIAL_STATE,
  reducers: {
    updateSelectedLanguages: (state, action) => {
      state.queryData.selectedLanguages = action.payload;
    },
    updateLocationCode: (state, action) => {
      state.queryData.locationCode = action.payload;
    },
    updateValidUserCity: (state, action) => {
      state.queryData.validUserCity = action.payload;
    },
    updatePseudoCoupon: (state, action) => {
      state.queryData.applyPseudoCoupon = action.payload;
    },
    updateLabVenue: (state, action) => {
      state.queryData.labVenue = action.payload;
    },
    updateParam: (
      state,
      action: {
        payload: {
          stateToUpdate?: string;
          paramValue: unknown;
        };
      },
    ) => {
      state.queryData[action.payload.stateToUpdate] = action.payload.paramValue;
    },
    updateCommonIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const CommonReducer = (state = INITIAL_STATE, action) => {
  const stateAfterSlice = commonSlice.reducer(state, action);
  return Reducer(stateAfterSlice, action);
};

export const {
  updateSelectedLanguages,
  updateLocationCode,
  updateValidUserCity,
  updateParam,
  updatePseudoCoupon,
  updateCommonIsLoading,
  updateLabVenue,
} = commonSlice.actions;
