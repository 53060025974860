import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storageSession from 'redux-persist/lib/storage/session';

import Reducers from '@Reducers/index';
import { LOG_ERROR } from '@Utils/logger';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageSession,
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_PUBLIC_REDUX_ENCRYPT_SECRET,
      onError: function (error) {
        LOG_ERROR('Error: persistConfig encryptTransform', error);
      },
    }),
  ],
  blacklist: ['auth', 'toggle'],
  // stateReconciler: 1,
};

const persistedReducer = persistReducer(persistConfig, Reducers);
const env = process.env.NEXT_PUBLIC_ENVIRONMENT;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: env !== 'production',
});
export const persistor = persistStore(store);

export const makeStore = () => store;

export type Store = ReturnType<typeof makeStore>;
export type AppDispatch = Store['dispatch'];
export type RootState = ReturnType<Store['getState']>;
export const wrapper = createWrapper(makeStore, {
  debug: env !== 'production',
});
