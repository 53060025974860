import Image from 'next/image';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { useTrans } from '@Utils/hooks/translation';

const HeaderWithBack = ({
  heading,
  onBack,
  btnHeading,
  onClickBtn,
}: {
  heading: string;
  onBack: () => void;
  btnHeading?: string;
  onClickBtn?: () => void;
}) => {
  const { languageUsed } = useTrans();
  return (
    <>
      <div className='flex justify-between'>
        <div className='flex gap-1 items-center cursor-pointer mb-3' onClick={onBack} onKeyDown={onBack}>
          <Image
            className='transform rotate-180'
            src={'/assets/svgs/chevron-right-purple.svg'}
            height={8}
            width={8}
            alt='allo-icon'
            layout='fixed'
          />
          <Text font={FontType.SEMIBOLD_12_170} color={ColorType.PRIMARY_600}>
            Back
          </Text>
        </div>
        <div className='flex gap-1 items-center cursor-pointer mb-3' onClick={onClickBtn} onKeyDown={onClickBtn}>
          <Text font={FontType.SEMIBOLD_12_170} color={ColorType.PRIMARY_600} language={languageUsed}>
            {btnHeading}
          </Text>
          <Image
            className='transform '
            src={'/assets/svgs/chevron-right-purple.svg'}
            height={8}
            width={8}
            alt='allo-icon'
            layout='fixed'
          />
        </div>
      </div>
      <Text font={FontType.SEMIBOLD_18_150} color={ColorType.GREYS_900} language={languageUsed}>
        {heading}
      </Text>
    </>
  );
};

export default HeaderWithBack;
