import Image from 'next/image';
import { useState, useEffect } from 'react';

import { ColorType, FontType } from '@Components/common-exports';
import { Button } from '@Components/design-system/button/button';
import Text from '@Components/design-system/text';
import { useTrans } from '@Utils/hooks/translation';

const NextStepBox = ({ handleBooking }) => {
  const { t, languageUsed } = useTrans('ax');
  const [showDoctorDiscussion, setShowDoctorDiscussion] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowDoctorDiscussion((prev) => !prev);
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className='flex flex-col gap-2 bg-SECONDARY_BLUE_100 p-4 items-center'>
      <div className='flex items-center gap-1 bg-SUCCESS_50 border border-SUCCESS_500 rounded-full px-4 py-1 '>
        <Image src='/assets/svgs/call-icon-primary.svg' width={16} height={16} alt='arrow-right' />
        <Text
          color={ColorType.GREYS_900}
          font={FontType.SEMIBOLD_11_170}
          language={languageUsed}
          className='transition ease-in-out duration-200'>
          {showDoctorDiscussion ? t('next-step.assessment-completed') : t('next-step.next-step-is-doctor-discussion')}
        </Text>
        <Image src='/assets/svgs/chevron-black.svg' width={16} height={16} alt='arrow-right' />
      </div>
      <div className='flex flex-col gap-1 items-center py-2'>
        <Image src='/assets/svgs/warning.svg' width={68} height={68} alt='arrow-right' />
        <Text color={ColorType.GREYS_900} font={FontType.SEMIBOLD_18_150} language={languageUsed}>
          {t('next-step.root-cause-to-be-determined')}
        </Text>
        <Text
          color={ColorType.GREYS_700}
          font={FontType.REGULAR_11_170}
          language={languageUsed}
          className='px-1 text-center w-72 sm:w-full'>
          {t('next-step.description')}
        </Text>
      </div>
      <div className='min-w-[90%]'>
        <Button
          label={
            <Text language={languageUsed} font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_100}>
              {t('talk-to-doctor')}
            </Text>
          }
          onPress={handleBooking}
          type='secondary'
          secondaryBackgroundColour={``}
          fullWidth={true}
          size='medium'
          align='center'
          borderRadius={50}
        />
      </div>
    </div>
  );
};

export default NextStepBox;
