import { useEffect, useMemo, useRef, useState } from 'react';

import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import { IAilments, IDiagnosis } from '@Reducers/encounter';
import { LanguageCode } from '@Reducers/metadata';
import { getFilteredAilments } from '@Utils/Helpers/AssessmentReport/helper';
import { useTrans } from '@Utils/hooks/translation';

import IssueGrid from './issue-grid';

const DiagnosesSummary = ({
  diagnoses,
  ailments,
  metadata,
  handleBooking,
}: {
  diagnoses: IDiagnosis[];
  ailments: IAilments[];
  metadata: Record<string, any[]>;
  handleBooking: () => void;
}) => {
  const { t, languageUsed } = useTrans('ax');
  const selectedDiagnosisRef = useRef(null);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<IDiagnosis | undefined>();

  const diagnosesWithSymptoms = useMemo(() => {
    const diagnosesSet = new Set();
    for (const diagnosis of diagnoses) {
      const symptoms = getFilteredAilments(ailments, 'symptom', diagnosis?.medicalCondition?.id);
      if (symptoms?.length > 0) {
        diagnosesSet.add(diagnosis);
      }
    }
    return diagnosesSet;
  }, [ailments, diagnoses]);

  useEffect(() => {
    if (!selectedDiagnosis && diagnosesWithSymptoms.size > 0) {
      setSelectedDiagnosis([...diagnosesWithSymptoms][0] as IDiagnosis);
    }
  }, [ailments, diagnoses, selectedDiagnosis, diagnosesWithSymptoms]);

  const checkIfDiagnosisSelected = (diagnosis) =>
    selectedDiagnosis?.medicalCondition?.id === diagnosis?.medicalCondition?.id;

  return (
    <main>
      {diagnosesWithSymptoms.size > 0 && (
        <div className='mt-8 relative'>
          <Text
            className='pb-6 px-4'
            font={FontType.SEMIBOLD_24_150}
            color={ColorType.GREYS_900}
            language={languageUsed}>
            {t('symptoms-you-may-be-facing')}
            {languageUsed !== LanguageCode.English && <span className='text-lg'> (Symptoms)</span>}
          </Text>
          <div className='px-1.5 flex gap-0.5 overflow-x-scroll absolute w-full'>
            {[...diagnosesWithSymptoms].map((diagnosis: IDiagnosis) => (
              <div key={diagnosis?.id} className='flex flex-col items-center min-w-max '>
                <div ref={checkIfDiagnosisSelected(diagnosis) ? selectedDiagnosisRef : undefined}>
                  <Text
                    onClick={() => setSelectedDiagnosis(diagnosis)}
                    font={checkIfDiagnosisSelected(diagnosis) ? FontType.SEMIBOLD_14_150 : FontType.REGULAR_14_150}
                    color={checkIfDiagnosisSelected(diagnosis) ? ColorType.ORANGE_YELLOW_900 : ColorType.GREYS_900}
                    className={`relative px-3 py-1 mb-2 rounded-t-lg whitespace-nowrap ${
                      checkIfDiagnosisSelected(diagnosis)
                        ? 'bg-ORANGE_YELLOW_50 border-t border-x border-ORANGE_YELLOW_600 z-10 translate-y-[3px]'
                        : 'bg-BLUE_GREY_75 border-t border-x border-BLUE_GREY_100'
                    }`}
                    language={languageUsed}>
                    {diagnosis?.medicalCondition?.languageNameMap?.[languageUsed] || diagnosis?.medicalCondition?.name}
                  </Text>
                </div>
              </div>
            ))}
          </div>
          <div className='mr-1 my-8 overflow-hidden bg-ORANGE_YELLOW_50 rounded-br-lg border border-ORANGE_YELLOW_600'>
            {selectedDiagnosis && (
              <IssueGrid
                issues={getFilteredAilments(ailments, 'symptom', selectedDiagnosis?.medicalCondition?.id)}
                metadata={metadata}
                handleBooking={handleBooking}
                showAccordion={false}
              />
            )}
          </div>
        </div>
      )}
    </main>
  );
};

export default DiagnosesSummary;
