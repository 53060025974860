import { ActionConsts } from '@Definitions/ActionConsts';

interface IUser {
  name: string;
  nickname: string;
  sub: string;
  picture: string;
  updated_at: string;
  email?: string;
  role?: string;
}

interface IAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: IUser;
}

const INITIAL_STATE: IAuth = {
  isLoading: true,
  isAuthenticated: false,
  user: undefined,
};

export const AuthReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionConsts.Authentication.InitAuthStateSuccess:
    case ActionConsts.Authentication.ParseHashSuccess:
    case ActionConsts.Authentication.UserAuthenticatedWithVerifiedOtp:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: !!action.user,
        user: action.user,
      };
    case ActionConsts.Authentication.InitAuthStateFail:
    case ActionConsts.Authentication.LoginFail:
      return {
        isLoading: false,
        isAuthenticated: false,
        user: undefined,
      };
    case ActionConsts.Authentication.Logout:
    case ActionConsts.Authentication.LogoutFail:
      return INITIAL_STATE;
    default:
      return state;
  }
};
