import Image from 'next/image';
import React, { useState } from 'react';

interface AccordionProps {
  className: string;
  header: React.ReactElement;
  children?: React.ReactElement;
  expanded?: boolean;
  backgroundColor?: string;
  mobile?: boolean;
  openComponent?: React.ReactElement;
  closeComponent?: React.ReactElement;
  headerClasses?: string;
}

const Accordion = (props: AccordionProps) => {
  const [expanded, setExpanded] = useState(props.expanded || false);
  const Header = props.header;
  const openComponent = props?.openComponent ?? (
    <div className='transform rotate-19'>
      <Image src='/assets/svgs/chevron-black.svg' width={16} height={16} objectFit='contain' alt='down-arrow' />
    </div>
  );
  const closeComponent = props?.closeComponent ?? (
    <div className='transform -rotate-90'>
      <Image src='/assets/svgs/chevron-black.svg' width={16} height={16} objectFit='contain' alt='up-arrow' />
    </div>
  );
  return (
    <div className={props.className}>
      <div
        className={'header flex flex-row justify-between items-center cursor-pointer rounded-lg ' + props.headerClasses}
        style={{
          backgroundColor: props?.backgroundColor ?? undefined,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setExpanded(!expanded);
        }}>
        {Header}
        {expanded ? closeComponent : openComponent}
      </div>
      {expanded ? <div className='body'>{props.children || undefined}</div> : undefined}
    </div>
  );
};

export default Accordion;
