import { showNotification, NotificationType } from '@Components/design-system/notification/notification';
import { useAppDispatch } from '@Redux/hooks';
import { BillingActions } from '@Actions/index';

function useSchedulingFailure() {
  const dispatch = useAppDispatch();

  const schedulingFailure = (afterNotifyHandler: () => void) => {
    showNotification(NotificationType.ERROR, 'Error scheduling your Appointment, Please try again', 3000);
    afterNotifyHandler();
    dispatch(BillingActions.ResetConsultationInvoice());
  };

  return schedulingFailure;
}

export default useSchedulingFailure;
