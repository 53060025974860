'use client';
import React, { useEffect, useState } from 'react';

import 'reactjs-bottom-navigation/dist/index.css';
import { usePathname, useRouter } from 'next/navigation';
import NextNProgress from 'nextjs-progressbar';

import { colors } from '@Components/design-system/color';
import Loader from '@Components/design-system/loader';
import { useAppSelector } from '@Redux/hooks';
import { smallScreenWidth } from '@Utils/constants';
import { getCurrentPage, isTransitionPage } from '@Utils/helper';
import useLoader from '@Utils/hooks/is-loading';

import styles from '../../styles/Layout.module.css';
import { BrandingHeader } from '../branding-header';
import Header from '../header';

interface LayoutProps {
  children: any;
  enableHeader?: boolean;
  width?: number;
  backgroundColor?: string;
  className?: string;
  screenBackgroundColor?: string;
  disableShadow?: boolean;
  enableWithoutAuth?: boolean;
  hidesupport?: boolean;
  enablebrandingheader?: boolean;
  isReportPreview?: boolean;
  loaderStates?: Record<string, boolean>;
  showLogout?: boolean;
  disablePaddingTop?: boolean;
}

const Layout = ({
  hidesupport = true,
  isReportPreview = false,
  showLogout = true,
  disablePaddingTop = false,
  ...props
}: LayoutProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const loading = useLoader(props.loaderStates);
  const { isAuthenticated, isLoading } = useAppSelector((state) => state.auth);
  const [windowWidth, setWindowWidth] = useState(window?.innerWidth);
  const isMobile = windowWidth < smallScreenWidth;
  const showTransitionLoader = window?.location?.href?.includes('embedded=true') && !isTransitionPage(getCurrentPage());

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !props?.enableWithoutAuth) {
      if (pathname.includes('login')) {
        router.push(pathname); // To retain the phone number passed from lead form
      } else if (!props?.enableWithoutAuth) {
        router.push('/login');
      }
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window?.innerWidth);
    };

    window?.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => window?.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      {loading || showTransitionLoader ? (
        <div className={styles.loaderWrapper + ' static'}>
          <Loader size={3} />
        </div>
      ) : (
        <></>
      )}
      <div
        className='innerWrap'
        style={
          isReportPreview
            ? {
                background: props.screenBackgroundColor ?? colors.PRIMARY_25,
                backgroundImage: `url(${isMobile ? 'assets/mobile-report-mask-bg.png' : 'assets/report-mask-bg.png'} )`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                paddingTop:
                  (props.enableHeader || props.enablebrandingheader) && !disablePaddingTop ? '80px' : undefined,
                visibility: showTransitionLoader ? 'hidden' : 'visible',
              }
            : {
                background: props.screenBackgroundColor ?? colors.PRIMARY_25,
                paddingTop:
                  (props.enableHeader || props.enablebrandingheader) && !disablePaddingTop ? '80px' : undefined,
                visibility: showTransitionLoader ? 'hidden' : 'visible',
              }
        }>
        <div
          className={
            styles.container +
            ' overflow-y-hidden layout ' +
            props.className +
            (props.disableShadow ? '' : ' layoutContent ')
          }>
          {props.enablebrandingheader ? <BrandingHeader /> : undefined}

          {props.enableHeader && !props.enablebrandingheader ? (
            <Header isAuthenticated={isAuthenticated} showSupport={!hidesupport} showLogout={showLogout} />
          ) : undefined}
          <NextNProgress
            color={colors.PRIMARY_1000}
            startPosition={0.3}
            stopDelayMs={200}
            height={4}
            showOnShallow={true}
          />
          <div
            className={
              'flex overflow-y-auto flex-col ' +
              (props.disableShadow ? '' : 'layoutContent ') +
              (isReportPreview ? ' arLoginInnerChildrenWrap' : ' innerChildrenWrap')
            }
            style={{
              background: props?.backgroundColor ?? 'white',
            }}>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
