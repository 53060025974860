import { usePathname } from 'next/navigation';
import React, { useEffect } from 'react';

import { AuthActions } from '@Actions/index';
import { useAppDispatch } from '@Redux/hooks';

export const AuthorizeContext = React.createContext({});

const AppStartupProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const pathname = usePathname();

  useEffect(() => {
    if (pathname !== '/logout') {
      dispatch(AuthActions.InitAuthState());
    }
  }, []);

  return <>{children}</>;
  // Move patient auth provider logic here
  // // Once the person has logged in Fetch his/her details from the backend
  // // Update all the query data
};

export default AppStartupProvider;
