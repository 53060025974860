import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { EncounterActions, MetaDataActions, PatientActions, ProviderActions, UserActions } from '@Actions/index';
import AskSexpertAI from '@Components/assessment-report/ask-sexpert-ai';
import DiagnosesSummary from '@Components/assessment-report/diagnoses-summary';
import DiagnosisSummaryCard from '@Components/assessment-report/diagnosis-summary-card';
import Disclaimer from '@Components/assessment-report/disclaimer';
import DoctorsGrid from '@Components/assessment-report/doctors-grid';
import HowToStart from '@Components/assessment-report/how-to-start';
import ImprovementTrustMarker from '@Components/assessment-report/improvement-trust-maker';
import MediaGrid from '@Components/assessment-report/media-grid';
import NextStepBox from '@Components/assessment-report/next-step';
import Testimonials from '@Components/assessment-report/testimonials';
import TreatmentSuggestions from '@Components/assessment-report/treatment-suggestions';
import TrustMakers from '@Components/assessment-report/trust-makers';
import WhatToAvoid from '@Components/assessment-report/what-to-avoid';
import ChatBot from '@Components/chatbot/chatbot';
import { ModalProvider } from '@Components/conditional-wrappers/modal-provider';
import Layout from '@Components/core/layout';
import { AuthorizeContext, PatientAuthData } from '@Components/core/patient-auth-provider';
import { Button } from '@Components/design-system/button/button';
import { colors } from '@Components/design-system/color';
import { ColorType } from '@Components/design-system/color/types';
import Text from '@Components/design-system/text';
import { FontType } from '@Components/design-system/text/types';
import EmptyDataSection from '@Components/empty-data-section';
import LanguageDropdown from '@Components/language-dropdown';
import MiniScheduler from '@Components/mini-scheduling';
import { Theme } from '@Components/mini-scheduling/mini-scheduling.component';
import { UndeterminedCauseCard } from '@Components/undetermined-card';
import { IQueryData } from '@Reducers/common';
import { IConsultationType } from '@Reducers/consultation';
import { IAilments, IAssessmentData, IDiagnosis } from '@Reducers/encounter';
import { LanguageCode } from '@Reducers/metadata';
import { IPatient } from '@Reducers/patient';
import { IProvider } from '@Reducers/provider';
import { IUser, IUserData, PatientStatus } from '@Reducers/user';
import { useAppSelector, useAppDispatch } from '@Redux/hooks';
import {
  smallScreenWidth,
  CONSULTATION_CODE_DEFAULT,
  PAGE,
  LANGUAGE,
  DEFAULT_LOCATION_CODE,
  alloPhoneNumber,
} from '@Utils/constants';
import { filterShowcaseDoctors, formatName } from '@Utils/helper';
import { isUuid } from '@Utils/Helpers/AssessmentReport/helper';
import useCustomRouter from '@Utils/hooks/custom-router';
import { useTrans } from '@Utils/hooks/translation';

import i18n from '../../../i18n';

export const enum CollapsableListTypes {
  causes = 'causes',
  treatments = 'treatments',
  solutions = 'solutions',
}

function AssessmentReport() {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const customRouter = useCustomRouter();
  const patientAuthData: PatientAuthData = useContext(AuthorizeContext) as PatientAuthData;
  const patient: IPatient = useAppSelector((state) => state.patient);
  const user: IUser = useAppSelector((state) => state.user);
  const userData: IUserData = useAppSelector((state) => state.user?.userData);
  const encounter = useAppSelector((state) => state.encounter);
  const common = useAppSelector((state) => state.common);
  const consultationType: IConsultationType = useAppSelector((state) => state.consultation.consultationType);
  const queryData: IQueryData = common?.queryData;
  const offlineClinicLocations = useAppSelector((state) => state.location.offlineClinicLocations);

  const metadata: Record<string, any[]> = useAppSelector((state) => state.metadata.metadata);
  const provider: IProvider = useAppSelector((state) => state.provider);
  const assessmentData: IAssessmentData = encounter?.assessmentData;
  const assessmentDataRef = useRef(null);
  assessmentDataRef.current = assessmentData;
  const diagnoses: IDiagnosis[] = assessmentData?.diagnoses;
  const ailments: IAilments[] = assessmentData?.ailments;
  const treatments = assessmentData?.treatmentReferences || [];
  const resources = patient?.resourceData;
  const doctorList = filterShowcaseDoctors(Object.values(provider?.allProviderData));

  const [selectedDiagnosis, setSelectedDiagnosis] = useState(diagnoses?.[0]);
  const [showCallBtn, setShowCallBtn] = useState(false);
  const changeDiagnosis = (diagnosis) => {
    setSelectedDiagnosis(diagnosis);
  };

  const { t, languageUsed } = useTrans('ax');
  const [language, setLanguage] = useState(i18n.language === LanguageCode.English ? LANGUAGE.ENGLISH : LANGUAGE.HINDI);
  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const diagnosysSection = useRef<HTMLDivElement>(null);
  const symptomsSection = useRef<HTMLDivElement>(null);
  const treatmentSection = useRef<HTMLDivElement>(null);
  const whatToAvoidSection = useRef<HTMLDivElement>(null);
  const whyTrustSection = useRef<HTMLDivElement>(null);

  const screeningNav = {
    diagnosysSection: {
      nav_item: 'Diagnoses',
      scroll_to: diagnosysSection,
    },
    symptomsSection: {
      nav_item: 'Causes & Symptoms',
      scroll_to: symptomsSection,
    },
    treatmentSection: {
      nav_item: 'Treatment Options',
      scroll_to: treatmentSection,
    },
    whyTrustSection: {
      nav_item: 'Why trust Allo?',
      scroll_to: whyTrustSection,
    },
    whatToAvoidSection: {
      nav_item: 'What to Avoid',
      scroll_to: whatToAvoidSection,
    },
  };

  const elementRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!router.isReady || !assessmentData?.id) return;
    i18n.changeLanguage(assessmentData?.language);
  }, [router.isReady, assessmentData]);

  useEffect(() => {
    setSelectedDiagnosis(diagnoses?.[0]);
  }, [diagnoses]);

  useEffect(() => {
    if (!router.isReady || !userData?.id) return;
    if (!user?.isLoading) dispatch(UserActions.GetUser(userData?.id));
    dispatch(
      EncounterActions.GetAssessmentData({
        ipxId: queryData?.ipxId,
        reference: assessmentDataRef,
        patientId: userData?.id,
      }),
    );
  }, [router.isReady, userData?.id, queryData?.ipxId]);

  useEffect(() => {
    if (!assessmentData?.version) return;
    if (assessmentData?.version !== '2.0') {
      //TODO: remove try catch block after migrating to next 13 stable
      try {
        router.push('/assessment-report');
      } catch {
        window.location.href = '/assessment-report';
      }
    }
  }, [router.isReady, assessmentData]);

  const handleScroll = () => {
    if (elementRef.current === null) return;
    if (window.innerWidth < smallScreenWidth) return;
    const rect = elementRef.current.getBoundingClientRect();
    if (rect.top <= 0 && window.scrollY !== 0) {
      setIsFixed(!isFixed);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    if (Object.keys(provider?.allProviderData).length > 0 || !userData?.id) return;
    dispatch(ProviderActions.GetAllProviderData());
  }, [router.isReady, userData?.id]);

  useEffect(() => {
    if (!router.isReady) return;
    i18n.init({});
  }, [router.isReady]);

  useEffect(() => {
    if (!assessmentData.date || !patientAuthData?.isAuthenticated) return;
    const diagnosesReferenceIds = diagnoses?.map((diagnosis) => diagnosis?.medicalCondition?.id);
    const ailmentsReferenceIds = ailments?.map((ailment) => ailment?.ailment?.id);

    dispatch(
      MetaDataActions.GetMetaDataForReferenceIds({
        referenceIds: [...diagnosesReferenceIds, ...(ailmentsReferenceIds || [])],
        languageCode: Object.values(LanguageCode).includes(i18n.language as LanguageCode)
          ? (i18n.language as LanguageCode)
          : LanguageCode.English,
      }),
    );
  }, [assessmentData, patientAuthData?.isAuthenticated, i18n?.language]);

  useEffect(() => {
    if (!assessmentData.date || !patientAuthData?.isAuthenticated || treatments?.length === 0) return;
    const isTreatmentUuid = isUuid(treatments?.[0]);
    dispatch(
      PatientActions.GetResourcesData({
        ...(isTreatmentUuid ? { ids: treatments } : { codes: treatments }),
        languageCode: Object.values(LanguageCode).includes(i18n.language as LanguageCode)
          ? (i18n.language as LanguageCode)
          : LanguageCode.English,
      }),
    );
  }, [assessmentData, patientAuthData?.isAuthenticated, i18n?.language]);

  const handleRedirection = () => {
    customRouter({
      pathname: PAGE.APPOINTMENT.ROUTE,
      query: {
        form_type: 'AR_SC',
        product_code: CONSULTATION_CODE_DEFAULT,
        location_code: queryData?.locationCode || DEFAULT_LOCATION_CODE,
        selected_languages: queryData?.selectedLanguages,
        ...(queryData?.locationCode !== DEFAULT_LOCATION_CODE && {
          voucher_code: queryData?.voucherCode,
        }),
      },
    });
  };

  useEffect(() => {
    setShowCallBtn(
      assessmentData.diagnoses?.length > 0 &&
        assessmentData.diagnoses.some(
          (d) => d?.medicalCondition?.name === 'Erectile Dysfunction' && d?.severity === 'severely',
        ) &&
        Object.keys(offlineClinicLocations)
          .map((l) => l.toLowerCase())
          .includes(user.ipCity?.city),
    );
  }, [assessmentData, offlineClinicLocations, user]);

  return (
    <>
      <Layout
        enableHeader={true}
        hidesupport={true}
        backgroundColor={ColorType.WHITE}
        loaderStates={{ patient: patient?.isLoading }}>
        {patientAuthData?.isAuthenticated && (
          <Container>
            {
              /* EMPTY REPORT */
              !assessmentData?.date ? (
                <EmptyDataSection message={t('report-unavailable')} />
              ) : (
                // REPORT RENDER
                <div className={'flex flex-col overflow-hidden relative'}>
                  {!!assessmentData?.date && (
                    <div className='bg-BLUE_GREY_75'>
                      {assessmentData?.language !== LanguageCode.English && (
                        <LanguageDropdown
                          language={language}
                          setLanguage={setLanguage}
                          handleChangeLanguage={handleChangeLanguage}
                        />
                      )}
                      <RenderWelcomePatientHeader />
                      <div ref={diagnosysSection}>
                        <PatientSummaryCard
                          userData={userData}
                          assessmentData={assessmentData}
                          diagnoses={diagnoses}
                          metadata={metadata}
                          changeDiagnosis={changeDiagnosis}
                          handleBooking={handleRedirection}
                          mainNav={screeningNav}
                          ailments={ailments}
                        />
                      </div>
                      <div className=''>
                        <NextStepBox handleBooking={handleRedirection} />
                      </div>
                      <div className='px-4 py-6'>
                        <TrustMakers />
                      </div>
                      {userData?.id && userData?.status === PatientStatus.Created && (
                        <div
                          className='bg-PRIMARY_1000 pt-8'
                          style={{
                            backgroundImage: 'url("/assets/svgs/bg-cubes.svg")',
                            backgroundSize: 'fit',
                          }}>
                          <MiniScheduler
                            theme={Theme.DARK}
                            handleRedirection={handleRedirection}
                            flow={PAGE.ASSESSMENT_REPORT.NAME}
                          />
                          <div className='pb-6'>
                            <DoctorsGrid doctorlist={doctorList} theme={'dark'} />
                          </div>
                        </div>
                      )}
                      {diagnoses?.length > 0 ? (
                        <>
                          <div ref={symptomsSection}>
                            <DiagnosesSummary
                              diagnoses={diagnoses}
                              ailments={ailments}
                              metadata={metadata}
                              handleBooking={handleRedirection}
                            />
                          </div>
                          <div className='px-4 pt-6' ref={whatToAvoidSection}>
                            <WhatToAvoid />
                          </div>
                          <div className='relative' ref={treatmentSection}>
                            <TreatmentSuggestions
                              diagnoses={diagnoses}
                              resources={resources}
                              handleBooking={handleRedirection}
                            />
                          </div>
                        </>
                      ) : (
                        <UndeterminedCauseCard
                          handleBooking={handleRedirection}
                          amount={consultationType?.staticPrice}
                        />
                      )}
                    </div>
                  )}
                  <div className='px-4 pt-6 bg-BLUE_GREY_75'>
                    <HowToStart redirectToBooking={handleRedirection} />
                  </div>
                  <div>
                    <ImprovementTrustMarker />
                  </div>
                  <div ref={whyTrustSection}>
                    <Testimonials theme={Theme.DARK} />
                  </div>
                  <div className='px-4 pt-6'>
                    <AskSexpertAI />
                  </div>
                  <div className='px-4 py-6'>
                    <MediaGrid />
                  </div>
                  <div className='px-4 pt-6'>
                    <Disclaimer />
                  </div>
                  <div className='mx-4 mt-8 mb-36 h-[1px] bg-GREYS_400' />
                  <div className='fixed bottom-0 w-full md:w-128 z-20 p-4 bg-GREYS_100 '>
                    <Text
                      font={FontType.REGULAR_11_170}
                      color={ColorType.GREYS_900}
                      language={languageUsed}
                      className='text-center pb-2'>
                      {t('have-doubts-about-your-report')}
                    </Text>
                    <div className='flex space-x-4'>
                      {showCallBtn && (
                        <Button
                          onPress={handleCallUsBtn}
                          label={
                            <Text language={languageUsed} font={FontType.SEMIBOLD_14_150} className='mx-1'>
                              {t('call-us.call-now')}
                            </Text>
                          }
                          prefixIcon={<Image src='/assets/call-black.png' width={16} height={16} alt='Call Us' />}
                          size='medium'
                          type='primary'
                          secondaryBackgroundColour={``}
                          justifyContent='center'
                          fullWidth={true}
                          borderRadius={999}
                          primaryColour='bg-white'
                          labelColour={colors.GREYS_700}
                        />
                      )}
                      <Button
                        onPress={() => handleRedirection()}
                        label={
                          <Text language={languageUsed} font={FontType.SEMIBOLD_14_150} color={ColorType.GREYS_100}>
                            {t(`book-appointment-now`)}
                          </Text>
                        }
                        size='medium'
                        type='secondary'
                        secondaryBackgroundColour={``}
                        justifyContent='center'
                        fullWidth={true}
                        borderRadius={999}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          </Container>
        )}
      </Layout>
      {(userData.status === PatientStatus.Created || userData.status === PatientStatus.FirstEvaluationBooked) && (
        <ChatBot />
      )}
    </>
  );
}

const RenderWelcomePatientHeader = () => {
  const { t, languageUsed } = useTrans('ax');
  return (
    <div className={'flex flex-col gap-1 mx-auto items-center pt-3 bg-BLUE_GREY_75 self-center'}>
      <div className='flex flex-col items-center gap-1'>
        <div className='flex items-center gap-1'>
          <Image src='/assets/svgs/shield-check-primary.svg' width={24} height={24} alt='Allo Logo' />
          <Text font={FontType.SEMIBOLD_14_150} color={ColorType.PRIMARY_600} language={languageUsed}>
            {t('safety-trust-marker.title')}
          </Text>
        </div>
        <Text
          font={FontType.REGULAR_11_170}
          color={ColorType.GREYS_900}
          language={languageUsed}
          className='w-[312px] text-center'>
          {t('safety-trust-marker.description')}
          <br />
          {t('safety-trust-marker.subDescription')}
        </Text>
      </div>
      <Text
        font={FontType.SEMIBOLD_14_150}
        color={ColorType.GREYS_700}
        language={languageUsed}
        className='w-[312px] text-center pt-4'>
        {t('safety-trust-marker.your-assessment-report')}
      </Text>
    </div>
  );
};

const handleCallUsBtn = () => {
  window.location.href = `tel:${alloPhoneNumber}`;
};

const Container = styled.div.attrs({
  className: 'flex-1 flex-col items-center justify-center pb-16',
})`
  background: ${colors.GREYS_300};
`;

export default function Wrapper() {
  return (
    <ModalProvider>
      <AssessmentReport />
    </ModalProvider>
  );
}

export const PatientSummaryCard = ({
  userData,
  assessmentData,
  diagnoses,
  metadata,
  changeDiagnosis,
  handleBooking,
  mainNav,
  ailments,
}: {
  userData: IUserData;
  assessmentData: any;
  diagnoses: IDiagnosis[];
  metadata: Record<string, any[]>;
  changeDiagnosis: (diagnosis: any) => void;
  handleBooking?: () => void;
  mainNav?: Record<string, { nav_item: string; scroll_to: any }>;
  ailments?: IAilments[];
}) => {
  const { t, languageUsed } = useTrans('ax');
  return (
    <div className='m-4 p-4 bg-PRIMARY_1000 rounded-lg shadow relative'>
      <div className='self-stretch flex-col justify-start items-start gap-1 flex'>
        <div className=' flex flex-col self-stretch justify-between'>
          <Text font={FontType.SEMIBOLD_24_150} color={ColorType.WARNING_200}>
            {userData?.firstName && userData?.lastName
              ? formatName(userData?.firstName + ' ' + userData?.lastName)
              : formatName(userData?.firstName)}
          </Text>
          <Text className='flex justify-between' font={FontType.REGULAR_14_150} color={ColorType.WHITE}>
            {[(userData?.gender || '') + (userData?.age || ''), formatName(userData?.address?.city)]
              .filter((i) => i !== '')
              .join(' | ')}
            {assessmentData?.bmi && <span className='font-semibold'>BMI: {assessmentData?.bmi}</span>}
          </Text>
        </div>
        <div className='self-stretch justify-between items-center inline-flex'>
          <Text font={FontType.REGULAR_14_150} color={ColorType.WHITE} language={languageUsed} className={'pt-6 pb-1'}>
            {diagnoses?.length === 1
              ? t('possible-cause-singular')
              : diagnoses?.length > 1
              ? diagnoses?.length + ' ' + t('possible-cause-plural')
              : ''}
          </Text>
        </div>
        {diagnoses?.map((concern) => (
          <div
            key={concern?.id}
            onClick={() => changeDiagnosis && changeDiagnosis(concern)}
            className='w-full'
            onKeyDown={() => changeDiagnosis && changeDiagnosis(concern)}>
            <DiagnosisSummaryCard
              diagnosis={concern}
              metadata={metadata}
              handleBooking={handleBooking}
              mainNav={mainNav}
              diagnoses={diagnoses}
              ailments={ailments}
            />
          </div>
        ))}
        {diagnoses?.length === 0 && <DiagnosisSummaryCard diagnosis={{}} metadata={undefined} />}
      </div>
    </div>
  );
};
