import { AxiosResponse } from 'axios';

import { Axios } from '../AxiosApiServerWrapper';

export const UserService = {
  GetEvaluationState: async (payload: { ipxId?: string }): Promise<AxiosResponse> => {
    const url = `core/patients/state`;
    return Axios.Request('GET', url, {}, { ipxId: payload?.ipxId });
  },
  GetUserIpCity: async (): Promise<AxiosResponse> => {
    const url = `vendors/ip`;
    return Axios.Request('GET', url, {}, {});
  },
};
