import Image from 'next/image';

import { useTrans } from '@Utils/hooks/translation';

import { ColorType } from './design-system/color/types';
import Text from './design-system/text';
import { FontType } from './design-system/text/types';

const SelectedLanguages = ({
  handleLaunchLanguageModal,
  selectedLanguages,
  isThemeDark,
}: {
  handleLaunchLanguageModal: (value: boolean) => void;
  selectedLanguages: string[];
  isThemeDark: boolean;
}) => {
  const { t, languageUsed } = useTrans();
  return (
    <button
      onClick={() => handleLaunchLanguageModal(true)}
      className={`flex flex-col gap-1 px-4 py-3 ${
        isThemeDark ? 'bg-PRIMARY_25' : 'bg-white shadow-evenly'
      } rounded-md`}>
      <div className='flex flex-wrap justify-between gap-2 w-full'>
        <Text font={FontType.REGULAR_11_170} color={ColorType.GREYS_900}>
          {!selectedLanguages?.length && 'No language selected'}
          {selectedLanguages?.slice(0, 3).join(', ')}&nbsp;
          <span className='text-INDIGO_600'>
            {selectedLanguages?.length > 3 ? `+${selectedLanguages?.length - 3} more` : ''}
          </span>
        </Text>
        <div className='flex gap-1 items-center'>
          <Text font={FontType.SEMIBOLD_11_170} color={ColorType.INDIGO_600} language={languageUsed}>
            {t('general.change')}
          </Text>
          <Image
            src={'/assets/svgs/chevron-indigo.svg'}
            height={16}
            width={16}
            className='transform -rotate-90'
            alt='allo icon'
            layout='fixed'
          />
        </div>
      </div>
    </button>
  );
};

export default SelectedLanguages;
