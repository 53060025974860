// eslint-disable-next-line unicorn/prefer-node-protocol
import { createHash } from 'crypto';

import Cookies from 'js-cookie';
import { useRef } from 'react';

import { OrderActions, AnalyticsActions } from '@Actions/index';
import { IAnalytics } from '@Reducers/analytics';
import { IConsultationInvoice } from '@Reducers/billing';
import { IConsultationType } from '@Reducers/consultation';
import { IUserData } from '@Reducers/user';
import { useAppDispatch, useAppSelector } from '@Redux/hooks';
import { UpdateLeadPayload } from '@Services/Analytics/analytics-payload';
import { InvoiceMode } from '@Services/Billing/billing-payload';
import * as gtm from '@Utils/analytics/gtm';
import { decodeCookie } from '@Utils/helper';
import { LOG_ERROR } from '@Utils/logger';
import SUMO_LOGGER from '@Utils/sumo-logger';

//only for consultation payment
//TODO: support prescription payment
const usePayment = () => {
  const dispatch = useAppDispatch();

  const userData: IUserData = useAppSelector((state) => state.user.userData);
  const consultationInvoice: IConsultationInvoice = useAppSelector((state) => state.billing.consultationInvoice);
  const consultationInvoiceRef = useRef(null);
  consultationInvoiceRef.current = consultationInvoice;
  const prescriptionInvoice: IConsultationInvoice = useAppSelector((state) => state.billing.prescriptionInvoice);
  const prescriptionInvoiceRef = useRef(null);
  prescriptionInvoiceRef.current = prescriptionInvoice;
  const inventoryItemInvoice: IConsultationInvoice = useAppSelector((state) => state.billing.inventoryItemPurchase);
  const inventoryItemPurchaseRef = useRef(null);
  inventoryItemPurchaseRef.current = inventoryItemInvoice;

  const analytics: IAnalytics = useAppSelector((state) => state.analytics);
  const consultationType: IConsultationType = useAppSelector((state) => state.consultation.consultationType);

  const openPaymentModal = ({
    itemType,
    onDismissFunction,
    onFailFunction,
    onSuccess,
  }: {
    itemType: 'rx' | 'consultation' | 'inventoryItem';
    onDismissFunction?: () => void;
    onFailFunction?: () => void;
    onSuccess?: () => void;
  }) => {
    let invoice;
    switch (itemType) {
      case 'rx':
        invoice = prescriptionInvoiceRef.current;
        break;
      case 'consultation':
        invoice = consultationInvoiceRef.current;
        break;
      case 'inventoryItem':
        invoice = inventoryItemPurchaseRef.current;
        break;
    }

    if (
      invoice?.id &&
      invoice?.payableAmount > 1 &&
      invoice?.externalRequestId &&
      invoice?.mode === InvoiceMode.Prepaid
    ) {
      try {
        const razorpay_options = {
          key: process.env.NEXT_PUBLIC_RAZORPAY_API_KEY,
          amount: invoice.payableAmount,
          name: 'Allo Health',
          image: 'https://cdn.razorpay.com/logos/KEzHoV2GnJEM7P_medium.png',
          handler: (response) => razorpayCallbackHandler(response, invoice, onSuccess),
          prefill: {
            contact: userData?.phoneNumber,
            email: userData?.email,
          },
          modal: {
            ondismiss: () => {
              if (onDismissFunction) onDismissFunction();
            },
          },
          config: {
            display: {
              hide:
                invoice.payableAmount < 70_000
                  ? [{ method: 'wallet' }, { method: 'paylater' }, { method: 'emi' }, { method: 'cardless_emi' }]
                  : [],
            },
          },
        };
        initiatedPaymentEvents(invoice);
        const order_id = invoice?.externalRequestId;
        razorpay_options['amount'] = invoice.payableAmount;
        razorpay_options['order_id'] = order_id;
        const razorpay = (window as any).Razorpay(razorpay_options);
        razorpay.open();
        razorpay.on('payment.failed', function () {
          SUMO_LOGGER('error', `Payment failed for number ${userData.phoneNumber}`);
          console.warn('Payment failed. Please try again');
          if (onFailFunction) onFailFunction();
        });
      } catch (error) {
        SUMO_LOGGER('error', `Error in initiating payment with razorpay ${userData?.phoneNumber}`, error);
        LOG_ERROR('Error in initiating payment with razorpay', error);
        if (onFailFunction) onFailFunction();
      }
    } else if (invoice?.id && invoice?.payableAmount === 0) {
      dispatch(OrderActions.UpdateOrderConfirmed(true));
    } else if (invoice?.id && invoice?.mode === InvoiceMode.Postpaid) {
      dispatch(OrderActions.UpdateOrderConfirmed(true));
    }
  };
  const initiatedPaymentEvents = (invoice) => {
    gtm.userEvent({
      event: 'payment_initiated',
      phone: userData.phoneNumber,
      amount: invoice.payableAmount,
      product_type: consultationType?.consultationCode,
      utm_source: decodeCookie(Cookies.get('utm_source')),
      acq_source: analytics.leadData?.utmSource || decodeCookie(Cookies.get('utm_source')),
      fbclid: decodeCookie(Cookies.get('fbclid')),
    });
  };

  const completedPaymentEvents = (amount, razorpayResponse, invoiceId) => {
    // Update lead
    const utm_medium = decodeCookie(Cookies.get('utm_medium')) || analytics?.leadData?.utmMedium;
    const utm_source = decodeCookie(Cookies.get('utm_source')) || analytics?.leadData?.utmSource;
    const utm_campaign = decodeCookie(Cookies.get('utm_campaign')) || analytics?.leadData?.utmCampaign;
    const utm_content = decodeCookie(Cookies.get('utm_content')) || analytics?.leadData?.utmContent;
    const utm_term = decodeCookie(Cookies.get('utm_term')) || analytics?.leadData?.utmTerm;
    const gclid = decodeCookie(Cookies.get('gclid')) || analytics?.leadData?.gclid;
    const fbclid = decodeCookie(Cookies.get('fbclid')) || analytics?.leadData?.fbclid;
    const fbc = decodeCookie(Cookies.get('fbc')) || analytics?.leadData?.fbc;
    const fbp = decodeCookie(Cookies.get('fbp')) || analytics?.leadData?.fbp;
    const CreateOrUpdateLeadPayload: UpdateLeadPayload = {
      phoneNo: userData.phoneNumber,
      paymentUtmMedium: utm_medium,
      paymentUtmSource: utm_source,
      paymentUtmCampaign: utm_campaign,
      paymentUtmContent: utm_content,
      paymentUtmTerm: utm_term,
      paymentGclid: gclid,
      paymentFbclid: fbclid,
      paymentFbc: fbc,
      paymentFbp: fbp,
    };

    const url = window.location.href;
    if (!analytics?.leadData?.utmSource && url.includes('/appointment') && !url.includes('reschedule')) {
      LOG_ERROR('No utm source founds in lead details', analytics?.leadData);
    }

    const gtmPayload = {
      event: 'payment_completed',
      phone: userData.phoneNumber,
      hashedPhone: createHash('sha256')?.update(userData.phoneNumber).digest('hex'),
      amount: amount,
      payment_id: razorpayResponse.razorpay_payment_id,
      product_type: consultationType?.consultationCode,
      utm_source: decodeCookie(Cookies.get('utm_source')) || analytics?.leadData?.utmSource,
      utm_campaign: decodeCookie(Cookies.get('utm_campaign')) || analytics?.leadData?.utmCampaign,
      acq_source: analytics?.leadData?.utmSource || decodeCookie(Cookies.get('utm_source')),
      fbclid: decodeCookie(Cookies.get('fbclid')),
      invoiceId: invoiceId,
      userId: userData.id,
    };

    if (!utm_campaign?.startsWith('MCV_')) {
      gtm.userEvent(gtmPayload);
    }

    if (utm_campaign?.startsWith('MCV_')) {
      gtm.userEvent({
        event: 'appointment_booked',
        phone: userData.phoneNumber,
        // eslint-disable-next-line unicorn/numeric-separators-style
        amount: amount <= 19900 ? 91200 : 246600,
        utm_source: decodeCookie(Cookies.get('utm_source')) || analytics?.leadData?.utmSource,
        utm_campaign: decodeCookie(Cookies.get('utm_campaign')) || analytics?.leadData?.utmCampaign,
        acq_source: analytics?.leadData?.utmSource || decodeCookie(Cookies.get('utm_source')),
        fbclid: decodeCookie(Cookies.get('fbclid')),
        invoiceId: invoiceId,
        userId: userData.id,
      });
    }

    if (Object.entries(CreateOrUpdateLeadPayload).some(([key, value]) => key !== 'phoneNo' && value)) {
      dispatch(AnalyticsActions.UpdateLead(CreateOrUpdateLeadPayload));
    }
  };

  const razorpayCallbackHandler = (razorpayResponse, invoice, onSuccess?) => {
    if (razorpayResponse?.razorpay_payment_id) {
      completedPaymentEvents(invoice?.payableAmount, razorpayResponse, invoice?.id);
      dispatch(OrderActions.UpdateOrderConfirmed(true));
      if (onSuccess) onSuccess();
    }
  };

  return openPaymentModal;
};

export default usePayment;
