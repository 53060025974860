import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import { BillingActions, LocationActions } from '@Actions/index';
import { PopProviderProps, PopupContext, PopupModals } from '@Components/conditional-wrappers/modal-provider';
import { NotificationType, showNotification } from '@Components/design-system/notification/notification';
import { IConsultationInvoice } from '@Reducers/billing';
import { IQueryData, updateLocationCode } from '@Reducers/common';
import { IOrder } from '@Reducers/order';
import { ISchedule, ISlot, ISlotData } from '@Reducers/schedule';
import { IUserData } from '@Reducers/user';
import { useAppDispatch, useAppSelector } from '@Redux/hooks';
import { InvoiceMode } from '@Services/Billing/billing-payload';
import { DEFAULT_LOCATION_CODE, PAGE, SLOT_SELECTION } from '@Utils/constants';
import useBookConsultation from '@Utils/hooks/book-consultation';
import useCustomRouter from '@Utils/hooks/custom-router';
import useGetLocations from '@Utils/hooks/get-locations';
import useConsultationType from 'modules/appointment/hooks/consultation-type/v1';
import useSelectables from 'modules/appointment/hooks/selectables/v1';
import useListSlots from 'modules/appointment/hooks/slots';

import MiniSchedulerComponent, { Theme } from './mini-scheduling.component';

// Props type
export type MiniSchedulerProps = {
  theme: Theme;
  handleRedirection: () => void;
  flow: string;
};

const MiniScheduler = (props: MiniSchedulerProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { clinicLocations } = useGetLocations();
  const bookConsultation = useBookConsultation();
  const customRouter = useCustomRouter();
  const { locationType, consultationType } = useConsultationType();
  const { listSlots, slots, trackSlots } = useListSlots(consultationType, locationType);
  const { selectables, changeDay, changeSlot } = useSelectables(slots, (eventName: string, slot: ISlot) =>
    trackSlots(eventName, slot, PAGE.ASSESSMENT_REPORT.NAME),
  );

  //contexts
  const popModal: PopProviderProps = useContext(PopupContext) as PopProviderProps;

  //redux states
  const userData: IUserData = useAppSelector((state) => state.user.userData);
  const order: IOrder = useAppSelector((state) => state.order);
  const queryData: IQueryData = useAppSelector((state) => state.common.queryData);
  const schedule: ISchedule = useAppSelector((state) => state.schedule);
  const consultationInvoice: IConsultationInvoice = useAppSelector((state) => state.billing.consultationInvoice);
  const consultationInvoicePreview: IConsultationInvoice = useAppSelector(
    (state) => state.billing.consultationInvoicePreview,
  );

  const consultationModeEligibility = Boolean(queryData?.validUserCity);

  //derived states
  const slotsData: ISlotData = schedule?.slotsData;
  const selectedLanguages = queryData?.selectedLanguages;
  const [slotsInitialized, setSlotsInitialized] = useState(false);

  const isOffline = queryData?.locationCode && queryData?.locationCode !== DEFAULT_LOCATION_CODE;

  useEffect(() => {
    if (consultationType?.isLoading || !locationType?.id || !consultationType?.consultationTypeId || slotsInitialized)
      return;
    listSlots();
    setSlotsInitialized(true);
  }, [consultationType?.isLoading, locationType?.id, consultationType?.consultationTypeId]);

  useEffect(() => {
    if (!userData?.id) return;
    dispatch(LocationActions.GetClinicLocations());
  }, [userData?.id]);

  useEffect(() => {
    if (Object.keys(clinicLocations).length === 0 || !queryData?.validUserCity) return;
    dispatch(updateLocationCode(clinicLocations[queryData?.validUserCity][0]?.code));
  }, [clinicLocations]);

  //change location
  const onChangeLocation = async (locationCode: string) => {
    new Promise((resolve) => {
      dispatch(updateLocationCode(locationCode));
      resolve('done');
    })
      .then(() => {
        listSlots();
        return;
      })
      .catch(() => {
        /* eslint-disable-next-line no-empty */
      });
  };

  const handleLaunchLanguageModal = () => {
    popModal.showPopup(PopupModals.ChooseLanguages, () => listSlots);
  };

  const handleBookAppointment = (selectedSlot, payOnArrival: boolean) => {
    //check if language is selected for online consultation
    if (!queryData.selectedLanguages?.length && !isOffline) {
      showNotification(NotificationType.ERROR, 'Please select language to continue', 3000);
      handleLaunchLanguageModal();
      return;
    }

    bookConsultation({
      selectedSlot,
      payOnArrival,
      activityNote: 'Booking Attempted From Assessment Report',
      getSlots: listSlots,
      trackSlots: (eventName) => trackSlots(eventName, selectables?.slot, PAGE.ASSESSMENT_REPORT.NAME),
      typeId: consultationType?.consultationTypeId,
      locationId: locationType?.id,
    });
  };

  //route to confirmation page after payment
  useEffect(() => {
    if (!order?.orderConfirmed) return;
    trackSlots(SLOT_SELECTION.PAYMENT_COMPLETED, selectables?.slot, PAGE.ASSESSMENT_REPORT.NAME);
    if (consultationInvoice?.mode === InvoiceMode.Postpaid) {
      popModal.showPopup(PopupModals.ConsultationConfirmation, undefined, undefined, {
        appointmentDate: queryData?.date,
        consultationPrice: consultationInvoice?.payableAmount,
        clinicAddress: locationType?.address,
        slotStatus: 'confirmed',
        closeModal: undefined,
        handleBookAppointment: undefined,
        changeMode: undefined,
      });

      setTimeout(() => {
        router.push('/dashboard');
      }, 6000);
    } else if (consultationInvoice?.mode === InvoiceMode.Prepaid) {
      customRouter({
        pathname: PAGE.CONFIRMATION.ROUTE,
        query: {
          confirmation_type: 'booking',
          date: queryData?.date,
          selected_languages: queryData?.selectedLanguages,
        },
      });
    }
  }, [consultationInvoice, order?.orderConfirmed]);

  useEffect(() => {
    if (!router?.isReady || !consultationType?.consultationTypeId || consultationType?.isLoading || !locationType?.id)
      return;
    dispatch(
      BillingActions.PreviewConsultationInvoice({
        typeId: consultationType?.consultationTypeId,
        locationId: locationType?.id,
        ...(!isOffline && {
          voucherCode: queryData?.voucherCode,
        }),
        providerIds: queryData?.providerId,
        mode: InvoiceMode.Prepaid,
      }),
    );
  }, [router.isReady, consultationType?.consultationTypeId, queryData?.voucherCode, locationType?.id]);

  return (
    <MiniSchedulerComponent
      slotsData={slotsData}
      locationData={locationType}
      offlineClinicLocations={clinicLocations}
      selectedLanguages={selectedLanguages}
      theme={props.theme}
      isOffline={isOffline}
      price={{
        sellingPrice: consultationInvoicePreview.payableAmount,
        listingPrice: consultationInvoicePreview.listingAmount,
      }}
      changeLocation={onChangeLocation}
      queryData={queryData}
      handleLaunchLanguageModal={handleLaunchLanguageModal}
      handleBookAppointment={handleBookAppointment}
      isLoading={consultationType?.isLoading || schedule?.isFetchingSlots || consultationInvoice?.isLoading}
      selectedSlot={selectables.slot}
      setSelectedSlot={changeSlot}
      selectedDate={selectables.day}
      setSelectedDate={changeDay}
      handleRedirection={props.handleRedirection}
      consultationModeEligibility={consultationModeEligibility}
    />
  );
};

export default MiniScheduler;
